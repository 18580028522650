import { ScopePickerMode } from 'sharedFrontBack/src/constants/scopePicker';
import { UrtConfig } from 'sharedFrontBack/src/types/urt';

export const initialScopePickerValues: UrtConfig['scopePicker'] = {
  scopePickerMode: ScopePickerMode.basicView as const,
  projects: [],
  epics: [],
  issues: [],
  filters: [],
  jql: '',
  isParentsShouldBeFetched: false,
  isChildrenShouldBeFetched: false,
};
