const globalConfig = {
  method: 'GET',
  allPages: false,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issueLinkType',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issueLinkType',
  },
};

export { config as getIssueLinkTypes };
