import { SrInputFieldSerialized, SrInputField, SrInputFieldExact } from 'sharedFrontBack/src/types/sr';
import { SrFieldTypes } from 'sharedFrontBack/src/constants/sr';

export const deserializeField = <T extends SrFieldTypes = SrFieldTypes>(
  serializedField: SrInputFieldSerialized,
): SrInputFieldExact<T> => {
  const { defaultValue } = serializedField;

  const deserialized = (() => {
    try {
      if (!defaultValue) {
        return defaultValue;
      }
      return JSON.parse(defaultValue);
    } catch {
      // some old reports may conatin not serialized primitive values (even strings)
      return defaultValue;
    }
  })();

  return {
    ...serializedField,
    defaultValue: deserialized,
  } as unknown as SrInputFieldExact<T>;
};

export const serializeField = (field: SrInputField): SrInputFieldSerialized => {
  return {
    ...field,
    defaultValue: JSON.stringify(field.defaultValue),
  };
};