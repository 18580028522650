import { getProjectStyle } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getProjectStyle,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  server: {
    ...globalConfig,
    allPages: false,
    url: '/rest/api/2/project/',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/project/',
  },
};

export { config as getProjectStyle };
