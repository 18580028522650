export const loadingButtonSpinnerStyle = { top: 'auto', left: 'auto', height: 'auto', marginRight: '8px' };
export const flexCenterdSpinnerStyle = {
  top: 'auto',
  left: 'auto',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  flex: 1,
};
