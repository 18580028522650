import { UrtVisualizationType } from 'sharedFrontBack/src/constants/urt';
import { SortingTargets, SortingDirections } from 'sharedFrontBack/src/singletonModules/pivotopia';
import { UrtConfig } from 'sharedFrontBack/src/types/urt';
import { defaultConfig, defaultCountOfIssuesMeasure, defaultStatusDimension } from 'features/urt/constants/constants';

export const createUrtInitialConfig = (
  visualizationType: UrtVisualizationType,
  currentUrtData?: UrtConfig,
): UrtConfig => {
  switch (visualizationType) {
    case UrtVisualizationType.matrix: {
      return {
        ...(currentUrtData ?? defaultConfig),
        visualizationType,
        rowsSorting: defaultConfig.rowsSorting,
      };
    }
    case UrtVisualizationType.list: {
      return {
        ...defaultConfig,
        ...currentUrtData,
        visualizationType,
        rows: [],
        cols: currentUrtData?.cols.length ? currentUrtData.cols : [defaultStatusDimension],
        rowsSorting: [{ target: SortingTargets.index, colIndex: 0, direction: SortingDirections.asc }],
      };
    }
    case UrtVisualizationType.number: {
      return {
        ...defaultConfig,
        ...currentUrtData,
        visualizationType,
        rows: [],
        cols: [],
        measures: [defaultCountOfIssuesMeasure],
        rowsSorting: undefined,
      };
    }
    case UrtVisualizationType.barChart: {
      return {
        ...defaultConfig,
        ...currentUrtData,
        visualizationType,
        rows: [defaultStatusDimension],
        measures: [defaultCountOfIssuesMeasure],
        cols: [],
        rowsSorting: undefined,
      };
    }
    case UrtVisualizationType.pieChart: {
      return {
        ...defaultConfig,
        ...currentUrtData,
        visualizationType,
        cols: [],
        rows: [defaultStatusDimension],
        measures: [defaultCountOfIssuesMeasure],
        rowsSorting: undefined,
      };
    }
    case UrtVisualizationType.doughnutChart: {
      return {
        ...defaultConfig,
        ...currentUrtData,
        visualizationType,
        cols: [],
        rows: [defaultStatusDimension],
        measures: [defaultCountOfIssuesMeasure],
        rowsSorting: undefined,
      };
    }
    default: {
      return { ...defaultConfig, visualizationType };
    }
  }
};
