import types from 'store/legacy/timesheets/types';

export const setIsWithWeekends = (isWithWeekends) => ({
  type: types.SET_WEEKENDS,
  payload: {
    isWithWeekends,
  },
});

export const setCurrentPeriod = ({ start, end, config }) => ({
  type: types.SET_CURRENT_PERIOD,
  payload: {
    currentPeriod: {
      start,
      end,
      config,
    },
  },
});

export const setCalendarView = (calendarView) => ({
  type: types.SET_CALENDAR_VIEW,
  payload: {
    calendarView,
  },
});

export const setCalendarViewConfig = (calendarViewConfig) => ({
  type: types.SET_CALENDAR_VIEW_CONFIG,
  payload: {
    calendarViewConfig,
  },
});

export const setIsColorIssueStatus = (isColorIssueStatus) => ({
  type: types.SET_IS_COLOR_ISSUE_STATUS,
  payload: {
    isColorIssueStatus,
  },
});

export const setGroupBy = (groupBy) => {
  const filteredGroupBy = groupBy.filter((item) => item !== 'subtask');

  return {
    type: types.SET_GROUP_BY,
    payload: {
      groupBy: filteredGroupBy,
    },
  };
};

export const setSortBy = (sortBy) => ({
  type: types.SET_SORT_BY,
  payload: {
    sortBy,
  },
});

export const setDatesRange = (datesRange) => ({
  type: types.SET_DATES_RANGE,
  payload: {
    datesRange,
  },
});

export const setDefinedPeriod = (definedPeriod) => ({
  type: types.SET_DEFINED_PERIOD,
  payload: {
    definedPeriod,
  },
});
