import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { getQueryParam } from 'utils/getQueryParam';

const useGetQueryParam = () => {
  const { search } = useLocation();

  return useCallback((param: string): string => getQueryParam(search, param), [search]);
};

export default useGetQueryParam;
