import { searchFilters } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: searchFilters,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/gadget/1.0/pickers/projectsAndFilters?fieldName=quickfind&',
    fieldName: 'filters',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/filter/search',
    maxResults: 10,
    fieldName: 'values',
  },
};

export { config as searchFilters };
