import { getAllUsers } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: false,
  migration: getAllUsers,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/user/search?username=.',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/latest/user/search?query=',
  },
};

export { config as getAllUsers };
