import produce from 'immer';
import types from 'store/legacy/scriptedReportsConfiguration/types';

const initialState = {
  pdfOptions: {
    pageSize: 'A4',
  },
  pdfDoc: null,
};

export const scriptedReportsConfiguration = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.SET_PDF_OPTIONS: {
      const { pdfOptions = initialState.pdfOptions } = action.payload;

      return { ...state, pdfOptions };
    }

    case types.SET_PDF_DOC: {
      const { pdfDoc = initialState.pdfDoc } = action.payload;

      return { ...state, pdfDoc };
    }

    case types.SET_DEFAULT_REPORTS_CONFIGURATION: {
      return initialState;
    }

    default:
      return state;
  }
});
