import types from 'store/legacy/app/types';

export const getFieldsSuccess = ({ fieldsList, areSoftwareFieldsInitialized }) => ({
  type: types.GET_FIELDS_SUCCESS,
  payload: {
    fieldsList,
    areSoftwareFieldsInitialized,
  },
});

export const getFieldsFailure = (error) => ({
  type: types.GET_FIELDS_FAILURE,
  payload: error,
});

export const setFieldsOptions = ({ fieldsOptions, jiraSoftwareFields }) => ({
  type: types.SET_FIELDS_OPTIONS,
  payload: {
    fieldsOptions,
    jiraSoftwareFields,
  },
});

export const setCurrentModule = (currentModule) => ({
  type: types.SET_CURRENT_MODULE,
  payload: {
    currentModule,
  },
});

export const setTimeTrackingUnit = (timeTrackingUnit) => ({
  type: types.SET_TIME_TRACKING_UNIT,
  payload: {
    timeTrackingUnit,
  },
});

export const setIsCompletionPanelVisible = (isCompletionPanelVisible) => ({
  type: types.SET_IS_COMPLETION_PANEL_VISIBLE,
  payload: {
    isCompletionPanelVisible,
  },
});

export const setIsTotalPanelVisible = (isTotalPanelVisible) => ({
  type: types.SET_IS_TOTAL_PANEL_VISIBLE,
  payload: {
    isTotalPanelVisible,
  },
});

export const setLocale = (locale) => ({
  type: types.SET_LOCALE,
  payload: {
    locale,
  },
});

export const setIsUnapprovedReportsEnabled = (isUnapprovedReportsEnabled) => ({
  type: types.SET_IS_UNAPPROVED_REPORTS_ENABLED,
  payload: { isUnapprovedReportsEnabled },
});

export const setGalleryViewType = (galleryViewType) => ({
  type: types.SET_GALLERY_VIEW_TYPE,
  payload: { galleryViewType },
});

export const setReportsListSortBy = (reportsListSortBy) => ({
  type: types.SET_REPORTS_LIST_SORT_BY,
  payload: { reportsListSortBy },
});
