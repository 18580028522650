import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FlagType } from 'types/store/flag';
import { selectFlags } from 'store/slices/flag/selectors';
import { removeFlag } from 'store/slices/flag/slice';

import InfoIcon from '@atlaskit/icon/glyph/info';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';

import { token } from '@atlaskit/tokens';
import { useFlags } from '@atlaskit/flag';

const getFlagIcon = (type: FlagType) => {
  switch (type) {
    case FlagType.info:
      return (
        <InfoIcon
          label='Info'
        />
      );
    case FlagType.warning:
      return (
        <WarningIcon
          label='Warning'
          primaryColor={token('color.background.warning.bold')}
        />
      );
    case FlagType.success:
      return (
        <SuccessIcon
          label='Success'
          primaryColor={token('color.background.success.bold')}
        />
      );

    default:
      return (
        <ErrorIcon
          label='Error'
          primaryColor={token('color.background.danger.bold')}
        />
      );
  }
};

export const FlagManager = () => {
  const dispatch = useDispatch();
  const flags = useSelector(selectFlags);

  const { showFlag } = useFlags();

  useEffect(() => {
    flags.forEach(({ id, title, description, type }) => {
      showFlag({
        id,
        isAutoDismiss: true,
        title: title || null,
        description: description || null,
        icon: getFlagIcon(type || FlagType.error),
      });

      setTimeout(() => dispatch(removeFlag(id))); // We need to wrap dispatch in setTimeout because changing the flags before forEach process completes may be unstable
    });
  }, [dispatch, flags, showFlag]);

  return null;
};