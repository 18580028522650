import React, { createContext, useRef, useMemo, RefObject } from 'react';
import { RequestLoadingCallback } from 'store/api/jira/baseApi/types';
import { ReactFC } from 'types/Global';
import usePersistentCallback from 'hooks/usePersistentCallback';

export type AbortRequest = () => void;
export type AbortRequestRef = RefObject<AbortRequest | null>;
export type RegisterAbortRequest = (abortRequest: AbortRequest) => any; // eslint-disable-line @typescript-eslint/no-explicit-any

interface PaginatedRequestContextValue {
  loadingProgressAggregatedHandler: RequestLoadingCallback;
  addLoadingHandler: (handler: RequestLoadingCallback) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  removeLoadingHandler: (handler: RequestLoadingCallback) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  registerAbortRequest: RegisterAbortRequest;
  abortRequestRef?: AbortRequestRef;
}

export const PaginatedRequestContext = createContext<PaginatedRequestContextValue>({
  loadingProgressAggregatedHandler: () => {
    throw new Error('Do not use loadingProgressAggregatedHandler without PaginatedRequestContextProvider');
  },
  addLoadingHandler: () => {
    throw new Error('Do not use addLoadingHandler without PaginatedRequestContextProvider');
  },
  removeLoadingHandler: () => {
    throw new Error('Do not use removeLoadingHandler without PaginatedRequestContextProvider');
  },
  registerAbortRequest: () => {
    throw new Error('Do not use removeLoadingHandler without PaginatedRequestContextProvider');
  },
});

export const PaginatedRequestContextProvider: ReactFC = ({ children }) => {
  const loadingHandlersRef = useRef<RequestLoadingCallback[]>([]);

  const addLoadingHandler = usePersistentCallback(
    (handler: RequestLoadingCallback) => loadingHandlersRef.current.push(handler),
  );
  const removeLoadingHandler = usePersistentCallback(
    (handler: RequestLoadingCallback) => {
      const handlerIndex = loadingHandlersRef.current.findIndex((h) => h === handler);
      // eslint-disable-next-line no-bitwise
      if (~handlerIndex) {
        loadingHandlersRef.current.splice(handlerIndex, 1);
      }
    },
  );

  const loadingProgressAggregatedHandler = usePersistentCallback<RequestLoadingCallback>((state) => {
    loadingHandlersRef.current.forEach((handler) => handler(state));
  });

  const abortRequestRef = useRef<AbortRequest | null>(null);

  const context = useMemo<PaginatedRequestContextValue>(() => ({
    loadingProgressAggregatedHandler,
    addLoadingHandler,
    removeLoadingHandler,
    abortRequestRef,
    registerAbortRequest: (abortRequestNext: AbortRequest) => {
      abortRequestRef.current = abortRequestNext;
    },
  }), [addLoadingHandler, loadingProgressAggregatedHandler, removeLoadingHandler]);

  return (
    <PaginatedRequestContext.Provider value={context}>
      {children}
    </PaginatedRequestContext.Provider>
  );
};