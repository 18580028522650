import { getFilters } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getFilters,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/gadget/1.0/pickers/projectsAndFilters?fieldName=quickfind',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/filter/my',
    maxResults: 10,
  },
};

export { config as getFilters };
