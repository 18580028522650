export default {
  SET_CURRENT_MODULE: 'SET_CURRENT_MODULE',
  SET_TIME_TRACKING_UNIT: 'SET_TIME_TRACKING_UNIT',
  SET_IS_COMPLETION_PANEL_VISIBLE: 'SET_IS_COMPLETION_PANEL_VISIBLE',
  SET_IS_TOTAL_PANEL_VISIBLE: 'SET_IS_TOTAL_PANEL_VISIBLE',
  SET_IS_UNAPPROVED_REPORTS_ENABLED: 'SET_IS_UNAPPROVED_REPORTS_ENABLED',
  SET_GALLERY_VIEW_TYPE: 'SET_GALLERY_VIEW_TYPE',
  SET_CURRENT_THEME: 'SET_CURRENT_THEME',
  SET_LOCALE: 'SET_LOCALE',

  GET_PROJECTS_KEYS_SUCCESS: 'GET_PROJECTS_KEYS_SUCCESS',
  GET_PROJECTS_KEYS_FAILURE: 'GET_PROJECTS_KEYS_FAILURE',

  GET_FIELDS_SUCCESS: 'GET_FIELDS_SUCCESS',
  GET_FIELDS_FAILURE: 'GET_FIELDS_FAILURE',

  GET_CONFIG_STARTED: 'GET_CONFIG_STARTED',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE: 'GET_CONFIG_FAILURE',

  SET_CONFIG_STARTED: 'SET_CONFIG_STARTED',
  SET_CONFIG_SUCCESS: 'SET_CONFIG_SUCCESS',
  SET_CONFIG_FAILURE: 'SET_CONFIG_FAILURE',

  SET_USERS_LIST: 'SET_USERS_LIST',

  SET_FIELDS_OPTIONS: 'SET_FIELDS_OPTIONS',

  SET_REPORTS_LIST_SORT_BY: 'SET_REPORTS_LIST_SORT_BY',
};
