import { NotificationController } from 'controllers/notification';
import { TransportController } from 'controllers/transport';
import { WorklogController } from 'controllers/WorklogController';
import { fieldControllerService } from 'controllers/field';
import { issueService } from 'controllers/issue';
import { CookieController } from 'controllers/cookie';

import { API } from 'controllers/API';

export { // todo we should not not use this pattern (exporting & importing everything via index.ts) because 1. it is hard to find usages 2. it is impossible to lazy load separate modules (one file is a module, not a folder)
  API,
  NotificationController,
  TransportController,
  WorklogController,
  issueService,
  fieldControllerService,
  CookieController,
};
