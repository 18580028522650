import { Store, configureStore, AnyAction } from '@reduxjs/toolkit';
import { combineReducers, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createThunkMiddleware } from 'actonic-controllers/dist/redux/createThunkMiddleware';
import { adminConfigSlice } from 'store/slices/adminConfig/slice';
import { logError } from 'utils/sentry';
import { onboardingSlice } from 'features/spotlight/store/slices/onboarding/onboardingSlice';
import { urtReportSlice } from 'store/slices/urtReport/slice';
import { user } from 'store/legacy/user/reducer';
import { app } from 'store/legacy/app/reducer';
import { filterBySelector } from 'store/legacy/filterBySelector/reducer';
import { reports } from 'store/legacy/reports/reducer';
import { timesheets } from 'store/legacy/timesheets/reducer';
import { configuration } from 'store/legacy/configuration/reducer';
import { galleryReports } from 'store/legacy/galleryReports/reducer';
import { favoriteReports } from 'store/legacy/favoriteReports/reducer';
import { scriptedReportsConfiguration } from 'store/legacy/scriptedReportsConfiguration/reducer';
import { project } from 'store/legacy/project/reducer';
import { progressBar } from 'store/legacy/progressBar/reducer';
import { pluginBaseApi } from 'store/api/plugin/baseApi/pluginBaseApi';
import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { appEnvironmentSlice } from 'store/slices/appEnvironment/slice';
import { pluginTmBaseApi } from 'store/api/pluginTm/baseApi/pluginTmBaseApi';
import { enableMapSet } from 'immer';
import { flagSlice } from './slices/flag/slice';

enableMapSet();

const reducers = {
  user,
  app,
  filterBySelector,
  reports,
  timesheets,
  configuration,
  galleryReports,
  favoriteReports,
  scriptedReportsConfiguration,
  project,
  progressBar,

  [pluginBaseApi.reducerPath]: pluginBaseApi.reducer,
  [pluginTmBaseApi.reducerPath]: pluginTmBaseApi.reducer,
  [jiraBaseApi.reducerPath]: jiraBaseApi.reducer,
  [appEnvironmentSlice.name]: appEnvironmentSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [adminConfigSlice.name]: adminConfigSlice.reducer,
  [urtReportSlice.name]: urtReportSlice.reducer,
  [flagSlice.name]: flagSlice.reducer,
};
const rootReducer = combineReducers(reducers);

type StateFromReducersMapObject<M> = M extends Record<any, any> // eslint-disable-line @typescript-eslint/no-explicit-any
  ? { [P in keyof M]: M[P] extends (state: infer S, ...args: any[]) => any ? (S extends undefined ? never : S) : never } // eslint-disable-line @typescript-eslint/no-explicit-any
  : never;

export type RootState = StateFromReducersMapObject<typeof reducers>;
export type GetState = () => RootState;

const thunkMiddleware = createThunkMiddleware<RootState>({
  onThunkRejection: async (e: unknown) => logError('SentryThunkMiddleware', e),
});

const store: Store<RootState> = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    thunkMiddleware,
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: true,
      serializableCheck: false,
    }),
    pluginBaseApi.middleware,
    pluginTmBaseApi.middleware,
    jiraBaseApi.middleware,
  ],
});

type PatchedThunkDispatch<S, E, A extends Action> = ( // we use middleware that makes converts all thunk dispatch to return promises
  <AA>(asyncAction: AA) => AA extends (...args: never[]) => infer R ? (
    R extends Promise<never> ? R : Promise<R>
  ) : never
) & ThunkDispatch<S, E, A>;

export type AppThunkAction = AnyAction;
export type AppDispatch = PatchedThunkDispatch<RootState, void, AppThunkAction>;

type AppStore = typeof store & { dispatch: AppDispatch };

export type Thunk<ThunkPayload = undefined, ThunkResult = void> = (
  payload: ThunkPayload,
) => (dispatch: AppDispatch, getState: GetState) => ThunkResult;

export default store as AppStore;
