import axios from 'axios';
import { getJwtToken } from 'utils/countWorklogs';
import { getContextPath } from 'utils/getContextPath';
import { mockAPI } from 'utils/mockRequests';

class TransportControllerForPrivateAPI {
  constructor() {
    this.transport = axios.create({
      baseURL: (() => {
        if (IS_CLOUD) {
          return `${window.baseUrl}/rest/api`;
        }

        return `${getContextPath()}/rest/worklog-pivot/1.0/api`;
      })(),
      timeout: 900000,
    });

    this.transport.interceptors;

    this.transport.interceptors.request.use(async (config) => {
      if (process.env.NODE_ENV === 'test') return config;

      if (process.env.JIRA_TYPE === 'cloud') {
        const token = await getJwtToken();
        config.headers.Authorization = `JWT ${token}`; // eslint-disable-line no-param-reassign
      }

      return config;
    }, (err) => Promise.reject(err));

    return this.transport; // eslint-disable-line no-constructor-return
  }
}

const API = process.env.IS_DEV_SERVER ? mockAPI : new TransportControllerForPrivateAPI();

export {
  API,
};
