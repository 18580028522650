import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Flag, FlagDto } from 'types/store/flag';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  flags: [] as Array<FlagDto>,
};

export const flagSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    addFlag: (state, { payload }: PayloadAction<Flag>) => {
      return {
        ...state,
        flags: [
          ...state.flags,
          {
            id: uuidv4(),
            ...payload,
          },
        ],
      };
    },
    removeFlag: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        flags: state.flags.filter(({ id }) => id !== payload),
      };
    },
  },
});

export const {
  addFlag,
  removeFlag,
} = flagSlice.actions;
