import { formatDateForTable } from 'utils/dates/formatDates';
import { getKeysForCurrentLog } from 'features/legacy/groupsLegacy/groupHelpers';
import { formatDataByTarget } from 'pages/ReportLegacyPage/ReportViews/UniversalReport/formatTargetData';
import { extendWithWorklogs } from 'store/legacy/timesheets/extendWithWorklogs';

const getCurrentLogIssueKey = (isIncludesSubtask, currentLog) => (isIncludesSubtask && currentLog.parent)
  ? currentLog.parentKey
  : currentLog.issueKey;

export const getColumnNameForComparison = (currentColumnName, columnKey) => {
  const isDateColumn = ['worklogDate'].includes(columnKey) && currentColumnName !== 'Total';

  const dateColumnName = formatDateForTable(new Date(currentColumnName));

  const columnNameForComparison = isDateColumn ? dateColumnName : currentColumnName;

  return columnNameForComparison;
};

export const extendForUniversalReport = (
  reportData, aggregationData,
) => (currentLog, elementForUpdate) => {
  const { isIncludesSubtask, columns } = reportData;
  const { aggregationType, aggregationTarget, dataType } = aggregationData;

  extendWithWorklogs()(currentLog, elementForUpdate);

  if (!elementForUpdate.info) {
    elementForUpdate.info = {
      // This property will be deleted after aggregation success
      raw: {},
    };
  }

  if (elementForUpdate.info && !elementForUpdate.info.raw) {
    elementForUpdate.info.raw = {};
  }

  const columnKey = columns[0];

  if (aggregationType && aggregationTarget) {
    const columnsNames = elementForUpdate.info.raw.columnsNames || new Set();

    const dataKeyForColumn = getKeysForCurrentLog()[columnKey];

    // IMPORTANT Column name could be array, for example component. In this case we need to add info in every column that this element has
    const columnName = dataKeyForColumn
      ? currentLog[dataKeyForColumn]
      : currentLog[columnKey];

    const dateColumnName = Object.prototype.toString.call(columnName) === '[object Date]'
      ? formatDateForTable(new Date(columnName))
      : null;

    const arrayColumnName = Array.isArray(columnName) ? columnName.join('_') : null;

    // Fix problem with toLowerCase when columnName is number
    const columnNameStringified = (arrayColumnName || dateColumnName || columnName)
      ? `${(arrayColumnName || dateColumnName || columnName)}`
      : null;

    const currentIssueKey = getCurrentLogIssueKey(isIncludesSubtask, currentLog);
    const currentWorklogId = currentLog.worklogId;

    const addDataToInfoColumn = (currentColumnName) => {
      const columnNameForComparison = getColumnNameForComparison((currentColumnName || 'empty'), columnKey);

      const data = {
        elementForUpdate,
        columnName,
        currentLog,
        currentIssueKey,
        columnNameForComparison,

        isIncludesSubtask,
      };

      const formattedData = formatDataByTarget(aggregationTarget, data);

      const { prevIssueKey } = elementForUpdate.info.raw;

      const oldValue = elementForUpdate.info.raw[columnNameForComparison];

      // Here we check if this aggregation calculate issue field, if so then we need to calculate it only once
      // eslint-disable-next-line no-empty
      if (prevIssueKey && prevIssueKey === currentIssueKey && dataType === 'issues' && aggregationTarget !== 'loggedTime') {
      } else if (oldValue) {
        elementForUpdate.info.raw[columnNameForComparison] = [...oldValue, ...formattedData];
      } else {
        elementForUpdate.info.raw[columnNameForComparison] = formattedData;
      }

      const formattedColumnName = columnNameStringified ? columnNameStringified.toLowerCase()
        .replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\s/gi, '_') : 'empty';

      if (currentIssueKey) {
        elementForUpdate.info[`${formattedColumnName}_issues`] = elementForUpdate.info[`${formattedColumnName}_issues`]
          ? [...elementForUpdate.info[`${formattedColumnName}_issues`], currentIssueKey]
          : [currentIssueKey];
        elementForUpdate.info.total_issues = elementForUpdate.info.total_issues
          ? [...elementForUpdate.info.total_issues, currentIssueKey]
          : [currentIssueKey];
      }

      if (currentWorklogId) {
        elementForUpdate.info[`${formattedColumnName}_worklogs`] = elementForUpdate.info[`${formattedColumnName}_worklogs`]
          ? [...elementForUpdate.info[`${formattedColumnName}_worklogs`], currentWorklogId]
          : [currentWorklogId];
        elementForUpdate.info.total_worklogs = elementForUpdate.info.total_worklogs
          ? [...elementForUpdate.info.total_worklogs, currentWorklogId]
          : [currentWorklogId];
      }
    };

    if (Array.isArray(columnName)) {
      columnName.forEach((columnNameItem) => {
        columnsNames.add(columnNameItem);

        addDataToInfoColumn(columnNameItem);
      });
    } else {
      columnsNames.add(columnName);
      addDataToInfoColumn(columnName);
    }

    elementForUpdate.info.raw.prevIssueKey = currentIssueKey;

    elementForUpdate.info.raw.columnsNames = columnsNames;
  }
};
