import produce from 'immer';
import { galleryViewTypes } from 'constants/constants';

import types from 'store/legacy/app/types';
import { defaultReportsListSortBy } from 'constants/report';

const initialState = {
  currentModule: 'calendar',
  reportsCurrentModule: 'dashboard',
  isConfigSettled: false,
  isSettingConfigLoading: false,

  isConfigFetched: false,
  isConfigFetching: false,

  timeTrackingUnit: 'hours',
  isCompletionPanelVisible: true,
  isTotalPanelVisible: true,
  isUnapprovedReportsEnabled: false,

  fieldsList: [],
  fieldsOptions: [],

  jiraSoftwareFields: [],

  usersList: [],
  locale: 'en',

  areSoftwareFieldsInitialized: false,

  galleryViewType: galleryViewTypes.groups,

  reportsListSortBy: defaultReportsListSortBy,
};

export const app = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.SET_CURRENT_MODULE: {
      const { currentModule } = action.payload;

      return { ...state, currentModule };
    }

    case types.SET_CONFIG_STARTED:
      return {
        ...state,
        isSettingConfigLoading: true,
      };

    case types.SET_CONFIG_FAILURE:
      return {
        ...state,
        isSettingConfigLoading: false,
        isConfigSettled: true,
        error: action.error,
      };

    case types.SET_CONFIG_SUCCESS:
      return {
        ...state,
        isSettingConfigLoading: false,
        isConfigSettled: true,
      };

    case types.GET_CONFIG_STARTED:
      return {
        ...state,
        isConfigFetching: true,
      };

    case types.GET_CONFIG_FAILURE:
      return {
        ...state,
        isConfigFetching: false,
        isConfigFetched: true,
        error: action.error,
      };

    case types.GET_CONFIG_SUCCESS:
      return {
        ...state,
        isConfigFetching: false,
        isConfigFetched: true,
      };

    case types.SET_TIME_TRACKING_UNIT: {
      const { timeTrackingUnit } = action.payload;

      return { ...state, timeTrackingUnit };
    }

    case types.SET_IS_COMPLETION_PANEL_VISIBLE: {
      const { isCompletionPanelVisible } = action.payload;

      return { ...state, isCompletionPanelVisible };
    }

    case types.SET_IS_TOTAL_PANEL_VISIBLE: {
      const { isTotalPanelVisible } = action.payload;

      return { ...state, isTotalPanelVisible };
    }

    case types.SET_IS_UNAPPROVED_REPORTS_ENABLED: {
      const { isUnapprovedReportsEnabled } = action.payload;

      return { ...state, isUnapprovedReportsEnabled };
    }

    case types.SET_GALLERY_VIEW_TYPE: {
      const { galleryViewType } = action.payload;

      return { ...state, galleryViewType };
    }

    case types.GET_FIELDS_SUCCESS:
      return {
        ...state,
        fieldsList: action.payload.fieldsList,
        areSoftwareFieldsInitialized: action.payload.areSoftwareFieldsInitialized,
      };

    case types.GET_FIELDS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case types.SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };

    case types.SET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload.usersList,
      };

    case types.SET_FIELDS_OPTIONS: {
      const { fieldsOptions, jiraSoftwareFields } = action.payload;

      return {
        ...state,
        fieldsOptions,
        jiraSoftwareFields,
      };
    }

    case types.SET_REPORTS_LIST_SORT_BY: {
      const { reportsListSortBy } = action.payload;

      return { ...state, reportsListSortBy };
    }

    default:
      return state;
  }
});
