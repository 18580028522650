import { formatDateForTable } from 'utils/dates/formatDates';

export const extendWithWorklogs = () => (currentLog, elementForUpdate) => {
  const { loggedTime, startDate, resolved } = currentLog;
  const { dates = { total: 0 }, info = {} } = elementForUpdate;

  const formattedStartDate = formatDateForTable(startDate);

  if (dates[formattedStartDate]) {
    dates[formattedStartDate] += loggedTime;
  } else {
    dates[formattedStartDate] = loggedTime;
  }

  dates.total += loggedTime;

  if (elementForUpdate._cellType === 'issue') {
    info.resolved = resolved;
  }

  elementForUpdate.dates = dates;
  elementForUpdate.info = info;
};
