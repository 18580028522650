const globalConfig = {
  method: 'POST',
  allPages: false,
  fieldName: 'projects',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  server: {},
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/permissions/project',
  },
};

export { config as getProjectsKeys };
