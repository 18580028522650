import { moment } from 'controllers/moment';
import { createIssueUrl, createUserUrl } from 'utils/jiraUrl';
import { calculateRemainingProgress } from 'utils/countWorklogs';
import { getPrettyPrintedTime } from 'utils/formatTime';
import { getCustomFieldValue } from 'controllers/reportMigrations/getCustomFieldValue';
import store from 'store';

import { getUserId } from 'controllers/reportMigrations/formatIssueTableData';
import { getEpicKey } from 'controllers/reportMigrations/migrationHelpers';
import { getWorklogComment } from 'controllers/WorklogController/getWorklogComment';

import { formatDataByFieldType } from 'controllers/WorklogController/formatDataByFieldType';

export const WORKLOGS_TYPE = {
  default: 'default',
  secure: 'secure',
};

export const getSprintName = (issueFields, sprintDataField) => {
  if (issueFields[sprintDataField] && issueFields[sprintDataField][0]) {
    return issueFields[sprintDataField][0].name;
  }
  return null;
};

export const formatWorklogData = (issue, worklog, formatOptions, customFieldsOptions, fieldDataByFieldId) => {
  const isCloud = process.env.JIRA_TYPE === 'cloud';
  const { isWorklogWithProperties } = formatOptions;

  const customFieldsKeysMapToType = customFieldsOptions.reduce((acc, { id, schema }) => {
    acc[id] = schema.type;

    return acc;
  }, {});

  const formattedCustomFields = Object.entries(issue.fields).reduce((acc, [key, value]) => {
    if (customFieldsKeysMapToType[key]) {
      const fieldType = customFieldsKeysMapToType[key];

      acc[key] = getCustomFieldValue(value, fieldType);
    }

    return acc;
  }, {});

  const { jiraSoftwareFields } = store.getState().app;

  const epicKeyField = jiraSoftwareFields.epic?.id;
  const epicNameField = jiraSoftwareFields.epicName?.id;
  const epicStatusKeyField = jiraSoftwareFields.epicStatus?.id;

  const worklogStartedMoment = moment(new Date(worklog.started));

  const worklogId = (() => {
    if (!isCloud && isWorklogWithProperties && worklog.worklogId) {
      return worklog.worklogId;
    }

    return worklog.id;
  })();

  const data = {
    started: worklog.started,
    author: worklog.author,

    startDate: new Date(worklog.started),

    issueKey: issue.key,
    parentKey: issue.fields.parent ? issue.fields.parent.key : null,

    parentData: issue.fields.parent ? { ...issue.fields.parent } : null,
    parentName: issue.fields.parent ? issue.fields.parent.fields.summary : null,
    parentUrl: issue.fields.parent ? createIssueUrl(issue.fields.parent.key) : null,
    parentIcon: issue.fields.parent ? issue.fields.parent.fields.issuetype.iconUrl : null,

    issueIcon: issue.fields.issuetype.iconUrl,
    issueUrl: createIssueUrl(issue.key),
    issueStatus: issue.fields.status ? issue.fields.status.name : null,
    issueType: issue.fields.issuetype.name,
    issueName: issue.fields.summary,
    isSecure: worklog?.worklogType?.toLowerCase() === WORKLOGS_TYPE.secure,

    loggedTime: worklog.timeSpentSeconds,

    epicKey: getEpicKey({ epicKeyField, epicNameField }, issue),
    epicName: issue.fields[epicNameField] ? issue.fields[epicNameField] : issue.fields?.parent?.fields?.summary,
    epicStatus: issue.fields[epicStatusKeyField],
    epicLink: null,
    epicIcon: issue.fields.issuetype.iconUrl,

    sprintName: getSprintName(issue.fields, formatOptions.sprintKeyField),

    worklogWeek: `Week ${worklogStartedMoment.isoWeek()}`,
    worklogMonth: worklogStartedMoment.format('MMM YY'),
    worklogQuarter: `Q${worklogStartedMoment.quarter()} ${worklogStartedMoment.format('YY')}`,
    worklogYear: worklogStartedMoment.year(),

    worklogWeekWithYear: `${moment(new Date(worklog.started)).year()}-${moment(new Date(worklog.started)).isoWeek()}`,
    worklogMonthWithYear: `${moment(new Date(worklog.started)).year()}-${moment(new Date(worklog.started)).month() + 1}`,
    worklogQuarterWithYear: `${moment(new Date(worklog.started)).year()}-${moment(new Date(worklog.started)).quarter()}`,

    worklogId,
    worklogAuthor: worklog.author ? worklog.author.displayName : null,
    worklogAuthorId: worklog.author?.accountId || worklog.author?.key || '',
    worklogAuthorIcons: worklog.author?.avatarUrls,
    worklogAuthorUrl: createUserUrl(worklog.author?.accountId || worklog.author?.name || ''),
    worklogDescription: getWorklogComment(worklog.comment, worklog.id),
    progressRemaining: calculateRemainingProgress(issue.fields.timespent, issue.fields.timeestimate),

    ...formattedCustomFields,

    // Fields for AddLogTimeModal, could be removed after component refactoring
    issueId: issue.id,
    projectId: issue.fields.project.id,
    loggedTimeText: worklog.timeSpent,
    timeOriginalEstimate: issue.fields.timeoriginalestimate,
    timeSpent: issue.fields.timespent,
    timeEstimate: issue.fields.timeestimate,
    originalEstimate: issue.fields.aggregatetimeoriginalestimate,
    remainingEstimate: issue.fields.timeestimate
      ? getPrettyPrintedTime(issue.fields.timeestimate).trim()
      : null,

    // TODO: fix problem with getting data from columns - assignee and reporter
    issueAssigneeId: issue.fields.assignee ? getUserId(issue.fields, 'assignee') : null,
    issueReporterId: issue.fields.reporter ? getUserId(issue.fields, 'reporter') : null,

    // TODO: delete and show user name by logic
    issueAssignee: issue.fields.assignee ? issue.fields.assignee.displayName : null,
    issueReporter: issue.fields.reporter ? issue.fields.reporter.displayName : null,
    teamProperty: Array.isArray(worklog.properties) && worklog.properties.find(({ key }) => key === 'teamValue'),
    properties: worklog.properties,
    ...(fieldDataByFieldId ? formatDataByFieldType(issue.fields, fieldDataByFieldId, issue.key) : {}),
  };

  return data;
};
