import { QueryParams } from 'types/queryParams';

export const createQueryString = (params: QueryParams, withQuestionMark = true): string => (
  params ? Object.entries(params).reduce(
    (acc, [key, value], index) => {
      if (index === 0) {
        acc += `${withQuestionMark ? '?' : ''}${key}=${value}`;
      } else {
        acc += `&${key}=${value}`;
      }

      return acc;
    },
    '',
  ) : ''
);
