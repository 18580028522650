export const selectFilterBy = ({ filterBySelector }) => filterBySelector;
export const selectFullJQL = ({ filterBySelector }) => filterBySelector.fullJQL;

export const selectFilterValues = ({ filterBySelector }) => filterBySelector.filterValues;
export const selectEpicValues = ({ filterBySelector }) => filterBySelector.epicValues;
export const selectIssueValues = ({ filterBySelector }) => filterBySelector.issueValues;

export const selectProjectValues = ({ filterBySelector }) => filterBySelector.projectValues;
export const selectTeamValues = ({ filterBySelector }) => filterBySelector.teamValues;
export const selectUserValues = ({ filterBySelector }) => filterBySelector.userValues;

export const selectIsFilterApplied = ({ filterBySelector }) => filterBySelector.isFilterApplied;

export const selectJqlField = ({ filterBySelector }) => filterBySelector.jqlField;

