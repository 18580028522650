import { createQueryString } from 'utils/createQueryString';
import { pluginBaseApi } from 'store/api/plugin/baseApi/pluginBaseApi';
import { DashboardTabs } from 'constants/constants';
import { AnyReportDto } from 'sharedFrontBack/src/types/report';
import { GalleryReportDto } from 'sharedFrontBack/src/types/galleryReport';

export interface GetReportsQueryParams {
  dashboardType: string;
  search: string;
  limit: number;
  page: number;
  isOnlyFavorites: boolean;
  sort?: string;
  order?: number;
}

export const anyReportTagType = 'anyReportTagType' as const;

export const createGetReportsTags = (dashboardType: string) => ([{ type: anyReportTagType, id: dashboardType }]);

export const reportApi = pluginBaseApi.enhanceEndpoints({
  addTagTypes: [anyReportTagType],
})
  .injectEndpoints({
    endpoints: (builder) => ({
      getReportById: builder.query<AnyReportDto, { id: string; sharedId?: string }>({
        query: ({ id, ...qs }) => `reports/${id}${createQueryString(qs)}`,
        providesTags: [anyReportTagType],
      }),
      getReports: builder.query<{ reports: AnyReportDto[]; total: number }, GetReportsQueryParams>({
        query: (query) => `reports${createQueryString(query)}`,
        providesTags: (result, error, { dashboardType }) => createGetReportsTags(dashboardType),
      }),
      createReport: builder.mutation<AnyReportDto, AnyReportDto>({
        query: (dto) => ({
          url: 'reports',
          method: 'POST',
          body: dto,
        }),
        invalidatesTags: (result, error) => (
          error ? [] : [
            ...createGetReportsTags(DashboardTabs.myReports),
            anyReportTagType,
          ]
        ),
      }),
      updateReport: builder.mutation<AnyReportDto, AnyReportDto>({
        query: (dto) => ({
          url: `reports/${dto._id}`,
          method: 'PUT',
          body: dto,
        }),
        async onQueryStarted(data, { dispatch, queryFulfilled }) {
          const id = data._id;
          if (!id) {
            return;
          }
          const patchResult = dispatch(
            reportApi.util.updateQueryData('getReportById', { id: String(id) }, (draft) => {
              Object.assign(draft, data);
            }),
          );
          const response = await queryFulfilled.catch(patchResult.undo);
          if (response?.data) {
            dispatch(
              reportApi.util.updateQueryData('getReportById', { id: String(id) }, (draft) => {
                Object.assign(draft, response?.data);
              }),
            );
          }
        },
        invalidatesTags: (result, error) => (
          error ? [] : [
            ...createGetReportsTags(DashboardTabs.myReports),
            ...createGetReportsTags(DashboardTabs.shared),
          ]
        ),
      }),
      deleteReport: builder.mutation<{ msg: string }, string>({
        query: (reportId) => ({
          url: `reports/${reportId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error) => (
          error ? [] : createGetReportsTags(DashboardTabs.myReports)
        ),
      }),
    }),
  });

export const galleryReportApi = pluginBaseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      getGalleryReportByIdOrKey: builder.query<GalleryReportDto, { idOrKey: string }>({
        query: ({ idOrKey }) => `scriptedreports/gallery/${idOrKey}`,
      }),
    }),
  });