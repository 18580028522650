import {
  CreateReportDtoPayload,
  CreateScriptedReportDtoPayload,
  CreateUniversalReportDtoPayload,
} from 'utils/reportDto.types';
import {
  checkIsReportUniversalCompatible,
  checkIsReportScriptedCompatible,
} from 'utils/checkIsUniversalCompatible';
import { selectCurrentReport } from 'store/legacy/reports/selectors';

import { AnyReportDto, UniversalReportDto, ScriptedReportDto, BaseReport } from 'sharedFrontBack/src/types/report';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { getDefaultPermissions } from 'utils/getDefaultPermissions';
import { serializeField } from 'features/sr/utils/serializeFields';

export const createUniversalReportDto = ({ getState }: CreateUniversalReportDtoPayload): UniversalReportDto => {
  const currentReport = selectCurrentReport(getState());

  if (!checkIsReportUniversalCompatible(currentReport)) {
    throw new Error('Current report is not universal report');
  }

  const {
    id, type, category, columns,
    datesRange, groupBy, sortBy,
    name = '', permissions, isIncludesSubtask,
    isParentsShouldBeFetched, isChildrenShouldBeFetched, updatedOwner = null,
    dataType, reportViewType, aggregationType, aggregationTarget, filters,
    timeTrackingUnit, colorTheme,
    reportExportVisibility,
    sources, calendarView, isWithWeekends,
  } = currentReport;

  const {
    projectValues, epicValues, issueValues, userValues,
    teamValues, filterValues, jqlField,
  } = getState().filterBySelector;

  const filterBy = [
    ...projectValues, ...epicValues, ...issueValues,
    ...userValues, ...teamValues, ...filterValues,
    ...jqlField,
  ];

  return {
    _id: id ?? '',
    permissions: permissions || getDefaultPermissions(),
    type,
    category: category as ReportCategory.universalReport,
    name: name.trim(),
    groupBy,
    sortBy,
    columns,
    filterBy,
    colorTheme,
    dateRange: {
      start: datesRange.startDate.getTime(),
      relativeDate: datesRange.relativeDate,
      end: datesRange.endDate.getTime(),
    },
    updatedOwner,
    sources,
    calendarView,
    isWithWeekends,
    isIncludesSubtask,
    timeTrackingUnit,
    isParentsShouldBeFetched,
    isChildrenShouldBeFetched,
    dataType,
    reportViewType,
    aggregationType,
    aggregationTarget,
    reportExportVisibility,
    exclusions: filters || [],
  };
};

export const createScriptedReportDto = ({ getState }: CreateScriptedReportDtoPayload): ScriptedReportDto => {
  const currentReport = selectCurrentReport(getState());

  if (!checkIsReportScriptedCompatible(currentReport)) {
    throw new Error('Current report is not scripted or scriptedFromGallery');
  }

  const {
    inputFields: { fieldsList: fieldsListCandidate },
    id,
    galleryReportId,
    category,
    version,
    name,
    key,
    tags,
    icon,
    docsLink,
    description,
    template,
    script,
    permissions,
    reportExportVisibility,
    externalEditor,
    updatedOwner = null,
    vulnerableFieldsUpdatedAt,
    vulnerableFieldsUpdatedBy,
    isSecureEnvironment,
  } = currentReport;

  const fieldsList = fieldsListCandidate?.map(serializeField);

  return {
    _id: id ?? '',
    permissions,
    galleryReportId,
    type: 'scriptedReport',
    category,
    version,
    name,
    key,
    tags,
    icon,
    docsLink,
    description,
    fields: fieldsList,
    template,
    script,
    updatedOwner,
    externalEditor,
    reportExportVisibility,
    vulnerableFieldsUpdatedAt,
    vulnerableFieldsUpdatedBy,
    isSecureEnvironment,
  };
};

export const createReportDto = ({ getState }: CreateReportDtoPayload) => {
  const currentReport = selectCurrentReport(getState());

  if (checkIsReportUniversalCompatible(currentReport)) {
    return createUniversalReportDto({ getState });
  }

  return createScriptedReportDto({ getState });
};

export const createReportFromDto = (dto: AnyReportDto): BaseReport => dto;