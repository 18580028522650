import { CurrentReport, CurrentUniversalReport, CurrentScriptedReport } from 'types/store/reports';

import { AnyReportDto, UniversalReportDto, BaseReport, ScriptedReportDto } from 'sharedFrontBack/src/types/report';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';

export const checkIsDtoUniversalCompatible = (payload: AnyReportDto): payload is UniversalReportDto => {
  if (payload.category === ReportCategory.universalReport || payload.category === ReportCategory.calendar) {
    return true;
  }
  return false;
};

export const checkIsReportUniversalCompatible = (payload: CurrentReport): payload is CurrentUniversalReport => {
  if (payload.category === ReportCategory.universalReport || payload.category === ReportCategory.calendar) {
    return true;
  }
  return false;
};

export const checkIsReportScriptedCompatible = (
  payload: BaseReport | CurrentReport | AnyReportDto | Partial<ScriptedReportDto>,
): payload is CurrentScriptedReport => {
  return (
    !payload.type
    || payload.type === ReportCategory.scriptedReport
    || payload.category === ReportCategory.scriptedReport
    || payload.category === ReportCategory.scriptedFromGallery
  );
};