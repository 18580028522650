class NotificationController {
  create = (data) => {
    if (process.env.JIRA_TYPE === 'cloud') {
      window.AP && window.AP.flag.create(data);
    } else {
      // eslint-disable-next-line no-undef
      AJS.flag(data);
    }
  };
}

const notification = new NotificationController();
export { notification as NotificationController }; // todo we should not use this anti-pattern in naming and export (it should be renamed to notificationController or notification and export it as it named. NotificationController is a class constructor, instance should not be named the same as constructor)
