import { getAllProjects } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getAllProjects,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/project',
  },
  cloud: {
    ...globalConfig,
    allPages: false,
    maxResults: 20,
    url: '/rest/api/3/project/search',
    fieldName: 'values',
  },
};

export { config as getAllProjects };
