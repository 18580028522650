import { searchEpics } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: searchEpics,
  allPages: false,
  fieldName: 'sections',
  maxResults: 20,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issue/picker?currentJQL=issueType = "epic"&showSubTasks=false',
    maxResults: 10,
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issue/picker?currentJQL=issueType = "epic"&showSubTasks=false',
    maxResults: 10,
  },
};

export { config as searchEpics };
