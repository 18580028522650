import { getBulkUsers } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: false,
  migration: getBulkUsers,
  maxResults: 10,
};

const config = {
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/user/bulk',
  },
};

export { config as getBulkUsers };
