export default {
  GET_USER_DATA_STARTED: 'GET_USER_DATA_STARTED',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

  GET_USER_ISSUE_TYPES_SUCCESS: 'GET_USER_ISSUE_TYPES_SUCCESS',
  GET_USER_ISSUE_TYPES_FAILURE: 'GET_USER_ISSUE_TYPES_FAILURE',

  GET_USER_ISSUE_LINK_TYPES_SUCCESS: 'GET_USER_ISSUE_LINK_TYPES_SUCCESS',
  GET_USER_ISSUE_LINK_TYPES_FAILURE: 'GET_USER_ISSUE_LINK_TYPES_FAILURE',

  GET_PROJECTS_KEYS_SUCCESS: 'GET_PROJECTS_KEYS_SUCCESS',
  GET_PROJECTS_KEYS_FAILURE: 'GET_PROJECTS_KEYS_FAILURE',
};
