export const getEpicKey = ({ epicKeyField, epicNameField }, issue) => {
  const { issuetype, parent } = issue.fields;

  const isSubtask = issuetype.subtask;

  if (issue.fields[epicKeyField]) {
    return issue.fields[epicKeyField];
  }

  if (issue.fields[epicNameField]) {
    return issue.key;
  }

  if (!isSubtask && parent && parent.key) {
    return parent.key;
  }

  // For Next Gen Projects
  if (issue.fields.issuetype.name === 'Epic') {
    return issue.key;
  }

  return null;
};
