import React, { FC, ReactNode } from 'react';
import { Router } from 'react-router-dom';
import { getAtlassianNavigator } from 'router/atlassianNavigator';

interface RouterProps {
  location: string;
  urlBasename: URL;
  children: ReactNode;
}

export const ConnectRouter: FC<RouterProps> = ({ location, urlBasename, children }) => {
  return window.AP && (
    <Router navigator={getAtlassianNavigator(urlBasename)} location={location}>
      {children}
    </Router>
  );
};
