import { useSelector } from 'react-redux';

import { REPORTS_ON_PAGE } from 'constants/constants';
import {
  selectReportsSearchString,
  selectReportCurrentTab,
  selectIsOnlyFavorites,
} from 'store/legacy/reports/selectors';

import { GetReportsQueryParams } from 'store/api/plugin/reportApi';
import usePageFromQuery from 'hooks/usePageFromQuery';
import { ReportsListSortBy } from 'types/reportsListSortBy';
import { useGetReportsQuery } from 'store/hooks/useGetReportsQuery';
import { selectReportsListSortBy } from 'store/legacy/app/selectors';

export const useGetReports = () => {
  const currentPage = usePageFromQuery();
  const search = useSelector(selectReportsSearchString);
  const currentTab = useSelector(selectReportCurrentTab);

  const reportsListSortBy = useSelector(selectReportsListSortBy);
  const isOnlyFavorites = useSelector(selectIsOnlyFavorites);

  const sortObject = Object.entries(reportsListSortBy).reduce((acc, [key, value]) => {
    if (value !== 0) {
      acc[key as keyof ReportsListSortBy] = value;
    }
    return acc;
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  }, {} as ReportsListSortBy);

  // TODO: figure out the logic and refactor to make the logic clearer
  const sort = Object.keys(sortObject)[0];
  const order = Object.values(sortObject)[0];

  const params: GetReportsQueryParams = {
    dashboardType: currentTab,
    search: search.trim(),
    limit: REPORTS_ON_PAGE,
    page: currentPage,
    isOnlyFavorites,
    ...(sort ? { sort } : {}),
    ...(order ? { order } : {}),
  };

  const { data: reportsQueryData, isFetching, refetch } = useGetReportsQuery(params);
  const reports = reportsQueryData?.reports ?? [];
  const totalPages = reportsQueryData?.total ?? 0;

  return {
    data: reports,
    totalPages,
    isFetching,
    refetch,
  };
};
