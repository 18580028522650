export enum FieldTypes {
  STRING = '1',
  NUMBER = '2',
  DATE = '3',
  CHECKBOX = '4',
  JQL = '5',
  PROJECT_PICKER = '6',
  USER_PICKER = '7',
  TEXTAREA = '8',
  RANGE_PICKER = '9',
  FIELDS_PICKER = '10',
  ISSUE_TYPE_PICKER = '11',
  RADIO_BUTTON = '12',
  SELECT_LIST = '13',
  SCOPE_PICKER = '14',
  ISSUE_PICKER = '15',
  ATTACHMENT_PICKER = '16',
  SPRINT_PICKER = '17',
  TEAM_PICKER = '18',
  LABEL_PICKER = '19',
  STATUS_PICKER = '20',
  READ_ONLY_TEXT = '0',
}
