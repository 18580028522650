import { appContentRootId, popupContentClassName } from 'constants/constants';
import sleep from 'utils/sleep';
import { useContext } from 'react';
import { GadgetContext } from 'features/gadget/components/GadgetContext';
import usePersistentCallback from 'hooks/usePersistentCallback';

export type ResizeIframe = (maxHeight?: number) => void;

export const infinityHeight = 999999;
const minAbsoluteElementHeight = 400;
const notInvestigatedCausePixelsCompensation = 20; // without it, for unknown reason in gadget on linux/windows - appear scroll bar

const getContentEl = () => document.getElementById(appContentRootId);
const gadgetMinHeight = 180;
const beautyOffset = 16;

const getHeight = (maxHeight: number, minHeight: number) => {
  const rootEl = getContentEl();
  if (!rootEl) {
    return minHeight;
  }

  const absoluteElements = document.querySelectorAll<HTMLDivElement>(`.${popupContentClassName}`);
  const rootElHeight = rootEl.scrollHeight;

  const absoluteElementsHeights = absoluteElements.length ? (
    Array.from(absoluteElements).map((el) => (
      el.getBoundingClientRect().top + Math.max(
        el.offsetHeight,
        el.offsetHeight ? minAbsoluteElementHeight : 0,
      ) + beautyOffset
    ))
  ) : [];

  const actualMaxHeight = Math.max(minHeight, rootElHeight, ...absoluteElementsHeights);

  return Math.min(
    maxHeight,
    actualMaxHeight,
  ) + notInvestigatedCausePixelsCompensation;
};

const resizeCloud: ResizeIframe = () => {
  return;
};

const resizeServer: ResizeIframe = () => {
  return;
};

const resizeGadgetServer: ResizeIframe = async (maxHeight = infinityHeight) => {
  if (!window.ActonicRB?.gadget) {
    return;
  }
  await sleep(1); // wait for elements update to get correct height

  const { gadget } = window.ActonicRB;
  const height = getHeight(maxHeight, gadgetMinHeight).toString();

  const gadgetRoot = gadget.getView()[0];
  const gadgetIframe = gadgetRoot.querySelector('iframe');
  if (!gadgetIframe) {
    return;
  }

  gadgetIframe.style.height = `${height}px`;

  setTimeout(gadget.resize, 1); // for unknown reason gadget.resize does not use new height without timeout
};

const resizeGadgetCloud: ResizeIframe = async (maxHeight = infinityHeight) => {
  const { AP } = window;

  AP.resize('100%', getHeight(maxHeight, gadgetMinHeight).toString()); // for cases when content renders rapidly

  await sleep(1);

  AP.resize('100%', getHeight(maxHeight, gadgetMinHeight).toString()); // for cases when we need to wait a little bit for content render complete
};

const resizeExportPanelCloud = async () => {
  const srIssuePickerValue = document.getElementById('sr-issue-picker-panel');

  const { AP, outerHeight } = window;
  if (IS_CLOUD) {
    if (srIssuePickerValue) {
      setTimeout(() => AP.resize('100%', '100%'), 500);
    } else {
      AP.resize('100%', outerHeight.toString());
    }
  }
};

export const useResizeIframe = (): ResizeIframe => {
  const isGadget = window.ActonicRB?.isGadget;
  const isIssuePanel = window.ActonicRB?.isIssuePanel;

  if (IS_CLOUD) {
    if (isGadget) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { gadgetMaxHeight } = useContext(GadgetContext);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return usePersistentCallback((maxHeight?: number) => resizeGadgetCloud(maxHeight ?? gadgetMaxHeight));
    }
    if (isIssuePanel) {
      return resizeExportPanelCloud;
    }
    return resizeCloud;
  }

  if (isGadget) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { gadgetMaxHeight } = useContext(GadgetContext);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePersistentCallback((maxHeight?: number) => resizeGadgetServer(maxHeight ?? gadgetMaxHeight));
  }
  return resizeServer;
};
