import { useDidMount, useWillUnmount } from 'hooks/lifecycle';
import { usePrevious } from 'hooks/usePrevious';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useDoubleClick } from 'hooks/useDoubleClick';
import { useEditLogs } from 'hooks/useEditLogs';

export {
  useDidMount,
  useWillUnmount,
  usePrevious,
  useOutsideClick,
  useDoubleClick,
  useEditLogs,
};
