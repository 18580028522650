export default {
  GET_FILTER_BY_DATA_STARTED: 'GET_FILTER_BY_DATA_STARTED',
  GET_FILTER_BY_DATA_SUCCESS: 'GET_FILTER_BY_DATA_SUCCESS',
  GET_FILTER_BY_DATA_FAILURE: 'GET_FILTER_BY_DATA_FAILURE',

  SET_PROJECTS_VALUES: 'SET_PROJECTS_VALUES',
  SET_EPICS_VALUES: 'SET_EPICS_VALUES',
  SET_ISSUES_VALUES: 'SET_ISSUES_VALUES',
  SET_USERS_VALUES: 'SET_USERS_VALUES',
  SET_TEAMS_VALUES: 'SET_TEAMS_VALUES',
  SET_FILTERS_VALUES: 'SET_FILTERS_VALUES',

  SET_TEAMS_JQL: 'SET_TEAMS_JQL',
  SET_USERS_JQL: 'SET_USERS_JQL',

  GET_ISSUES_COUNT_STARTED: 'GET_ISSUES_COUNT_STARTED',
  GET_ISSUES_COUNT_SUCCESS: 'GET_ISSUES_COUNT_SUCCESS',

  REMOVE_SELECTS_VALUES: 'REMOVE_SELECTS_VALUES',

  REMOVE_JQL: 'REMOVE_JQL',
  SET_FULL_JQL: 'SET_FULL_JQL',

  CHANGE_JQL_FIELD: 'CHANGE_JQL_FIELD',

  SET_IS_FILTER_APPLIED: 'SET_IS_FILTER_APPLIED',
};
