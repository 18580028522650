import { ExternalEditorType } from 'sharedFrontBack/src/constants/report';

export const initialExternalEditorTypeParamsMap = {
  [ExternalEditorType.cloudEditor]: {
    url: '',
  },
  [ExternalEditorType.devServer]: {
    devServerUrl: 'http://localhost:5050',
    reportBuildPath: 'build/playground',
    enableHotRerender: false,
  },
};
export const srExamplesRepoUrl = 'https://bitbucket.org/actonic/sr-examples/';
