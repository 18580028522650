import {
  searchIssues, searchEpics, getFilters,
  getIssuesCount as getIssuesCountRequest, getAllProjects,
} from 'controllers/transport/requestConfigurations';
import { TransportController as Transport, API } from 'controllers';
import { fieldTypes } from 'pages/ScriptedReportPage/GeneralConfiguration/fieldTypes';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { ScopePickerMode } from 'sharedFrontBack/src/constants/scopePicker';
import { UniversalReportLookingFor } from 'sharedFrontBack/src/constants/universalReport';
import { selectIsCustomWorklogFuncAvailable } from 'store/slices/appEnvironment/selectors';
import {
  setIsWithChanges,
  cleanReportsTableData,
} from 'store/legacy/reports/actions';
import {
  selectFiltersAsString,
  selectIsEnoughUniversalReportFilters,
} from 'store/legacy/reports/selectors';
import { getReportTableData, setGroupBy, setLastFilterAsString } from 'store/legacy/reports/thunks';

import UsersController from 'controllers/users/UsersController';
import { createWorklogDateJql } from 'utils/createWorklogDateJql';

import { createJQLForUser } from 'store/legacy/filterBySelector/createJQL';
import {
  getFilterByDataFailure, getFilterByDataStarted, getFilterByDataSuccess,
  getIssuesCountStarted, getIssuesCountSuccess, removeSelectsValues, setEpicsValues,
  setFiltersValues,
  setFullJQL, setIsFilterApplied, setIssuesValues, setProjectsValues, setUsersJQL,
  setUsersValues, changeJQLField as changeJQLFieldAction,
} from 'store/legacy/filterBySelector/actions';

export const getDataForFilters = () => async (dispatch) => {
  try {
    dispatch(getFilterByDataStarted());
    let projectsList = [];

    if (process.env.JIRA_TYPE === 'cloud') {
      projectsList = await Transport.request({ config: getAllProjects });
    } else {
      projectsList = (await API.get('/custom/projects/search')).data.values;
    }

    const projects = await projectsList.map(({ key, name }) => ({
      value: key,
      label: name,
    }));

    const query = '?query=.&excludeConnectUsers=true&showAvatar=true&avatarSize=32x32';
    const users = await UsersController.getUsersByQuery(query);

    const filters = await Transport.request({ config: getFilters })
      .catch((error) => {
        console.error('[store.filterBySelector.actions][getDataForFilters] Error while getting filters', error);
      }) || [];

    const epics = await Transport.request({ config: searchEpics });

    const issues = await Transport.request({ config: searchIssues });

    dispatch(getFilterByDataSuccess({ issues, users, projects, epics, filters }));
  } catch (error) {
    console.error('[store.filterBySelector.actions][getDataForFilters] Error while getting data for filters', error);

    dispatch(getFilterByDataFailure());
  }
};

export const clearAllSelects = (setDefault) => (dispatch) => {
  dispatch(removeSelectsValues());
  dispatch(cleanReportsTableData());

  dispatch(setLastFilterAsString(''));

  !setDefault && dispatch(setIsWithChanges());
};

export const getTableDataWithFilter = (isInitial) => async (dispatch, getState) => {
  const state = getState();
  const {
    projectValues, epicValues, issueValues,
    userValues, teamValues, filterValues, jqlField,
  } = state.filterBySelector;
  const { lastFilterAsString, isParentsShouldBeFetched, isChildrenShouldBeFetched } = getState().reports.currentReport;

  const allFilters = [
    ...projectValues, ...epicValues, ...issueValues,
    ...userValues, ...teamValues, ...filterValues,
    ...jqlField,
  ];

  if (allFilters && allFilters.length <= 0) {
    return !isInitial ? dispatch(clearAllSelects()) : null;
  }

  const filterAsString = selectFiltersAsString(state);

  if (!isInitial) {
    dispatch(setIsWithChanges());
  }

  if (filterAsString && filterAsString.length > 0) {
    // eslint-disable-next-line max-len
    if (`${filterAsString} isParentsShouldBeFetched=${isParentsShouldBeFetched} isChildrenShouldBeFetched=${isChildrenShouldBeFetched}` !== lastFilterAsString) {
      dispatch(getReportTableData(isInitial));
    }
  }
};

// TODO: add try...catch block
export const getIssuesCount = () => async (dispatch, getState) => {
  const state = getState();
  const isEnoughUniversalReportFilters = selectIsEnoughUniversalReportFilters(state);

  if (!isEnoughUniversalReportFilters) {
    return;
  }

  dispatch(getIssuesCountStarted());

  const { currentReport } = state.reports;
  const { datesRange, category, dataType } = currentReport;
  const { startDate, endDate } = datesRange;
  const filterAsString = selectFiltersAsString(state);

  let issuesCount = 0;

  if (filterAsString && filterAsString.length > 0) {
    const isCustomWorklogFuncAvailable = selectIsCustomWorklogFuncAvailable(getState());
    const dateQuery = createWorklogDateJql(startDate, endDate, isCustomWorklogFuncAvailable);
    const withDates = category === ReportCategory.universalReport && dataType === UniversalReportLookingFor.worklogs;

    const filterQuery = ` AND (${filterAsString})`;
    const fullJQL = withDates ? dateQuery + filterQuery : filterAsString;

    dispatch(setFullJQL(fullJQL));

    try {
      issuesCount = await Transport.request({
        config: getIssuesCountRequest, query: `?jql=${fullJQL}&maxResults=0`,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  dispatch(getIssuesCountSuccess({ issuesCount }));
};

export const checkIsJQLAvailable = () => (dispatch, getState) => {
  const {
    jqlField,
    projectValues,
    epicValues,
    issueValues,
    userValues,
    teamValues,
    filterValues,
  } = getState().filterBySelector;

  const allFilters = [
    ...projectValues, ...epicValues, ...issueValues,
    ...userValues, ...teamValues, ...filterValues,
    ...jqlField,
  ];

  if (allFilters && allFilters.length <= 0) {
    dispatch(clearAllSelects(true));
  }
};

// eslint-disable-next-line default-param-last
export const changeProjects = (values = [], shouldRefreshIssuesCount) => (dispatch) => {
  dispatch(setProjectsValues({ values }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

// eslint-disable-next-line default-param-last
export const changeEpics = (values = [], shouldRefreshIssuesCount) => (dispatch) => {
  dispatch(setEpicsValues({ values }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

// eslint-disable-next-line default-param-last
export const changeIssues = (values = [], shouldRefreshIssuesCount) => (dispatch) => {
  dispatch(setIssuesValues({ values }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

// eslint-disable-next-line default-param-last
export const changeUsers = (values = [], shouldRefreshIssuesCount) => async (dispatch) => {
  dispatch(setUsersValues({ values }));

  shouldRefreshIssuesCount && dispatch(getIssuesCount());

  const usersJQL = await createJQLForUser(values || [], 'worklogAuthor in (');

  dispatch(setUsersJQL({ usersJQL }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

// eslint-disable-next-line default-param-last
export const changeTeams = () => async () => {
  // todo remove
};

// eslint-disable-next-line default-param-last
export const changeFilters = (values = [], shouldRefreshIssuesCount) => (dispatch) => {
  dispatch(setFiltersValues({ values }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

export const changeJQLField = (jqlField, shouldRefreshIssuesCount) => (dispatch) => {
  dispatch(changeJQLFieldAction({ jqlField }));

  dispatch(checkIsJQLAvailable());

  shouldRefreshIssuesCount && dispatch(getIssuesCount());
};

export const getInitialDataForFilter = (data = [], getTableData = true) => async (dispatch) => {
  let allFilters = [];
  let projects = [];
  let epics = [];
  let issues = [];
  let filters = [];
  let jqlField = [];

  if (Array.isArray(data)) {
    projects = data.filter(({ type }) => type === 'project');
    epics = data.filter(({ type }) => type === 'epic');
    issues = data.filter(({ type }) => type === 'issue');
    filters = data.filter(({ type }) => type === 'filter' || type === 'predefinedFilter');
    jqlField = data.filter(({ type }) => type === 'jqlField');
  } else if (data.scopePickerMode === ScopePickerMode.basicView) {
    projects = data.projects;
    epics = data.epics;
    issues = data.issues;
    filters = data.filters;
  } else {
    jqlField = [{ type: 'jqlField', label: data.jql }];
  }

  const isJQLFilter = Boolean(jqlField?.[0]?.label);
  allFilters = isJQLFilter ? jqlField : [...projects, ...epics, ...issues, ...filters];

  if (allFilters && allFilters.length <= 0) {
    dispatch(clearAllSelects(true));
  }

  dispatch(changeJQLField(isJQLFilter ? jqlField : [], isJQLFilter));
  dispatch(changeProjects(isJQLFilter ? [] : projects, !isJQLFilter));
  dispatch(changeEpics(isJQLFilter ? [] : epics, !isJQLFilter));
  dispatch(changeIssues(isJQLFilter ? [] : issues, !isJQLFilter));
  dispatch(changeFilters(isJQLFilter ? [] : filters, !isJQLFilter));

  dispatch(setIsFilterApplied(true));

  if (getTableData) dispatch(getTableDataWithFilter(true));
};

export const updateFilterByValuesFromReport = (report) => async (dispatch) => {
  if (!report?.inputFields) {
    return;
  }

  const { fieldsList } = report.inputFields;
  const filterByField = fieldsList?.find((field) => field.type === fieldTypes.SCOPE_PICKER);

  if (!filterByField || !filterByField.defaultValue) {
    return;
  }

  await dispatch(getInitialDataForFilter(filterByField.defaultValue));
};

export const setDefaultValues = (categoryParams) => (dispatch) => {
  switch (categoryParams) {
    default: {
      dispatch(setGroupBy(['project'], true));
      dispatch(getInitialDataForFilter([], false));
    }
  }
};
