import { OnboardingState } from 'features/spotlight/store/slices/onboarding/onboardingSlice';
import { getActiveOnboarding } from 'features/spotlight/utils/getActiveOnboarding';
import { onboardingTargets, SpotlightTargets } from 'features/spotlight/constants';

export const getActiveOnboardingTargetsArray = (state: OnboardingState) => {
  const activeOnboarding = getActiveOnboarding(state);
  if (!activeOnboarding) {
    return;
  }
  const activeOnboardingTargets = onboardingTargets[activeOnboarding];
  if (!activeOnboardingTargets) {
    return;
  }
  return Object.values(activeOnboardingTargets) as Array<SpotlightTargets>;
};