import { getDefinedPeriodDates } from 'utils/dates/getStartAndEndDates';
import { defineDatesRange } from 'store/legacy/defineDatesRange';
import { validateGroupBy, validateSortBy } from 'store/legacy/dataValidation';
import {
  getCurrentReportSuccess,
  getScriptedReportSuccess,
} from 'store/legacy/reports/actions';
import { setColumns, getCalendarReportData } from 'store/legacy/reports/thunks/index';
import { CurrentUniversalReport } from 'types/store/reports';
import {
  SetLoadedUniversalReport,
  SetLoadedReport,
  SetLoadedScriptedReport,
} from 'store/legacy/reports/thunks/setLoadedReport.types';
import { getInitialDataForFilter } from 'store/legacy/filterBySelector/thunks';
import { checkIsDtoUniversalCompatible } from 'utils/checkIsUniversalCompatible';

import { UniversalReportDto, UniversalReportCommon, ScriptedReportDto } from 'sharedFrontBack/src/types/report';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { getDefaultPermissions } from 'utils/getDefaultPermissions';
import { fieldTypes } from 'pages/ScriptedReportPage/GeneralConfiguration/fieldTypes';
import { SrFieldTypes } from 'sharedFrontBack/src/constants/sr';
import { ScopePickerMode } from 'sharedFrontBack/src/constants/scopePicker';
import { deserializeField } from 'features/sr/utils/serializeFields';

export const setLoadedScriptedReport: SetLoadedScriptedReport = ({
  dto,
  fetchId,
  locationIssue,
}) => async (dispatch, getState) => {
  const { inputFields: currentStateInputFields } = getState().reports.currentReport;

  const {
    _id: id, name, type, category, template,
    galleryReportId,
    version,
    key,
    tags,
    icon,
    docsLink,
    description,
    fields,
    script,
    permissions: permissionsRaw,
    createdBy,
    reportExportVisibility = [],
    shareableLink,
    externalEditor,
    vulnerableFieldsUpdatedAt,
    vulnerableFieldsUpdatedBy,
    isSecureEnvironment,
  } = dto;

  const permissions = permissionsRaw ?? getDefaultPermissions();

  let isLocationIssueKeySet = false;

  const inputFields = {
    ...currentStateInputFields,
    fieldsList: fields?.map((field) => {
      if (locationIssue) {
        if (!isLocationIssueKeySet) {
          const label = `${locationIssue.key} ${locationIssue.fields?.summary}`;

          if (field.type === fieldTypes.ISSUE_PICKER) {
            isLocationIssueKeySet = true;
            return {
              ...deserializeField(field),
              defaultValue: {
                value: locationIssue.key,
                label,
              },
            };
          }
          if (field.type === SrFieldTypes.SCOPE_PICKER) {
            const deserializedField = deserializeField<SrFieldTypes.SCOPE_PICKER>(field);
            isLocationIssueKeySet = true;
            return {
              ...deserializedField,
              type: SrFieldTypes.SCOPE_PICKER,
              defaultValue: {
                scopePickerMode: ScopePickerMode.basicView,
                issues: [
                  { label, value: locationIssue.key, type: 'issue' },
                ],
              },
            };
          }
        }
      }

      return deserializeField(field);
    }),
  };

  const currentReport = {
    id,
    galleryReportId,
    key,
    version,
    tags,
    icon,
    docsLink,
    description,
    name,
    type,
    category,
    template,
    script,
    externalEditor,
    permissions,
    reportExportVisibility,
    createdBy,
    inputFields,
    reportSharedId: shareableLink?.sharedIdHash,
    shareableLink: shareableLink?.shareableLink,
    isPredefined: !type,
    vulnerableFieldsUpdatedAt,
    vulnerableFieldsUpdatedBy,
    isSecureEnvironment,
  };

  return dispatch(getScriptedReportSuccess({ currentReport, ...(fetchId ? fetchId : {}) }));
};

export const setLoadedUniversalReport: SetLoadedUniversalReport = ({ dto }) => async (dispatch, getState) => {
  if (!checkIsDtoUniversalCompatible(dto)) {
    throw new Error(`Cannot setLoadedUniversalReport using wrong report category: ${(dto as UniversalReportCommon).category}`);
  }
  const {
    dateRange, filterBy, columns, groupBy, sortBy, name, _id: id,
    createdBy, permissions: permissionsRaw, type, category, sources = [], reportExportVisibility,
    calendarView, isWithWeekends, isIncludesSubtask, isParentsShouldBeFetched,
    isChildrenShouldBeFetched, dataType, reportViewType, aggregationType, aggregationTarget,
    exclusions: filters = [], timeTrackingUnit, shareableLink, colorTheme = 'atlassian',
  } = dto;

  const { startWeekly } = getState().configuration.general;

  let datesRange;

  if (dateRange) { // some old dashboard reports does not have dateRange
    const { start, end, relativeDate } = dateRange;
    const definedPeriodDates = getDefinedPeriodDates(relativeDate, startWeekly);
    const { startDate, endDate } = defineDatesRange(definedPeriodDates, { start, end });
    datesRange = {
      startDate,
      relativeDate,
      endDate,
    };
  }
  const fieldsIds = getState().app.fieldsOptions.map(({ value }) => value);

  const permissions = permissionsRaw ?? permissionsRaw;

  const currentReport: Partial<CurrentUniversalReport> = {
    id,
    type,
    category,
    name,
    // TODO: left only validate after removing old reports
    groupBy: fieldsIds && groupBy ? validateGroupBy(fieldsIds, groupBy) : groupBy,
    quickReportFilterBy: filterBy,
    sortBy: validateSortBy(sortBy),
    colorTheme,
    createdBy,
    sources,
    calendarView,
    isWithWeekends,
    timeTrackingUnit,
    isIncludesSubtask,
    isParentsShouldBeFetched,
    isChildrenShouldBeFetched,

    reportSharedId: shareableLink?.sharedIdHash,
    shareableLink: shareableLink?.shareableLink,

    dataType,
    reportViewType,
    aggregationType,
    aggregationTarget,
    filters,
    permissions: permissions ?? getDefaultPermissions(),
    reportExportVisibility,
    ...(datesRange ? { datesRange } : {}),
  };

  // Dispatching actions should always be in this order
  if (category === ReportCategory.universalReport) {
    await dispatch(setColumns(columns ? validateGroupBy(fieldsIds, columns) : []));
  } else {
    await dispatch(setColumns(columns ? columns : []));
  }

  await dispatch(getCurrentReportSuccess({ currentReport }));

  if (category === ReportCategory.universalReport) {
    dispatch(getInitialDataForFilter(filterBy));
  } else {
    dispatch(getCalendarReportData());
  }
};

export const setLoadedReport: SetLoadedReport = ({ dto, fetchId }) => (dispatch) => {
  if (checkIsDtoUniversalCompatible(dto)) {
    return dispatch(setLoadedUniversalReport({ dto: dto as UniversalReportDto }));
  }

  return dispatch(setLoadedScriptedReport({ dto: dto as ScriptedReportDto, fetchId }));
};
