export const mapFieldTypeToGroup = {
  string: 'singleGroup',
  number: 'singleGroup',

  user: 'singleGroup',
  datetime: 'singleGroup',
  date: 'singleGroup',
  issuetype: 'singleGroup',

  priority: 'singleGroup',
  progress: 'singleGroup',
  group: 'singleGroup',
  project: 'singleGroup',

  resolution: 'singleGroup',
  status: 'singleGroup',
  timetracking: 'singleGroup',

  option: 'singleGroup',
  'option-with-child': 'singleGroup',

  array: 'multipleGroup',

  issuerestriction: 'singleGroup',
  securitylevel: 'singleGroup',

  votes: 'singleGroup',
  watches: 'singleGroup',

  'sd-customerrequesttype': 'singleGroup',
  'sd-request-lang': 'singleGroup',

  any: 'singleGroup',
  systemFieldValue: 'singleGroup',
};
