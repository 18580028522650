import { getDateConstants } from 'constants/dateConstants';

export const getStartAndEndDates = () => {
  const curr = new Date();
  const first = curr.getDate() - curr.getDay();
  const last = first + 6;

  return { start: new Date(curr.setDate(first)), end: new Date(curr.setDate(last)) };
};

export const getEndAndStartOfMonth = (momentWithDefinedMonth) => ({
  startDate: momentWithDefinedMonth.startOf('month').valueOf(),
  endDate: momentWithDefinedMonth.endOf('month').valueOf(),
});

export const getEndAndStartOfWeek = (momentWithDefinedWeek) => ({
  startDate: momentWithDefinedWeek.startOf('week').valueOf(),
  endDate: momentWithDefinedWeek.endOf('week').valueOf(),
});

export const getDefinedPeriodDates = (definedPeriod, startWeekly) => {
  const {
    TODAY_START, TODAY_END, YESTERDAY_START, YESTERDAY_END, THIS_WEEK_START,
    THIS_WEEK_END, LAST_WEEK_START, LAST_WEEK_END,
    THIS_MONTH_START, THIS_MONTH_END, LAST_MONTH_START, LAST_MONTH_END,
  } = getDateConstants(startWeekly);

  switch (definedPeriod) {
    case 'today':
      return {
        startDate: new Date(TODAY_START),
        endDate: new Date(TODAY_END),
      };

    case 'yesterday':
      return {
        startDate: new Date(YESTERDAY_START),
        endDate: new Date(YESTERDAY_END),
      };

    case 'thisWeek':
      return {
        startDate: new Date(THIS_WEEK_START),
        endDate: new Date(THIS_WEEK_END),
      };

    case 'lastWeek':
      return {
        startDate: new Date(LAST_WEEK_START),
        endDate: new Date(LAST_WEEK_END),
      };

    case 'thisMonth':
      return {
        startDate: new Date(THIS_MONTH_START),
        endDate: new Date(THIS_MONTH_END),
      };

    case 'lastMonth':
      return {
        startDate: new Date(LAST_MONTH_START),
        endDate: new Date(LAST_MONTH_END),
      };

    default:
      return null;
  }
};
