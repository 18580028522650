import { moment } from 'controllers/moment';

import { TFunction } from 'hooks/useTranslation';
import { reportLabelsByReportType } from 'pages/ReportsDashboard/CreateReport/reportLabelsByReportType';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';

interface CreateReportDefaultNameArgs {
  category: ReportCategory;
  dateFormat: string;
  date?: undefined | string | number | Date;
  t: TFunction;
}

export const createReportDefaultName = ({ category, date, dateFormat, t }: CreateReportDefaultNameArgs) => {
  const dateForName = date ? moment(date).formatWithJDF(dateFormat) : '';
  const prefix = window.ActonicRB?.isGadget ? t('reports.createReport.dashboardReportPrefix') + ' ' : '';

  return `${prefix}${reportLabelsByReportType(t).find(({ reportType }) => category === reportType)?.label} ${dateForName}`;
};