const globalConfig = {
  method: 'POST',
  allPages: false,
  fieldName: 'queries',
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/3/jql/parse',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/jql/parse',
  },
};

export { config as validateJQL };
