import 'globalStyle.tsx';
import { initI18n } from 'utils/i18n';
import { initLicense } from 'utils/initLicense';

const initPluginConvoyShared = async () => {
  await Promise.all([
    initLicense(),
    initI18n(),
  ]);
};

export default initPluginConvoyShared;