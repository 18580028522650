import { getDefaultPermissions } from 'utils/getDefaultPermissions';
import { createUrtInitialConfig } from 'features/urt/utils/createUrtInitialConfig';
import { UrtVisualizationType } from 'sharedFrontBack/src/constants/urt';

import { UrtReportDto } from 'sharedFrontBack/src/types/urt';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';

export const getInitialUrtReport = (): UrtReportDto => {
  const urtConfig = createUrtInitialConfig(UrtVisualizationType.matrix);
  return {
    _id: undefined,
    type: 'report',
    category: ReportCategory.urtReport as const,
    name: '',
    permissions: getDefaultPermissions(),
    urtConfig,
  };
};
