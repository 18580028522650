import produce from 'immer';
import { getDateConstants } from 'constants/dateConstants';
import { getStartAndEndDates } from 'utils/dates/getStartAndEndDates';
import types from 'store/legacy/timesheets/types';

const { THIS_WEEK_START, THIS_WEEK_END } = getDateConstants('sunday');

const { start, end } = getStartAndEndDates();

const initialState = {
  // Calendar
  isWithWeekends: true,
  calendarView: 'week_without_time',
  currentPeriod: {
    start: new Date(THIS_WEEK_START),
    end: new Date(THIS_WEEK_END),
  },
  logs: [],
  logsByDates: {},
  mouseOnDate: null,
  isLogsLoading: false,
  calculatedLogsSeconds: 0,
  isWithTime: false,
  calendarViewConfig: false,
  isColorIssueStatus: false,

  // Table
  datesRange: {
    startDate: start,
    endDate: end,
  },
  groupBy: ['issue'],
  columns: [],
  sortBy: {
    isAscending: true,
    path: 'name',
    type: 'text',
  },

  // Always stored for calculation subtasks in parent
  issues: [],

  data: [],
  loggedTimeInSeconds: 0,
  isDataFetching: false,
  definedPeriod: null,
};

// eslint-disable-next-line default-param-last
export const timesheets = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_LOGS_STARTED:
      return {
        ...state,
        isLogsLoading: true,
      };

    case types.GET_LOGS_SUCCESS: {
      const { logs } = action.payload;

      return {
        ...state,
        logs,
        isLogsLoading: false,
      };
    }

    case types.GET_LOGS_FAILURE:
      return { ...state, error: action.error, isLogsLoading: false };

    case types.SET_LOGS: {
      const { logs } = action.payload;

      return { ...state, logs };
    }

    case types.SET_WEEKENDS: {
      const { isWithWeekends } = action.payload;

      return { ...state, isWithWeekends };
    }

    case types.SET_WITH_TIME: {
      const { isWithTime } = action.payload;

      return { ...state, isWithTime };
    }

    case types.SET_CURRENT_PERIOD: {
      const { currentPeriod } = action.payload;

      return { ...state, currentPeriod };
    }

    case types.SET_CALENDAR_VIEW: {
      const { calendarView } = action.payload;

      return { ...state, calendarView };
    }

    case types.SET_CALCULATED_LOGS_SECONDS: {
      const { calculatedLogsSeconds } = action.payload;

      return { ...state, calculatedLogsSeconds };
    }

    case types.REMOVE_PLUS_BUTTON: {
      const { logs } = action.payload;

      return { ...state, logs };
    }

    case types.SET_CALENDAR_VIEW_CONFIG: {
      const { calendarViewConfig } = action.payload;

      return { ...state, calendarViewConfig };
    }

    case types.SET_IS_COLOR_ISSUE_STATUS: {
      const { isColorIssueStatus } = action.payload;

      return { ...state, isColorIssueStatus };
    }

    case types.SET_MOUSE_ON_DATE: {
      const { mouseOnDate } = action.payload;

      return { ...state, mouseOnDate };
    }

    case types.SET_LOGS_BY_DATES: {
      const { logsByDates } = action.payload;

      return { ...state, logsByDates };
    }

    case types.SET_GROUP_BY: {
      const { groupBy } = action.payload;

      return { ...state, groupBy };
    }

    case types.SET_SORT_BY: {
      const { sortBy } = action.payload;

      return { ...state, sortBy };
    }

    case types.SET_DATES_RANGE: {
      const { datesRange } = action.payload;

      return { ...state, datesRange };
    }

    case types.GET_TABLE_DATA_STARTED:
      return { ...state, isDataFetching: true };

    case types.GET_TABLE_DATA_SUCCESS: {
      const { data, loggedTimeInSeconds } = action.payload;

      return { ...state, data, loggedTimeInSeconds, isDataFetching: false };
    }

    case types.GET_TABLE_DATA_FAILURE:
      return { ...state, isDataFetching: false, error: action.error };

    case types.SET_DEFINED_PERIOD: {
      const { definedPeriod } = action.payload;

      return { ...state, definedPeriod };
    }

    case types.SET_ISSUES_DATA: {
      const { issues } = action.payload;

      return { ...state, issues };
    }

    default:
      return state;
  }
});
