export const mockAP = {
  context: {
    getToken: () => 'sometoken',
  },
  request: ({ url }) => {
    console.log('url give to mockAP', url);

    switch (url) {
      case '/rest/api/3/user?accountId=admin&expand=groups&startAt=0&maxResults=100': {
        return {
          accountId: '5dd5022db3a9c80fc0a328af',
          accountType: 'atlassian',
          active: true,
          applicationRoles: { size: 2, items: [] },
          avatarUrls: {
            /* eslint-disable */
            '16x16': 'https://secure.gravatar.com/avatar/f9af0f68cbf068f13faf9c3b4de55826?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVK-6.png',
            '24x24': 'https://secure.gravatar.com/avatar/f9af0f68cbf068f13faf9c3b4de55826?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVK-6.png',
            '32x32': 'https://secure.gravatar.com/avatar/f9af0f68cbf068f13faf9c3b4de55826?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVK-6.png',
            '48x48': 'https://secure.gravatar.com/avatar/f9af0f68cbf068f13faf9c3b4de55826?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FVK-6.png',
            /* eslint-enable */
          },
          displayName: 'Vladyslav Komanchy',
          expand: 'groups,applicationRoles',
          groups: { size: 0, items: [] },
          locale: 'en_US',
          self: 'https://mylovelyapp.atlassian.net/rest/api/3/user?accountId=5dd5022db3a9c80fc0a328af',
          timeZone: 'Europe/Moscow',
        };
      }

      case '/rest/api/3/permissions/check?startAt=0&maxResults=100': {
        return {
          globalPermissions: ['ADMINISTER'],
          projectPermissions: [],
        };
      }

      case '/rest/api/3/user/properties/onboarding?accountId=5dd5022db3a9c80fc0a328af&startAt=0&maxResults=100': {
        return {
          key: 'onboarding',
          value: {
            'worklogs-page': true,
            reports: true,
            teams: true,
            report: true,
          },
        };
      }

      case '/rest/api/3/user/properties/fast-track-config?accountId=5dd5022db3a9c80fc0a328af&startAt=0&maxResults=100': {
        return {
          key: 'fast-track-config',
          value: {
            calendarView: 'week_without_time',
            currentModule: 'timesheet',
            currentPeriod: { start: '2020-08-15T21:00:00.000Z', end: '2020-08-22T20:59:59.999Z', config: true },
            definedPeriod: null,
            groupBy: ['project', 'component', 'issue', 'worklog'],
            isCalendarWithWeekends: true,
            isColorIssueStatus: true,
            isCompletionPanelVisible: false,
            isTotalPanelVisible: false,
            isSelectorOpened: true,
            lastReportId: '60000ac1ab1276008a1a20fa2323',
            lastReportType: 'report',
            sortBy: { isAscending: false, path: 'name', type: 'text' },
            tableDatesRange: { startDate: '2020-11-30T21:00:00.000Z', endDate: '2020-12-31T20:59:59.999Z' },
            timeTrackingUnit: 'minutes',
          },
        };
      }

      case '/rest/api/3/permissions/project?startAt=0&maxResults=100': {
        return {
          projects: [
            {
              id: 10000,
              key: 'GP',
            },
          ],
        };
      }

      default:
        return null;
    }
  },
  user: {
    getLocale: (callback) => callback('en_US'),
    getCurrentUser: (callback) => callback({ atlassianAccountId: 'admin' }),
  },
};

export const mockAPI = {
  get: async (url) => {
    switch (url) {
      case '/teams': {
        return {
          data: {
            page: 0,
            teams: [
              { _id: '5f847fa46a13de008b58c448', name: '9', summary: '', lead: '5dd5022db3a9c80fc0a328af' },
            ],
            lead: '5dd5022db3a9c80fc0a328af',
            name: '9',
            summary: '',
            _id: '5f847fa46a13de008b58c448',
            teamsCount: 1,
            total: 1,
          },
        };
      }

      case '/admin-config': {
        return {
          data: {
            permissions: {
              _id: '5f5a4ac31ccb620068c3c284',
              teamManager: [{ _id: '5f69ec9eab7a350068bc228c', type: 'user', value: '5dd5022db3a9c80fc0a328af' }],
              teamGlobalAdmin: [{ _id: '5f5a4ac31ccb620068c3c286', type: 'private', value: 'private' }],
              scriptedReportsManager: [{ _id: '5f7ef42e3b3610008abe0011', type: 'public', value: 'public' }],
            },
            general: {
              _id: '5f5a4ac31ccb620068c3c287',
              periodsEnabled: true,
              periodsConfiguration: 'monthly',
              periodsStartDate: 1561928400000,
              startWeekly: 'sunday',
            },
          },
        };
      }

      default:
        return null;
    }
  },
  head: async (url) => {
    switch (url) {
      default:
        return null;
    }
  },
};
