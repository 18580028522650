export const formatDataForGroupWorklogs = (worklogs) => worklogs.map((worklog) => {
  const hiddenWorklog = worklog.isHiddenWorklog ? {
    worklogAuthorId: 'hiddenUser',
    worklogAuthor: 'Hidden',
    worklogDescription: 'Hidden',
    worklogAuthorUrl: '',
    worklogAuthorIcons: null,
  } : {};

  return { ...worklog, ...hiddenWorklog };
});
