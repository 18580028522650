import { moment } from 'controllers/moment';

export const jiraDatesDelimiter = '/';
export const tmSequreDatesDelimiter = '-';

export const getDateConstants = (startOfWeek) => {
  const currentLocale = moment.locale();

  moment.updateLocale(currentLocale, {
    week: {
      dow: Number(startOfWeek === 'monday'),
    },
  });

  return {
    ESTIMATED_WEEK_REGEX: '(\\d*(\\.[1-9]{1})?w)',
    ESTIMATED_DAY_REGEX: '(\\d*(\\.[1-9]{1})?d)',
    ESTIMATED_HOUR_REGEX: '(\\d*(\\.[1-9]{1})?h)',
    ESTIMATED_MINUTE_REGEX: '(\\d*(\\.[1-9]{1})?m)',

    LOGGED_WEEK_REGEX: '(((?!0(\\d|w))\\d+(?:\\.[1-9]{1})?)w)',
    LOGGED_DAY_REGEX: '(((?!0(\\d|d))\\d+(?:\\.[1-9]{1})?)d)',
    LOGGED_HOUR_REGEX: '(((?!0(\\d|h))\\d+(?:\\.[1-9]{1})?)h)',
    LOGGED_MINUTE_REGEX: '(((?!0(\\d|m))\\d+(?:\\.[1-9]{1})?)m)',

    TODAY_START: new Date().setHours(0, 0, 0, 0),
    TODAY_END: new Date().setHours(23, 59, 59, 999),

    YESTERDAY_START: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
    YESTERDAY_END: new Date(new Date().setDate(new Date().getDate() - 1))
      .setHours(23, 59, 59, 999),

    THIS_WEEK_START: new Date(moment().startOf('week').toDate()).setHours(0, 0, 0, 0),
    THIS_WEEK_END: new Date(moment().endOf('week').toDate()).setHours(23, 59, 59, 999),

    LAST_WEEK_START: new Date(moment().subtract(1, 'weeks').startOf('week').toDate())
      .setHours(0, 0, 0, 0),
    LAST_WEEK_END: new Date(moment().subtract(1, 'weeks').endOf('week').toDate())
      .setHours(23, 59, 59, 999),

    THIS_MONTH_START: new Date(moment().startOf('month').toDate()).setHours(0, 0, 0, 0),
    THIS_MONTH_END: new Date(moment().endOf('month').toDate())
      .setHours(23, 59, 59, 999),

    LAST_MONTH_START: new Date(moment().subtract(1, 'month').startOf('month').toDate())
      .setHours(0, 0, 0, 0),
    LAST_MONTH_END: new Date(moment().subtract(1, 'month').endOf('month').toDate())
      .setHours(23, 59, 59, 999),
  };
};

export const getShortMonthNames = (t) => [
  t('months.short.jan'), t('months.short.feb'), t('months.short.mar'),
  t('months.short.apr'), t('months.short.may'), t('months.short.june'),
  t('months.short.july'), t('months.short.aug'), t('months.short.sep'),
  t('months.short.oct'), t('months.short.nov'), t('months.short.dec'),
];

export const getDays = (t) => [
  t('days.sunday'), t('days.monday'), t('days.tuesday'), t('days.wednesday'),
  t('days.thursday'), t('days.friday'), t('days.saturday'),
];

const createDatesRange = (result, start, end) => {
  let currentDate = start;

  function addDays(days) {
    const date = new Date(this.valueOf());

    date.setDate(date.getDate() + days);

    return date;
  }

  while (currentDate <= end) {
    result.push(currentDate);

    currentDate = addDays.call(currentDate, 1);
  }
};

const createWeeksRange = (result, start, end) => {
  const currentMoment = moment(start);
  const endMoment = moment(end);

  if (`${currentMoment.year()}-${currentMoment.isoWeek()}` === `${endMoment.year()}-${endMoment.isoWeek()}`) {
    result.push({
      textHeader: `Week ${currentMoment.isoWeek()}`,
      header: `Week ${currentMoment.isoWeek()}`,
      accessor: `${currentMoment.year()}-${currentMoment.isoWeek()}`,

      isWorklogRangeItem: true,
    });

    return;
  }

  while (`${currentMoment.year()}-${currentMoment.isoWeek()}` !== `${endMoment.year()}-${endMoment.isoWeek()}`) {
    result.push({
      textHeader: `Week ${currentMoment.isoWeek()}`,
      header: `Week ${currentMoment.isoWeek()}`,
      accessor: `${currentMoment.year()}-${currentMoment.isoWeek()}`,

      isWorklogRangeItem: true,
    });

    currentMoment.add(1, 'weeks');
  }

  result.push({
    textHeader: `Week ${currentMoment.isoWeek()}`,
    header: `Week ${currentMoment.isoWeek()}`,
    accessor: `${currentMoment.year()}-${currentMoment.isoWeek()}`,

    isWorklogRangeItem: true,
  });

  currentMoment.add(1, 'weeks');
};

const createMonthsRange = (result, start, end) => {
  const currentMoment = moment(start);
  const endMoment = moment(end);

  if (`${currentMoment.year()}-${currentMoment.month()}` === `${endMoment.year()}-${endMoment.month() + 1}`) {
    result.push({
      textHeader: currentMoment.format('MMM YY'),
      header: currentMoment.format('MMM YY'),
      accessor: `${currentMoment.year()}-${currentMoment.month() + 1}`,

      isWorklogRangeItem: true,
    });

    return;
  }

  while (`${currentMoment.year()}-${currentMoment.month()}` !== `${endMoment.year()}-${endMoment.month()}`) {
    result.push({
      textHeader: currentMoment.format('MMM YY'),
      header: currentMoment.format('MMM YY'),
      accessor: `${currentMoment.year()}-${currentMoment.month() + 1}`,

      isWorklogRangeItem: true,
    });

    currentMoment.add(1, 'months');
  }

  result.push({
    textHeader: currentMoment.format('MMM YY'),
    header: currentMoment.format('MMM YY'),
    accessor: `${currentMoment.year()}-${currentMoment.month() + 1}`,

    isWorklogRangeItem: true,
  });

  currentMoment.add(1, 'months');
};

const createQuartersRange = (result, start, end) => {
  const currentMoment = moment(start);
  const endMoment = moment(end);

  if (`${currentMoment.year()}-${currentMoment.quarter()}` === `${endMoment.year()}-${endMoment.quarter()}`) {
    result.push({
      textHeader: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
      header: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
      accessor: `${currentMoment.year()}-${currentMoment.quarter()}`,

      isWorklogRangeItem: true,
    });

    return;
  }

  while (`${currentMoment.year()}-${currentMoment.quarter()}` !== `${endMoment.year()}-${endMoment.quarter()}`) {
    result.push({
      textHeader: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
      header: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
      accessor: `${currentMoment.year()}-${currentMoment.quarter()}`,

      isWorklogRangeItem: true,
    });

    currentMoment.add(1, 'quarters');
  }

  result.push({
    textHeader: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
    header: `Q${currentMoment.quarter()} ${currentMoment.format('YY')}`,
    accessor: `${currentMoment.year()}-${currentMoment.quarter()}`,

    isWorklogRangeItem: true,
  });

  currentMoment.add(1, 'quarters');
};

const createYearsRange = (result, start, end) => {
  const currentMoment = moment(start);
  const endMoment = moment(end);

  if (currentMoment.year() === endMoment.year()) {
    result.push({
      textHeader: currentMoment.year(),
      header: currentMoment.year(),
      accessor: currentMoment.year(),

      isWorklogRangeItem: true,
    });

    return;
  }

  while (currentMoment.year() !== endMoment.year()) {
    result.push({
      textHeader: currentMoment.year(),
      header: currentMoment.year(),
      accessor: currentMoment.year(),

      isWorklogRangeItem: true,
    });

    currentMoment.add(1, 'years');
  }

  result.push({
    textHeader: currentMoment.year(),
    header: currentMoment.year(),
    accessor: currentMoment.year(),

    isWorklogRangeItem: true,
  });

  currentMoment.add(1, 'years');
};

export const getDatesArrayFromRange = (start, end, dateType) => {
  const result = [];

  const startDate = new Date(start);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(end);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);

  switch (dateType) {
    case 'worklogWeek': {
      createWeeksRange(result, startDate, endDate);
      break;
    }

    case 'worklogMonth': {
      createMonthsRange(result, startDate, endDate);
      break;
    }

    case 'worklogQuarter': {
      createQuartersRange(result, startDate, endDate);
      break;
    }

    case 'worklogYear': {
      createYearsRange(result, startDate, endDate);
      break;
    }

    // eslint-disable-next-line default-case-last
    default:
    case 'worklogDate': {
      createDatesRange(result, startDate, endDate);
      break;
    }
  }

  return result;
};
