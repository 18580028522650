import { FULL_DAY_IN_MILLISECONDS } from 'constants/constants';
import {
  getShortMonthNames, jiraDatesDelimiter,
} from 'constants/dateConstants';
import { moment } from 'controllers/moment';

export const period = (start, end) => Math.round(Math.abs((Number(start) - Number(end)) / FULL_DAY_IN_MILLISECONDS));

export const formatDateForSearch = (date, delimiter = jiraDatesDelimiter) => {
  if (!(date instanceof Date)) {
    throw new Error('date should be instance of Date');
  }

  const month = `${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}`;
  const dateString = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

  return `${date.getFullYear()}${delimiter}${month}${delimiter}${dateString}`;
};

export const formatDateForTable = (date, isExcel = false) => {
  if (date && date.getMonth) {
    const month = `${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}`;
    const dateString = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

    if (isExcel) {
      return { v: `${date.getFullYear()}-${month}-${dateString}`, t: 'd' };
    }

    return `${dateString}/${month}/${date.getFullYear()}`;
  }

  return '';
};

export const formatDateForDateGroup = (date, dateFormat) => {
  if (date) {
    return moment(date).formatWithJDF(dateFormat);
  }

  return '';
};

export const getTimeFromDate = (date) => {
  const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;

  return `${hours}:${minutes}`;
};

export const formatDateForTableHeader = (t, date, endDate) => {
  const SHORT_DAYS_ARRAY = [
    t('shortDays.sunday'), t('shortDays.monday'), t('shortDays.tuesday'), t('shortDays.wednesday'),
    t('shortDays.thursday'), t('shortDays.friday'), t('shortDays.saturday'),
  ];

  if (endDate) {
    const startMonth = date.getMonth();
    const endMonth = endDate.getMonth();

    if (startMonth === endMonth) {
      return [
        `${getShortMonthNames(t)[startMonth]} ${date.getDate()} - ${endDate.getDate()}`,
        date.getFullYear(),
      ];
    }

    return [
      `${date.getDate()} ${getShortMonthNames(t)[startMonth]} - ${endDate.getDate()} ${getShortMonthNames(t)[endMonth]}`,
      date.getFullYear() === endDate.getFullYear()
        ? date.getFullYear()
        : `${date.getFullYear()} - ${endDate.getFullYear()}`,
    ];
  }

  return [
    date.getDate(),
    SHORT_DAYS_ARRAY[date.getDay()],
  ];
};

export const createCurrentPeriod = (view, date) => {
  let start;
  let end;

  switch (view) {
    case 'work_month':
    case 'month':
      start = moment(date).startOf('month').toDate();
      end = moment(date).endOf('month').toDate();
      break;

    case 'week_without_time':
    case 'work_week_without_time':
    case 'work_week':
    case 'week':
      start = moment(date).startOf('week').toDate();
      end = moment(date).endOf('week').toDate();
      break;

    default:
      break;
  }
  return { start, end };
};

export const setDate = (date, daysCount, isEnd) => {
  if (typeof daysCount === 'number') {
    return moment(date).add(daysCount, 'days').toDate();
  }

  const startWithAddedMonth = moment(date).add(1, daysCount);

  const dateForStart = startWithAddedMonth.startOf(daysCount).toDate();
  const dateForEnd = startWithAddedMonth.endOf(daysCount).toDate();

  return isEnd
    ? dateForEnd
    : dateForStart;
};

export const setSubtractDate = (date, daysCount, isEnd) => {
  if (typeof daysCount === 'number') {
    return moment(date).subtract(daysCount, 'days').toDate();
  }

  const startWithAddedMonth = moment(date).subtract(1, daysCount);

  const dateForStart = startWithAddedMonth.startOf(daysCount).toDate();
  const dateForEnd = startWithAddedMonth.endOf(daysCount).toDate();

  return isEnd
    ? dateForEnd
    : dateForStart;
};
