import { useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { UserPropertyKeys } from 'constants/userPropertyKeys';
import { useCurrentUser } from 'store/hooks/useCurrentUser';
import { createQueryString } from 'utils/createQueryString';
import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { QueryParams } from 'types/queryParams';

type UserPropertyValue = any; // eslint-disable-line @typescript-eslint/no-explicit-any

interface GetAllUserPropertiesRawResponse {
  keys: Array<{ key: string }>;
}
interface GetUserPropertyRawResponse {
  value: UserPropertyValue;
}
type UserPropertiesIdentity = { accountId: string } | { userKey: string };
type GetAllUserPropertiesParams = UserPropertiesIdentity;
type GetUserPropertyParams = GetAllUserPropertiesParams & { propertyKey: UserPropertyKeys };
type UpdateUserPropertyParams = GetUserPropertyParams & { propertyKey: UserPropertyKeys; propertyValue: UserPropertyValue };

const pathPrefix = IS_CLOUD ? 'api/3/user/properties' : 'api/2/user/properties';

const type = 'userProperty' as const;

const createGetAllUserPropertiesTags = (qs: QueryParams) => {
  const { accountId, userKey } = qs;
  return [{ type, id: `all-${createQueryString({ accountId, userKey })}` }];
};

const createGetPropertyTags = (propertyKey: UserPropertyKeys, qs: QueryParams) => {
  const { accountId, userKey } = qs;
  return [
    ...createGetAllUserPropertiesTags(qs),
    { type, id: `${propertyKey}-${createQueryString({ accountId, userKey })}` },
  ];
};

export const userPropertyApi = jiraBaseApi.enhanceEndpoints({
  addTagTypes: [type],
}).injectEndpoints({
  endpoints: (builder) => ({
    getAllUserProperties: builder.query<string[], GetAllUserPropertiesParams>({
      query: (queryParams) => `${pathPrefix}/${createQueryString(queryParams)}`,
      transformResponse: ({ keys }: GetAllUserPropertiesRawResponse) => keys.map(({ key }) => key),
      providesTags: (result, error, queryParams) => createGetAllUserPropertiesTags(queryParams),
    }),

    getUserProperty: builder.query<UserPropertyValue, GetUserPropertyParams>({
      query: ({ propertyKey, ...queryParams }) => `${pathPrefix}/${propertyKey}${createQueryString(queryParams)}`,
      transformResponse: ({ value }: GetUserPropertyRawResponse) => value,
      providesTags: (result, error, { propertyKey, ...queryParams }) => createGetPropertyTags(propertyKey, queryParams),
    }),

    saveUserProperty: builder.mutation<undefined, UpdateUserPropertyParams>({
      query: ({ propertyValue, propertyKey, ...queryParams }) => {
        const body = propertyValue;

        return {
          url: `${pathPrefix}/${propertyKey}${createQueryString(queryParams)}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { propertyValue, propertyKey, ...queryParams }) => (
        error ? [] : createGetPropertyTags(propertyValue, queryParams)
      ),
    }),
  }),
});

const {
  useGetAllUserPropertiesQuery,
  useGetUserPropertyQuery,
  useSaveUserPropertyMutation,
} = userPropertyApi;

export const useCurrentUserPropertyIdentity = () => {
  const { data: currentUser } = useCurrentUser();
  if (currentUser) {
    const { userId } = currentUser;
    if (!userId) {
      return;
    }

    if (IS_CLOUD) {
      return { accountId: userId };
    }
    if (!IS_CLOUD) {
      return { userKey: userId };
    }
  }
};

const useCurrentUserProperties = () => {
  const userPropertiesIdentity = useCurrentUserPropertyIdentity();
  const skip = !userPropertiesIdentity;
  return useGetAllUserPropertiesQuery(skip ? skipToken : userPropertiesIdentity, { skip });
};

export const useCurrentUserProperty = (
  propertyKey: UserPropertyKeys,
) => {
  const userPropertiesIdentity = useCurrentUserPropertyIdentity();
  const { data: allProperties } = useCurrentUserProperties();

  const skip = !userPropertiesIdentity || !allProperties?.includes(propertyKey);

  return useGetUserPropertyQuery(skip ? skipToken : { ...userPropertiesIdentity, propertyKey }, { skip });
};

export const useSaveCurrentUserProperty = () => {
  const userPropertiesIdentity = useCurrentUserPropertyIdentity();

  const [saveUserProperty, meta] = useSaveUserPropertyMutation();

  const saveCurrentUserProperty = useCallback((propertyKey: UserPropertyKeys, propertyValue: UserPropertyValue) => {
    if (!userPropertiesIdentity) {
      throw new Error('Cannot save userProperty without current user identity');
    }

    return saveUserProperty({
      ...userPropertiesIdentity,
      propertyKey,
      propertyValue,
    });
  }, [userPropertiesIdentity, saveUserProperty]);

  return [saveCurrentUserProperty, meta] as const;
};