import { UrtVisualizationType } from 'sharedFrontBack/src/constants/urt';
import { ChartType } from 'chart.js/auto';

import { GeneralChartType } from 'features/urt/types';
import { UrtConfig } from 'sharedFrontBack/src/types/urt';
import { initialScopePickerValues } from 'features/legacy/Selectors/ScopePicker/initialScopePickerValues';
import { SortingTargets, SortingDirections } from 'actonic-pivotopia/dist';

export const commonChartDataSet = {
  borderWidth: 0,
  borderRadius: 0,
  borderSkipped: false,
};

export const pieChartDataSet = {
  ...commonChartDataSet,
  hoverOffset: 50,
  borderWidth: 5,
};

export const urtVisualizationTypeChartJsTypeMap: Record<GeneralChartType, ChartType> = {
  [UrtVisualizationType.barChart]: 'bar',
  [UrtVisualizationType.pieChart]: 'pie',
  [UrtVisualizationType.doughnutChart]: 'doughnut',
};

export const optionsInitialValue = {
  hideSubtotalColumns: false,
  isReportDetailsShown: true,
  dataTablePosition: {
    label: 'Not displayed',
    value: 'notDisplayed',
  },
};

export const stylesInitialValue = {
  fontSize: 14,
  fontWeight: '400',
};

export const conditionalFormatClassNamePrefix = 'condititional-format';

export const defaultStatusDimension = {
  name: 'status',
  group: { name: 'statusName' },
  format: { name: 'statusPretty' },
  filter: {
    name: 'none',
    inputType: 'status',
    values: [] as string[],
  },
} as const;

export const defaultIssueDimension = {
  name: 'issue',
  group: { name: 'issueDefault' },
  format: { name: 'issueDefault' },
  filter: {
    isDefault: true,
    inputType: 'issue',
    name: 'none',
    values: [],
  },
};

export const defaultCountOfIssuesMeasure = {
  name: 'countOfIssues',
  format: {
    name: 'countDefault',
    extraParams: { locale: 'default' },
  },
};

export const defaultConfig: UrtConfig = {
  visualizationType: UrtVisualizationType.matrix,
  scopePicker: initialScopePickerValues,
  styles: stylesInitialValue,
  rowsSorting: [{ target: SortingTargets.headers, direction: SortingDirections.asc }],
  rows: [
    {
      name: 'project',
      group: { name: 'projectDefault' },
      format: { name: 'projectNameAndIcon' },
      filter: {
        name: 'none',
        values: [],
      },
    },
    {
      name: 'issuetype',
      group: { name: 'issueTypeDefault' },
      format: { name: 'issueTypeNameAndIcon' },
      filter: {
        name: 'none',
        values: [],
      },
    },
  ],
  cols: [defaultStatusDimension],
  measures: [defaultCountOfIssuesMeasure],
  options: optionsInitialValue,
} as const;