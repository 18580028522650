/* eslint-disable import/first, import/order */
import 'utils/patchWebpackPublicPath';
import { initSentry, logError } from 'utils/sentry';

if (IS_CLOUD) {
  initSentry(); // we should init sentry as early as possible (to handle more possible errors)
}

import React from 'react';
import ReactDOM from 'react-dom';
import { initPlugin } from 'initPlugin';
import { NotReadyAppSpinner } from 'globalStyle';
import { reactAppRootId } from 'constants/constants';
import App from 'components/App';

async function initApp() {
  const rootNode = document.getElementById(reactAppRootId);

  if (!rootNode) {
    return false;
  }

  ReactDOM.render(<NotReadyAppSpinner />, rootNode);

  await initPlugin();

  ReactDOM.render(<App />, rootNode);

  return true;
}

if (IS_CLOUD) {
  const timeoutRef: { current?: ReturnType<typeof setTimeout> } = {};
  let isInited = false;

  window.AP.theming.initializeTheming(); // should be called as early as possible (update this comment if you will investigate why)

  const mayBeInit = async () => {
    if ('AP' in window && 'request' in AP) {
      isInited = await initApp().catch((e) => {
        logError('mayBeInit error', e);
        return false;
      });
    }

    if (!isInited) {
      timeoutRef.current = setTimeout(mayBeInit, 60);
    }
  };

  mayBeInit();
} else {
  window.AJS.toInit(() => {
    initApp();
  });
}
