import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { JiraAddon } from 'types/jiraAddon';

const pathPrefix = IS_CLOUD ? 'atlassian-connect/1/addons' : 'notsupportedapi';

export const addonsApi = jiraBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTmAddon: builder.query<JiraAddon, undefined>({
      query: () => `${pathPrefix}/de.actonic.tm`,
    }),
  }),
});

export const {
  useGetTmAddonQuery,
} = addonsApi;
