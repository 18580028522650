const deepObjectFind = (object, path) => {
  const paths = path.split('.');

  return paths.reduce((acc, currentPath) => {
    if (!acc || !acc[currentPath]) return undefined;

    acc = acc[currentPath];

    return acc;
  }, object);
};

const sortByNumberColumn = (data, path, isAscending) => data.sort((a, b) => {
  const aValue = deepObjectFind(a, path);
  const bValue = deepObjectFind(b, path);

  if (typeof aValue === 'undefined' || aValue === null) {
    return 1;
  }

  if (typeof bValue === 'undefined' || bValue === null) {
    return -1;
  }

  if (isAscending) {
    return aValue - bValue;
  }

  return bValue - aValue;
});

const sortByTextColumn = (data, path, isAscending) => data.sort((a, b) => {
  const aValue = deepObjectFind(a, path);
  const bValue = deepObjectFind(b, path);

  if (typeof aValue === 'undefined' || aValue === null) {
    return 1;
  }

  if (typeof bValue === 'undefined' || bValue === null) {
    return -1;
  }

  if (isAscending) {
    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;

    return 0;
  }

  if (aValue < bValue) return 1;
  if (aValue > bValue) return -1;

  return 0;
});

export const sortByParameters = (data, sortBy) => {
  const { isAscending, type, path } = sortBy;

  if (type === 'text') {
    sortByTextColumn(data, path, isAscending);
  } else {
    sortByNumberColumn(data, path, isAscending);
  }

  data.forEach((item) => {
    if (item._children && item._children.length > 0) {
      sortByParameters(item._children, sortBy);
    }
  });
};
