export default {
  // Calendar
  SET_WEEKENDS: 'SET_WEEKENDS',
  SET_CURRENT_PERIOD: 'SET_CURRENT_PERIOD',
  SET_CALENDAR_VIEW: 'SET_CALENDAR_VIEW',
  SET_WITH_TIME: 'SET_WITH_TIME',
  SET_CALCULATED_LOGS_SECONDS: 'SET_CALCULATED_LOGS_SECONDS',
  SET_LOGS: 'SET_LOGS',
  SET_CALENDAR_VIEW_CONFIG: 'SET_CALENDAR_VIEW_CONFIG',
  SET_IS_COLOR_ISSUE_STATUS: 'SET_IS_COLOR_ISSUE_STATUS',
  REMOVE_PLUS_BUTTON: 'REMOVE_PLUS_BUTTON',

  GET_LOGS_STARTED: 'GET_LOGS_STARTED',
  GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE: 'GET_LOGS_FAILURE',

  SET_LOGS_BY_DATES: 'SET_LOGS_BY_DATES',

  SET_MOUSE_ON_DATE: 'SET_MOUSE_ON_DATE',

  // Table
  SET_GROUP_BY: 'SET_GROUP_BY',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_DATES_RANGE: 'SET_DATES_RANGE',

  GET_TABLE_DATA_STARTED: 'GET_TABLE_DATA_STARTED',
  GET_TABLE_DATA_SUCCESS: 'GET_TABLE_DATA_SUCCESS',
  GET_TABLE_DATA_FAILURE: 'GET_TABLE_DATA_FAILURE',

  SET_DEFINED_PERIOD: 'SET_DEFINED_PERIOD',

  SET_ISSUES_DATA: 'SET_ISSUES_DATA',
};
