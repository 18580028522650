import { AdminConfig } from 'types/adminConfig';
import { pluginBaseApi } from 'store/api/plugin/baseApi/pluginBaseApi';

const type = 'adminConfigType' as const;
const id = 'adminConfigId' as const;

export const adminConfigApi = pluginBaseApi.enhanceEndpoints({
  addTagTypes: [type],
}).injectEndpoints({
  endpoints: (builder) => ({
    getAdminConfig: builder.query<Partial<AdminConfig>, void>({
      query: () => 'admin-config',
      providesTags: () => [{ type, id }],
    }),

    updateAdminConfig: builder.mutation<void, Partial<AdminConfig>>({
      query: (dto) => ({
        url: 'admin-config',
        method: 'PUT',
        body: dto,
      }),
      invalidatesTags: (result, error) => error ? [] : [{ type, id }],
    }),
  }),
});

export const {
  useGetAdminConfigQuery,
  useUpdateAdminConfigMutation,
} = adminConfigApi;
