import { TransportController as Transport, fieldControllerService } from 'controllers/index';
import { searchJqlPost } from 'controllers/transport/requestConfigurations';
import store from 'store';
import { setLoadingState } from 'store/legacy/progressBar/actions';
import { selectShouldUseTmWorklogs } from 'store/slices/appEnvironment/selectors';
import { dedupeArray } from 'utils/dedupeArray';
import { promiseAllWithConcurrency } from 'utils/promiseAllWithConcurrency';
import { createWorklogDateJql } from 'utils/createWorklogDateJql';

export class IssueService {
  // eslint-disable-next-line no-shadow
  constructor({ transport, store }) {
    this.transport = transport;
    this.store = store;
  }

  // todo remove createDateRangeJQLForWorklogs, use createWorklogDateJql
  createDateRangeJQLForWorklogs = createWorklogDateJql;

  extendWithParentsIssues = async (issues, fetchingFieldsList) => {
    const { jiraSoftwareFields } = store.getState().app;

    const epicKeyField = jiraSoftwareFields.epic?.id;

    const issuesKeys = issues.reduce(
      (acc, current) => {
        if (current && current.key) {
          acc[current.key] = true;
        }

        return acc;
      },
      {},
    );

    const epicsAndParentsIssueKeys = issues
      .reduce(
        (acc, { fields }) => {
          if (fields) {
            if (epicKeyField && fields[epicKeyField] && !issuesKeys[epicKeyField]) {
              acc.push(fields[epicKeyField]);
            }

            if (fields.parent && !issuesKeys[fields.parent.key]) {
              acc.push(fields.parent.key);
            }
          }

          return acc;
        },
        [],
      );

    if (epicsAndParentsIssueKeys.length > 0) {
      const parentIssues = await this.transport.request({
        config: searchJqlPost,
        data: {
          jql: `issuekey in (${dedupeArray(epicsAndParentsIssueKeys).join(', ')})`,
          fields: fetchingFieldsList,
          maxResults: 20,
          startAt: 0,
        },
      });

      return [...issues, ...parentIssues];
    }

    return issues;
  };

  extendWithParentsIssuesForSubtasks = async (issues, fetchingFieldsList) => {
    const { jiraSoftwareFields } = store.getState().app;

    const epicKeyField = jiraSoftwareFields.epic?.id;

    const issuesKeys = issues.reduce(
      (acc, current) => {
        if (current && current.key) {
          acc[current.key] = true;
        }

        return acc;
      },
      {},
    );

    const epicsAndParentsIssueKeys = issues
      .reduce(
        (acc, { fields }) => {
          if (fields) {
            if (epicKeyField && fields[epicKeyField] && !issuesKeys[epicKeyField]) {
              acc.push(fields[epicKeyField]);
            }

            if (fields.parent && !issuesKeys[fields.parent.key]) {
              acc.push(fields.parent.key);
            }
          }

          return acc;
        },
        [],
      );

    if (epicsAndParentsIssueKeys.length > 0) {
      const parentIssues = await this.transport.request({
        config: searchJqlPost,
        data: {
          jql: `issuekey in (${dedupeArray(epicsAndParentsIssueKeys).join(', ')})`,
          fields: fetchingFieldsList,
          maxResults: 20,
          startAt: 0,
        },
      });

      return [...issues, ...parentIssues.map((issue) => ({ ...issue, isVirtualParentIssue: true }))];
    }

    return issues;
  };

  extendWithChildrenIssues = async (issues, requestData) => {
    const parentIssuesKeys = issues.map(({ key }) => key);

    const subtasksKeys = issues
      .reduce(
        (acc, current) => {
          /* eslint-disable no-param-reassign */
          acc = acc.concat(current.fields.subtasks);

          return acc;
        },
        [],
      )
      .map(({ key }) => key);

    if (subtasksKeys && subtasksKeys.length > 0) {
      const subtasks = new Set(subtasksKeys);

      parentIssuesKeys.forEach((issueKey) => {
        subtasks.delete(issueKey);
      });

      const subtasksFetchList = Array.from(subtasks);

      if (Array.isArray(subtasksFetchList) && subtasksFetchList.length > 0) {
        const jql = `issuekey in (${subtasksFetchList.join(', ')})`;

        const subtasksIssues = await this.transport.request({
          config: searchJqlPost,
          data: {
            ...requestData,
            jql,
          },
        });

        return [...issues, ...subtasksIssues];
      }
    }

    return issues;
  };

  // eslint-disable-next-line default-param-last
  getIssues = async (jql, fetchingFieldsList, isWithProgressBar = false, currentReportId, fetchingOptions = {}) => {
    const { loadingStartedProcessId } = store.getState().progressBar;
    const {
      isParentsShouldBeFetched, isWithRenderedFields,
      isChildrenShouldBeFetched, isParentsForSubtaskShouldBeFetched,
    } = fetchingOptions;

    const loadingCallback = (loadingState) => {
      const { total, loaded } = loadingState;
      const { loadingStartedProcessId: processIdForCheck } = store.getState().progressBar;

      if (currentReportId !== processIdForCheck) {
        return;
      }

      store.dispatch(setLoadingState({
        total,
        loaded,
        step: 1,
      }));
    };

    if (loadingStartedProcessId !== currentReportId && isWithProgressBar) {
      return [];
    }

    const requestData = {
      jql,
      fields: isChildrenShouldBeFetched
        ? ['subtasks', ...fetchingFieldsList]
        : fetchingFieldsList,
      expand: isWithRenderedFields ? ['renderedFields'] : [],
      maxResults: 50,
      startAt: 0,
    };

    try {
      const issues = await this.transport.request({
        config: searchJqlPost,
        data: requestData,
        loadingCallback: isWithProgressBar && loadingCallback,
      });

      let resultIssues = issues;

      if (isParentsShouldBeFetched) {
        resultIssues = await this.extendWithParentsIssues(resultIssues, fetchingFieldsList);
      }

      if (isParentsForSubtaskShouldBeFetched) {
        resultIssues = await this.extendWithParentsIssuesForSubtasks(resultIssues, fetchingFieldsList);
      }

      if (isChildrenShouldBeFetched) {
        resultIssues = await this.extendWithChildrenIssues(resultIssues, requestData);
      }

      return resultIssues;
    } catch (error) {
      console.error('[issueService][getIssues] issueService - Error while getting issues', error);

      return [];
    }
  };

  getFieldsForIssues = async (fields, issues, currentReportId) => {
    try {
      const { loadingStartedProcessId } = store.getState().progressBar;

      if (loadingStartedProcessId !== currentReportId) {
        return [];
      }

      if (!Array.isArray(fields) || !Array.isArray(issues)) {
        throw new Error('[getFieldsForIssues] The passed parameters should be arrays.');
      }

      if (!loadingStartedProcessId) {
        return;
      }

      const issuesToExtend = issues.filter((issue) => {
        const { worklog: worklogData } = issue.fields;

        if (!worklogData) return false;

        const { maxResults, total } = worklogData;

        return total > maxResults;
      });

      store.dispatch(setLoadingState({
        loaded: 0,
        total: issuesToExtend.length,
        step: 2,
      }));

      const extendedIssues = [];

      const shouldUseTmWorklogs = selectShouldUseTmWorklogs(store.getState());

      const getIssueFields = (issue) => async () => {
        if (fields.includes('worklog')) {
          const worklogsList = shouldUseTmWorklogs
            ? await fieldControllerService()._getWorklogsWithTBProperty(issue, currentReportId)
            : await fieldControllerService()._getWorklogs(issue, currentReportId);

          /* eslint-disable no-param-reassign */
          issue = {
            ...issue,
            fields: {
              ...issue.fields,
              worklog: {
                ...issue.fields.worklog,
                worklogs: worklogsList,
              },
            },
          };

          const { worklog: worklogData } = issue.fields;

          const { maxResults, total } = worklogData;

          if (total > maxResults) {
            extendedIssues.push(issue.id);
          }

          store.dispatch(setLoadingState({
            loaded: extendedIssues.length,
            total: issuesToExtend.length,
            step: 2,
          }));
        }

        return issue;
      };

      const data = await promiseAllWithConcurrency(issues.map((issue) => getIssueFields(issue)), 20);

      return data;
    } catch (e) {
      console.error(`[IssueController][getFieldsForIssues] Exception: ${e}`);
      return issues;
    }
  };
}

let issueServiceInstance;
export const issueService = () => {
  if (!issueServiceInstance) {
    if (!Transport) {
      throw new Error('Transport service is not ready yet');
    }
    issueServiceInstance = new IssueService({ transport: Transport, store });
  }

  return issueServiceInstance;
};
