import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components5';

export const MainRoot = styled.div`
  ${() => window.ActonicRB?.isGadget && !IS_CLOUD ? css`
    padding-top: ${token('space.050', '4px')};
  ` : ''};
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const HiddenContainerForCanvas = styled.div`
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
`;