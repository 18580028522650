import { useSelector } from 'react-redux';
import { useGetBoardsQuery } from 'store/api/jira/boardApi';
import { useGetInstanceLicensesQuery } from 'store/api/jira/instanceLicenseApi';
import { selectAppEnvironment, selectShouldUseTmWorklogs } from 'store/slices/appEnvironment/selectors';
import { useGetTmAddonQuery } from 'store/api/jira/addonsApi';
import { useGetConfigQuery } from 'store/api/plugin/configApi';
import { useGetTeamPermissionsQuery } from 'store/api/pluginTm/teamPermissionApi';
import { useGetTmConfigQuery } from 'store/api/pluginTm/tmConfigApi';

const useCloudAppEnvironment = () => {
  useGetBoardsQuery({ name: '', startAt: 0, maxResults: 10 });
  useGetInstanceLicensesQuery(undefined);
  useGetTmAddonQuery(undefined);
};

const useServerAppEnvironment = () => {
  useGetConfigQuery(undefined);
  useGetTeamPermissionsQuery(undefined);
  useGetTmConfigQuery(undefined);
};

export const useAppEnvironment = () => {
  if (IS_CLOUD) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCloudAppEnvironment();
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useServerAppEnvironment();
  }

  const appEnvironment = useSelector(selectAppEnvironment);
  const shouldUseTmWorklogs = useSelector(selectShouldUseTmWorklogs);

  return {
    ...appEnvironment,
    shouldUseTmWorklogs,
    shouldUseTmSecureWorklogs: shouldUseTmWorklogs && !IS_CLOUD,
  };
};
