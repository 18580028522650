import { useState } from 'react';

export const useEditLogs = () => {
  const [isEditLogsOpen, setIsEditLogsOpen] = useState(false);

  const defaultEditLogsCellState = {
    currentKey: null,
    cellDate: null,
    cellType: null,
    cellWorklogs: null,
  };

  const [editLogsCellState, setEditLogsCellState] = useState(defaultEditLogsCellState);

  const setDefaultEditLogsCellState = () => {
    setEditLogsCellState(defaultEditLogsCellState);
  };

  return {
    editLogsCellState,
    isWorklogsModalOpen: isEditLogsOpen,
    setEditLogsCellState,
    setIsEditLogsOpen,
    setDefaultEditLogsCellState,
  };
};
