
const globalConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  server: {
    ...globalConfig,
    method: 'GET',
    url: '/rest/api/2/mypermissions',
  },
  cloud: {
    ...globalConfig,
    method: 'POST',
    url: '/rest/api/3/permissions/check',
  },
};

export { config as checkUserPermissions };
