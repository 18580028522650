import {
  TransportController as Transport,
  API,
} from 'controllers';
import { getTimeTrackingConfiguration } from 'controllers/transport/requestConfigurations';
import {
  getConfigurationStarted, getConfigurationSuccess,
  getConfigurationFailure, setPermissionsAction,
} from 'store/legacy/configuration/actions';

export const getConfiguration = () => async (dispatch) => {
  try {
    dispatch(getConfigurationStarted());
    const { data: {
      general,
      permissions,
      tmTeamsPermissions,
      tmTeamsListNames: actonicTeamsListNames,
    } } = await API.get('/admin-config');
    const actonicPermissions = IS_CLOUD ? tmTeamsPermissions.permissions : permissions;

    const timeTrackingConfiguration = await Transport.request({
      config: getTimeTrackingConfiguration,
    });

    window.ActonicRB = window.ActonicRB || {};
    window.ActonicRB.timeTrackingConfiguration = timeTrackingConfiguration;

    dispatch(getConfigurationSuccess({ general, permissions, actonicPermissions, actonicTeamsListNames }));
  } catch (error) {
    console.error('[store.configuration.actions][getConfiguration] Error while getting configuration for admin-config', error);
    dispatch(getConfigurationFailure(error));
  }
};

export const setPermissions = (id, permissions) => (dispatch) => {
  dispatch(setPermissionsAction({ id, permissions }));
};
