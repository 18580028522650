const globalConfig = {
  method: 'GET',
  allPages: true,
  fieldName: 'issues',
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/search?jql=',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/search?jql=',
  },
};

export { config as searchJql };
