export default {
  SET_CONFIGURATION_CURRENT_TAB: 'SET_CONFIGURATION_CURRENT_TAB',
  GET_PERMISSIONS_STARTED: 'GET_PERMISSIONS_STARTED',
  GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILURE: 'GET_PERMISSIONS_FAILURE',

  SET_PERMISSIONS: 'SET_PERMISSIONS',

  SAVE_CONFIGURATION_SUCCESS: 'SAVE_CONFIGURATION_SUCCESS',
  SAVE_CONFIGURATION_FAILURE: 'SAVE_CONFIGURATION_FAILURE',

  SET_GENERAL_CONFIGURATION: 'SET_GENERAL_CONFIGURATION',

  GET_CONFIGURATION_STARTED: 'GET_CONFIGURATION_STARTED',
  GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_FAILURE: 'GET_CONFIGURATION_FAILURE',

  SET_ADDON_VISIBILITY_SUCCESS: 'SET_ADDON_VISIBILITY_SUCCESS',
  SET_ADDON_VISIBILITY_FAILURE: 'SET_ADDON_VISIBILITY_FAILURE',

  SAVE_ADDON_VISIBILITY_SUCCESS: 'SAVE_ADDON_VISIBILITY_SUCCESS',
  SAVE_ADDON_VISIBILITY_FAILURE: 'SAVE_ADDON_VISIBILITY_FAILURE',

  GET_ADDON_VISIBILITY_START: 'GET_ADDON_VISIBILITY_START',
  GET_ADDON_VISIBILITY_ERROR: 'GET_ADDON_VISIBILITY_ERROR',
  GET_ADDON_VISIBILITY_SUCCESS: 'GET_ADDON_VISIBILITY_SUCCESS',

  SET_ADDON_ACTIVE_MODULES: 'SET_ADDON_ACTIVE_MODULES',
  SET_TEAM_PERMISSIONS_ENABLED: 'SET_TEAM_PERMISSIONS_ENABLED',
};
