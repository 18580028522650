export const findLogsValuesInRange = (logsByDates, datesRange) => Object.entries(logsByDates)
  .map(([key, value]) => {
    const [date, month, year] = key.split('/');
    const { startDate, endDate } = datesRange;
    const { start, end } = {
      start: new Date(startDate.getTime()),
      end: new Date(endDate.getTime()),
    };

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    if (!month || !date || !year) return false;

    const itemDate = new Date(`${month}/${date}/${year}`);

    const itemTime = itemDate.getTime();
    const startTime = start.getTime();
    const endTime = end.getTime();

    if (itemTime >= startTime && itemTime <= endTime) {
      return value;
    }

    return false;
  })
  .filter((item) => !!item)
  .reduce((acc, item) => acc + item, 0);

export const calculateProgress = (timespent, timeoriginalestimate) => {
  if (timespent && timeoriginalestimate) {
    return Math.round((timespent / timeoriginalestimate) * 100) / 100;
  }

  return 1;
};

export const calculateRemainingProgress = (timespent, timeestimate) => {
  if (timespent && timeestimate) {
    return Math.round((timespent / (timespent + timeestimate)) * 100) / 100;
  }

  return 1;
};

export const getJwtToken = async () => {
  if (IS_CLOUD) {
    return new Promise((resolve) => {
      window.AP?.context?.getToken((token) => {
        resolve(token);
      });
    });
  }
};
