import { getContextPath } from 'utils/getContextPath';

export const getPluginApiBaseUrl = () => {
  if (IS_CLOUD) {
    return `${window.baseUrl}/rest/api`;
  }

  if (window.AJS) {
    return `${getContextPath()}/rest/worklog-pivot/1.0/api`;
  }

  return '';
};