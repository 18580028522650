import { token } from '@atlaskit/tokens';
import React, { useState } from 'react';
import Heading from '@atlaskit/heading';
import styled, { createGlobalStyle, css } from 'styled-components5';
import Spinner from 'actonic-ui-components/dist/components/Spinner';
import Button from 'actonic-ui-components/dist/components/Button';
import { globalPrintStyles } from 'features/print/constants/globalStyles';
import { flexCenterdSpinnerStyle } from 'constants/styles';
import { pageContainerHorizontalPadding, switcherWidth, mainBgColor } from 'constants/style';
import Group, { GapSize } from 'actonic-ui-components/dist/components/Group';
import { Text } from 'components/Text/Text';
import { ReactChild } from 'types/Global';
import usePersistentCallback from 'hooks/usePersistentCallback';
import { ifProp } from "styled-tools";

export { default as Group } from 'actonic-ui-components/dist/components/Group';
export { default as BorderedGroup } from 'actonic-ui-components/dist/components/BorderedGroup';

export const GlobalStyle = createGlobalStyle`
  
  ${() => window.ActonicRB?.isGadget && !IS_CLOUD ? css`
    html, body {
      width: 100%;
      height: 100%;
    }
  ` : ''};
  
  body {
    padding-right: 0 !important;
    background-color: ${mainBgColor};
  }
  
  h3 {
    margin-top: 12px;
  }
  
  #rb-root {
    display: flex;
    flex-direction: column;
  }

  .atlaskit-portal:first-of-type {
    z-index: 701 !important;
  }

  #export-visibility-settings-popup {
    z-index: 701;
  }

  #filter-by-selector-popup {
    overflow: visible;
  }
  
  .rbc-row-content {
    z-index: 3 !important;
  }
  
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none !important;
  }
  
  .project-picker {
    input:focus {
      outline: none !important;
      box-shadow: none;
    }
  }
  
  .ace_scrollbar-v {
    overflow-y: hidden !important;
  }
  
  a:focus {
    outline: none !important;
  }
  
  .td {
    word-break: break-word !important;
  }

  .filter-by-selector-popover {
    overflow: visible !important;
  }
  
  .rbc-overlay {
    max-height: 500px;
    overflow: auto;
  }
  
  .rbc-header {
    line-height: 17px;
  }
  
  .rbc-time-view .rbc-header {
      border: 1px solid #DDD;
  }

  li#issue-item {
    list-style-type: none;
  }

  #group-by-popup {
    overflow: visible;
  }

  #empty-list-icon-container {
    span {
      height: 120px;
      width: 120px;
    }

    span > svg {
      height: 120px !important;
      width: 120px !important;
    }
  }

  #rb-root {
    height: 100%;
  }

  #jsd-widget {
    visibility: hidden;
  }
  
  .button-for-dropdown {
    margin-bottom: 8px;
  }
  
  #open-dropdown {
    margin: 0;
  }

  .button-for-dropdown > span {
    width: 100% !important;

    justify-content: space-between !important;
    display: flex !important;

    span:first-child {
      flex: 0 0 auto !important;
    }
  }
  
  ${globalPrintStyles}
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const DefaultSignikaText = styled.div`
  font-size: 21px;
  color: ${token('color.text')};
  font-weight: bold;
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const Label = styled.label`
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: #6B778C;
  font-weight: 600;
  display: inline-block;
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const TH = styled.span`
    color: #7a869a;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.66666667;
    letter-spacing: 0;
    text-transform: none;
`;

export const TransparentButton = styled(Button)`
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  padding: 0;
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  max-width: 100%;
  padding: ${token('space.050')} 5px;
  text-transform: uppercase;
  vertical-align: baseline;
  cursor: pointer;
    
  background-color: ${ifProp('isSelected', token('color.background.accent.gray.subtler.pressed'), token('color.background.accent.gray.subtler'))};
  color: ${ifProp('isSelected', token('color.text.inverse'), token('color.text'))};
`;

export const SelectedTag = styled(Tag)`
  background-color: ${token('color.background.accent.gray.subtler.pressed')};
  color: ${token('color.text.inverse')};
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const RedTag = styled(Tag)`
  background-color: #FFEBE6;
  color: #DE350B;
`;

export const TagsTitle = styled.div`
  margin: ${token('space.250')} 0 0;
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${pageContainerHorizontalPadding}px; /* note that jira uses 32px offset from top bar, but our desing not ready for too large offset */
`;

export const PageTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const PageHeader = styled.div`
  font-size: 24px;
  margin-left: 21px;
`;

const maxWidthCompensation = pageContainerHorizontalPadding * 2 + switcherWidth;

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${token('space.200')};
  max-width: calc(100vw - ${maxWidthCompensation}px);
`;

export const TableHeaderText = styled.div`
  font-size: ${token('font.size.075', '12px')};
  font-weight: ${token('font.weight.medium', '600')};
`;

const blockSpinnerStyle = {
  height: '100px',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  top: 'auto',
  left: 'auto',
  zIndex: 100,
};

const appSpinnerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  top: 0,
  zIndex: 100,
} as const;

export const ContentSpinner = () => (<Spinner isVisible size='large' style={blockSpinnerStyle} />);

interface ContentSpinnerWithHintProps {
  title?: string;
  description?: ReactChild;
  gap?: GapSize;
  cancelButton?: JSX.Element;
}

export const ContentSpinnerWithHint = ({
  title,
  description,
  cancelButton,
  gap = GapSize.s6,
}: ContentSpinnerWithHintProps) => {
  const [minWidth, setMinWidth] = useState(0);

  const handleDynamicContainerResize = usePersistentCallback<ResizeObserverCallback>((entries) => {
    const [firstEntry] = entries;
    if (!firstEntry) {
      return;
    }
    if (firstEntry.contentRect.width > minWidth) {
      setMinWidth(firstEntry.contentRect.width);
    }
  });

  const dynamicContainerResizeObserver = new ResizeObserver(handleDynamicContainerResize);
  const handleDynamicContainerRef = usePersistentCallback((current: HTMLDivElement | null) => {
    if (current) {
      dynamicContainerResizeObserver.observe(current);
    } else {
      dynamicContainerResizeObserver.disconnect();
    }
  });

  return (
    <Group direction='row' flex='1' justifyContent='center' gap={GapSize.s0}>
      <Group direction='column' gap={GapSize.s0}>
        <Group gap={gap}>
          <Spinner size='large' isVisible style={{ ...blockSpinnerStyle, flex: 'unset', width: '50px', height: '50px' }} />
          {(title || description) && (
            <Group gap={GapSize.s2} direction='column' ref={handleDynamicContainerRef} style={{ minWidth: `${minWidth}px` }}>
              {title && <Heading level='h500'>{title}</Heading>}
              {description && <Text appearance='secondary'>{description}</Text>}
            </Group>
          )}
        </Group>

        {cancelButton && (
          <Group gap={gap}>
            <div style={{ width: '50px' }} />
            {cancelButton}
          </Group>
        )}
      </Group>
    </Group>
  );
};

export const AppSpinner = () => (
  <div style={window.ActonicRB?.isIssuePanel ? blockSpinnerStyle : appSpinnerStyle}>
    <Spinner isVisible size='large' style={flexCenterdSpinnerStyle} />
  </div>
);

export const NotReadyAppSpinner = () => {
  return (
    <>
      <GlobalStyle />
      <AppSpinner />
    </>
  );
};
