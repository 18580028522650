import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'hooks';
import { routeChanged } from 'store/globalActions';

export const RouteWatcher = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    dispatch(routeChanged({ from: prevLocation, to: location }));
  }, [location, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};