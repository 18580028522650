import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getJwtToken } from 'utils/countWorklogs';
import { getPluginTmApiBaseUrl } from 'store/api/pluginTm/baseApi/getPluginTmApiBaseUrl';

export const pluginTmBaseApi = createApi({
  reducerPath: 'pluginTmApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getPluginTmApiBaseUrl(),
    ...(IS_CLOUD ? {
      prepareHeaders: async (headers) => {
        const token = await getJwtToken();
        headers.set('Authorization', `JWT ${token}`);
        return headers;
      },
    } : {}),
  }),
  endpoints: () => ({}),
});