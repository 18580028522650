/* eslint-disable @stylistic/multiline-ternary */
import { SrFieldTypes } from 'sharedFrontBack/src/constants/sr';
import { TFunction } from '../../../hooks/useTranslation';

export const fieldTypes = SrFieldTypes;

export const getFieldTypesSelectValues = (t: TFunction) => [
  { value: fieldTypes.STRING, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.string') },
  { value: fieldTypes.NUMBER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.number') },
  { value: fieldTypes.DATE, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.date') },
  { value: fieldTypes.CHECKBOX, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.checkbox') },
  { value: fieldTypes.TEXTAREA, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.textarea') },
  { value: fieldTypes.RADIO_BUTTON, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.radioButton') },
  { value: fieldTypes.JQL, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.jql') },
  { value: fieldTypes.PROJECT_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.project') },
  { value: fieldTypes.USER_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.userPicker') },
  { value: fieldTypes.RANGE_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.rangePicker') },
  { value: fieldTypes.FIELDS_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.fieldsPicker') },
  { value: fieldTypes.ISSUE_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.issuePicker') },
  { value: fieldTypes.ISSUE_TYPE_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.issueTypePicker') },
  { value: fieldTypes.SELECT_LIST, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.selectList') },
  { value: fieldTypes.SCOPE_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.filterBy') },
  { value: fieldTypes.ATTACHMENT_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.attachmentPicker') },
  { value: fieldTypes.SPRINT_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.sprintPicker') },
  { value: fieldTypes.TEAM_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.teamPicker') },
  { value: fieldTypes.STATUS_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.statusPicker') },
  ...(!IS_CLOUD
    ? [{ value: fieldTypes.LABEL_PICKER, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.labelPicker') }]
    : []),
  { value: fieldTypes.READ_ONLY_TEXT, label: t('scriptedReports.fieldsConfiguration.fieldTypesList.readOnlyText') },
] as const;
