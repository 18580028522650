import { request } from 'store/api/jira/baseApi/request';
import { promiseAllLimited } from 'utils/promiseAllLimited';
import { ResponseBody, PaginatedRequestParams } from 'store/api/jira/baseApi/types';
import { mergeResponseBody } from 'store/api/jira/baseApi/mergeResponseBody';

interface InnerRequestWithPaginationParams {
  requestParams: ({
    type: 'GET' ;
    data?: undefined;
  } | {
    type: Parameters<typeof window.AP.request>[0]['type'];
    data?: {
      startAt?: number;
    };
  }) & {
    url: string;
  };
  requestId?: string;
  requestLoadingCallback?: PaginatedRequestParams['requestLoadingCallback'];
  signal: AbortSignal;
}

export const requestWithPagination = async <
  ResponseJson extends ResponseBody,
>({
  requestParams, requestLoadingCallback, requestId, signal,
}: InnerRequestWithPaginationParams) => {
  const startTime = performance.now();

  if (requestLoadingCallback) {
    requestLoadingCallback({ requestId, total: 0, fetched: 0, isFetching: true });
  }

  const firstResponseBody = await request<ResponseBody, true>(requestParams, true);
  const limit = firstResponseBody?.maxResults ?? 100;

  if (!firstResponseBody?.total || firstResponseBody.total <= limit) {
    if (requestLoadingCallback) {
      requestLoadingCallback({ requestId, total: 0, fetched: 0, isFetching: false });
    }
    return firstResponseBody;
  }

  const { total } = firstResponseBody;
  const offset = limit;

  const amountLeft = total - offset;
  if (amountLeft <= 0) {
    if (requestLoadingCallback) {
      requestLoadingCallback({ requestId, total: 0, fetched: 0, isFetching: false });
    }
    return;
  }

  const afterEachResolveCallback = (resolvedPromises: number) => {
    if (requestLoadingCallback) {
      const fetched = (resolvedPromises + (offset ? offset / limit : 0)) * limit;
      requestLoadingCallback({ requestId, total, fetched, isFetching: true });
    }
  };

  afterEachResolveCallback(0);

  const partsCount = Math.ceil(amountLeft / limit);

  const promiseRunners = Array(partsCount).fill(null).map((_, i) => () => {
    const startAt = offset + i * limit;
    const { type, url, data, ...restRequestParams } = requestParams;
    let partUrl = url;
    const partData = { ...(data ?? {}) };

    if (type === 'GET') {
      const urlObj = new URL(url as string);
      const urlSp = new URLSearchParams(urlObj.search);
      urlSp.set('startAt', startAt.toString());
      urlObj.search = urlSp.toString();
      partUrl = urlObj.toString();
    } else {
      partData.startAt = startAt;
    }

    const partParams = {
      ...restRequestParams,
      type,
      url: partUrl,
      data: partData,
    };

    if (signal.aborted) {
      return Promise.reject(signal.reason);
    }

    return request<ResponseJson, true>(partParams, true);
  });

  if (signal.aborted) {
    return Promise.reject(signal.reason);
  }

  const restReponseBodies = await promiseAllLimited<ResponseJson>(promiseRunners, {
    interval: 15,
    concurrency: 30,
    afterEachResolveCallback,
  });

  if (requestLoadingCallback) {
    requestLoadingCallback({ requestId, total, fetched: total, isFetching: false });
  }

  if (restReponseBodies) {
    const reponseBody = mergeResponseBody<keyof ResponseBody, ResponseBody>([
      firstResponseBody,
      ...restReponseBodies,
    ]);

    if (RUNTIME_ENV === 'development') {
      const time = ((performance.now() - startTime) / 1000);
      // eslint-disable-next-line no-console
      console.info(
        `Paginated request finished in ${time} by ${restReponseBodies.length + 1} requests.`,
        requestParams,
      );
    }

    return reponseBody;
  }
};