import { hotjar } from 'react-hotjar';

const hjid = 2976033;
const hjsv = 6;

let isHotjarInited = false;

export const initHotjar = () => {
  if (isHotjarInited) {
    console.log('hotjar already inited');
    return;
  }

  isHotjarInited = true;

  hotjar.initialize(hjid, hjsv);
};