export enum PermissionTypes {
  public = 'public',
  private = 'private',
}

export interface Permission {
  type: PermissionTypes.private | PermissionTypes.public;
  value: string;
}

export interface TelemetryConfig {
  isHotjarEnabled: boolean;
  isAmplitudeEnabled: boolean;
  isSentryEnabled: boolean;
}

export interface PermissionsConfig {
  teamManager: Permission[];
  teamGlobalAdmin: Permission[];
  scriptedReportsManager: Permission[];
  issuePanelExport?: Permission[];
}

export interface GeneralConfig {
  periodsEnabled: boolean;
  periodsConfiguration: string;
  periodsStartDate: number;
  startWeekly: string;
  dateFormat: string;
  appMenuName: string;
}

export type AddonVisibilityConfig = string[];

export interface AdminConfig {
  general: GeneralConfig;
  permissions: PermissionsConfig;
  telemetry: TelemetryConfig;
  addonVisibility?: AddonVisibilityConfig;
}

export interface AdminConfigState extends AdminConfig {
  isLoaded: boolean;
}
