const globalConfig = {
  method: 'GET',
  allPages: false,
  url: '/rest/api/2/field',
};

const config = {
  server: {
    ...globalConfig,
  },
  cloud: {
    ...globalConfig,
  },
};

export { config as getFields };
