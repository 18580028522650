import { token } from '@atlaskit/tokens';
import React from 'react';
import tableIcon from 'pages/ReportLegacyPage/ReportViews/UniversalReport/ReportSidebar/icons/table.png';
import { UniversalReportLookingFor } from 'sharedFrontBack/src/constants/universalReport';

export const createDataTypeOptions = (t) => [
  {
    label: t ? t('universalReport.sidebar.dataTypeOptions.issues') : 'Issues',
    value: UniversalReportLookingFor.issues,
  },
  {
    label: t ? t('universalReport.sidebar.dataTypeOptions.worklogs') : 'Issues + Work logs',
    value: UniversalReportLookingFor.worklogs,
  },
];

export const formatOptionLabel = (option, { context }) => {
  if (context === 'menu') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {option.avatar ? option.avatar : ''}
        <span
          style={{
            paddingLeft: token('space.100'),
            paddingBottom: 0,
          }}
        >
          {option.label}
        </span>
      </div>
    );
  }
  return option.label;
};

export const createReportViewTypeOptions = (t) => [
  {
    label: t ? t('universalReport.sidebar.reportViewTypeOptions.table') : 'Table',
    value: 'table',
    avatar: (
      <img
        alt='project icon'
        height='16'
        width='16'
        align='absmiddle'
        src={tableIcon}
      />
    ),
  },
  {
    label: t ? t('universalReport.sidebar.reportViewTypeOptions.number') : 'Number',
    value: 'number',
  },
  {
    label: t ? t('universalReport.sidebar.reportViewTypeOptions.pieChart') : 'Pie Chart',
    value: 'pieChart',
  },
  {
    label: t ? t('universalReport.sidebar.reportViewTypeOptions.doughnutChart') : 'Doughnut Chart',
    value: 'doughnutChart',
  },
  {
    label: t ? t('universalReport.sidebar.reportViewTypeOptions.barChart') : 'Bar Chart',
    value: 'barChart',
  },
  // {
  //   label: 'Line Chart',
  //   value: 'lineChart',
  // },
  // {
  //   label: 'Bar Chart',
  //   value: 'barChart',
  // },
];

export const createAggregationsTypesOptions = (t) => [
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.count') : 'COUNT',
    value: 'COUNT',
  },
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.sum') : 'SUM',
    value: 'SUM',
  },
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.average') : 'AVERAGE',
    value: 'AVERAGE',
  },
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.max') : 'MAX',
    value: 'MAX',
  },
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.min') : 'MIN',
    value: 'MIN',
  },
  {
    label: t ? t('universalReport.sidebar.aggregationsTypesOptions.median') : 'MEDIAN',
    value: 'MEDIAN',
  },
];

export const getAggregationTargetsOptions = (t, dataType, aggregationType, numberFieldsOptions) => {
  if (aggregationType === 'COUNT') {
    return [
      {
        label: t ? t('universalReport.sidebar.aggregationTargetsOptions.issues') : 'Issues',
        value: 'issues',

        dataTypes: ['issues'],
      },
      {
        label: t ? t('universalReport.sidebar.aggregationTargetsOptions.subtasks') : 'Sub-tasks',
        value: 'subtasks',

        dataTypes: ['issues'],
      },
      {
        label: t ? t('universalReport.sidebar.aggregationTargetsOptions.worklogs') : 'Work logs',
        value: 'worklogs',

        dataTypes: ['worklogs'],
      },
      {
        label: t ? t('universalReport.sidebar.aggregationTargetsOptions.worklogsAuthors') : 'Worklogs Authors',
        value: 'worklogsAuthors',

        dataTypes: ['worklogs'],
      },
    ]
      .filter(({ dataTypes }) => dataTypes.includes(dataType));
  }

  const targetsOptions = [
    {
      label: 'Story Points',
      value: 'storyPoints',

      dataTypes: ['issues'],
      isSoftwareOption: true,
    },
    {
      label: 'Original Estimations',
      value: 'originalEstimation',

      dataTypes: ['issues'],
    },
    {
      label: 'Remaining Estimate',
      value: 'remainingEstimate',

      dataTypes: ['issues'],
    },
    {
      label: 'Logged time',
      value: 'loggedTime',

      dataTypes: ['issues', 'worklogs'],
    },
  ];

  const numberFieldsOptionsAsTargets = numberFieldsOptions
    .map(({ key, name, id }) => ({ label: name, value: key || id, dataTypes: ['issues'] }));

  return [
    ...targetsOptions
      .filter(
        ({ label }) => !numberFieldsOptionsAsTargets.find(
          ({ label: numberOptionLabel }) => numberOptionLabel === label,
        ),
      ),
    ...numberFieldsOptionsAsTargets,
  ]
    .filter(({ dataTypes }) => dataTypes.includes(dataType));
};
