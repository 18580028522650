import produce from 'immer';
import types from 'store/legacy/filterBySelector/types';

const initialState = {
  jqlField: [],
  projectValues: [],
  epicValues: [],
  issueValues: [],
  userValues: [],
  teamValues: [],
  filterValues: [],

  teamsJQL: '',
  usersJQL: '',

  issuesCount: 0,
  isIssuesCountFetching: false,

  isFilterDataFetching: false,
  fullJQL: '',

  isFilterApplied: true,
};

export const filterBySelector = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_ISSUES_COUNT_STARTED:
      return { ...state, isIssuesCountFetching: true };

    case types.GET_ISSUES_COUNT_SUCCESS:
      return { ...state, issuesCount: action.payload.issuesCount, isIssuesCountFetching: false };

    case types.GET_FILTER_BY_DATA_STARTED:
      return { ...state, isFilterDataFetching: true };

    case types.GET_FILTER_BY_DATA_SUCCESS: {
      const { issues, users, projects, epics, teams, filters } = action.payload;

      return {
        ...state,
        issues,
        users,
        projects,
        epics,
        teams,
        filters,
        isFilterDataFetching: false,
      };
    }

    case types.GET_FILTER_BY_DATA_FAILURE:
      return { ...state, isFilterDataFetching: false, error: action.error };

    case types.CHANGE_JQL_FIELD: {
      const { jqlField } = action.payload;

      return { ...state, jqlField, isFilterApplied: false };
    }

    case types.REMOVE_SELECTS_VALUES:
      return {
        ...state,
        projectValues: [],
        epicValues: [],
        issueValues: [],
        userValues: [],
        teamValues: [],
        filterValues: [],
        currentFilter: [],
        filterAsString: '',
        usersJQL: '',
        teamsJQL: '',
        issuesCount: 0,
        jqlField: [],
        fullJQL: '',
      };

    case types.SET_PROJECTS_VALUES:
      return { ...state, projectValues: action.payload, isFilterApplied: false };

    case types.SET_EPICS_VALUES:
      return { ...state, epicValues: action.payload, isFilterApplied: false };

    case types.SET_ISSUES_VALUES:
      return { ...state, issueValues: action.payload, isFilterApplied: false };

    case types.SET_USERS_VALUES:
      return { ...state, userValues: action.payload, isFilterApplied: false };

    case types.SET_TEAMS_VALUES:
      return { ...state, teamValues: action.payload, isFilterApplied: false };

    case types.SET_TEAMS_JQL:
      return { ...state, teamsJQL: action.payload, isFilterApplied: false };

    case types.SET_USERS_JQL:
      return { ...state, usersJQL: action.payload, isFilterApplied: false };

    case types.SET_FILTERS_VALUES:
      return { ...state, filterValues: action.payload, isFilterApplied: false };

    case types.REMOVE_JQL:
      return { ...state, filterAsString: '' };

    case types.SET_FULL_JQL:
      return { ...state, fullJQL: action.payload ?? '' };

    case types.SET_IS_FILTER_APPLIED: {
      const { isFilterApplied } = action.payload;

      return { ...state, isFilterApplied };
    }

    default:
      return state;
  }
});
