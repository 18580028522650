import { getWatchersForIssue } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: false,
  migration: getWatchersForIssue,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issue/',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issue/',
  },
};

export { config as getWatchersForIssue };
