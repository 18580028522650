const globalConfig = {
  method: 'GET',
  allPages: false,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issuetype',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issuetype',
  },
};

export { config as getIssueTypes };
