import { createJQL } from 'store/legacy/filterBySelector/createJQL';

import { ScopePickerOption } from 'sharedFrontBack/src/types/scopePicker';

interface Payload {
  filters: Array<ScopePickerOption>;
  teamsJQL?: string;
  usersJQL?: string;
  customJQL?: string;
}

export const createJqlFromFilterByValues = ({ filters, customJQL }: Payload) => {
  const projectsJQL = createJQL(filters, 'project in (', 'project');
  const epicsJQL = createJQL(filters, '"Epic Link" in (', 'epic');
  const issuesJQL = createJQL(filters, 'issue in (', 'issue');

  const jiraFilters = filters.filter(({ type }) => type === 'filter');
  const predefinedFilters = filters.filter(({ type }) => type === 'predefinedFilter');

  const filtersJQL = jiraFilters.reduce((acc, current, index) => {
    if (index === 0) {
      acc += `(filter in (${current.value}`;
    } else {
      acc += `, ${current.value}`;
    }

    if (index === jiraFilters.length - 1) {
      acc += '))';
    }
    return acc;
  }, '');

  const predefinedFiltersJQL = predefinedFilters.reduce((acc, current, index) => {
    if (index === 0) {
      acc += `(${current.value}`;
    } else {
      acc += ` OR ${current.value}`;
    }

    if (index === predefinedFilters.length - 1) {
      acc += ')';
    }
    return acc;
  }, '');

  const jqlInArray: string[] = [
    projectsJQL, epicsJQL, issuesJQL,
    filtersJQL, predefinedFiltersJQL,
  ];

  const resultArray = jqlInArray.filter((item) => !!item);

  let filterAsString = resultArray.reduce((acc, item, index) => {
    if (index > 0) {
      acc += ' OR ';
    }

    acc += item;

    return acc;
  }, '');

  if (customJQL) {
    filterAsString = filterAsString
      ? `${filterAsString} OR ${customJQL}`
      : customJQL;
  }

  return filterAsString;
};