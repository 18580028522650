export const tryMultipleTimes = (func, callback, tries = 0, milliseconds = 250) => {
  let count = 0;
  const attempt = setInterval(() => {
    count++;
    const result = func();
    if (result || count >= tries) {
      clearInterval(attempt);
      callback(result);
    }
  }, milliseconds);
};
