// @ts-expect-error fixme
export const validateGroupBy = (fieldsIds, groupBy) => (
  // @ts-expect-error fixme
  groupBy.filter((group) => !!fieldsIds.find((id) => id === group))
);

// @ts-expect-error fixme
export const validateSortBy = (sortBy) => {
  if (typeof sortBy !== 'object') {
    const isAscending = sortBy === 'asc';

    return {
      isAscending,
      type: 'text',
      path: 'name',
    };
  }

  return sortBy;
};
