const globalConfig = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issue/',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issue/',
  },
};

export { config as getCommentByIssueId };
