import i18next from 'i18next';
import {
  getIssueFields,
} from 'controllers/transport/requestConfigurations';
import { TransportController as Transport } from 'controllers';

import { setConfig } from 'store/legacy/thunks';
import {
  getFieldsFailure, getFieldsSuccess,
  setFieldsOptions,
  setIsUnapprovedReportsEnabled, setGalleryViewType,
  setReportsListSortBy,
} from 'store/legacy/app/actions';
import { calculatedFieldsList } from 'store/legacy/app/calculatedFieldsList';
import { defaultReportsListSortBy } from 'constants/report';

export const getFieldsList = () => async (dispatch) => {
  try {
    const fieldsList = await Transport.request({ config: getIssueFields });

    const epic = fieldsList.find(
      ({ name, untranslatedName }) => name === 'Epic Link' || untranslatedName === 'Epic Link',
    );

    const epicName = fieldsList.find(
      ({ name, untranslatedName }) => name === 'Epic Name' || untranslatedName === 'Epic Name',
    );

    const storyPoints = fieldsList.find(
      ({ name, untranslatedName }) => name === 'Story Points' || untranslatedName === 'Story Points',
    );

    const epicStatus = fieldsList.find(
      ({ name, untranslatedName }) => name === 'Epic Status' || untranslatedName === 'Epic Status',
    );

    const sprint = fieldsList.find(
      ({ name, untranslatedName }) => name === 'Sprint' || untranslatedName === 'Sprint',
    );

    const areSoftwareFieldsInitialized = Boolean(
      epic && epicName && epicStatus && sprint && storyPoints,
    );

    const t = i18next.t.bind(i18next);

    dispatch(
      getFieldsSuccess({
        fieldsList,
        areSoftwareFieldsInitialized,
      }),
    );

    const hiddenOptions = [
      'worklog', 'comment',
      'description', 'issue',
      'issuerestriction', 'sd-approvals',
      'sd-request-lang', 'sd-customerrequesttype',
      'sd-feedback', 'sd-servicelevelagreement',

      'statusCategory',
    ];

    const jiraSoftwareFields = {
      epic,
      epicName,
      storyPoints,
      epicStatus,
      sprint,
    };

    const fieldsOptions = [
      ...fieldsList,
      ...calculatedFieldsList(t),
    ]
      .map(({ id, name, untranslatedName, type, arrayItemType, isForUniversalReport, isWorklogData }) => ({
        label: name,
        value: id,

        fieldType: type,
        arrayItemType,
        isForUniversalReport,
        isWorklogData,
        untranslatedName,
      }))
      .filter(({ value, fieldType, untranslatedName, label }) => {
        if (fieldType === 'calculated') return true;
        if (untranslatedName === 'development' || label === 'Development') return false;

        return hiddenOptions.every((option) => option !== value);
      });

    dispatch(
      setFieldsOptions({
        fieldsOptions,
        jiraSoftwareFields,
      }),
    );
  } catch (error) {
    console.error(`[store.app.actions][getFieldsList] Exception: ${error}`);
    dispatch(getFieldsFailure(error));
  }
};

export const updateIsUnapprovedReportsEnabled = (isUnapprovedReportsEnabled) => async (dispatch) => {
  await dispatch(setIsUnapprovedReportsEnabled(isUnapprovedReportsEnabled));
  await dispatch(setConfig());
};

export const updateGalleryViewType = (galleryViewType) => async (dispatch) => {
  await dispatch(setGalleryViewType(galleryViewType));
  await dispatch(setConfig());
};

export const toggleReportsListSortBy = (columnName) => async (dispatch, getState) => {
  const { reportsListSortBy: oldReportsListSortBy } = getState().app;
  const reportsListSortBy = {
    ...defaultReportsListSortBy,
    [columnName]: oldReportsListSortBy[columnName] === -1 ? 1 : (oldReportsListSortBy[columnName] - 1),
  };
  await dispatch(setReportsListSortBy(reportsListSortBy));
  await dispatch(setConfig());
};
