import React, { useMemo, useEffect, useState } from 'react';

import InfoIcon from '@atlaskit/icon/glyph/info';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import {
  selectLoadingFinishedProcessId,
  selectLoadingStartedProcessId,
  selectLoadingState,
} from 'store/legacy/progressBar/selectors';
import { setLoadingStartedProcess, setIsLoadingCanceled } from 'store/legacy/progressBar/actions';
import { checkIsEqual } from 'utils/checkIsEqual';
import ConfiguredLoadable from 'components/ConfiguredLoadable';

import {
  ProgressBarActionButton, ProgressBarActionButtonContainer, ProgressBarContainer,
  ProgressBarInfo, ProgressBarContentSizeWarning, ProgressBarIconBlock,
  ProgressBarInfoBody, ProgressBarInfoIcon,
  ProgressBarInfoTitle, ProgressBarLoaderContainer,
} from 'components/ReportProgressBar/styled';

const ProgressBar = ConfiguredLoadable({
  loader: () => import('components/ProgressBar'),
});

const ReportProgressBarInner = ({ top, left, hasCancel = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingState = useSelector(selectLoadingState, checkIsEqual);

  const isHidden = useMemo(() => {
    const { location } = window;
    if (process.env.JIRA_TYPE === 'cloud') {
      return location.pathname === '/scripted-reports-issue-picker';
    }
    return !location.hash;
  }, []);

  const isGadget = useMemo(() => {
    const { location } = window;
    const gadgetModuleURL = '/scripted-reports-gadget';
    if (process.env.JIRA_TYPE === 'cloud') {
      return location.pathname.indexOf(gadgetModuleURL) !== -1;
    }
    return location.hash.indexOf(gadgetModuleURL) !== -1;
  }, []);

  const initialProgressTitle = `${t('reports.progressbar.getting')} ${t('reports.progressbar.issues')}...`;
  const initialProgressPercent = 0;

  const [progressTitle, setProgressTitle] = useState(initialProgressTitle);
  const [progressPercent, setProgressPercent] = useState(initialProgressPercent);

  const cancelReportCalculation = () => {
    dispatch(setLoadingStartedProcess());
    dispatch(setIsLoadingCanceled(true));
  };

  useEffect(() => {
    if (loadingState) {
      const { step, loaded, total } = loadingState;

      switch (step) {
        case 1: {
          const newProgress = total > 0
            ? Number((loaded * 0.01 * 80) / total).toFixed(2)
            : 0;

          setProgressPercent(newProgress);
          setProgressTitle(`${t('reports.progressbar.getting')} ${t('reports.progressbar.issues')}...`);

          break;
        }

        case 2: {
          setProgressPercent(0.8);
          setProgressTitle(`${t('reports.progressbar.getting')} ${t('reports.progressbar.worklogs')}...`);

          break;
        }

        case 3: {
          setProgressPercent(0.9);
          setProgressTitle(t('reports.progressbar.calculatingData'));

          break;
        }

        default:
          setProgressPercent(0);
      }
    }
  }, [t, loadingState]);

  return (
    <ProgressBarContainer
      id='progress-bar-container'
      top={top}
      left={isGadget ? '15%' : left}
      style={isHidden ? { display: 'none' } : {}}
    >
      <ProgressBarLoaderContainer>
        <ProgressBar value={progressPercent} isIndeterminate={progressPercent === 0} />
      </ProgressBarLoaderContainer>
      <ProgressBarInfo>
        <ProgressBarInfoIcon>
          <InfoIcon size='small' primaryColor='#998DD9' />
        </ProgressBarInfoIcon>
        <ProgressBarInfoTitle>
          {loadingState && progressTitle}
          {(loadingState && loadingState.total)
            ? `${loadingState.loaded} ${t('reports.progressbar.of')} ${loadingState.total}`
            : ''}
        </ProgressBarInfoTitle>
        {(loadingState && loadingState.total && loadingState.total > 1000) ? (
          <ProgressBarContentSizeWarning>
            <ProgressBarIconBlock>
              <EditorWarningIcon size='small' />
            </ProgressBarIconBlock>
            <div>
              {loadingState.total} {t('reports.progressbar.contentSizeWarning')}
            </div>
          </ProgressBarContentSizeWarning>
        ) : ''}
        <ProgressBarInfoBody>
          {t('reports.progressbar.body')}
        </ProgressBarInfoBody>
      </ProgressBarInfo>

      {hasCancel && (
        <ProgressBarActionButtonContainer>
          <ProgressBarActionButton onClick={cancelReportCalculation}>
            {t('reports.progressbar.buttons.cancel')}
          </ProgressBarActionButton>
        </ProgressBarActionButtonContainer>
      )}
    </ProgressBarContainer>
  );
};

export const ReportProgressBar = (props) => {
  const loadingStartedProcessId = useSelector(selectLoadingStartedProcessId);
  const loadingFinishedProcessId = useSelector(selectLoadingFinishedProcessId);

  const processSuccessfullyFinished = useMemo(
    () => loadingStartedProcessId === loadingFinishedProcessId,
    [loadingFinishedProcessId, loadingStartedProcessId],
  );

  const isLoadingReportData = loadingStartedProcessId && !processSuccessfullyFinished;

  if (window.ActonicRB?.isIssuePanel || !isLoadingReportData) {
    return null;
  }

  return (
    <ReportProgressBarInner {...props} />
  );
};
