import { searchBoard } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: searchBoard,
  allPages: false,
  maxResults: 20,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/agile/1.0/board',
    fieldName: 'values',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/agile/1.0/board',
    fieldName: 'values',
  },
};

export { config as searchBoard };
