import produce from 'immer';
import types from 'store/legacy/progressBar/types';

const initialState = {
  loadingState: {
    total: 0,
    loaded: 0,
    step: 0,
  },

  isLoadingCanceled: false,

  loadingFinishedProcessId: '',
  loadingStartedProcessId: '',
};

export const progressBar = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.SET_LOADING_STATE:
      const { loadingState } = action.payload;

      return {
        ...state,
        loadingState,
      };

    case types.SET_IS_LOADING_CANCEL:
      const { isLoadingCanceled } = action.payload;

      return {
        ...state,
        isLoadingCanceled,
      };

    case types.SET_LOADING_STARTED_PROCESS: {
      const { loadingStartedProcessId } = action.payload;

      return {
        ...state,
        loadingStartedProcessId,
        isLoadingCanceled: false,
      };
    }

    case types.SET_LOADING_FINISHED_PROCESS:
      const { loadingFinishedProcessId } = action.payload;

      if (state.loadingStartedProcessId !== loadingFinishedProcessId) return state;

      return {
        ...state,
        loadingFinishedProcessId,
      };

    default:
      return state;
  }
});
