import { createSlice } from '@reduxjs/toolkit';
import { initialAppEnvironment } from 'store/slices/appEnvironment/initialAppEnvironment';
import { boardApi } from 'store/api/jira/boardApi';
import { instanceLicenseApi } from 'store/api/jira/instanceLicenseApi';
import { addonsApi } from 'store/api/jira/addonsApi';
import { configApi } from 'store/api/plugin/configApi';
import { teamPermissionApi } from 'store/api/pluginTm/teamPermissionApi';
import { tmConfigApi } from 'store/api/pluginTm/tmConfigApi';

export const appEnvironmentSlice = createSlice({
  name: 'appEnvironment',
  initialState: initialAppEnvironment,
  reducers: {},

  extraReducers(builder) {
    builder.addMatcher(addonsApi.endpoints.getTmAddon.matchFulfilled, (state, action) => {
      state.isTimesheetBuilderInstalled = action.payload.state === 'ENABLED';
      state.isTimesheetBuilderActive = !!action.payload.license?.active;
    });

    builder.addMatcher(boardApi.endpoints.getBoards.matchFulfilled, (state) => {
      state.isJiraSoftwareInstalled = true;
    });

    builder.addMatcher(instanceLicenseApi.endpoints.getInstanceLicenses.matchFulfilled, (state, action) => {
      state.isServiceDeskInstalled = action.payload.some(({ id }) => id === 'jira-servicedesk');
    });

    if (!IS_CLOUD) {
      builder.addMatcher(configApi.endpoints.getConfig.matchFulfilled, (state, action) => {
        const {
          jiraSoftwareInstalled, jiraServiceManagementInstalled: isServiceDeskInstalled,
        } = action.payload;

        state.isJiraSoftwareInstalled = jiraSoftwareInstalled;
        state.isServiceDeskInstalled = isServiceDeskInstalled;
      });

      builder.addMatcher(teamPermissionApi.endpoints.getTeamPermissions.matchFulfilled, (state, action) => {
        const userTeamPermissions = action.payload;

        state.isTimesheetBuilderActive = Array.isArray(userTeamPermissions);
      });

      builder.addMatcher(tmConfigApi.endpoints.getTmConfig.matchFulfilled, (state, action) => {
        const { version } = action.payload;

        state.isTimesheetBuilderInstalled = !!version;
        state.timesheetBuilderVersion = version;
      });
    }
  },
});
