export enum FlagType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export interface Flag {
  title: string;
  description?: string;
  type?: FlagType;
}

export interface FlagDto extends Flag {
  id: string;
}