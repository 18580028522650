import { UniversalReportLookingFor } from 'sharedFrontBack/src/constants/universalReport';

const calculateNumberFieldInParent = (numberFieldsKeys, parent, subtask) => numberFieldsKeys.reduce(
  (accumulator, currentNumberField) => {
    const parentField = parent.fields[currentNumberField];
    const subtaskField = subtask.fields[currentNumberField];

    const parentValue = parentField ? parentField : 0;
    const subtaskValue = subtaskField ? subtaskField : 0;

    const calculatedFieldValue = parentValue + subtaskValue;

    accumulator[currentNumberField] = calculatedFieldValue ? calculatedFieldValue : null;

    return accumulator;
  },
  {},
);

const calculateURFieldsInParent = (parent, subtask) => {
  const timeoriginalestimate = Number(parent.fields.timeoriginalestimate) + Number(subtask.fields.timeoriginalestimate);
  const timeestimate = Number(parent.fields.timeestimate) + Number(subtask.fields.timeestimate);

  return {
    timeoriginalestimate,
    timeestimate,
  };
};

export const calculateSubtasksInParent = (issues, isIncludesSubtask, dataType, numberFieldsOptions = []) => {
  if (!isIncludesSubtask) return issues.filter(({ isVirtualParentIssue }) => !isVirtualParentIssue);

  const sortedIssues = [...issues]
    .sort(({ fields: { issuetype, parent } }) => {
      if (issuetype.name === 'Sub-task') {
        return 1;
      }

      if (parent && parent.fields.issuetype.name !== 'Epic') {
        return 1;
      }

      return -1;
    });

  if (dataType === UniversalReportLookingFor.worklogs) {
    const data = sortedIssues.reduce(
      (acc, currentIssue) => {
        const { fields: { issuetype, parent, worklog } } = currentIssue;
        const { fields: { issuetype: parentIssueType } } = parent || { fields: {} };

        if (issuetype.name === 'Sub-task' || (parentIssueType && parentIssueType !== 'Epic')) {
          const foundParent = acc.find(({ key }) => key === parent?.key);
          const parentIndex = acc.findIndex(({ key }) => key === parent?.key);

          if (foundParent) {
            const copiedWorklogs = foundParent.fields.worklog.worklogs.slice();

            copiedWorklogs.push(...worklog.worklogs);

            const newParent = {
              ...foundParent,
              fields: {
                ...foundParent.fields,
                worklog: {
                  ...foundParent.fields.worklog,
                  worklogs: copiedWorklogs,
                },
              },
            };

            acc[parentIndex] = newParent;
          } else {
            acc.push(currentIssue);
          }
        } else {
          acc.push(currentIssue);
        }

        return acc;
      },
      [],
    );

    return data;
  }

  const numberFieldsKeys = numberFieldsOptions.map(({ key }) => key);

  const filteredIssues = sortedIssues.reduce(
    (acc, currentIssue) => {
      const { fields: { issuetype, parent, worklog: currentItemWorklog } } = currentIssue;
      const { fields: { issuetype: parentIssueType } } = parent || { fields: {} };

      if (issuetype.name === 'Sub-task' || (parentIssueType && parentIssueType !== 'Epic')) {
        const foundParent = acc.find(({ key }) => key === parent?.key);
        const parentIndex = acc.findIndex(({ key }) => key === parent?.key);

        if (foundParent) {
          const calculatedNumberFields = calculateNumberFieldInParent(numberFieldsKeys, foundParent, currentIssue);
          const UniversalReportFields = calculateURFieldsInParent(foundParent, currentIssue);

          const copiedWorklogs = foundParent.fields.worklog.worklogs.slice();

          copiedWorklogs.push(...currentItemWorklog.worklogs);

          const newParent = {
            ...foundParent,
            fields: {
              ...foundParent.fields,
              ...calculatedNumberFields,
              ...UniversalReportFields,

              worklog: {
                ...foundParent.fields.worklog,
                worklogs: copiedWorklogs,
              },
            },
            includedSubtasks: foundParent.includedSubtasks
              ? [...foundParent.includedSubtasks, currentIssue.key]
              : [currentIssue.key],
          };

          acc[parentIndex] = newParent;
        } else {
          acc.push(currentIssue);
        }
      } else {
        acc.push(currentIssue);
      }

      return acc;
    },
    [],
  );

  return filteredIssues;
};
