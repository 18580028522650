import { InitialState } from 'store/slices/urtReport/types';
import { getInitialUrtReport } from 'features/urt/utils/getInitialUrtReport';
import { UrtSideBarPanels } from 'features/urt/components/UrtSideBar/constants';

export const getInitialUrtState = (): InitialState => ({
  validation: {},
  report: getInitialUrtReport(),
  drillDownReport: {},
  expandedSidebarPanel: UrtSideBarPanels.getData,
  expandedFieldParams: {},
  expandedCondition: undefined,
  isDirty: false,
});
