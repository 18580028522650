import { getBoard } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getBoard,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  url: '/rest/agile/1.0/board/',
};

const config = {
  server: {
    ...globalConfig,
  },
  cloud: {
    ...globalConfig,
  },
};

export { config as getBoard };
