import produce from 'immer';
import types from 'store/legacy/user/actionTypes';

const initialState = {
  name: '',
  username: '',
  displayName: 'Current user',
  userPic: '',
  userId: '',
  accountId: '',
  timeZone: null,
  clientKey: window.clientKey,
  admin: null,
  globalTeamAdmin: false,
  groups: [],
  teamPermissions: [],
  issueTypes: [],
  issueLinkTypes: [],
  teamManager: false,
  actonicGlobalTeamAdmin: false,
  actonicTeamManager: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-explicit-any
export const user = (_prevState = initialState, action: any) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_USER_DATA_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.GET_USER_DATA_SUCCESS: {
      const {
        displayName,
        userId,
        name,
        username,
        userPic,
        accountId,
        admin,
        globalTeamAdmin,
        teamManager,
        groups,
        teamPermissions,
        actonicGlobalTeamAdmin,
        timeZone,
      } = action.payload;

      return {
        ...state,
        displayName,
        userId,
        name,
        username,
        userPic,
        accountId,
        admin,
        globalTeamAdmin,
        teamManager,
        groups,
        teamPermissions,
        actonicGlobalTeamAdmin,
        timeZone,

        isLoading: false,
        error: null,
      };
    }

    case types.GET_USER_DATA_FAILURE: {
      const { displayName, userPic, accountId } = action.payload;
      return {
        ...state,
        displayName,
        userPic,
        accountId,
        error: action.error,
        isLoading: false,
      };
    }

    case types.GET_USER_ISSUE_TYPES_SUCCESS: {
      const { issueTypes } = action.payload;
      return {
        ...state,
        issueTypes,
      };
    }

    case types.GET_USER_ISSUE_TYPES_FAILURE: {
      return {
        ...state,
        issueTypes: [],
        error: action.error,
      };
    }

    case types.GET_USER_ISSUE_LINK_TYPES_SUCCESS: {
      const { issueLinkTypes } = action.payload;
      return {
        ...state,
        issueLinkTypes,
      };
    }

    case types.GET_USER_ISSUE_LINK_TYPES_FAILURE: {
      return {
        ...state,
        issueLinkTypes: [],
        error: action.error,
      };
    }

    default:
      return state;
  }
});
