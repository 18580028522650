export const getEmptyName = (name) => {
  switch (name) {
    case 'assignee':
      return 'Unassigned';

    case 'fixVersion':
      return 'Fix version';

    case 'dueDate':
      return 'Empty Due Date';

    case 'versions':
      return 'Empty versions';

    case 'resolution':
      return 'Resolution: Unresolved';

    case 'resolved':
      return 'Not resolved';

    case 'worklogDate':
      return 'Empty worklog date';

    case 'worklogAuthor':
      return 'Empty worklog author';

    default:
      return 'Undefined';
  }
};

export const updateExistingGroup = (
  currentLog, elementForUpdate, optionalData,
) => {
  const currentIssueKey = currentLog[(optionalData.isIncludesSubtask && currentLog.parentKey) ? 'parentKey' : 'issueKey'];

  elementForUpdate._issues[currentIssueKey] = true;
  elementForUpdate._worklogs[currentLog.worklogId] = true;
};

export const findGroupInArray = (array, key, cellType) => {
  const searchKey = key || null;

  return array.find((item) => item._cellType === cellType && item.key === searchKey);
};

export const searchInItemByMultipleKeys = (itemForSearch, searchArray, level, prevArray) => {
  let data = null;

  if (prevArray) {
    data = prevArray;
  } else {
    data = [];
  }

  if (itemForSearch && searchArray.some((search) => search === itemForSearch.key && level === itemForSearch._level)) {
    if (prevArray) {
      data.push(itemForSearch);
    } else {
      return [itemForSearch];
    }
  }

  if (itemForSearch && itemForSearch._children != null) {
    for (let i = 0; i < itemForSearch._children.length; i++) {
      searchInItemByMultipleKeys(itemForSearch._children[i], searchArray, level, data);
    }
  }

  return data;
};

export const searchInArrayByMultipleKeys = (array, search, level, currentGroup) => {
  const data = [];

  const isForSearchInArray = array && array.some((item) => search.some((searchItem) => {
    if (!searchItem) {
      return searchItem === item.key && currentGroup === item._cellType;
    }

    return (searchItem === item.key && level === item._level);
  }));

  if (isForSearchInArray) {
    return array.filter((item) => search.some((searchItem) => {
      if (!searchItem) {
        return searchItem === item.key && currentGroup === item._cellType;
      }

      return (searchItem === item.key && level === item._level);
    }));
  }

  if (array && array[0] && array[0]._children != null) {
    for (let j = 0; j < array.length; j++) {
      const item = array[j];

      for (let i = 0; i < item._children.length; i++) {
        searchInItemByMultipleKeys(item._children[i], search, level, data);
      }
    }
  }

  return data;
};

export const findPrevGroup = (currentGroupLevel, groups, accumulator, links) => {
  const previousGroups = groups.slice(0, currentGroupLevel);

  return previousGroups.reduce((prevFounded, group, index) => {
    // if we have grouping components > fixVersion > issue or similar
    const linkInArray = [links[group] ? links[group] : null];

    const isLinkArray = Array.isArray(links[group]);

    prevFounded = searchInArrayByMultipleKeys(
      index === 0 ? accumulator : prevFounded,
      isLinkArray ? links[group] : linkInArray,
      index,
      group,
    );

    return prevFounded;
  }, null);
};

export const flatTreeInArray = (sortedArray) => {
  const deleteServiceFields = (object) => {
    delete object._children;
    delete object._issues;
    delete object._worklogs;
  };

  let data = sortedArray.reduce((acc, current) => {
    if (current._children && current._children.length === 0) {
      deleteServiceFields(current);

      acc.push(current);

      return acc;
    }

    if (current._children) {
      acc = [...acc, current, ...current._children];
    } else {
      acc = [...acc, current];
    }

    deleteServiceFields(current);

    return acc;
  }, []);

  if (data.some((item) => item._children)) data = flatTreeInArray(data);

  return data;
};

export const getKeysForCurrentLog = () => ({
  project: 'projectKey',
  user: 'worklogAuthorId',
  epic: 'epicKey',
  issue: 'issueKey',
  worklog: 'worklogId',
  status: 'issueStatus',
  assignee: 'issueAssigneeId',
  reporter: 'issueReporterId',

  summary: 'issueName',
  resolved: 'resolvedDate',
  worklogAuthor: 'worklogAuthorId',
  component: 'components',
  fixVersion: 'fixVersions',
  label: 'issueLabels',

  dateGroup: 'startDate',
  worklogDate: 'startDate',

  dateCreated: 'issueCreationDate',
  // TODO: remove after reports deletion
  dateUpdated: 'updated',
  sprint: 'sprintName',
  updated: 'formattedUpdated',

  worklogWeek: 'worklogWeekWithYear',
  worklogMonth: 'worklogMonthWithYear',
  worklogQuarter: 'worklogQuarterWithYear',
  worklogYear: 'worklogYear',

  urgency: 'urgency',
  pendingReason: 'pendingReason',
  productCategorization: 'productCategorization',
  operationalCategorization: 'operationalCategorization',
  impact: 'impact',
  approvers: 'approvers',
  requestParticipants: 'requestParticipants',
  organizations: 'organizations',
});
