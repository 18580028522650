const globalConfig = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    allPages: false,
  },
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/jql/autocompletedata/suggestions',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/jql/autocompletedata/suggestions',
  },
};

export { config as autocompleteSuggestions };
