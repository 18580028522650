import produce from 'immer';
import types from 'store/legacy/configuration/types';

export const initialState = {
  currentTab: 'general',
  permissions: {
    teamManager: [{ type: 'public', value: 'public' }],
    teamGlobalAdmin: [{ type: 'private', value: 'private' }],
    scriptedReportsManager: [{ type: 'public', value: 'public' }],
  },
  actonicPermissions: {
    teamManager: [{ type: 'public', value: 'public' }],
    teamGlobalAdmin: [{ type: 'private', value: 'private' }],
    scriptedReportsManager: [{ type: 'public', value: 'public' }],
  },
  actonicTeamsListNames: [],
  general: {
    periodsEnabled: false,
    periodsConfiguration: 'monthly',
    periodsStartDate: Date.now(),
    // TODO: change for startOfWeek
    startWeekly: 'sunday',
    dateFormat: 'd/MMM/yy',
  },
  isLoading: false,
  isFetched: false,
  isFetchedWithErrors: false,
};

export const configuration = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.SET_CONFIGURATION_CURRENT_TAB: {
      const { currentTab } = action.payload;

      return { ...state, currentTab };
    }

    case types.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.payload.id]: action.payload.permissions,
        },
      };
    }

    case types.SAVE_CONFIGURATION_SUCCESS: {
      return state;
    }

    case types.SAVE_CONFIGURATION_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case types.SET_GENERAL_CONFIGURATION: {
      return {
        ...state,
        general: {
          ...state.general,
          [action.payload.id]: action.payload.value,
        },
      };
    }

    case types.GET_CONFIGURATION_STARTED: {
      return {
        ...state,
        isLoading: true,
        isFetched: false,
        isFetchedWithErrors: false,
      };
    }

    case types.GET_CONFIGURATION_SUCCESS: {
      const {
        general,
        permissions,
        actonicPermissions,
        actonicTeamsListNames,
      } = action.payload;

      if (permissions) {
        if (Array.isArray(permissions.teamManager) && permissions.teamManager.length === 0) {
          permissions.teamManager = state.permissions.teamManager;
        }

        if (Array.isArray(permissions.teamGlobalAdmin) && permissions.teamGlobalAdmin.length === 0) {
          permissions.teamGlobalAdmin = state.permissions.teamGlobalAdmin;
        }

        if (Array.isArray(permissions.scriptedReportsManager) && permissions.scriptedReportsManager.length === 0) {
          permissions.scriptedReportsManager = state.permissions.scriptedReportsManager;
        }
      }

      return {
        ...state,
        general: {
          ...state.general,
          ...general,
        },
        permissions: {
          ...state.permissions,
          ...permissions,
        },
        actonicPermissions: {
          ...state.actonicPermissions,
          ...actonicPermissions,
        },
        actonicTeamsListNames,
        isLoading: false,
        isFetched: true,
      };
    }

    case types.GET_CONFIGURATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetchedWithErrors: true,
      };
    }

    default:
      return state;
  }
});
