import promiseRetry from 'promise-retry';
import { NotificationController as Notification } from 'controllers/index';
import { getContextPath } from 'utils/getContextPath';
import { promiseAllWithConcurrency } from 'utils/promiseAllWithConcurrency';
import { requestFunction } from 'utils/requestFunction';

import store from 'store';

const errorHandler = (data) => {
  console.error('[request][errorHandler] Exception:', data);
  const { err, responseJSON } = data;
  if (err) {
    const errorData = JSON.parse(err);
    if (errorData) {
      const { errorMessages } = errorData;
      return Notification.create({
        body: errorMessages[0],
        type: 'error',
        close: 'auto',
      });
    }
  }
  if (responseJSON) {
    const { errorMessages } = responseJSON;
    return Notification.create({
      body: errorMessages[0],
      type: 'error',
      close: 'auto',
    });
  }
};

const request = async (requestData, api) => await api(requestData);

const getUrlToPaginationRequest = (isExtraParameterExist, url, maxResults, startAt) => isExtraParameterExist
  ? `${url}&startAt=${startAt ? startAt : 0}&maxResults=${maxResults}`
  : `${url}?startAt=${startAt ? startAt : 0}&maxResults=${maxResults}`;

const requestWithPaginationSequently = async (requestData, maxResults, api, isCloud, loadingCallback) => {
  const { fieldName, type, url } = requestData;
  const isPost = type === 'POST';
  const isExtraParameterExist = requestData.url.indexOf('?') !== -1;

  const resultRequestData = {
    ...requestData,
    url: isPost ? url : getUrlToPaginationRequest(isExtraParameterExist, url, maxResults),
  };

  if (isPost) {
    resultRequestData.data = new Blob([JSON.stringify(requestData.data)],
      { type: 'application/json' });
  }
  try {
    const firstRequestResult = await api({ ...resultRequestData });

    const bodyData = isCloud ? JSON.parse(firstRequestResult.body) : firstRequestResult;
    const { startAt, total } = bodyData;

    if (loadingCallback) {
      loadingCallback({
        total,
        loaded: 0,
      });
    }

    const field = bodyData[fieldName];

    if (!field) {
      console.error(
        '[request][paginationRequests] No field data! Try to use some of these fields - ',
        Object.keys(bodyData)
          .reduce((acc, cur) => acc + `${cur}, `, ''),
      );
      return;
    }

    const resultsLength = field.length;
    let resultData = [];

    resultData = resultData.concat(field);

    if (total > (startAt + resultsLength)) {
      for (let i = startAt + resultsLength; i < total; i += maxResults) {
        if (loadingCallback) {
          const { loadingStartedProcessId } = store.getState().progressBar;
          if (!loadingStartedProcessId) return;

          loadingCallback({
            total,
            loaded: i,
          });
        }

        const newResultRequestData = {
          ...requestData,
          url: isPost ? url : getUrlToPaginationRequest(isExtraParameterExist, url, maxResults, i),
        };

        if (isPost) {
          // eslint-disable-next-line no-param-reassign
          requestData.data.startAt = i;
          newResultRequestData.data = new Blob([JSON.stringify(requestData.data)],
            { type: 'application/json' });
        }

        const requestResult = await api({ ...newResultRequestData });

        const result = isCloud ? JSON.parse(requestResult.body)[fieldName] : requestResult[fieldName];
        resultData = resultData.concat(result);
      }

      return Promise.all(resultData);
    }

    return resultData;
  } catch (data) {
    errorHandler(data);
    return [];
  }
};

const requestWithPaginationParallel = async (requestData, maxResults, api, isCloud, loadingCallback) => {
  const { fieldName, type, url } = requestData;
  const isPost = type === 'POST';
  const isExtraParameterExist = requestData.url.indexOf('?') !== -1;

  const resultRequestData = {
    ...requestData,
    url: isPost ? url : getUrlToPaginationRequest(isExtraParameterExist, url, maxResults),
  };

  if (isPost) {
    resultRequestData.data = new Blob([JSON.stringify(requestData.data)],
      { type: 'application/json' });
  }

  try {
    const firstRequestResult = await api({ ...resultRequestData });

    const bodyData = isCloud ? JSON.parse(firstRequestResult.body) : firstRequestResult;
    const { startAt, total } = bodyData;

    if (loadingCallback) {
      loadingCallback({
        total,
        loaded: 0,
      });
    }

    const field = bodyData[fieldName];

    if (!field) {
      console.error(
        '[request][paginationRequests] No field data! Try to use some of these fields - ',
        Object.keys(bodyData)
          .reduce((acc, cur) => acc + `${cur}, `, ''),
      );
      return;
    }

    const resultsLength = field.length;
    const resultData = [];

    resultData.push(...field);

    if (total > (startAt + resultsLength)) {
      const pagesCount = Math.ceil((total - maxResults) / maxResults);

      const startAtArray = new Array(pagesCount).fill(0).map((item, index) => 50 + 50 * index);

      await Promise.all(startAtArray.map(async (currentStartAt) => {
        await promiseRetry(async (retry) => {
          try {
            if (isPost) {
              resultRequestData.data.startAt = currentStartAt;
            }

            const requestResult = await api({
              ...resultRequestData,
              url: getUrlToPaginationRequest(isExtraParameterExist, url, maxResults, currentStartAt),
            });

            const result = isCloud ? JSON.parse(requestResult.body)[fieldName] : requestResult[fieldName];

            resultData.push(...result);
          } catch (error) {
            retry(error);
          }
        });
      }));
    }

    return resultData;
  } catch (data) {
    errorHandler(data);
    return [];
  }
};

const requestWithPaginationConcurrently = async (requestData, maxResults, api, isCloud, loadingCallback) => {
  const { fieldName, type, url } = requestData;
  const isPost = type === 'POST';
  const isExtraParameterExist = requestData.url.indexOf('?') !== -1;

  const resultRequestData = {
    ...requestData,
    url: isPost ? url : getUrlToPaginationRequest(isExtraParameterExist, url, maxResults),
  };

  if (isPost) {
    resultRequestData.data = new Blob([JSON.stringify(requestData.data)],
      { type: 'application/json' });
  }

  try {
    const firstRequestResult = await api({ ...resultRequestData });

    const bodyData = isCloud ? JSON.parse(firstRequestResult.body) : firstRequestResult;
    const { startAt, total } = bodyData;

    if (loadingCallback) {
      loadingCallback({
        total,
        loaded: 0,
      });
    }

    const field = bodyData[fieldName];

    if (!field) {
      console.error(
        '[request][paginationRequests] No field data! Try to use some of these fields - ',
        Object.keys(bodyData)
          .reduce((acc, cur) => acc + `${cur}, `, ''),
      );
      return;
    }

    const resultsLength = field.length;
    const resultData = [];

    resultData.push(...field);

    if (total > (startAt + resultsLength)) {
      const pagesCount = Math.ceil((total - maxResults) / maxResults);

      const startAtArray = new Array(pagesCount).fill(0).map((item, index) => 50 + 50 * index);

      const requestForStartAt = (currentStartAt) => async () => {
        if (loadingCallback) {
          const { loadingStartedProcessId } = store.getState().progressBar;
          if (!loadingStartedProcessId) return;
        }

        await promiseRetry(async (retry) => {
          try {
            const finalRequestData = {
              ...{
                ...resultRequestData,
                ...(isPost
                  ? {
                    data: new Blob(
                      [JSON.stringify({ ...requestData.data, startAt: currentStartAt })],
                      { type: 'application/json' },
                    ),
                  }
                  : {}),
              },
              url: getUrlToPaginationRequest(isExtraParameterExist, url, maxResults, currentStartAt),
            };

            const requestResult = await api(finalRequestData);

            const result = isCloud ? JSON.parse(requestResult.body)[fieldName] : requestResult[fieldName];

            resultData.push(...result);

            if (loadingCallback) {
              const { loadingStartedProcessId } = store.getState().progressBar;
              if (!loadingStartedProcessId) return;

              loadingCallback({
                total,
                loaded: resultData.length,
              });
            }
          } catch (error) {
            retry(error);
          }
        });
      };

      const tasks = startAtArray.map((currentStartAt) => requestForStartAt(currentStartAt));

      await promiseAllWithConcurrency(tasks, 6);
    }

    return resultData;
  } catch (data) {
    errorHandler(data);
    return [];
  }
};

// eslint-disable-next-line
const serverUrl = (url) => getContextPath() + url;

export const requestAPI = async ({
  url,
  method,
  migration,
  headers,
  allPages,
  allPagesConcurrently,
  allPagesParallel,
  fieldName,
  contentType,
  startAt = 0,
  maxResults = 100,
}, query, data, isCloud, loadingCallback) => {
  let requestUrl = isCloud ? url : serverUrl(url);
  const api = requestFunction;
  if (query && query.length > 0) {
    requestUrl += query;
  }

  const requestData = {
    type: method,
    url: requestUrl,
  };

  const { AJS } = window;

  if (!isCloud && AJS) {
    requestData.dataType = 'json';
    requestData.async = true;
    requestData.processData = false;
  }

  if (headers) requestData.headers = headers;
  if (fieldName) requestData.fieldName = fieldName;
  if (typeof contentType !== 'undefined') requestData.contentType = contentType;
  if (data) requestData.data = data;

  let result;

  if (allPagesConcurrently) {
    result = await requestWithPaginationConcurrently(requestData, maxResults, api, isCloud, loadingCallback);
  } else if (allPagesParallel) {
    result = await requestWithPaginationParallel(requestData, maxResults, api, isCloud, loadingCallback);
  } else if (allPages) {
    result = await requestWithPaginationSequently(requestData, maxResults, api, isCloud, loadingCallback);
  } else {
    const isExtraParameterExist = requestData.url.indexOf('?') !== -1;

    const requestResult = await request({
      ...requestData,
      url: isExtraParameterExist
        ? `${requestData.url}`
        : `${requestData.url}?startAt=${startAt}&maxResults=${maxResults}`,
    }, api);

    const bodyData = isCloud && requestResult && requestResult.body ? JSON.parse(requestResult.body) : requestResult;

    const fieldData = fieldName ? bodyData[fieldName] : bodyData;

    result = fieldData;
  }

  const resultData = migration ? await migration(result) : result;

  return resultData;
};
