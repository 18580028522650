import { TransportController } from 'controllers';
import { searchJqlPost } from 'controllers/transport/requestConfigurations';
import { createIssueUrl } from 'utils/jiraUrl';
import store from 'store/index';

export const addEpicData = async (issuesOrWorklogsForExtend) => {
  const { jiraSoftwareFields } = store.getState().app;

  const epicKeyField = jiraSoftwareFields.epic?.id;
  const epicNameField = jiraSoftwareFields.epicName?.id;
  const epicStatusKeyField = jiraSoftwareFields.epicStatus?.id;

  if (!epicNameField || !epicStatusKeyField) return issuesOrWorklogsForExtend;

  const remainedEpics = new Set(issuesOrWorklogsForExtend
    .map(({ epicKey }) => epicKey)
    .filter((epicKey) => !!epicKey));

  const epicDataByEpicKey = issuesOrWorklogsForExtend.reduce((acc, item) => {
    const newEpicData = {
      epicName: item.epicName,
      epicIcon: item.epicIcon,
      epicStatus: item.epicStatus,
      epicLink: createIssueUrl(item.epicKey),
    };

    if (newEpicData.epicName) {
      acc[item.epicKey] = newEpicData;

      remainedEpics.delete(item.epicKey);
    }

    return acc;
  }, {});

  if (remainedEpics.size > 0) {
    const epicsKeys = Array.from(remainedEpics);

    const jqlForGetEpics = `issuekey in (${epicsKeys.join(', ')})`;

    const requestData = {
      jql: jqlForGetEpics,
      fields: [epicKeyField, epicNameField, epicStatusKeyField, 'issuetype', 'summary'],
      expand: [],
      maxResults: 50,
      startAt: 0,
    };

    const fetchedEpics = await TransportController.request({
      config: searchJqlPost,
      data: requestData,
    });

    fetchedEpics.forEach(
      (epic) => {
        const newEpicData = {
          epicName: epic.fields[epicNameField] ? epic.fields[epicNameField] : epic.fields.summary,
          epicIcon: epic.fields.issuetype.iconUrl,
          epicStatus: epic.fields[epicStatusKeyField],
          epicLink: createIssueUrl(epic.key),
        };

        epicDataByEpicKey[epic.key] = newEpicData;
      },
    );
  }

  return issuesOrWorklogsForExtend.map((item) => {
    const epicData = epicDataByEpicKey[item.epicKey];

    return {
      ...item,
      ...epicData,
    };
  });
};
