class CookieController {
  save = (name, value, options = {}) => {
    if (process.env.JIRA_TYPE === 'cloud') {
      const { AP } = window;
      AP.cookie.save(name, value);
    } else {
      const cookieOptions = {
        path: '/',
        ...options,
      };

      let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      for (const optionKey in cookieOptions) {
        if (cookieOptions.hasOwnProperty(optionKey)) {
          updatedCookie += '; ' + optionKey;

          const optionValue = cookieOptions[optionKey];

          if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
          }
        }
      }

      document.cookie = updatedCookie;
    }
  };

  read = async (name) => {
    if (!name) return;

    if (IS_CLOUD) {
      const { AP } = window;
      return new Promise((resolve) => {
        AP.cookie.read(name, (data) => {
          resolve(data);
        });
      });
    }
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
  };
}

const cookie = new CookieController();
export { cookie as CookieController };
