import { useState, useEffect } from 'react';
import { fetchAccountIdOrUsername } from 'utils/fetchAccountIdOrUsername';

let currentUserIdentifierCache: string;

export const useAccountIdOrUsername = () => {
  const [accountIdOrUsername, setAccountIdOrUsernameState] = useState<string>(
    currentUserIdentifierCache,
  );

  const setCurrentUserIdentifier = (identifier: string) => {
    currentUserIdentifierCache = identifier;
    setAccountIdOrUsernameState(identifier);
  };

  useEffect(() => {
    if (!accountIdOrUsername) {
      fetchAccountIdOrUsername().then(setCurrentUserIdentifier);
    }
  }, [accountIdOrUsername]);

  return currentUserIdentifierCache;
};