enum RouteNames {
  root = 'root',
  notFoundPage = 'notFoundPage',
  timesheets = 'timesheets',
  reports = 'reports',
  reportPage = 'reportPage',
  test = 'test',
  teams = 'teams',
  teamPage = 'teamPage',
  configuration = 'configuration',
  scriptedReportsDashboard = 'scriptedReportsDashboard',
  scriptedReportsDetailPage = 'scriptedReportsDetailPage',
  scriptedReportsGadget = 'scriptedReportsGadget',
  scriptedReportsIssuePicker = 'scriptedReportsIssuePicker',
  universalReportGadget = 'universalReportGadget',
  projectReport = 'projectReport',
  projectUniversalReport = 'projectUniversalReport',
  startPage = 'startPage',
  gettingStartedPage = 'gettingStartedPage',
}

export default RouteNames;
