import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { useGetUserQuery } from 'store/api/jira/userApi';

const stubUser = {
  userId: undefined,
  groups: undefined,
  displayName: undefined,
  avatarUrls: undefined,
};

export const useGetUser = (userData: { accountId?: string; username?: string; key?: string }) => {
  const { username, accountId, key } = userData;
  const skip = !username && !accountId && !key;

  const { data, isFetching } = useGetUserQuery(skip ? skipToken : userData, { skip });

  return useMemo(() => ({
    data: data ?? stubUser,
    isFetching: isFetching || skip,
  }), [data, isFetching, skip]);
};