import { HiddenContainerForCanvas, MainRoot } from 'components/App/Main/styled';
import React, { useLayoutEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppSpinner } from 'globalStyle';
import { useCheckIsMounted } from 'hooks/lifecycle';
import { useAppEnvironment } from 'store/slices/appEnvironment/useAppEnvironment';
import { getUserData } from 'store/legacy/user/thunks';
import { useDidMount } from 'hooks';
import { CookieController } from 'controllers';

import {
  appContentRootId,
  DAY_IN_HOURS, WEEK_IN_DAYS,
} from 'constants/constants';

import { selectUserTimeZone } from 'store/legacy/user/selectors';

import { setLocale } from 'store/legacy/app/actions';

import { tryMultipleTimes } from 'utils/tryMultipleTimes';
import { mockAP } from 'utils/mockRequests';
import { addFlag } from 'store/slices/flag/slice';
import { FlagType } from 'types/store/flag';

if (process.env.IS_DEV_SERVER) {
  window.AP = mockAP;
  window.appUrl = '';
}

window.ActonicRB = window.ActonicRB || {};
window.ActonicRB.timeTrackingConfiguration = {
  workingHoursPerDay: DAY_IN_HOURS,
  workingDaysPerWeek: WEEK_IN_DAYS,
};

export const Main = memo(({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timeZone = useSelector(selectUserTimeZone);

  const [isUserDataFetched, setIsUserDataFetched] = useState(false);

  const iframe = document.getElementById('jsd-widget');
  const checkIsMounted = useCheckIsMounted();

  if (iframe?.contentWindow) {
    const checkHelpButtonExist = () => iframe.contentWindow?.document.querySelector('#help-button');
    const hideButton = (el) => {
      if (!el) {
        return;
      }
      iframe.style.zIndex = '-1';
      if (!el) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      el.style.visibility = 'hidden';
      return el?.style.visibility;
    };

    tryMultipleTimes(checkHelpButtonExist, hideButton, 30, 100);
  }

  useLayoutEffect(() => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    (async () => {
      const isNotificationWatched = await CookieController.read('timezone_message') === 'watched';

      if (timeZone && timeZone !== browserTimeZone && !isNotificationWatched) {
        dispatch(addFlag({
          title: t('warnings.wrongTimeZone', { browserTimeZone, userTimeZone: timeZone }),
          type: FlagType.info,
        }));

        CookieController.save('timezone_message', 'watched');
      }
    })();
  }, [dispatch, t, timeZone]);

  useAppEnvironment();

  useDidMount(() => {
    async function fetchData() {
      await dispatch(getUserData(t));

      const { AP, AJS } = window;
      if (IS_CLOUD) {
        AP.user?.getLocale((locale) => {
          dispatch(setLocale(locale));
        });
      }

      if (!IS_CLOUD) {
        dispatch(setLocale(AJS.Meta.get('user-locale')));
      }

      if (checkIsMounted()) {
        setIsUserDataFetched(true);
      }
    }

    fetchData();
  });

  if (!isUserDataFetched) {
    return (
      <AppSpinner />
    );
  }

  return (
    <MainRoot id={appContentRootId}>
      {children}
      <HiddenContainerForCanvas id='hiddenContainerForCanvas' />
    </MainRoot>
  );
});
