const getIssuesAsTarget = ({ elementForUpdate, currentIssueKey, currentLog }) => {
  const issueKeysForElement = Object.keys(elementForUpdate._issues);

  return [
    ...issueKeysForElement
      .filter((issueKey) => issueKey === currentIssueKey),
    ...(currentLog.includedSubtasks ? currentLog.includedSubtasks : []),
  ];
};

const getSubtasksAsTarget = ({ currentLog }) => {
  const subtasks = (currentLog.subtasksCount
    ? new Array(currentLog.subtasksCount).fill('empy subtask')
    : []);

  return subtasks;
};

const getWorklogsAsTarget = ({ currentLog }) => [currentLog.loggedTime];

const getWorklogsAuthorsAsTarget = ({ elementForUpdate, currentLog, columnNameForComparison }) => {
  const isAdded = !!(elementForUpdate.info.raw[columnNameForComparison]?.find((item) => item === currentLog.worklogAuthor));

  if (isAdded) return [];

  return [currentLog.worklogAuthor];
};

const getStoryPointsAsTarget = ({ currentLog }) => [Number(currentLog.storyPoints)];

const getOriginalEstimationAsTarget = ({ currentLog }) => [Number(currentLog.timeoriginalestimate)];

const getRemainigEstimationAsTarget = ({ currentLog }) => [Number(currentLog.timeestimate)];

const getLoggedTimeAsTarget = ({ currentLog }) => [Number(currentLog.loggedTime)];

const getNumberFieldAsTarget = ({ currentLog }, numberFieldName) => [Number(currentLog[numberFieldName])];

export const formatDataByTarget = (aggregationTarget, aggregationData) => {
  const mapTargetToFormatter = {
    issues: getIssuesAsTarget,
    subtasks: getSubtasksAsTarget,
    worklogs: getWorklogsAsTarget,
    worklogsAuthors: getWorklogsAuthorsAsTarget,

    storyPoints: getStoryPointsAsTarget,
    originalEstimation: getOriginalEstimationAsTarget,
    remainingEstimate: getRemainigEstimationAsTarget,
    loggedTime: getLoggedTimeAsTarget,
  };

  const currentFormatter = mapTargetToFormatter[aggregationTarget];

  if (currentFormatter) {
    return currentFormatter(aggregationData);
  }

  return getNumberFieldAsTarget(aggregationData, aggregationTarget);
};
