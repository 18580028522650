import produce from 'immer';
import { Reducer } from 'redux';
import { getDateConstants } from 'constants/dateConstants';
import { CurrentReport } from 'types/store/reports';
import { filterOptionsBasedOnApplications } from 'utils/filterSoftwareOptions';
import { getAggregationTargetsOptions } from 'pages/ReportLegacyPage/ReportViews/UniversalReport/ReportSidebar/options';
import { checkIsReportScriptedCompatible } from 'utils/checkIsUniversalCompatible';
import types from 'store/legacy/reports/types';
import { initialExternalEditorTypeParamsMap } from 'constants/externalEditor';
import { ReportPermissionListItem } from 'sharedFrontBack/src/types/reportPermissions';

import { UniversalReportLookingFor } from 'sharedFrontBack/src/constants/universalReport';
import { CalendarView } from 'sharedFrontBack/src/types/report';
import { ReportCategory, ExternalEditorType } from 'sharedFrontBack/src/constants/report';
import { getDefaultPermissions } from 'utils/getDefaultPermissions';

const { THIS_MONTH_START, THIS_MONTH_END } = getDateConstants('sunday');

const defaultTemplate = '<div>Scripted report</div>';

const defaultScript = 'SR.render();';

export const initialCurrentReport: CurrentReport = {
  id: null,
  name: '',
  type: '',
  key: '',
  category: ReportCategory.universalReport,
  createdBy: '',
  updatedAt: 0,
  updatedOwner: null,
  permissions: getDefaultPermissions(),

  colorTheme: 'atlassian',

  datesRange: {
    startDate: new Date(THIS_MONTH_START),
    endDate: new Date(THIS_MONTH_END),
    relativeDate: null,
  },
  loggedTimeInSeconds: 0,
  groupBy: [],
  sortBy: {
    isAscending: true,
    path: 'name',
    type: 'text',
  },
  filterBy: [],
  quickReportFilterBy: [],
  worklogs: [],
  columns: [],
  additionalColumnsOptions: [],
  lastFilterAsString: '',

  tempIssues: [],

  sources: [],
  calendarView: CalendarView.month,
  isWithWeekends: false,

  data: [],
  fieldsByType: [],

  timeTrackingUnit: 'hours',

  // Scripted Reports
  template: defaultTemplate,
  script: defaultScript,

  reportExportVisibility: [],

  inputFields: {
    fieldsList: [],
    creationForm: {
      title: '',
      name: '',
      type: '',
      option: '',
      optionsList: [],
      isMulti: false,
    },
  },
  pdfOptions: {
    pageSize: 'A4',
  },

  // Gallery Reports
  description: '',
  tags: [],
  docsLink: '',
  icon: '',

  // Universal Report
  dataType: UniversalReportLookingFor.issues,
  reportViewType: 'table',

  aggregationType: 'COUNT',
  aggregationTarget: 'issues',
  filters: [],

  isPredefined: false,
  isDemoMode: false,
  isEditMode: false,
  isChanged: false,

  isDescriptionVisible: true,

  isIncludesSubtask: false,

  isParentsShouldBeFetched: false,
  isChildrenShouldBeFetched: false,

  // Data Process Status
  isDataFetched: false,
  isDataFetching: false,

  // If true, then we can render report
  isScriptedReportFetched: false,

  isSavingReport: false,
  isWithChanges: false,
  isFetchedWithErrors: false,
  isNewOwnerSaved: false,
  isExternalEditorSyncing: false,
  isSrLoading: true,
  vulnerableFieldsUpdatedAt: null,
  vulnerableFieldsUpdatedBy: null,

  isSecureEnvironment: true,
};

const initialState = {
  reportsList: [],
  dashboardReports: {
    reports: [],
    sharedReports: [],
    galleryReports: [],
  },
  currentTab: 'my',
  currentReport: {
    ...initialCurrentReport,
  },
  lastViewedReport: {},

  totalPages: 0,

  // Dashboard search
  searchString: '',

  customFieldsOptions: [],
  numberCustomFieldsOptions: [],

  attachmentStatus: '',

  lastFetchId: null,
  lastPreviewFetchId: null,

  isExporting: false,
  isAttaching: false,
  isOnlyFavorites: false,

  isReportsFetched: false,
  isReportsFetching: false,
  isInitialFetchCompleted: false,

  isGalleryReportsFetched: false,

  isReportDeleting: false,
  isReportDeleted: false,
  vulnerableFieldsUpdatedAt: null,
  vulnerableFieldsUpdatedBy: null,
  isVulnerableFieldsUpdated: null,
};

export const reports: Reducer<typeof initialState> = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_REPORTS_STARTED: {
      return {
        ...state,
        isReportsFetching: true,
        isReportsFetched: false,
      };
    }

    case types.GET_DASHBOARD_REPORTS_SUCCESS: {
      const {
        reports: dashboardReports,
        sharedReports: dashboardSharedReports,
        galleryReports: dashboardGalleryReports,
      } = action.payload;

      return {
        ...state,
        isReportsFetching: false,
        isReportsFetched: true,
        dashboardReports: {
          ...state.dashboardReports,
          ...(dashboardReports && { reports: dashboardReports }),
          ...(dashboardSharedReports && { sharedReports: dashboardSharedReports }),
          ...(dashboardGalleryReports && { galleryReports: dashboardGalleryReports }),
        },
      };
    }

    case types.GET_REPORTS_FAILURE: {
      return {
        ...state,
        isReportsFetching: false,
        error: action.error,
      };
    }

    case types.DELETING_REPORTS_STARTED: {
      return {
        ...state,
        isReportDeleting: true,
      };
    }

    case types.DELETING_REPORTS_SUCCESS: {
      return {
        ...state,
        isReportDeleting: false,
      };
    }

    case types.DELETING_REPORTS_FAILURE: {
      return {
        ...state,
        isReportDeleting: false,
        error: action.error,
      };
    }

    case types.SET_NAME_CURRENT_REPORT: {
      const { name } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          name,
          isWithChanges: true,
        },
      };
    }

    case types.SET_REPORT_NEW_OWNER: {
      const { updatedOwner } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          updatedOwner,
          isWithChanges: true,
        },
      };
    }

    case types.SET_CATEGORY_CURRENT_REPORT: {
      const { category } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          category,
          isWithChanges: true,
        },
      };
    }

    case types.SET_GROUP_BY_CURRENT_REPORT: {
      const { groupBy, isWithChanges } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          groupBy,
          isWithChanges,
        },
      };
    }

    case types.SET_DATES_RANGE_CURRENT_REPORT: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          datesRange: action.payload.datesRange,
          isWithChanges: true,
        },
      };
    }

    case types.SET_SORT_BY_CURRENT_REPORT: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          sortBy: action.payload.sortBy,
          isWithChanges: true,
        },
      };
    }

    case types.SET_LAST_FILTER_AS_STRING: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          lastFilterAsString: action.payload.lastFilterAsString,
        },
      };
    }

    case types.GET_CURRENT_REPORT_TABLE_DATA_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: true,
          isDataFetched: false,
        },
      };
    }

    case types.GET_CURRENT_REPORT_TABLE_DATA_SUCCESS: {
      const { worklogs, epicsByKeys, loggedTimeInSeconds, lastDataFetchDate, lastDataFetchItemsCount } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          worklogs,
          epicsByKeys,
          loggedTimeInSeconds,
          isDataFetching: false,
          isDataFetched: true,
          lastDataFetchDate,
          lastDataFetchItemsCount,
        },
      };
    }

    case types.CHECK_IS_AVAILABLE_LAST_REPORT: {
      return {
        ...state,
        lastViewedReport: {
          ...state.lastViewedReport,
          isAvailable: action.payload.isAvailable,
        },
      };
    }

    case types.GET_CURRENT_REPORT_TABLE_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
          isDataFetched: true,
        },
      };
    }

    case types.GET_CURRENT_REPORT_TABLE_DATA_FINISHED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
          isDataFetched: true,
        },
      };
    }

    case types.CREATE_REPORT_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: true,
          isSavingReport: true,
        },
      };
    }

    case types.CREATE_REPORT_SUCCESS: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          createdBy: action.payload.userId,
          isDataFetching: false,
          isSavingReport: false,
          isWithChanges: false,
        },
      };
    }

    case types.CREATE_REPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
          isSavingReport: false,
        },
      };
    }

    case types.GET_CURRENT_REPORT_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetched: false,
          isDataFetching: true,
        },
      };
    }

    case types.GET_CURRENT_REPORT_SUCCESS: {
      const { currentReport, ...rest } = action.payload;

      return {
        ...state,
        ...rest,
        currentReport: {
          ...state.currentReport,
          ...currentReport,
          ...(checkIsReportScriptedCompatible(state.currentReport) ? {
            inputFields: {
              ...state.currentReport.inputFields,
              ...currentReport.inputFields,
            },
          } : {}),
          isDataFetched: true,
          isDataFetching: false,
          isWithChanges: false,
          isFetchedWithErrors: false,
        },
      };
    }

    case types.GET_CURRENT_REPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        currentReport: {
          ...state.currentReport,
          isDataFetched: true,
          isDataFetching: false,
          isFetchedWithErrors: true,
        },
      };
    }

    case types.GET_SCRIPTED_REPORT_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isScriptedReportFetched: false,
          isDataFetching: true,
        },
      };
    }

    case types.GET_SCRIPTED_REPORT_SUCCESS: {
      const { currentReport, ...rest } = action.payload;

      return {
        ...state,
        ...rest,
        currentReport: {
          ...state.currentReport,
          ...currentReport,
          inputFields: {
            ...state.currentReport.inputFields,
            ...currentReport.inputFields,
          },
          isScriptedReportFetched: true,
          isDataFetching: false,
          isWithChanges: false,
          isFetchedWithErrors: false,
        },
      };
    }

    case types.GET_SCRIPTED_REPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        currentReport: {
          ...state.currentReport,
          isScriptedReportFetched: true,
          isDataFetching: false,
          isFetchedWithErrors: true,
        },
      };
    }

    case types.SET_DEFAULT_CURRENT_REPORT: {
      const { category, type, isEditMode } = state.currentReport;
      const { name } = action.payload;

      return {
        ...state,
        error: action.error,
        currentReport: {
          ...initialCurrentReport,
          name,
          category,
          type,
          isPredefined: true,
          ...(category === 'universalReport' && { isEditMode }),
        },
      };
    }

    case types.RESET_CURRENT_REPORT: {
      return {
        ...state,
        currentReport: {
          ...initialCurrentReport,
          ...(action.payload ?? {}),
        },
      };
    }

    case types.EDIT_REPORT_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: true,
          isSavingReport: true,
        },
      };
    }

    case types.EDIT_REPORT_SUCCESS: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,

          isSavingReport: false,
          isWithChanges: false,
        },
      };
    }

    case types.EDIT_REPORT_FAILURE: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,

          isSavingReport: false,
        },
        error: action.error,
      };
    }

    case types.SAVE_REPORT_STARTED: {
      return {
        ...state,

        currentReport: {
          ...state.currentReport,
          isSavingReport: true,
        },
      };
    }

    case types.SAVE_REPORT_SUCCESS: {
      return {
        ...state,

        currentReport: {
          ...state.currentReport,
          id: action.payload.reportId,
          name: action.payload.newName,
          createdBy: action.payload.createdBy,
          isSavingReport: false,
          isPredefined: false,
        },
      };
    }

    case types.SAVE_REPORT_COMPLETE: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isSavingReport: false,
        },
      };
    }

    case types.SAVE_REPORT_FAILURE: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isSavingReport: false,
        },
        error: action.error,
      };
    }

    case types.SET_IS_CURRENT_REPORT_WITH_CHANGES: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isWithChanges: action.payload.isWithChanges,
        },
      };
    }

    case types.SET_UPDATED_VULNERABLE_FIELDS: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          vulnerableFieldsUpdatedAt: new Date().toISOString(),
          vulnerableFieldsUpdatedBy: action.payload.vulnerableFieldsUpdatedBy,
        },
      };
    }

    case types.SET_IS_VULNERABLE_FIELDS_UPDATED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isVulnerableFieldsUpdated: action.payload.isVulnerableFieldsUpdated,
        },
      };
    }

    case types.SET_ID_CURRENT_REPORT: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          id: action.payload.id,
        },
      };
    }

    case types.SET_LAST_REPORT: {
      const { lastViewedReport } = action.payload;

      return { ...state, lastViewedReport };
    }

    case types.SET_COLUMNS: {
      const { columns } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          columns,
        },
      };
    }

    case types.SET_REPORT_CATEGORY: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          category: action.payload.category,
        },
      };
    }

    case types.SET_REPORT_TYPE: {
      return { ...state, currentReport: { ...state.currentReport, type: action.payload.type } };
    }

    case types.SET_RELATIVE_DATE: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          datesRange: { ...state.currentReport.datesRange, ...action.payload },
        },
      };
    }

    case types.SET_TEMP_PERMISSIONS: {
      const { permissions } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          permissions,
        },
      };
    }

    case types.SET_REPORT_CREATED_BY: {
      const { createdBy } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          createdBy,
        },
      };
    }

    case types.SET_PERMISSIONS: {
      const { id, permissions } = action.payload;
      if (permissions[0]?.category) {
        return {
          ...state,
          currentReport: {
            ...state.currentReport,
            permissions,
          },
        };
      }

      if (!Array.isArray(state.currentReport.permissions)) {
        throw new Error('Unforeseen scenario: permissions is now array');
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          permissions: [
            ...state.currentReport.permissions,
            permissions.map((permission: ReportPermissionListItem) => ({ ...permission, category: id })),
          ],
        },
      };
    }

    case types.SAVE_PERMISSIONS_STARTED: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: true,
        },
      };
    }

    case types.SAVE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,

          updatedOwner: null,
        },
      };
    }

    case types.SET_IS_NEW_OWNER_SAVED: {
      const { isNewOwnerSaved } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isNewOwnerSaved,
        },
      };
    }

    case types.GET_ADDITIONAL_COLUMNS_START: {
      return state;
    }

    case types.GET_ADDITIONAL_COLUMNS_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case types.GET_ADDITIONAL_COLUMNS_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case types.SAVE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        error: action.error,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
        },
      };
    }

    case types.SET_CURRENT_TAB: {
      const { currentTab } = action.payload;
      return { ...state, currentTab, isInitialFetchCompleted: false };
    }

    case types.RESET_REPORTS_TO_INITIAL_DATA: {
      return initialState;
    }

    case types.SET_REPORTS_LIST: {
      const { reportsList } = action.payload;

      return {
        ...state,
        reportsList,
      };
    }

    // Scripted Reports
    case types.SET_KEY_CURRENT_REPORT: {
      const { key } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          key,
          isWithChanges: true,
        },
      };
    }

    case types.SET_DESCRIPTION_CURRENT_REPORT: {
      const { description } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          description,
          isWithChanges: true,
        },
      };
    }

    case types.SET_TAGS_CURRENT_REPORT: {
      const { tags } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          tags,
          isWithChanges: true,
        },
      };
    }

    case types.SET_ICON_CURRENT_REPORT: {
      const { icon } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          icon,
          isWithChanges: true,
        },
      };
    }

    case types.SET_TEMPLATE_CURRENT_REPORT: {
      const { template } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          template,
          isWithChanges: true,
        },
      };
    }

    case types.SET_SCRIPT_CURRENT_REPORT: {
      const { script } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          script,
          isWithChanges: true,
        },
      };
    }

    case types.SET_EXTERNAL_EDITOR: {
      const { externalEditor } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          externalEditor,
          isWithChanges: true,
        },
      };
    }

    case types.RESET_EXTERNAL_EDITOR: {
      const { type } = action.payload as { type: ExternalEditorType };
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          externalEditor: {
            type,
            params: initialExternalEditorTypeParamsMap[type],
          },
          isWithChanges: true,
        },
      };
    }

    case types.SET_EXTERNAL_EDITOR_SYNCING: {
      const { isExternalEditorSyncing } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isExternalEditorSyncing,
        },
      };
    }

    case types.SET_SCRIPT_CALLBACK_CURRENT_REPORT: {
      const { scriptCallback } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          scriptCallback,
        },
      };
    }

    case types.SET_INPUT_FIELDS_FORM_SCRIPTED_REPORTS: {
      const { name, title, type, option, optionsList, isMulti } = action.payload;

      // TODO: rewrite this as actions for each field
      // will be fixed in FEWR-1792
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          inputFields: {
            ...state.currentReport.inputFields,
            creationForm: {
              ...state.currentReport.inputFields.creationForm,
              ...(typeof name === 'string' && { name }),
              ...(typeof title === 'string' && { title }),
              ...(type && { type }),
              ...(typeof option === 'string' && { option }),
              ...(optionsList && { optionsList }),
              ...(typeof isMulti === 'boolean' && { isMulti }),
            },
          },
        },
      };
    }

    case types.SET_INPUT_FIELDS_LIST_SCRIPTED_REPORTS: {
      const { fieldsList } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          inputFields: {
            ...(checkIsReportScriptedCompatible(state.currentReport) ? state.currentReport.inputFields : {}),
            fieldsList,
          },
          isWithChanges: true,
        },
      };
    }

    case types.SET_INPUT_FIELDS_LIST_VALUES_SCRIPTED_REPORTS: {
      const { fieldsList } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          inputFields: {
            ...(checkIsReportScriptedCompatible(state.currentReport) ? state.currentReport.inputFields : {}),
            fieldsList,
          },
          isWithChanges: true,
        },
      };
    }

    case types.SET_GALLERY_REPORTS_FETCHED: {
      return {
        ...state,
        isGalleryReportsFetched: action.payload.value,
      };
    }

    case types.GET_ISSUES_BY_JQL: {
      const { issues } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
          issues,
        },
      };
    }

    case types.CHANGE_ISSUE_IN_TABLE_NAME: {
      const { issues } = state.currentReport;

      const newIssues = produce(issues, (draftIssues) => {
        // @ts-expect-error fixme
        const foundIssue = draftIssues.find(({ issueKey }) => issueKey === action.payload.issueKey);

        if (foundIssue) {
          // @ts-expect-error fixme
          foundIssue.issueName = action.payload.newName;
        }
      });

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDataFetching: false,
          issues: newIssues,
        },
      };
    }

    case types.SET_SEARCH_STRING: {
      const { searchString } = action.payload;

      return {
        ...state,
        searchString,
      };
    }

    case types.SET_IS_ONLY_FAVORITES: {
      const { isOnlyFavorites } = action.payload;

      return {
        ...state,
        isOnlyFavorites,
        isInitialFetchCompleted: false,
      };
    }

    case types.ADD_FAVORITE_REPORT_FRONT: {
      const { favoriteReportId } = action.payload;

      return {
        ...state,
        reportsList: produce(state.reportsList, (draftReportsList) => {
          draftReportsList.forEach((report) => {
            // @ts-expect-error fixme
            if (report._id === favoriteReportId) {
              // @ts-expect-error fixme

              report.isFavorite = true;
            }
          });
        }),
      };
    }
    case types.REMOVE_FAVORITE_REPORT_FRONT: {
      const { favoriteReportId, isOnlyFavorites } = action.payload;

      if (isOnlyFavorites) {
        return {
          ...state,
          // @ts-expect-error fixme
          reportsList: state.reportsList.filter((report) => report._id !== favoriteReportId),
        };
      }

      return {
        ...state,
        reportsList: produce(state.reportsList, (draftReportsList) => {
          draftReportsList.forEach((report) => {
            // @ts-expect-error fixme
            if (report._id === favoriteReportId) {
              // @ts-expect-error fixme

              report.isFavorite = false;
            }
          });
        }),
      };
    }

    case types.SET_AGGREGATIONS: {
      const { aggregations } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          aggregations,
        },
      };
    }

    case types.SET_IS_EXPORTING: {
      const { isExporting } = action.payload;
      return {
        ...state,
        isExporting,
      };
    }

    case types.SET_IS_ATTACHING: {
      const { isAttaching } = action.payload;
      return {
        ...state,
        isAttaching,
      };
    }

    case types.SET_ATTACHMENT_STATUS: {
      const { attachmentStatus } = action.payload;

      return {
        ...state,
        attachmentStatus,
      };
    }

    case types.SET_REPORT_CURRENT_PERIOD: {
      const { currentPeriod } = action.payload;

      return { ...state, currentReport: { ...state.currentReport, currentPeriod } };
    }

    case types.SET_SOURCES: {
      const { sources } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isWithChanges: true,
          sources,
        },
      };
    }

    case types.SAVE_SOURCE: {
      const { source } = action.payload;

      const newSources = [...state.currentReport.sources, source];

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isWithChanges: true,
          sources: newSources,
        },
      };
    }

    case types.GET_CALENDAR_DATA_STARTED: {
      return {
        ...state,
        isDataFetching: true,
      };
    }

    case types.GET_CALENDAR_DATA_SUCCESS: {
      const { data, fieldsByType } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          data,
          fieldsByType,
          isDataFetching: false,
        },
      };
    }

    case types.GET_CALENDAR_DATA_FAILURE: {
      return {
        ...state,
        isDataFetching: false,
        isFetchedWithErrors: true,
      };
    }

    case types.SET_REPORT_CALENDAR_VIEW: {
      const { calendarView } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          calendarView,
        },
      };
    }

    case types.SET_IS_REPORT_WITH_WEEKENDS: {
      const { isWithWeekends } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isWithWeekends,
        },
      };
    }

    case types.GET_LAST_VIEWED_REPORT_SUCCESS: {
      const { lastViewedReportValues } = action.payload;
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          ...lastViewedReportValues,
          isDataFetching: false,
          isWithChanges: false,
          isFetchedWithErrors: false,
        },
      };
    }

    case types.GET_LAST_VIEWED_REPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case types.SET_IS_INCLUDES_SUBTASK_IN_REPORT: {
      const { isIncludesSubtask } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isIncludesSubtask,
          isWithChanges: true,
        },
      };
    }

    case types.SET_IS_PARENTS_SHOULD_BE_FETCHED: {
      const { isParentsShouldBeFetched } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isParentsShouldBeFetched,
        },
      };
    }

    case types.SET_IS_CHILDREN_SHOULD_BE_FETCHED: {
      const { isChildrenShouldBeFetched } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isChildrenShouldBeFetched,
        },
      };
    }

    case types.FETCH_CUSTOM_FIELDS_OPTIONS_SUCCESS: {
      const { customFieldsOptions, numberCustomFieldsOptions } = action.payload;

      return {
        ...state,
        customFieldsOptions,
        numberCustomFieldsOptions,
      };
    }

    case types.FETCH_CUSTOM_FIELDS_OPTIONS_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case types.SET_REPORT_EXPORT_PANEL_VISIBILITY: {
      const { reportExportVisibility } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          reportExportVisibility,
        },
      };
    }

    case types.SET_REPORT_DATA_TYPE: {
      const { dataType, areSoftwareFieldsInitialized } = action.payload;

      let { groupBy, columns, aggregationTarget, filters, aggregationType } = state.currentReport;

      if (state.currentReport.dataType !== dataType) {
        if (dataType === 'issues') {
          groupBy = ['project'];
          columns = ['issuetype'];
          filters = [];
          aggregationType = 'COUNT';

          // we don't need custom number fields when we set default values (last argument)
          const aggregationTargetsOptions = filterOptionsBasedOnApplications(
            getAggregationTargetsOptions(() => { }, dataType, aggregationType, []),
            areSoftwareFieldsInitialized,
          );
          aggregationTarget = aggregationTargetsOptions[0].value;
        } else {
          groupBy = ['project'];
          columns = ['worklogDate'];

          filters = [];

          aggregationType = 'SUM';
          aggregationTarget = 'loggedTime';
        }
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,

          dataType,
          groupBy,
          columns,
          aggregationTarget,
          aggregationType,

          filters,
          isWithChanges: true,
        },
      };
    }
    case types.SET_REPORT_VIEW_TYPE: {
      const { reportViewType } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          reportViewType,
          isWithChanges: true,
        },
      };
    }

    case types.SET_REPORT_TIME_TRACKING_UNIT: {
      const { timeTrackingUnit } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          timeTrackingUnit,
        },
      };
    }

    case types.SET_AGGREGATION_TYPE: {
      const { aggregationType, areSoftwareFieldsInitialized, isServiceDeskInstalled } = action.payload;
      const { dataType } = state.currentReport;
      let { aggregationTarget } = state.currentReport;

      // we don't need custom number fields when we set default values (last argument)
      const aggregationTargetsOptions = filterOptionsBasedOnApplications(
        getAggregationTargetsOptions(() => { }, dataType, aggregationType, []),
        areSoftwareFieldsInitialized,
        isServiceDeskInstalled,
      );

      if (state.currentReport.aggregationType !== aggregationType) {
        // @ts-expect-error fixme
        if (!aggregationTargetsOptions.find(({ value }) => value === aggregationTarget)) {
          aggregationTarget = aggregationTargetsOptions[0].value;
        }
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          aggregationType,
          aggregationTarget,
          isWithChanges: true,
        },
      };
    }

    case types.SET_AGGREGATION_TARGET: {
      const { aggregationTarget } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          aggregationTarget,
          isWithChanges: true,
        },
      };
    }

    case types.SET_REPORT_FILTERS: {
      const { filters } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          filters,
          isWithChanges: true,
        },
      };
    }

    case types.SET_IS_EDIT_MODE: {
      const { isEditMode } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isEditMode,
        },
      };
    }

    case types.SET_IS_DEMO_MODE: {
      const { isDemoMode, demoData } = action.payload;

      const { aggregationTarget, aggregationType } = state.currentReport;
      const isWorklogDataType = demoData.dataType === UniversalReportLookingFor.worklogs;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          ...demoData,
          isDemoMode,
          aggregationType: isWorklogDataType ? 'SUM' : aggregationType,
          aggregationTarget: isWorklogDataType ? 'loggedTime' : aggregationTarget,
        },
      };
    }

    case types.SET_DOCS_LINK: {
      const { docsLink } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          docsLink,
        },
      };
    }

    case types.SET_IS_DESCRIPTION_VISIBLE: {
      const { isDescriptionVisible } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isDescriptionVisible,
        },
      };
    }

    case types.SET_SHAREABLE_LINK: {
      const { shareableLink } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          shareableLink,
        },
      };
    }

    case types.SET_SHARED_ID: {
      const { sharedId } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          sharedId,
        },
      };
    }

    case types.SET_TEMP_ISSUES_DATA: {
      const { tempIssues } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          tempIssues,
        },
      };
    }

    case types.SET_COLOR_THEME: {
      const { colorTheme } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          colorTheme,
          isWithChanges: true,
        },
      };
    }

    case types.SET_IS_SECURE_ENVIRONMENT: {
      const { isSecureEnvironment } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isSecureEnvironment,
          isWithChanges: true,
        },
      };
    }

    case types.SET_SR_RENDER_ERROR: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          srRenderError: action.payload,
        },
      };
    }

    case types.SET_SR_LOADING: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          isSrLoading: action.payload,
        },
      };
    }

    case types.SET_VERSION_CURRENT_REPORT: {
      const { version } = action.payload;

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          version,
          isWithChanges: true,
        },
      };
    }

    default:
      return state;
  }
});
