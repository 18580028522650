import { requestApi } from 'controllers/transport/requestApi';

export const fetchAccountIdOrUsername = () => new Promise<string>(async (resolve) => { // eslint-disable-line no-async-promise-executor
  if (IS_CLOUD) {
    window.AP.user.getCurrentUser((user) => {
      if (user.atlassianAccountId) {
        resolve(user.atlassianAccountId);
      }
    });
  } else {
    if (window.AJS.params.loggedInUser) {
      resolve(window.AJS.params.loggedInUser);
      return;
    }

    const { username } = await requestApi<{ username: string }>({
      path: '/rest/gadget/1.0/currentUser',
      method: 'GET' as const,
    });
    resolve(username);
  }
});