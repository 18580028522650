import { ReportCategory, ReportPlacement, DashboardReportPlacementContext } from '../constants/report';
import { UniversalReportLookingFor } from '../constants/universalReport';
import { LegacyFilterBy } from './scopePicker';
import { ReportPermissionsList } from './reportPermissions';
import { SelectOption } from './select';
import { UrtReportDto } from './urt';
import { ExternalEditorConfig } from './srExternalEditor';
import { SrInputFieldSerialized } from './sr';

export type ReportColumn = 'issuetype' | 'worklogDate';
export type ReportFilter = unknown;
export type ReportFilters = ReportFilter[];
export type ReportDto<Report extends BaseReport> = Omit<Report, 'id' | 'permissions'> & {
  _id?: string | number | undefined;
  permissions: ReportPermissionsList;
};

export interface ShareableLink {
  permissionType: 'view';
  shareableLink: string;
  sharedIdHash: string;
}

export enum CalendarView {
  month = 'month',
  week = 'week',
  work_week = 'work_week',
}

export type ReportPermissions = ReportPermissionsList;

export interface SelectOptionFieldValue { value: string; label: string }

export interface GalleryMetaFields {
  description: string;
  tags: string[];
  docsLink: string;
  icon: string;
  key: string;
}

export interface BaseReport extends Partial<GalleryMetaFields> {
  id?: null | string | number;
  name: string;
  permissions: ReportPermissionsList;
  category?: string; // todo use only type field (reportType)
  type: string;
  createdBy?: string;
  createdAt?: number;
  updatedAt?: number;

  colorTheme?: 'atlassian';

  updatedOwner?: string | null; // null needs for DC
  reportExportVisibility?: SelectOption[];

  placement?: ReportPlacement;
  placementContext?: DashboardReportPlacementContext;
}

export type ScriptedReportCommon = BaseReport & {
  type: 'scriptedReport';
  version?: string;
  category: ReportCategory.scriptedReport | ReportCategory.scriptedFromGallery;
  template: string;
  script: string;
  externalEditor: ExternalEditorConfig;
};

export type ScriptedReportDto = ReportDto<ScriptedReportCommon & {
  galleryReportId?: string;
  fields: SrInputFieldSerialized[];
  shareableLink?: ShareableLink; // todo check why we still have shareableLink: string in currentReport
  vulnerableFieldsUpdatedAt?: string | null;
  vulnerableFieldsUpdatedBy?: string | null;
  isSecureEnvironment?: boolean | null;
}>;

export type ScriptedReportDtoWithId = Omit<ScriptedReportDto, '_id'> & {
  _id: string | number;
};

export interface CalendarReportCommon {
  sources: [];
  calendarView: CalendarView;
  isWithWeekends: false;
}

export type UniversalReportCommon = BaseReport & CalendarReportCommon & {
  category: ReportCategory.universalReport;

  dataType: UniversalReportLookingFor;
  reportViewType: 'table';

  aggregationType: 'COUNT' | 'SUM';
  aggregationTarget: 'issues' | 'loggedTime';

  groupBy: string[];
  sortBy: {
    isAscending: true;
    path: 'name';
    type: 'text';
  };
  filterBy: unknown[];
  columns: ReportColumn[];

  timeTrackingUnit: 'hours';

  lastDataFetchDate?: string;
  isIncludesSubtask: boolean;
  isParentsShouldBeFetched: boolean;
  isChildrenShouldBeFetched: boolean;

  shareableLink?: string;
};
export type CalendarReport = Omit<UniversalReportCommon, 'category'> & {
  category: ReportCategory.calendar;
};
export type UniversalReportDto = ReportDto<UniversalReportCommon & {
  filterBy: LegacyFilterBy;

  // @deprecated (looks like we use only filters from filterBy
  exclusions: ReportFilters;

  dateRange: {
    start: number;
    end: number;
    relativeDate: null;
  };

  shareableLink?: ShareableLink;
}>;
export type CalendarReportDto = Omit<UniversalReportDto, 'category'> & {
  category: ReportCategory.calendar;
};
export type AnyReportDto = ScriptedReportDto | UniversalReportDto | CalendarReportDto | UrtReportDto;
export type ScriptedReportDashboardDto = Pick<
  ScriptedReportDto, '_id' | 'type' | 'category' | 'fields' | 'createdBy' | 'permissions'
> & {
  reportId?: string; // @legacy gadget data kept _id inside reportId
};