export enum AmplitudeEvents {
  PAGE_OPEN_EVENT = 'PAGE_OPEN_EVENT',
  GALLERY_REPORT_CLICK_EVENT = 'GALLERY_REPORT_CLICK_EVENT',
  SAVE_REPORT_EVENT = 'SAVE_REPORT_EVENT',
  SAVE_GADGET = 'SAVE_GADGET',
  REFRESH_REPORT_GADGET = 'REFRESH_REPORT_GADGET',
  ISSUE_PANEL_OPEN_REPORT = 'ISSUE_PANEL_OPEN_REPORT',
  ISSUE_PANEL_SHOW_PREVIEW = 'ISSUE_PANEL_SHOW_PREVIEW',
  ISSUE_PANEL_EXPORT = 'ISSUE_PANEL_EXPORT',
  ISSUE_PANEL_PRINT = 'ISSUE_PANEL_PRINT',
  ISSUE_PANEL_ATTACH = 'ISSUE_PANEL_ATTACH',
  PROJECT_PAGE_OPEN_EVENT = 'PROJECT_PAGE_OPEN_EVENT',
  UR_DEMO_ISSUES_CLICK_EVENT = 'UR_DEMO_ISSUES_CLICK_EVENT',
  UR_DEMO_WORKLOGS_CLICK_EVENT = 'UR_DEMO_WORKLOGS_CLICK_EVENT',
  REPORT_LOADED = 'REPORT_LOADED',
  AMPLITUDE_ENABLED = 'AMPLITUDE_ENABLED',
  AMPLITUDE_DISABLED = 'AMPLITUDE_DISABLED',
  HOTJAR_ENABLED = 'HOTJAR_ENABLED',
  HOTJAR_DISABLED = 'HOTJAR_DISABLED',
  EXPORT_TO_XLS = 'EXPORT_TO_XLS',
  EXPORT_TO_CSV = 'EXPORT_TO_CSV',
  EXPORT_TO_PDF_IMAGE = 'EXPORT_TO_PDF_IMAGE',
  EXPORT_TO_JSON = 'EXPORT_TO_JSON',
  PRINT = 'PRINT',
}

export const { PAGE_OPEN_EVENT } = AmplitudeEvents;
export const { GALLERY_REPORT_CLICK_EVENT } = AmplitudeEvents;
export const { SAVE_REPORT_EVENT } = AmplitudeEvents;
export const { SAVE_GADGET } = AmplitudeEvents;
export const { REFRESH_REPORT_GADGET } = AmplitudeEvents;
export const { ISSUE_PANEL_SHOW_PREVIEW } = AmplitudeEvents;
export const { ISSUE_PANEL_OPEN_REPORT } = AmplitudeEvents;
export const { ISSUE_PANEL_EXPORT } = AmplitudeEvents;
export const { ISSUE_PANEL_PRINT } = AmplitudeEvents;
export const { ISSUE_PANEL_ATTACH } = AmplitudeEvents;
export const { PROJECT_PAGE_OPEN_EVENT } = AmplitudeEvents;
export const { UR_DEMO_ISSUES_CLICK_EVENT } = AmplitudeEvents;
export const { UR_DEMO_WORKLOGS_CLICK_EVENT } = AmplitudeEvents;
export const { REPORT_LOADED } = AmplitudeEvents;
export const { AMPLITUDE_ENABLED } = AmplitudeEvents;
export const { AMPLITUDE_DISABLED } = AmplitudeEvents;
export const { HOTJAR_ENABLED } = AmplitudeEvents;
export const { HOTJAR_DISABLED } = AmplitudeEvents;
export const { EXPORT_TO_XLS } = AmplitudeEvents;
export const { EXPORT_TO_CSV } = AmplitudeEvents;
export const { EXPORT_TO_PDF_IMAGE } = AmplitudeEvents;
export const { EXPORT_TO_JSON } = AmplitudeEvents;
export const { PRINT } = AmplitudeEvents;