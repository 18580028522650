import produce from 'immer';
import { getDateConstants } from 'constants/dateConstants';
import types from 'store/legacy/project/types';

const { THIS_MONTH_START, THIS_MONTH_END } = getDateConstants('sunday');

const initialState = {
  groupBy: ['issue'],
  sortBy: {
    isAscending: true,
    path: 'name',
    type: 'text',
  },
  loggedTimeInSeconds: 0,
  data: [],
  datesRange: {
    startDate: new Date(THIS_MONTH_START),
    endDate: new Date(THIS_MONTH_END),
  },
  isDataFetching: false,
};

export const project = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.SET_DATES_RANGE_CURRENT_PROJECT:
      return { ...state, datesRange: action.payload.datesRange };

    case types.GET_CURRENT_PROJECT_TABLE_DATA_STARTED:
      return { ...state, isDataFetching: true };

    case types.GET_CURRENT_PROJECT_TABLE_DATA_SUCCESS: {
      const { data, loggedTimeInSeconds } = action.payload;

      return { ...state, isDataFetching: false, data, loggedTimeInSeconds };
    }

    case types.GET_CURRENT_PROJECT_TABLE_DATA_FAILURE:
      return { ...state, error: action.payload.error };

    default:
      return state;
  }
});
