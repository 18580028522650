import { PaginatedRequestParams, ResponseBody } from 'store/api/jira/baseApi/types';
import { requestWithPagination } from 'store/api/jira/baseApi/requestWithPagination';
import { request } from 'store/api/jira/baseApi/request';
import { BaseQueryApi } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { getContextPath } from 'utils/getContextPath';

type InputParams = string | (PaginatedRequestParams & {
  body?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  method: string;
  url: string;
  headers?: Record<string, string>;
});

export const jiraBaseQueryFn = async (args: InputParams, api: BaseQueryApi) => {
  const baseUrl = IS_CLOUD ? window.appUrl : getContextPath();
  const params = typeof args === 'string' ? {
    type: 'GET' as const,
    url: `${baseUrl}/rest/${args}`,
    headers: {},
  } : {
    type: args.method as 'GET',
    url: `${baseUrl}/rest/${args.url}`,
    data: args.body,
    headers: {
      ...(args.headers ?? {}),
    },
  };

  params.headers = {
    ...params.headers,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  try {
    if (typeof args !== 'string' && args.withPagination) {
      const { requestLoadingCallback, registerAbortRequest, requestId } = args;

      const abortController = new AbortController();
      const abort = (reason?: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        api.abort(reason);
        abortController.abort(reason);
      };

      if (registerAbortRequest) {
        registerAbortRequest(abort);
      }

      const responseBody = await requestWithPagination<ResponseBody>({
        requestParams: params,
        requestLoadingCallback,
        requestId,
        signal: abortController.signal,
      });

      return { data: responseBody } as { data: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    const responseBody = await request(params, true);
    return { data: responseBody } as { data: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
  } catch (error) {
    // todo implement unificated error throwing (throw same object on cloud and server)
    if (RUNTIME_ENV !== 'production') {
      console.error('jiraBaseApi error', params, error);
    }
    return { error };
  }
};