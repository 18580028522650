import { requestAPI } from 'controllers/transport/requestFunctions';

class TransportController {
  request = async ({ config, query, data, loadingCallback }) => {
    const cloudType = process.env.JIRA_TYPE;
    const isCloud = cloudType === 'cloud';

    return requestAPI(config[cloudType], query, data, isCloud, loadingCallback);
  };
}

const transport = new TransportController();

export { transport as TransportController };
