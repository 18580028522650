export const calculatedFieldsList = (t) => [
  {
    name: t('timesheets.groupsSelect.options.issue'),
    id: 'issue',

    type: 'calculated',
  },
  {
    name: t('timesheets.groupsSelect.options.worklog'),
    id: 'worklog',

    type: 'calculated',
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogAuthor'),
    id: 'worklogAuthor',

    type: 'calculated',
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogDate'),
    id: 'worklogDate',

    type: 'calculated',
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogYear'),
    id: 'worklogYear',

    type: 'calculated',
    isForUniversalReport: true,
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogQuarter'),
    id: 'worklogQuarter',

    type: 'calculated',
    isForUniversalReport: true,
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogMonth'),
    id: 'worklogMonth',

    type: 'calculated',
    isForUniversalReport: true,
    isWorklogData: true,
  },
  {
    name: t('reports.columnsSelector.options.commonFields.worklogWeek'),
    id: 'worklogWeek',

    type: 'calculated',
    isForUniversalReport: true,
    isWorklogData: true,
  },
  {
    name: 'Epic',
    id: 'epic',

    type: 'calculated',
  },
];
