import produce from 'immer';
import types from 'store/legacy/galleryReports/types';

const initialState = [];

export const galleryReports = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_GALLERY_REPORTS:
      return [...action.payload];

    case types.ADD_GALLERY_REPORT:
      return [...state, action.payload];

    case types.REMOVE_GALLERY_REPORT:
      const id = action.payload;

      return state.filter((galleryReport) => galleryReport._id !== id);

    case types.UPDATE_GALLERY_REPORT:
      const galleryReport = action.payload;

      return state.map((gr) => {
        if (gr.id === galleryReport.id) {
          gr = { ...gr, ...galleryReport };
        }
        return gr;
      });

    default:
      return state;
  }
});
