import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { JiraLicenseCloud, JiraLicense, JiraLicenseServer } from 'actonic-types-atlassian/src/jira/jiraLicense';

const pathPrefix = IS_CLOUD ? 'api/3/instance/license' : 'api/2/applicationrole';

export const instanceLicenseApi = jiraBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstanceLicenses: builder.query<JiraLicense[], undefined>({
      query: () => pathPrefix,
      ...(IS_CLOUD ? {
        transformResponse: (
          rawResult: { applications: JiraLicenseCloud[] },
        ) => rawResult.applications.map(({ id }) => ({ id, defined: true })),
      } : {
        transformResponse: (
          rawResult: JiraLicenseServer[],
        ) => rawResult.map(({ key, defined }) => ({ id: key, defined })),
      }),
    }),
  }),
});

export const {
  useGetInstanceLicensesQuery,
} = instanceLicenseApi;
