import { useRef } from 'react';
import { logError } from 'utils/sentry';
import { OnlyFunction } from 'types/Global';

interface IUsePersistentCallback {
  // eslint-disable-next-line @typescript-eslint/ban-types
  <OT, T = OnlyFunction<OT>>(callback: T): T;
}

/**
 * usePersistentCallback
 * to use with React.memo components
 * returns permanent function wrapper that calls callback via ref, so it always calls actual callback
 */
const usePersistentCallback: IUsePersistentCallback = (func) => {
  const calleRef = useRef(func);
  const callerRef = useRef<typeof func>(null as unknown as typeof func);
  if (!callerRef.current) { // not in initial ref's value to not create wrapper function each time
    callerRef.current = function persistendCallback() {
      // eslint-disable-next-line prefer-rest-params
      if (typeof calleRef.current !== 'function') {
        return;
      }
      try {
        const res = calleRef.current.apply(undefined, arguments); // eslint-disable-line prefer-rest-params
        return res;
      } catch (e) {
        logError('React callback error', e);
      }
    } as unknown as typeof func;
  }

  calleRef.current = func;

  return callerRef.current;
};

export default usePersistentCallback;