import { createSelector } from 'reselect';
import { RootState } from 'store/index';

export const selectAppEnvironment = ({ appEnvironment }: RootState) => appEnvironment;

export const selectShouldUseTmWorklogs = createSelector(
  ({ appEnvironment: { isTimesheetBuilderInstalled } }: RootState) => Boolean(isTimesheetBuilderInstalled),
  (isTimesheetBuilderInstalled) => Boolean(isTimesheetBuilderInstalled),
);

export const selectIsSequreWorklogsAvailable = createSelector(
  selectShouldUseTmWorklogs,
  (shouldUseTmWorklogs) => (!IS_CLOUD && shouldUseTmWorklogs),
);

export const selectIsCustomWorklogFuncAvailable = createSelector(
  selectIsSequreWorklogsAvailable,
  ({ appEnvironment }: RootState) => Boolean(appEnvironment.timesheetBuilderVersion),
  (isSequreWorklogsAvailable, isTmWithsWorklogDateFunction) => (
    isSequreWorklogsAvailable && isTmWithsWorklogDateFunction
  ),
);
