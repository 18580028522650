import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorContainer, ErrorText, ButtonContainer } from 'components/ErrorBoundary/ErrorBoundaryPage/styled';
import Button from '@atlaskit/button';

const supportUrl = 'https://actonic.atlassian.net/servicedesk/customer/portal/8';

export const ErrorBoundaryPage = () => {
  const { t } = useTranslation();
  const refreshPage = () => {
    if (process.env.JIRA_TYPE === 'cloud') {
      const { AP } = window;

      AP.navigator.reload();
    } else {
      window.location.reload();
    }
  };

  return (
    <ErrorContainer>
      <ErrorText>
        {t('errorBoundary.text')}
      </ErrorText>
      <ButtonContainer>
        <Button spacing='compact' onClick={refreshPage}>
          {t('errorBoundary.refresh')}
        </Button>
        <Button spacing='compact' appearance='link' href={supportUrl} target='_blank'>
          {t('errorBoundary.support')}
        </Button>
      </ButtonContainer>
    </ErrorContainer>
  );
};
