import { pluginTmBaseApi } from 'store/api/pluginTm/baseApi/pluginTmBaseApi';
import { ServerPluginTmConfig } from 'types/serverPluginConfig';

export const tmConfigApi = pluginTmBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTmConfig: builder.query<ServerPluginTmConfig, undefined>({
      query: () => 'configs',
    }),
  }),
});

export const {
  useGetTmConfigQuery,
} = tmConfigApi;