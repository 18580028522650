import { getContextPath } from 'utils/getContextPath';

export const getPluginTmApiBaseUrl = () => {
  if (IS_CLOUD) {
    return `${window.baseUrl}/rest/api`;
  }

  if (window.AJS) {
    return `${getContextPath()}/rest/actonic-tb/1.0/api`;
  }

  return '';
};