import { JiraIssueFieldMeta } from 'sharedFrontBack/src/singletonModules/pivotopia';
import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';

const url = IS_CLOUD ? 'api/3/field' : 'api/2/field';

export const jiraFieldsApi = jiraBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getJiraFields: builder.query<JiraIssueFieldMeta[], void>({
      query: () => url,
    }),
  }),
});

export const {
  useGetJiraFieldsQuery,
} = jiraFieldsApi;
