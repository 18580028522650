import { getContextPath } from 'utils/getContextPath';

export const createIssueUrl = (key: string) => {
  if (process.env.JIRA_TYPE === 'cloud') {
    return `${window.appUrl}/browse/${key}`;
  }

  return `${getContextPath()}/browse/${key}`;
};

export const createSearchUrl = (key: string) => {
  if (process.env.JIRA_TYPE === 'cloud') {
    return `${window.appUrl}/issues/?jql=${key}`;
  }

  return `${getContextPath()}/issues/?jql=${key}`;
};

export const createProjectUrl = (key: string) => {
  if (process.env.JIRA_TYPE === 'cloud') {
    return `${window.appUrl}/browse/${key}`;
  }

  return `${getContextPath()}/browse/${key}`;
};

export const createUserUrl = (key: string) => {
  if (process.env.JIRA_TYPE === 'cloud') {
    return `${window.appUrl}/people/${key}`;
  }

  return `${getContextPath()}/secure/ViewProfile.jspa?name=${key}`;
};
