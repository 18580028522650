import { searchUsers } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: searchUsers,
  allPages: false,
  fieldName: 'users',
  maxResults: 20,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/user/picker',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/user/picker',
  },
};

export { config as searchUsers };
