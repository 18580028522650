const toPathname = (to: string | URL) => typeof to === 'string'
  ? to
  : `${to.pathname}${to.search}`;

export const getAtlassianNavigator = (urlBasename?: URL) => ({
  createHref: (to: string | URL) => {
    if (urlBasename) {
      const href = new URL(urlBasename);

      href.hash = `!${toPathname(to)}`;

      return href.toString();
    }

    return '';
  },

  go: window.AP.history.go,

  push: (to: string | URL) => {
    window.AP.history.pushState(toPathname(to));
  },

  replace: (to: string | URL) => window.AP.history.replaceState(toPathname(to)),
});
