import types from 'store/legacy/filterBySelector/types';

export const setIsFilterApplied = (isFilterApplied) => ({
  type: types.SET_IS_FILTER_APPLIED,
  payload: {
    isFilterApplied,
  },
});

export const getFilterByDataStarted = () => ({ type: types.GET_FILTER_BY_DATA_STARTED });

export const getFilterByDataSuccess = ({
  issues, users, projects, epics, teams, filters,
}) => ({
  type: types.GET_FILTER_BY_DATA_SUCCESS,
  payload: { issues, users, projects, epics, teams, filters },
});

export const getFilterByDataFailure = () => ({ type: types.GET_FILTER_BY_DATA_FAILURE });

export const removeSelectsValues = () => ({ type: types.REMOVE_SELECTS_VALUES });

export const setFullJQL = (fullJQL) => ({ type: types.SET_FULL_JQL, payload: fullJQL });

export const getIssuesCountStarted = () => ({ type: types.GET_ISSUES_COUNT_STARTED });

export const getIssuesCountSuccess = ({ issuesCount }) => ({ type: types.GET_ISSUES_COUNT_SUCCESS, payload: { issuesCount } });

export const setProjectsValues = ({ values }) => ({ type: types.SET_PROJECTS_VALUES, payload: values });

export const setEpicsValues = ({ values }) => ({ type: types.SET_EPICS_VALUES, payload: values });

export const setIssuesValues = ({ values }) => ({
  type: types.SET_ISSUES_VALUES,
  payload: values,
});

export const setUsersValues = ({ values }) => ({ type: types.SET_USERS_VALUES, payload: values });

export const setUsersJQL = ({ usersJQL }) => ({
  type: types.SET_USERS_JQL,
  payload: usersJQL,
});

export const setTeamsValues = ({ values }) => ({
  type: types.SET_TEAMS_VALUES,
  payload: values,
});

export const setTeamsJQL = ({ teamsJQL }) => ({
  type: types.SET_TEAMS_JQL,
  payload: teamsJQL,
});

export const setFiltersValues = ({ values }) => ({ type: types.SET_FILTERS_VALUES, payload: values });

export const changeJQLField = ({ jqlField }) => ({
  type: types.CHANGE_JQL_FIELD,
  payload: {
    jqlField,
  },
});

export const removeJQLAction = () => ({ type: types.REMOVE_JQL });
