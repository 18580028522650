import { TmTeamPermissions, TmUserTeamPermission } from 'actonic-sr-api/src/types/tm/teamPermissions';
import { pluginTmBaseApi } from 'store/api/pluginTm/baseApi/pluginTmBaseApi';

export const teamPermissionApi = pluginTmBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamPermissions: builder.query<TmUserTeamPermission[], void>({
      query: () => 'teampermissions',
      transformResponse: (rawResult: TmTeamPermissions) => rawResult.userTeamPermissions,
    }),
  }),
});

export const {
  useGetTeamPermissionsQuery,
} = teamPermissionApi;