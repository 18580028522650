import { UrtVisualizationType } from 'sharedFrontBack/src/constants/urt';
import { InputConfigPropNames } from 'sharedFrontBack/src/types/urt';

import TableIcon from 'images/table.png';
import ListIcon from 'images/list.png';
import NumberIcon from 'images/number.png';
import BarIcon from 'images/bar.png';
import PieIcon from 'images/pie.png';

export enum UrtSideBarPanels {
  getData = 'getData',
  setChart = 'setChart',
  setData = 'setData',
  setStyles = 'setStyles',
}

const tableCharts = [UrtVisualizationType.matrix, UrtVisualizationType.list];
const circleCharts = [UrtVisualizationType.pieChart, UrtVisualizationType.doughnutChart];
const otherCharts = [UrtVisualizationType.number, UrtVisualizationType.barChart];

export const chartCategories = {
  tableCharts,
  circleCharts,
  otherCharts,
};

export const reportIconsMap = {
  [UrtVisualizationType.matrix]: TableIcon,
  [UrtVisualizationType.list]: ListIcon,
  [UrtVisualizationType.number]: NumberIcon,
  [UrtVisualizationType.barChart]: BarIcon,
  [UrtVisualizationType.pieChart]: PieIcon,
  [UrtVisualizationType.doughnutChart]: PieIcon,
};

export const forbiddenFieldCategoriesMap: Record<InputConfigPropNames, Partial<Record<UrtVisualizationType, string[]>>> = {
  measures: {
    [UrtVisualizationType.doughnutChart]: ['date'],
    [UrtVisualizationType.pieChart]: ['date'],
    [UrtVisualizationType.barChart]: ['date'],
  },
  rows: {},
  cols: {},
};

export const reportsWithNotDefaultConfigNames = [
  UrtVisualizationType.barChart,
  UrtVisualizationType.pieChart,
  UrtVisualizationType.doughnutChart,
  UrtVisualizationType.list,
];

export enum DataTablePositionValues {
  notDisplayed = 'notDisplayed',
  underTheChart = 'underTheChart',
  leftOfTheChart = 'leftOfTheChart',
  rightOfTheChart = 'rightOfTheChart',
}
