import { createProjectUrl, createUserUrl } from 'utils/jiraUrl';
import { getTextFromField } from 'controllers/WorklogController/getWorklogComment';

export const formatDataByFieldType = (fields, fieldDataByFieldId, issueKey) => {
  const fieldsData = {};

  const allFields = {
    ...fields,
    issuekey: issueKey,
  };

  for (const prop in allFields) {
    if (Object.prototype.hasOwnProperty.call(allFields, prop)) {
      // prop names for final formatted object
      const fieldId = prop;
      const fieldName = `${fieldId}-name`;
      const fieldUrl = `${fieldId}-url`;
      const fieldIcon = `${fieldId}-icon`;

      if (fieldDataByFieldId[prop]) {
        const fieldType = fieldDataByFieldId[prop].type;

        // This name predefined in data about field
        const nameOfField = fieldDataByFieldId[prop].name;

        switch (fieldType) {
          case 'string': {
            fieldsData[prop] = null;

            if (typeof fields[prop] === 'string') {
              fieldsData[prop] = fields[prop];
            }

            if (fields[fieldId] && fields[fieldId].type === 'doc') {
              fieldsData[fieldId] = getTextFromField(fields[fieldId]);
            }

            break;
          }

          case 'project': {
            if (fields[prop]) {
              fieldsData[fieldId] = fields[prop].key;
              fieldsData[fieldName] = fields[prop].name;
              fieldsData[fieldUrl] = createProjectUrl(fields[prop].key);
              fieldsData[fieldIcon] = fields[prop].avatarUrls['16x16'];
            }

            break;
          }

          case 'user': {
            if (fields[prop]) {
              fieldsData[fieldId] = fields[prop]?.accountId ? fields[prop]?.accountId : fields[prop]?.key;
              fieldsData[fieldName] = fields[prop]?.displayName;
              fieldsData[fieldUrl] = createUserUrl(fields[prop]?.accountId ? fields[prop]?.accountId : fields[prop]?.name);
              fieldsData[fieldIcon] = fields[prop]?.avatarUrls['16x16'];
            }
            break;
          }

          case 'group': {
            if (fields[prop]) {
              fieldsData[fieldId] = fields[prop].name;
            }

            break;
          }

          case 'array': {
            // We working with worklogs via custom calculated fields, so we don't need it
            if (prop === 'worklog') {
              fieldsData[fieldId] = null;
              break;
            }

            if (fields[prop] && fields[prop].length > 0) {
              const arrayItemsType = fieldDataByFieldId[prop].arrayItemType;

              if (arrayItemsType === 'option') {
                fieldsData[fieldId] = fields[prop].map(({ value }) => value);

                break;
              }

              if (arrayItemsType === 'user' || nameOfField === 'Responders') {
                fieldsData[fieldId] = fields[prop].map(({ accountId, key }) => accountId || key);

                // Only name available in the field "Responders"
                fieldsData[fieldName] = fields[prop].map(({ displayName, name }) => displayName ? displayName : name);
                fieldsData[fieldUrl] = fields[prop].map(({ accountId, name }) => createUserUrl(accountId ? accountId : name));

                // Only avatarUrl in the field "Responders"
                fieldsData[fieldIcon] = fields[prop].map(({ avatarUrls, avatarUrl }) => avatarUrls
                  ? avatarUrls['16x16']
                  : avatarUrl);

                break;
              }

              if (arrayItemsType === 'attachment') {
                fieldsData[fieldId] = fields[prop].map((attach) => attach.filename);

                break;
              }

              if (arrayItemsType === 'issuelinks') {
                if (fieldId === 'issuelinks') {
                  fieldsData[fieldId] = fields[fieldId]
                    .map((issueLink) => {
                      if (issueLink.inwardIssue) {
                        return `${issueLink.inwardIssue.key} ${issueLink.inwardIssue.fields.summary}`;
                      }

                      return `${issueLink.outwardIssue.key} ${issueLink.outwardIssue.fields.summary}`;
                    });

                  break;
                }

                if (fieldId === 'subtasks') {
                  fieldsData[fieldId] = fields[fieldId]
                    .map((subTask) => `${subTask.key} ${subTask.fields.summary}`);

                  break;
                }
              }

              fieldsData[fieldId] = fields[prop].map((item) => {
                const { name } = item;

                if (process.env.JIRA_TYPE !== 'cloud' && fieldDataByFieldId[fieldId].name === 'Sprint') {
                  const foundSprintName = item.match(/name=.*?,startDate/);

                  if (foundSprintName && foundSprintName[0]) {
                    const [text] = foundSprintName;

                    return text.replace('name=', '').replace(',startDate', '');
                  }

                  return '';
                }

                // TODO: check if here could be something wrong
                if (arrayItemsType === 'json' || arrayItemsType === 'version' || arrayItemsType === 'component') {
                  return name;
                }

                if (arrayItemsType === 'string') {
                  return item;
                }

                if (name) return name;

                return null;
              });
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'issuetype': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].name;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'number': {
            if (typeof fields[fieldId] === 'number') {
              fieldsData[fieldId] = `${fields[fieldId]}`;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'priority': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].name;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'option': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].value;
            } else {
              fieldsData[fieldId] = null;
            }
            break;
          }

          case 'option-with-child': {
            if (fields[fieldId]) {
              const field = fields[fieldId];

              fieldsData[fieldId] = field && field.value
                && field.child && field.child.value && `${field.value} - ${field.child.value}`;
            } else {
              fieldsData[fieldId] = null;
            }
            break;
          }

          case 'resolution': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].name;
            } else {
              fieldsData[fieldId] = 'Not resolved';
            }

            break;
          }

          case 'progress': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = `${fields[fieldId].progress}`;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'date': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId];
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'datetime': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId];
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'issuerestriction': {
            fieldsData[fieldId] = null;
            break;
          }

          case 'status': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].name;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'securitylevel': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].name;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'timetracking': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = fields[fieldId].timeSpent;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'votes': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = `${fields[fieldId].votes}`;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'watches': {
            if (fields[fieldId]) {
              fieldsData[fieldId] = `${fields[fieldId].watchCount}`;
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'systemFieldValue': {
            if (fieldId === 'issuekey') {
              fieldsData[fieldId] = issueKey;
              break;
            }

            if (fields[fieldId]) {
              if (fieldId === 'parent') {
                fieldsData[fieldId] = fields[fieldId].key;
              }
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'sd-request-lang': {
            if (fields[fieldId]) {
              const dataInField = fields[fieldId];

              if (typeof dataInField === 'object') {
                if (dataInField.languageCode) {
                  fieldsData[fieldId] = dataInField.languageCode;
                  fieldsData[fieldName] = dataInField.displayName;
                } else {
                  fieldsData[fieldId] = null;
                }
              } else {
                fieldsData[fieldId] = null;
              }
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'sd-customerrequesttype': {
            if (fields[fieldId]) {
              const dataInField = fields[fieldId];

              if (typeof dataInField === 'object') {
                if (dataInField.requestType) {
                  fieldsData[fieldId] = dataInField.requestType.id;
                  fieldsData[fieldName] = dataInField.requestType.name;
                } else {
                  fieldsData[fieldId] = null;
                }
              } else {
                fieldsData[fieldId] = null;
              }
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          case 'any': {
            if (fields[fieldId]) {
              const dataInField = fields[fieldId];

              if (typeof dataInField === 'object') {
                if (dataInField.showField) {
                  fieldsData[fieldId] = dataInField.hasEpicLinkFieldDependency;
                } else {
                  fieldsData[fieldId] = null;
                }
              } else if (typeof dataInField === 'string') {
                fieldsData[fieldId] = fields[fieldId];
              }
            } else {
              fieldsData[fieldId] = null;
            }

            break;
          }

          default:
            break;
        }
      }
    }
  }

  return fieldsData;
};
