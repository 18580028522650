import React from 'react';
import { PrintContextValue } from 'features/print/types';

const initialPrintContextValue = {
  getPrintElement: () => undefined,
  setContainerRef: () => undefined,
  isPrinting: false,
  handlePrintClick: () => undefined,
};

export const PrintContext = React.createContext<PrintContextValue>(initialPrintContextValue);