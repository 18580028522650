const globalConfig = {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/dashboard',
  },
  server: {
    ...globalConfig,
    url: '/rest/api/2/dashboard',
  },
};

export { config as deleteDashboardItemProperty };
