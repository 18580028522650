/* eslint-disable max-len, no-irregular-whitespace */
import { DashboardTabs } from 'constants/constants';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { UniversalReportLookingFor } from 'sharedFrontBack/src/constants/universalReport';
import { createGetReportsTags, reportApi, anyReportTagType } from 'store/api/plugin/reportApi';
import { getDefaultPermissions } from 'utils/getDefaultPermissions';
import { v4 as uuidv4 } from 'uuid';
import { getFields, getWatchersForIssue } from 'controllers/transport/requestConfigurations';
import {
  selectIsCustomWorklogFuncAvailable,
  selectIsSequreWorklogsAvailable,
  selectShouldUseTmWorklogs,
} from 'store/slices/appEnvironment/selectors';
import { setLoadedReport, setLoadedScriptedReport } from 'store/legacy/reports/thunks/setLoadedReport';
import { changeRouteWithParams } from 'utils/changeRoute';
import { createReportDefaultName } from 'utils/createReportDefaultName';
import {
  issueService,
  TransportController as Transport,
  WorklogController,
} from 'controllers';
import { API } from 'controllers/API';
import { moment } from 'controllers/moment';
import { formatIssueTableData } from 'controllers/reportMigrations';
import { checkWorklogsPermissions } from 'controllers/reportMigrations/addWorklogTeamPermissions';
import { getFetchingFieldsList } from 'controllers/WorklogController/fetchingFieldsList';
import { createReportDto, createReportFromDto } from 'utils/reportDto';
import { createUserUrl } from 'utils/jiraUrl';
import { dedupeArray } from 'utils/dedupeArray';

import { getTotalInSecondsForWorklogs } from 'utils/formatTime';
import { getDefinedPeriodDates } from 'utils/dates/getStartAndEndDates';
import paths from 'router/routePaths';
import { defineDatesRange } from 'store/legacy/defineDatesRange';
import {
  getInitialDataForFilter,
  getIssuesCount,
  getTableDataWithFilter,
} from 'store/legacy/filterBySelector/thunks';
import { setLoadingFinishedProcess, setLoadingStartedProcess, setLoadingState } from 'store/legacy/progressBar/actions';
import {
  selectCurrentReport,
  selectFiltersAsString,
  selectIsAddable,
  selectIsEnoughUniversalReportFilters,
} from 'store/legacy/reports/selectors';
import { addEpicData } from 'store/legacy/timesheets/addEpicData';
import { calculateSubtasksInParent } from 'store/legacy/timesheets/calculateSubtasksInParent';
import { createWorklogDateJql } from 'utils/createWorklogDateJql';
import { validateLicenseStatus } from 'utils/checkLicense';
import {
  addFavoriteReportFrontAction,
  checkIsAvailableLastReportAction,
  cleanReportsTableData,
  createReportFailure,
  createReportStarted,
  createReportSuccess,
  deletingReportFailure,
  deletingReportsStarted,
  deletingReportsSuccess,
  editReportFailure,
  editReportStarted,
  editReportSuccess,
  fetchCustomFieldsOptionsFailure,
  fetchCustomFieldsOptionsSuccess,
  getCalendarDataFailure,
  getCalendarDataStarted,
  getCalendarDataSuccess,
  getCurrentReportFailure,
  getCurrentReportStarted,
  getDashboardReportsSuccess,
  getLastViewedReportFailure,
  getLastViewedReportSuccess,
  getReportsFailure,
  getReportsStarted,
  getReportTableDataFailure,
  getReportTableDataFinished,
  getReportTableDataStarted,
  getReportTableDataSuccess,
  getScriptedReportFailure,
  getScriptedReportStarted,
  removeFavoriteReportFrontAction,
  savePermissionsFailure,
  savePermissionsStarted,
  savePermissionsSuccess,
  saveReportComplete,
  saveReportFailure,
  saveReportStarted,
  saveReportSuccess,
  saveSource as saveSourceAction,
  setAggregationTypeAction,
  setColumnsAction,
  setDatesRangeCurrentReport,
  setDefaultCurrentReportAction,
  setGroupByAction,
  setInputFieldsListValues,
  setIsChildrenShouldBeFetchedAction,
  setIsNewOwnerSaved,
  setIsParentsShouldBeFetchedAction,
  setIsReportWithWeekendsAction,
  setIsWithChanges,
  setLastFilterAsString as setLastFilterAsStringAction,
  setLastViewedReport,
  setPermissions as setPermissionsAction,
  setReportCalendarViewAction, setReportCategory,
  setReportDataTypeAction,
  setReportId, setReportName,
  setReportNewOwner as setReportNewOwnerAction,
  setReportsList,
  setReportTimeTrackingUnitAction, setReportType,
  setShareableLink,
  setSourcesAction,
  setTempIssuesData,
  setTempPermissions,
  setVersion,
  updateVulnerableFieldsUpdatedFields,
} from 'store/legacy/reports/actions';
import { getFieldsList } from 'store/legacy/app/thunks';
import store from 'store/index';
import { selectUserId } from 'store/legacy/user/selectors';

import { checkIsReportAvailable } from 'store/legacy/reports/checkIsReportAvailable';
import { formatDataForGroupWorklogs } from 'store/legacy/reports/formatDataForGroupWorklogs';
import { getSecuredWorklogs } from 'store/legacy/reports/getSecuredWorklogs';
import { addFlag } from 'store/slices/flag/slice';
import { FlagType } from 'types/store/flag';
import { sendSaveReportEvent } from 'features/amplitude/utils/sendSaveReportEvent';

export const setLastFilterAsString = (filterAsString) => (dispatch, getState) => {
  const { isParentsShouldBeFetched } = getState().reports.currentReport;

  dispatch(
    setLastFilterAsStringAction({ filterAsString, isParentsShouldBeFetched }),
  );
};

export const getDashboardReports = () => async (dispatch) => {
  try {
    dispatch(getReportsStarted());

    const { data: { reports, sharedReports, galleryReports } } = await API.get('/dashboardreports');

    dispatch(
      getDashboardReportsSuccess({
        payload: {
          ...(reports && { reports: reports.map(createReportFromDto) }),
          ...(sharedReports && { sharedReports: sharedReports.map(createReportFromDto) }),
          ...(galleryReports && { galleryReports: galleryReports.map(createReportFromDto) }),
        },
      }),
    );
  } catch (error) {
    console.error(`[store.reports.actions][getDashboardReports] Exception: ${error}`);

    dispatch(getReportsFailure(error));
  }
};

export const getWorklogTableData = (filterAsString, formatOptions, userValues = []) => async (dispatch, getState) => {
  const { currentReport } = getState().reports;
  const {
    datesRange: { startDate: start, endDate: end },
    columns,
    isParentsShouldBeFetched,
    isChildrenShouldBeFetched,
  } = currentReport;

  const { loadingStartedProcessId } = getState().progressBar;
  const { numberCustomFieldsOptions } = getState().reports;

  const query = 'AND worklogAuthor = currentUser()';
  const fieldsList = ['parent', ...getFetchingFieldsList(false, formatOptions).split(','), ...columns];

  const isSecuredWorklogs = selectIsSequreWorklogsAvailable(getState());
  const isCustomWorklogFuncAvailable = selectIsCustomWorklogFuncAvailable(getState());

  const issues = await issueService().getIssues(
    filterAsString.length > 0
      ? `${createWorklogDateJql(start, end, isCustomWorklogFuncAvailable)} AND (${filterAsString})`
      : query,
    fieldsList,
    true,
    loadingStartedProcessId,
    {
      isParentsShouldBeFetched,
      isChildrenShouldBeFetched,
    },
  );

  const resultIssues = isSecuredWorklogs
    ? await getSecuredWorklogs({ issues, processId: loadingStartedProcessId, fieldsList, expand: [] }, { dispatch, getState })
    : await issueService().getFieldsForIssues(['worklog'], issues, loadingStartedProcessId);

  const worklogController = new WorklogController([]);

  worklogController.getFormattedWorklogsFromIssues(
    resultIssues, WorklogController.formatWorklogsForTable,
    formatOptions, numberCustomFieldsOptions,
  );

  const filterParams = {
    period: {
      start,
      end,
    },
    ...(userValues.length > 0 && !isSecuredWorklogs && { users: userValues.map(({ value }) => value) }),
  };

  const worklogs = worklogController.filter(filterParams);

  if (!store.getState().progressBar.loadingStartedProcessId) {
    return dispatch(cleanReportsTableData());
  }

  const uniqueIssueKeys = dedupeArray(worklogs.map(({ issueKey }) => issueKey).filter((item) => !!item));
  const watchersByIssueKey = [];

  if (columns.find((column) => column === 'watchers')) {
    for (const issueKey of uniqueIssueKeys) {
      if (!getState().progressBar.loadingStartedProcessId) {
        return dispatch(cleanReportsTableData());
      }

      const result = await Transport.request({
        config: getWatchersForIssue,
        query: `${issueKey}/watchers`,
      });

      const watchers = result.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.url = createUserUrl(item.accountId ? item.accountId : item.name);
        return item;
      });

      watchersByIssueKey.push({ key: issueKey, watchers });
    }
  }

  if (!getState().progressBar.loadingStartedProcessId) {
    return dispatch(cleanReportsTableData());
  }

  const extendedWorklogs = await addEpicData(worklogs, formatOptions);
  const resultWorklogTable = extendedWorklogs
    .map((worklog) => {
      let watchers = null;

      if (watchersByIssueKey.find(({ key }) => key === worklog.issueKey)) {
        const item = watchersByIssueKey.find(({ key }) => key === worklog.issueKey);

        watchers = item.watchers;
      }

      return { ...worklog, watchers };
    });

  store.dispatch(setLoadingFinishedProcess(store.getState().progressBar.loadingStartedProcessId));

  const loggedTimeInSeconds = getTotalInSecondsForWorklogs(resultWorklogTable);

  dispatch(getReportTableDataSuccess({
    worklogs: resultWorklogTable,
    loggedTimeInSeconds,
    lastDataFetchItemsCount: extendedWorklogs.length,
  }));
};

export const getCalendarReportData = () => async (dispatch, getState) => {
  const { sources } = getState().reports.currentReport;

  dispatch(getCalendarDataStarted());

  if (sources.length === 0) {
    dispatch(
      getCalendarDataSuccess({
        data: [],
      }),
    );

    dispatch(setLoadingFinishedProcess(getState().progressBar.loadingStartedProcessId));
  } else {
    try {
      const data = [];

      const { isParentsShouldBeFetched, isChildrenShouldBeFetched, datesRange } = getState().reports.currentReport;
      const { startDate, endDate } = datesRange;

      const allFields = await Transport.request({ config: getFields });

      const fieldsByType = allFields.map((field) => ({
        key: field.key ? field.key : field.id,
        name: field.name,
        type: (field.schema && field.schema.type) ? field.schema.type : field.id,
      }));

      for (const source of sources) {
        if (source.filterByFields && source.filterByFields.fullJQL.length > 0 && source.isSelected) {
          const { eventStartTime, eventEndTime } = source;

          const startTimeField = eventStartTime.isCustom
            ? eventStartTime.label
            : eventStartTime.value;

          let endTimeField;

          if (eventEndTime) {
            endTimeField = eventEndTime.isCustom
              ? eventEndTime.label
              : eventEndTime.value;
          }

          const startDateFormatted = moment(startDate).format('YYYY/MM/DD');
          const endDateFormatted = moment(endDate).format('YYYY/MM/DD');

          const datesJQL = (() => {
            if (endTimeField) {
              // eslint-disable-next-line max-len
              return `(("${startTimeField}" >= "${startDateFormatted}" AND "${startTimeField}" <= "${endDateFormatted}") OR ("${endTimeField}" >= "${startDateFormatted}" AND "${endTimeField}" <= "${endDateFormatted}") OR ("${startTimeField}" <= "${endDateFormatted}" AND "${endTimeField}" >= "${endDateFormatted}") OR ("${startTimeField}" <= "${startDateFormatted}" AND "${endTimeField}" >= "${endDateFormatted}"))`;
            }

            return `("${startTimeField}" >= "${startDateFormatted}")`;
          })();

          const jqlWithDatesFromSource = `${datesJQL} AND ${source.filterByFields.fullJQL}`;

          const { loadingStartedProcessId } = getState().progressBar;

          const issues = await issueService().getIssues(
            jqlWithDatesFromSource,
            ['*all'],
            true,
            loadingStartedProcessId,
            {
              isParentsShouldBeFetched,
              isChildrenShouldBeFetched,
              isWithRenderedFields: true,
            },
          );

          data.push({
            sourceData: source,
            issues,
          });
        }
      }

      dispatch(setLoadingFinishedProcess(getState().progressBar.loadingStartedProcessId));

      dispatch(
        getCalendarDataSuccess({
          data,
          fieldsByType,
        }),
      );
    } catch (error) {
      console.error('[store.reports.actions][getCalendarReportData] Error while getting timesheets data', error);

      dispatch(getCalendarDataFailure(error));
      dispatch(setLoadingFinishedProcess(getState().progressBar.loadingStartedProcessId));
    }
  }
};

export const getReportTableData = () => async (dispatch, getState) => {
  const processId = uuidv4();
  const { currentReport } = getState().reports;
  const { fieldsList } = getState().app;
  const { category } = currentReport;

  if (category !== 'universalReport') {
    dispatch(setLoadingFinishedProcess());
    dispatch(setLoadingStartedProcess(processId));
    dispatch(setLoadingState({ loaded: 0, total: 0, step: 1 }));
  }

  const { userValues } = getState().filterBySelector;

  const filterAsString = selectFiltersAsString(getState());

  dispatch(setLastFilterAsString(filterAsString));

  await dispatch(getReportTableDataStarted());

  try {
    if (!fieldsList || fieldsList.length === 0) {
      await dispatch(getFieldsList());
    }

    const {
      epic: epicKeyField,
      epicName: epicNameField,
      storyPoints: storyPointsKeyField,
      sprint: sprintKeyField,
      startDate: startDateKeyField,
      epicStatus: epicStatusKeyField,
    } = getState().app.fieldsList;

    const isWorklogWithProperties = selectShouldUseTmWorklogs(getState());
    const formatOptions = {
      epicKeyField,
      epicNameField,
      storyPointsKeyField,
      sprintKeyField,
      startDateKeyField,
      epicStatusKeyField,
      isWorklogWithProperties,
    };

    if (category === 'calendar') {
      dispatch(getCalendarReportData());
    } else if (category === 'universalReport') {
      dispatch(getReportTableDataFinished());
    } else {
      dispatch(getWorklogTableData(filterAsString, formatOptions, userValues));
    }
  } catch (err) {
    console.error(`[store.reports.actions][getReportTableData] ${err}`);

    dispatch(getReportTableDataFailure({ error: err }));
  }

  dispatch(setLoadingFinishedProcess(processId));
};

export const checkIsAvailableLastReport = () => async (dispatch, getState) => {
  const { id } = getState().reports.lastViewedReport;
  if (!id) {
    return;
  }

  const isAvailable = await checkIsReportAvailable(id);

  dispatch(checkIsAvailableLastReportAction({ isAvailable }));
};

export const deleteReport = (reportId, t) => async (dispatch, getState) => {
  const { reportsList, lastViewedReport } = getState().reports;

  try {
    dispatch(deletingReportsStarted());

    await API.delete(`/reports/${reportId}`);

    dispatch(deletingReportsSuccess());

    if (lastViewedReport.id && reportId.toString() === lastViewedReport.id.toString()) {
      dispatch(checkIsAvailableLastReport());
    }

    dispatch(addFlag({ title: t('reports.table.flags.delete'), type: FlagType.success }));

    const updatedReportsList = reportsList.filter((report) => report._id !== reportId);

    dispatch(
      setReportsList({
        reportsList: updatedReportsList,
      }),
    );
  } catch (error) {
    console.error(`[store.reports.actions][deleteReport] Exception: ${error}`);

    dispatch(deletingReportFailure());
    const { status } = error.response;

    dispatch(addFlag({ title: t(`reports.table.flags.${status === 403 ? 'deletePermissionError' : 'deleteError'}`) }));
  }
};

// eslint-disable-next-line default-param-last
export const setGroupBy = (groupBy = [], defaultValue) => async (dispatch) => {
  const filteredGroupBy = groupBy.filter((item) => item !== 'subtask');

  return dispatch(
    setGroupByAction({
      groupBy: filteredGroupBy,
      isWithChanges: !defaultValue,
    }),
  );
};

export const setColumns = (columns, setDefault) => (dispatch, getState) => {
  const oldColumns = JSON.parse(JSON.stringify(getState().reports.currentReport.columns));

  dispatch(setColumnsAction({ columns }));

  const watchersFoundInOldColumns = oldColumns.find((item) => item === 'watchers');
  const watchersFoundInColumns = columns.find((item) => item === 'watchers');

  const isWatchersFoundOnlyInOldColumns = watchersFoundInOldColumns && !watchersFoundInColumns;
  const isWatchersFoundOnlyInColumns = !watchersFoundInOldColumns && watchersFoundInColumns;

  if (isWatchersFoundOnlyInOldColumns || isWatchersFoundOnlyInColumns) {
    dispatch(getReportTableData());
  }

  if (!setDefault) {
    dispatch(setIsWithChanges());
  }
};

export const setDefaultCurrentReport = (t) => async (dispatch, getState) => {
  const { category } = getState().reports.currentReport;
  const { dateFormat } = getState().configuration.general;

  const name = createReportDefaultName({ category, dateFormat, t });

  dispatch(setDefaultCurrentReportAction({ name }));

  dispatch(setColumns([], true));
  dispatch(getInitialDataForFilter([], false));
};

export const getOneReport = () => async (dispatch, getState) => {
  try {
    dispatch(getCurrentReportStarted());

    const { id, sharedId } = getState().reports.currentReport;
    const { data: dto } = await API.get(`/reports/${id}?sharedId=${sharedId}`);

    dispatch(setLoadedReport({ dto }));
  } catch (error) {
    console.error(`[store.reports.actions][getOneReport] Exception: ${error}`);
    dispatch(getCurrentReportFailure(error));
  }
};

export const setReportTimeTrackingUnit = (timeTrackingUnit) => (dispatch) => {
  dispatch(setReportTimeTrackingUnitAction({ timeTrackingUnit }));

  dispatch(setIsWithChanges());
};

export const setDatesRange = (datesRange) => (dispatch, getState) => {
  dispatch(setDatesRangeCurrentReport({ datesRange }));

  const { fullJQL = '' } = getState().filterBySelector;

  dispatch(setIsWithChanges());

  if (fullJQL.length > 0) {
    dispatch(getReportTableData());

    dispatch(getIssuesCount());
  }

  if (getState().reports.currentReport.category === 'calendar') {
    dispatch(getCalendarReportData());
  }
};

export const createUniversalReport = ({
  t,
  navigate,
  newName = '',
  isCopying = false,
}) => async (dispatch, getState) => {
  await validateLicenseStatus(t, async () => {
    try {
      dispatch(createReportStarted());

      const { user } = getState();
      const { userId } = user;
      const { dateFormat } = getState().configuration.general;

      const { _id: id, sharedId, ...restDto } = createReportDto({ getState });

      const { name, permissions, createdBy } = restDto;
      const isShared = createdBy !== userId;

      if (!name) {
        return dispatch(addFlag({ title: t('report.errors.reportCannotBeEmpty') }));
      }

      const withDefaultPermissions = isShared || isCopying || !permissions;
      const reportPermissions = withDefaultPermissions ? getDefaultPermissions() : permissions;

      const nameFinal = newName || (isCopying ? `${name} ${moment().formatWithJDF(dateFormat)}` : name);

      const dto = {
        ...restDto,
        name: nameFinal,
        permissions: reportPermissions,
      };

      const report = await API.post('/reports', dto);
      const { _id: reportId } = report.data;

      dispatch(createReportSuccess({ userId }));
      dispatch(setReportId(reportId));

      dispatch(saveReportSuccess({
        payload: {
          reportId,
          newName: nameFinal,
          createdBy: userId,
        },
      }));

      dispatch(addFlag({ title: t('reports.notifications.success.reportAdded'), type: FlagType.success }));

      dispatch(reportApi.util.invalidateTags(createGetReportsTags(DashboardTabs.myReports)));

      changeRouteWithParams(paths.reportPage, navigate, { reportId, edit: true });
    } catch (error) {
      console.error(`[store.reports.actions][createReport] Exception: ${error}`);

      if (newName) {
        dispatch(saveReportFailure(error));
      } else {
        dispatch(createReportFailure(error));
      }

      if (error.response.status === 409) {
        dispatch(addFlag({ title: t('reports.table.flags.notUniq') }));
      } else {
        dispatch(addFlag({ title: t('reports.table.flags.createError') }));
      }
    }
  });
};

export const editReport = (t) => async (dispatch, getState) => {
  try {
    dispatch(editReportStarted());
    const dto = createReportDto({ getState });

    if (!dto.name) {
      return dispatch(addFlag({ title: t('report.errors.reportCannotBeEmpty') }));
    }

    await API.put(`/reports/${dto._id}`, dto);

    dispatch(reportApi.util.invalidateTags([anyReportTagType]));

    dispatch(editReportSuccess());

    dispatch(addFlag({ title: t('reports.table.flags.edit'), type: FlagType.success }));
  } catch (error) {
    console.error(`[store.reports.actions][editReport] Exception: ${error}`);

    dispatch(editReportFailure());

    const { status } = error.response;
    let text = 'editError';
    if (status === 403) {
      text = 'editReportPermissionError';
    }
    if (status === 409) {
      text = 'notUniq';
    }

    dispatch(addFlag({ title: t(`reports.table.flags.${text}`) }));
  }
};

// eslint-disable-next-line default-param-last
export const setLastReport = ({ id = '', category = '' } = {}, isInit) => async (dispatch) => {
  let isAvailable = false;

  if (isInit && id) {
    isAvailable = await checkIsReportAvailable(id);
  }

  dispatch(setLastViewedReport(
    { lastViewedReport: { id, category, isAvailable } },
  ));
};

export const setPermissions = (payload) => async (dispatch) => {
  await dispatch(setPermissionsAction(payload));
};

export const setReportNewOwner = ({ value = null } = {}) => async (dispatch, getState) => {
  const { createdBy, updatedOwner = null } = getState().reports.currentReport;
  if (createdBy !== value || updatedOwner) {
    dispatch(setReportNewOwnerAction({ updatedOwner: value }));
  }
};

export const savePermissions = (t) => async (dispatch, getState) => {
  try {
    const { id, permissions, updatedOwner = null, reportExportVisibility, isSecureEnvironment } = getState().reports.currentReport;
    dispatch(savePermissionsStarted());

    if (!Array.isArray(permissions)) {
      throw new Error('permissions is not an Array');
    }
    await API.put(`/reports/${id}/permissions`, {
      permissions,
      updatedOwner,
      isSecureEnvironment,
      reportExportVisibility,
    });

    dispatch(savePermissionsSuccess());

    if (updatedOwner) {
      dispatch(setIsNewOwnerSaved({ isNewOwnerSaved: true }));
    }

    dispatch(addFlag({ title: t('reports.table.flags.editPermission'), type: FlagType.success }));

    dispatch(reportApi.util.invalidateTags(createGetReportsTags(DashboardTabs.myReports)));
    dispatch(reportApi.util.invalidateTags([anyReportTagType]));
  } catch (error) {
    console.error(`[store.reports.actions][savePermissions] Exception: ${error}`);

    dispatch(savePermissionsFailure(error));

    dispatch(addFlag({ title: t('reports.table.flags.editPermissionError') }));
  }
};

export const getOneScriptedReport = (scriptedReportId, reportFetchIdObj = {}, locationIssue = undefined) => async (dispatch, getState) => {
  try {
    dispatch(getScriptedReportStarted());

    const { sharedId } = getState().reports.currentReport;

    const { data: scriptedReportDto } = await API.get(`/reports/${scriptedReportId}?sharedId=${sharedId}`);

    const dto = { ...scriptedReportDto, category: ReportCategory.scriptedReport };
    await dispatch(setLoadedScriptedReport({ dto, fetchId: reportFetchIdObj, locationIssue }));

    return dto;
  } catch (error) {
    console.error(`[store.reports.actions][getOneScriptedReport] Exception: ${error}`);
    await dispatch(getScriptedReportFailure(error));
  }
};

export const createScriptedReport = ({ t, navigate }) => async (dispatch, getState) => {
  const {
    name: initialName,
    permissions: initialPermissions,
    galleryReportId,
    version,
  } = getState().reports.currentReport;
  const state = getState();
  const { dateFormat } = state.configuration.general;
  const userId = selectUserId(state);

  const name = galleryReportId ? `${initialName} ${moment().formatWithJDF(dateFormat)}` : initialName;
  const permissions = galleryReportId ? getDefaultPermissions() : initialPermissions;

  await Promise.all([
    dispatch(setReportName(name)),
    dispatch(setReportType(ReportCategory.scriptedReport)),
    dispatch(setReportCategory(ReportCategory.scriptedReport)),
    dispatch(setTempPermissions(permissions)),
    ...version ? [] : [dispatch(setVersion(uuidv4()))],
  ]);

  dispatch(updateVulnerableFieldsUpdatedFields(userId));

  const rawDto = createReportDto({ getState });
  const { _id: id, ...dtoRest } = rawDto;
  const dto = galleryReportId ? dtoRest : { ...dtoRest, sampleReportId: id };

  try {
    const { data, status } = await API.post('/reports', dto);
    if (status === 200) {
      dispatch(addFlag({ title: t('reports.notifications.success.reportAdded'), type: FlagType.success }));
      const { _id: reportId } = data;
      if (!IS_CLOUD) {
        await dispatch(getOneScriptedReport(reportId));
      } else {
        dispatch(setLoadedReport({ dto: data }));
      }
      changeRouteWithParams(paths.scriptedReportsDetailPage, navigate, { reportId });
    } else {
      throw new Error(`Server error - ${status}`);
    }
  } catch (error) {
    console.error('[store.reports.actions][createScriptedReport]', error);
    const { status } = error.response;

    if (status === 409) {
      dispatch(addFlag({ title: t('reports.table.flags.notUniq') }));
    } else {
      dispatch(addFlag({ title: t('reports.notifications.errors.reportAdded') }));
    }
  }
};

export const importScriptedReports = (t, json, changePage) => async (dispatch) => {
  try {
    if (!json.name) {
      dispatch(addFlag({ title: t('scriptedReports.detailPage.errors.reportCannotBeEmpty') }));
      return;
    }
    const category = ReportCategory.scriptedReport;
    const { permissions, ...dtoPart } = json;
    const dto = {
      ...dtoPart,
      permissions: getDefaultPermissions(),
      type: category,
      category,
    };
    const importReportRes = await API.post('/reports', dto);

    if (importReportRes && importReportRes.status === 200) {
      dispatch(reportApi.util.invalidateTags(createGetReportsTags(DashboardTabs.myReports)));
      dispatch(addFlag({ title: t('reports.notifications.success.reportImported'), type: FlagType.success }));

      changePage(
        paths.scriptedReportsDetailPage,
        {
          reportId: importReportRes.data._id,
          edit: true,
        },
      );
    } else {
      dispatch(addFlag({ title: t('reports.notifications.errors.reportImported') }));
    }
  } catch (e) {
    if (e?.response?.status === 403) {
      dispatch(addFlag({ title: t('warnings.noScriptedReportsPermission') }));
      return;
    }
    if (e?.response?.status === 422 && e?.response?.data?.detail) {
      dispatch(addFlag({ title: `${t('reports.notifications.errors.reportImported')}\n\n${e.response.data.detail}` }));
      return;
    }
    dispatch(addFlag({ title: t('reports.notifications.errors.reportImported') }));
    throw e;
  }
};

export const editScriptedReport = (t) => async (dispatch, getState) => {
  const {
    reportsList,
    currentReport: {
      id,
      updatedOwner = null,
      version,
    },
  } = getState().reports;

  if (!version) {
    await dispatch(setVersion(uuidv4()));
  }

  const scriptedReportDto = createReportDto({ getState });

  const editScriptedReportRes = await API.put(`/reports/${id}`, scriptedReportDto);

  if (editScriptedReportRes && editScriptedReportRes.status === 200) {
    dispatch(addFlag({ title: t('reports.notifications.success.reportSaved'), type: FlagType.success }));
  } else {
    dispatch(addFlag({ title: t('reports.notifications.errors.reportSaved') }));
  }

  const updatedReportsList = reportsList.map((report) => {
    if (report.id === scriptedReportDto._id) {
      // eslint-disable-next-line no-param-reassign
      report = { ...report, ...scriptedReportDto };
    }
    return report;
  });

  dispatch(setIsWithChanges(false));

  dispatch(
    setReportsList({
      reportsList: updatedReportsList,
    }),
  );

  if (updatedOwner) {
    dispatch(setIsNewOwnerSaved({ isNewOwnerSaved: true }));
  }
};

export const saveReport = ({ t, navigate, userPermissions }) => async (dispatch, getState) => {
  await validateLicenseStatus(t, async () => {
    const state = getState();
    const { name, key, category, tags} = selectCurrentReport(state);

    if (name.trim().length === 0) {
      return dispatch(addFlag({ title: t('scriptedReports.detailPage.errors.reportCannotBeEmpty') }));
    }

    const isAddable = selectIsAddable(userPermissions)(state);

    await dispatch(saveReportStarted());

    if (isAddable) {
      await dispatch(createScriptedReport({ t, navigate }));
    } else {
      await dispatch(editScriptedReport(t));
    }

    dispatch(reportApi.util.invalidateTags(createGetReportsTags(DashboardTabs.myReports)));

    await dispatch(saveReportComplete());

    sendSaveReportEvent({
      reportCategory: category,
      actionType: isAddable ? 'added' : 'saved',
      reportKey: key,
      tagList: tags,
    });
  });
};

export const setInputFieldsListValuesScriptedReports = ({ id, value }) => (dispatch, getState) => {
  const { fieldsList: oldFieldsList } = getState().reports.currentReport.inputFields;

  const fieldsList = oldFieldsList.map((field) => {
    if (field._id === id) {
      return { ...field, defaultValue: value };
    }

    return field;
  });

  dispatch(setInputFieldsListValues({ fieldsList }));
};

export const addFavoriteReportFront = (favoriteReportId) => async (dispatch) => {
  dispatch(addFavoriteReportFrontAction({ favoriteReportId }));
};

export const removeFavoriteReportFront = (favoriteReportId, isOnlyFavorites = false) => async (dispatch) => {
  dispatch(removeFavoriteReportFrontAction({ favoriteReportId, isOnlyFavorites }));
};

export const setSources = (sources) => (dispatch) => {
  dispatch(setSourcesAction({ sources }));

  dispatch(getCalendarReportData());
};

export const saveSource = (source) => (dispatch) => {
  dispatch(saveSourceAction({ source }));

  dispatch(getCalendarReportData());
};

export const setReportCalendarView = (calendarView) => (dispatch) => {
  dispatch(setReportCalendarViewAction({ calendarView }));
};

export const setIsReportWithWeekends = (isWithWeekends) => (dispatch) => {
  dispatch(setIsReportWithWeekendsAction({ isWithWeekends }));

  dispatch(getCalendarReportData());
};

export const setIsParentsShouldBeFetched = (isParentsShouldBeFetched) => (dispatch) => {
  dispatch(setIsParentsShouldBeFetchedAction({ isParentsShouldBeFetched }));
};

export const setIsChildrenShouldBeFetched = (isChildrenShouldBeFetched) => (dispatch) => {
  dispatch(setIsChildrenShouldBeFetchedAction({ isChildrenShouldBeFetched }));

  dispatch(getTableDataWithFilter());
};

export const fetchCustomFieldsOptions = () => async (dispatch, getState) => {
  try {
    const {
      fieldsList: {
        impact,
        urgency,
        pendingReason,
        productCategorization,
        operationalCategorization,
        approvers,
        requestParticipants,
        organizations,
      },
    } = getState().app;
    const responseFields = await Transport.request({ config: getFields });

    const serviceDeskFields = [
      impact, urgency, pendingReason,
      productCategorization, operationalCategorization, approvers,
      requestParticipants, organizations,
    ];

    const customFieldsOptions = responseFields
      .filter((field) => {
        if (field.schema && field.custom) {
          const { type, items } = field.schema;

          if (type === 'option-with-child' || type === 'array' || type === 'option') {
            if (!items) {
              return true;
            }

            if (items === 'option') {
              return true;
            }
          }
        }

        return false;
      })
      .filter(({ id }) => !serviceDeskFields.some((fieldId) => fieldId !== id));

    const {
      storyPoints: storyPointsKeyField,
    } = getState().app.fieldsList;

    const numberCustomFieldsOptions = responseFields.filter((field) => {
      if (field.id !== storyPointsKeyField && field.schema && field.custom) {
        const { type } = field.schema;

        return type === 'number';
      }

      return false;
    });

    dispatch(fetchCustomFieldsOptionsSuccess({ customFieldsOptions, numberCustomFieldsOptions }));
  } catch (error) {
    console.error('[store.reports.actions][setCustomFieldsOptions] Error while getting custom fields for group');

    dispatch(fetchCustomFieldsOptionsFailure(error));
  }
};

export const recalculateUniversalReportData = () => async (dispatch, getState) => {
  const { currentReport } = getState().reports;
  const { dataType, isIncludesSubtask } = currentReport;

  const {
    epic: epicKeyField,
    epicName: epicNameField,
    storyPoints: storyPointsKeyField,
    sprint: sprintKeyField,
    startDate: startDateKeyField,
    epicStatus: epicStatusKeyField,

    ...serviceDeskList
  } = getState().app.fieldsList;

  const { dateFormat } = getState().configuration.general;

  const { customFieldsOptions, numberCustomFieldsOptions } = getState().reports;
  const { fieldsList } = getState().app;
  const isWorklogWithProperties = selectShouldUseTmWorklogs(getState());
  const formatOptions = {
    epicKeyField,
    epicNameField,
    storyPointsKeyField,
    sprintKeyField,
    startDateKeyField,
    epicStatusKeyField,
    dateFormat,
    isWorklogWithProperties,

    ...serviceDeskList,
  };

  const fieldDataByFieldId = fieldsList.reduce((acc, current) => {
    acc[current.id] = {
      type: current.type,
      arrayItemType: current.arrayItemType,
      name: current.name,
    };

    return acc;
  }, {});

  const {
    datesRange: { startDate: start, endDate: end },
    columns,
    filters,
  } = currentReport;

  const isWithDateRangeFilter = start && end;
  const worklogAuthorFilter = filters.find(({ label }) => label === 'worklogAuthor')?.value;

  const { tempIssues: issues } = getState().reports.currentReport;

  if (dataType === UniversalReportLookingFor.worklogs) {
    const worklogController = new WorklogController([]);

    const calculatedIssues = calculateSubtasksInParent(issues, isIncludesSubtask, dataType);

    worklogController.getFormattedWorklogsFromIssues(
      calculatedIssues,
      WorklogController.formatWorklogsForTable,
      formatOptions,
      [...customFieldsOptions, ...numberCustomFieldsOptions],
      fieldDataByFieldId,
    );

    const filterParams = {
      period: isWithDateRangeFilter && {
        start,
        end,
      },
      ...(typeof worklogAuthorFilter === 'string'
        && JSON.parse(worklogAuthorFilter).length > 0
        && ({
          users: JSON.parse(worklogAuthorFilter),
        })
      ),
    };

    const worklogs = worklogController.filter(filterParams);

    if (!store.getState().progressBar.loadingStartedProcessId) {
      return dispatch(cleanReportsTableData());
    }

    const uniqueIssueKeys = dedupeArray(worklogs.map(({ issueKey }) => issueKey).filter((item) => !!item));
    const watchersByIssueKey = [];

    if (columns.find((column) => column === 'watchers')) {
      for (const issueKey of uniqueIssueKeys) {
        if (!getState().progressBar.loadingStartedProcessId) {
          return dispatch(cleanReportsTableData());
        }

        const result = await Transport.request({
          config: getWatchersForIssue,
          query: `${issueKey}/watchers`,
        });

        const watchers = result.map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.url = createUserUrl(item.accountId ? item.accountId : item.name);
          return item;
        });

        watchersByIssueKey.push({ key: issueKey, watchers });
      }
    }

    if (!getState().progressBar.loadingStartedProcessId) {
      return dispatch(cleanReportsTableData());
    }

    const extendedWorklogs = await addEpicData(worklogs, formatOptions);

    const resultWorklogTable = extendedWorklogs
      .map((worklog) => {
        let watchers = null;

        if (watchersByIssueKey.find(({ key }) => key === worklog.issueKey)) {
          const item = watchersByIssueKey.find(({ key }) => key === worklog.issueKey);

          watchers = item.watchers;
        }

        return { ...worklog, watchers };
      });

    const loggedTimeInSeconds = getTotalInSecondsForWorklogs(resultWorklogTable);

    dispatch(
      getReportTableDataSuccess({
        worklogs: resultWorklogTable,
        loggedTimeInSeconds,
        lastDataFetchItemsCount: extendedWorklogs.length,
      }),
    );
  } else {
    const calculatedIssues = calculateSubtasksInParent(issues, isIncludesSubtask, 'issues', numberCustomFieldsOptions);

    const rawData = formatIssueTableData(
      calculatedIssues,
      formatOptions,
      [...customFieldsOptions, ...numberCustomFieldsOptions],
      fieldDataByFieldId,
    );

    const loggedTimeInSeconds = getTotalInSecondsForWorklogs(rawData);

    dispatch(
      getReportTableDataSuccess({
        worklogs: await addEpicData(rawData, formatOptions),
        loggedTimeInSeconds,
        lastDataFetchItemsCount: calculatedIssues.length,
      }),
    );
  }
};

export const fetchUniversalReportData = () => async (dispatch, getState) => {
  const state = getState();
  const isEnoughUniversalReportFilters = selectIsEnoughUniversalReportFilters(state);

  if (!isEnoughUniversalReportFilters) {
    return;
  }

  try {
    dispatch(getCurrentReportStarted());
    const processId = uuidv4();

    const { currentReport, customFieldsOptions, numberCustomFieldsOptions } = state.reports;
    const { dateFormat } = state.configuration.general;
    const { teamPermissions, userId: currentUserAccountId, actonicGlobalTeamAdmin } = state.user;
    const { actonicTeamsListNames } = state.configuration;

    const {
      epic: epicKeyField,
      epicName: epicNameField,
      storyPoints: storyPointsKeyField,
      sprint: sprintKeyField,
      startDate: startDateKeyField,
      epicStatus: epicStatusKeyField,

      ...serviceDeskList
    } = state.app.fieldsList;

    const isWorklogWithProperties = selectShouldUseTmWorklogs(getState());
    const formatOptions = {
      epicKeyField,
      epicNameField,
      storyPointsKeyField,
      sprintKeyField,
      startDateKeyField,
      epicStatusKeyField,
      dateFormat,
      isWorklogWithProperties,

      ...serviceDeskList,
    };

    const filterAsString = selectFiltersAsString(state);
    if (!filterAsString) {
      return;
    }

    dispatch(setLoadingFinishedProcess());
    dispatch(setLoadingStartedProcess(processId));
    dispatch(setLoadingState({ loaded: 0, total: 0, step: 1 }));

    dispatch(cleanReportsTableData());

    if (!getState().app.fieldsList || getState().app.fieldsList.length <= 0) {
      await dispatch(getFieldsList());
    }

    const { dataType, isIncludesSubtask } = currentReport;
    const { fieldsList } = getState().app;

    const fieldDataByFieldId = fieldsList.reduce((acc, current) => {
      acc[current.id] = {
        type: current.type,
        arrayItemType: current.arrayItemType,
        name: current.name,
      };

      return acc;
    }, {});

    if (dataType === UniversalReportLookingFor.worklogs) {
      const {
        datesRange: { startDate: start, endDate: end },
        columns,
        filters,
        isParentsShouldBeFetched,
        isChildrenShouldBeFetched,
      } = currentReport;

      const isWithDateRangeFilter = start && end;
      const worklogAuthorFilter = filters.find(({ label }) => label === 'worklogAuthor')?.value;

      const { loadingStartedProcessId } = getState().progressBar;

      if (!isWithDateRangeFilter && columns[0] === 'worklogDate') {
        store.dispatch(setLoadingFinishedProcess(processId));

        return;
      }

      const isSecuredWorklogs = selectIsSequreWorklogsAvailable(getState());
      const isCustomWorklogFuncAvailable = selectIsCustomWorklogFuncAvailable(getState());

      const jql = isWithDateRangeFilter
        ? `${createWorklogDateJql(start, end, isCustomWorklogFuncAvailable)} AND (${filterAsString})`
        : filterAsString;

      const fetchingFieldsList = [...fieldsList, { id: 'parent' }].map(({ id }) => id);

      const issues = await issueService().getIssues(
        jql,
        fetchingFieldsList,
        true,
        processId,
        {
          isParentsShouldBeFetched,
          isChildrenShouldBeFetched,

          isParentsForSubtaskShouldBeFetched: true,
        },
      );

      if (!issues.length) {
        store.dispatch(setLoadingFinishedProcess(processId));
        return dispatch(
          getReportTableDataSuccess({
            worklogs: [],
            loggedTimeInSeconds: 0,
            lastDataFetchItemsCount: 0,
          }),
        );
      }

      const resultIssues = isSecuredWorklogs
        ? await getSecuredWorklogs({ issues, processId, fieldsList, expand: [] }, { dispatch, getState })
        : await issueService().getFieldsForIssues(['worklog'], issues, loadingStartedProcessId);

      dispatch(setTempIssuesData({ tempIssues: resultIssues }));

      const worklogController = new WorklogController([]);

      const calculatedIssues = calculateSubtasksInParent(resultIssues, isIncludesSubtask, UniversalReportLookingFor.worklogs);

      worklogController.getFormattedWorklogsFromIssues(
        calculatedIssues,
        WorklogController.formatWorklogsForTable,
        formatOptions,
        [...customFieldsOptions, ...numberCustomFieldsOptions],
        fieldDataByFieldId,
      );

      const filterParams = {
        period: isWithDateRangeFilter && {
          start,
          end,
        },
        ...(typeof worklogAuthorFilter === 'string'
          && JSON.parse(worklogAuthorFilter).length > 0
          && ({
            users: JSON.parse(worklogAuthorFilter),
          })
        ),
      };

      const worklogsCandidate = worklogController.filter(filterParams);

      const teamPermissionsParams = {
        teamPermissions, currentUserAccountId,
      };

      const worklogs = isWorklogWithProperties && !actonicGlobalTeamAdmin
        ? checkWorklogsPermissions(worklogsCandidate, teamPermissionsParams, actonicTeamsListNames)
        : worklogsCandidate;

      const formattedWorklog = formatDataForGroupWorklogs(worklogs);

      if (!store.getState().progressBar.loadingStartedProcessId) {
        return dispatch(cleanReportsTableData());
      }

      const uniqueIssueKeys = dedupeArray(worklogs.map(({ issueKey }) => issueKey).filter((item) => !!item));
      const watchersByIssueKey = [];

      if (columns.find((column) => column === 'watchers')) {
        for (const issueKey of uniqueIssueKeys) {
          if (!getState().progressBar.loadingStartedProcessId) {
            return dispatch(cleanReportsTableData());
          }

          const result = await Transport.request({
            config: getWatchersForIssue,
            query: `${issueKey}/watchers`,
          });

          const watchers = result.map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.url = createUserUrl(item.accountId ? item.accountId : item.name);
            return item;
          });

          watchersByIssueKey.push({ key: issueKey, watchers });
        }
      }

      if (!getState().progressBar.loadingStartedProcessId) {
        return dispatch(cleanReportsTableData());
      }

      const extendedWorklogs = await addEpicData(formattedWorklog, formatOptions);

      const resultWorklogTable = extendedWorklogs
        .map((worklog) => {
          let watchers = null;

          if (watchersByIssueKey.find(({ key }) => key === worklog.issueKey)) {
            const item = watchersByIssueKey.find(({ key }) => key === worklog.issueKey);

            watchers = item.watchers;
          }

          return { ...worklog, watchers };
        });

      store.dispatch(setLoadingFinishedProcess(processId));

      const loggedTimeInSeconds = getTotalInSecondsForWorklogs(resultWorklogTable);

      dispatch(
        getReportTableDataSuccess({
          worklogs: resultWorklogTable,
          loggedTimeInSeconds,
          lastDataFetchItemsCount: calculatedIssues.length, // todo check rest getReportTableDataSuccess calls
        }),
      );
    } else {
      const { isParentsShouldBeFetched, isChildrenShouldBeFetched } = getState().reports.currentReport;

      const resultIssues = await issueService().getIssues(
        filterAsString,
        [...fieldsList, { id: 'parent' }].map(({ id }) => id),
        true,
        processId,
        {
          isParentsShouldBeFetched,
          isChildrenShouldBeFetched,

          isParentsForSubtaskShouldBeFetched: true,
        },
      );

      dispatch(setTempIssuesData({ tempIssues: resultIssues }));

      if (!getState().progressBar.loadingStartedProcessId) {
        return dispatch(cleanReportsTableData());
      }

      dispatch(setLoadingState({ total: resultIssues.length, loaded: resultIssues.length, step: 3 }));

      const calculatedIssues = calculateSubtasksInParent(resultIssues, isIncludesSubtask, 'issues', numberCustomFieldsOptions);

      const rawData = formatIssueTableData(
        calculatedIssues,
        formatOptions,
        [...customFieldsOptions, ...numberCustomFieldsOptions],
        fieldDataByFieldId,
      );

      dispatch(setLoadingFinishedProcess(processId));

      const loggedTimeInSeconds = getTotalInSecondsForWorklogs(rawData);

      dispatch(
        getReportTableDataSuccess({
          worklogs: await addEpicData(rawData, formatOptions),
          loggedTimeInSeconds,
          lastDataFetchItemsCount: calculatedIssues.length,
        }),
      );
    }
  } catch (err) {
    console.error(`[store.reports.actions][fetchUniversalReportData] ${err}`);
    dispatch(setLoadingStartedProcess());

    dispatch(getReportTableDataFailure({ error: err }));
  }
};

export const setReportDataType = (dataType) => (dispatch, getState) => {
  dispatch(
    setReportDataTypeAction({
      dataType,
      areSoftwareFieldsInitialized: getState().app.areSoftwareFieldsInitialized,
    }),
  );

  const filterAsString = selectFiltersAsString(getState());

  if (filterAsString && filterAsString.length > 0) {
    dispatch(fetchUniversalReportData());
  }
};

export const setAggregationType = (aggregationType) => (dispatch, getState) => {
  dispatch(setAggregationTypeAction({
    aggregationType,
    areSoftwareFieldsInitialized: getState().app.areSoftwareFieldsInitialized,
  }));
};

export const getLastViewedReportData = (id) => async (dispatch, getState) => {
  try {
    const { data: report } = await API.get(`/reports/${id}`);

    const {
      filterBy = [],
      columns = [],
      groupBy = [],
      dataType = 'issues',
      reportViewType = 'table',
      aggregationType = 'COUNT',
      aggregationTarget = 'issues',
      exclusions: filters = [],
      dateRange = null,
    } = report;

    if (columns) {
      dispatch(setColumns(columns));
    }
    if (groupBy) {
      dispatch(setGroupBy(groupBy, true));
    }

    if (filterBy) {
      dispatch(getInitialDataForFilter(filterBy));
    }

    const lastViewedReportValues = {
      dataType,
      reportViewType,
      aggregationType,
      aggregationTarget,
      filters,
    };

    if (dateRange) {
      const { startWeekly } = getState().configuration.general;
      const { start, end, relativeDate } = dateRange;

      const definedPeriodDates = getDefinedPeriodDates(relativeDate, startWeekly);

      const { startDate, endDate } = defineDatesRange(definedPeriodDates, { start, end });

      lastViewedReportValues.datesRange = {
        startDate,
        relativeDate,
        endDate,
      };
    }

    dispatch(getLastViewedReportSuccess({ lastViewedReportValues }));
  } catch (error) {
    console.error(`[store.reports.actions][getLastViewedReportData] Exception: ${error}`);
    dispatch(getLastViewedReportFailure(error));
  }
};

export const createShareableLink = () => async (dispatch, getState) => {
  try {
    const { id: reportId } = getState().reports.currentReport;

    const { data: { shareableLink } } = await API.put(`/reports/${reportId}/create-shared-link`);

    dispatch(setShareableLink(shareableLink));
  } catch (error) {
    console.error(`[store.reports.actions][createShareableLink] Exception: ${error}`);
  }
};

export const deleteShareableLink = () => async (dispatch, getState) => {
  try {
    const { id: reportId } = getState().reports.currentReport;

    await API.delete(`/reports/${reportId}/shared-link`);

    dispatch(setShareableLink(null));
  } catch (error) {
    console.error(`[store.reports.actions][createShareableLink] Exception: ${error}`);
  }
};
