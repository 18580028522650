import { getGroups } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: false,
  migration: getGroups,
  fieldName: 'groups',
  maxResults: 20,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/groups/picker',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/groups/picker',
  },
};

export { config as getGroups };
