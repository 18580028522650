import styled from 'styled-components5';

import { token } from '@atlaskit/tokens';

export const ErrorText = styled.div`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: center;
  gap: ${token('space.075')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${token('space.200')};
`;
