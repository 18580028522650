import { createSelectorCreator, createSelector, defaultMemoize } from 'reselect';
import i18next from 'i18next';
import { checkIsLicensed } from 'utils/checkLicense';
import { createGroupedData } from 'features/legacy/groupsLegacy/group';
import { createGroupedData as createGroupedDataV2 } from 'controllers/groupLogic/group';
import { checkIsEqual } from 'utils/checkIsEqual';
import { selectDateFormat, selectIsConfigurationFetched } from 'store/legacy/configuration/selectors';
import {
  selectAreSoftwareFieldsInitialized, selectFieldsList,
  selectTimeTrackingUnit,
} from 'store/legacy/app/selectors';
import { moment } from 'controllers/moment';
import { extendWithWorklogs } from 'store/legacy/timesheets/extendWithWorklogs';

import { COUNT, SUM, AVERAGE, MAX, MIN, MEDIAN } from 'pages/ReportLegacyPage/ReportViews/UniversalReport/aggregations';

import { selectFilterBy } from 'store/legacy/filterBySelector/selectors';
import {
  createAggregationsTypesOptions, createDataTypeOptions, getAggregationTargetsOptions,
  createReportViewTypeOptions,
} from 'pages/ReportLegacyPage/ReportViews/UniversalReport/ReportSidebar/options';

import { createJqlFromFilterByValues } from 'utils/createJqlFromFilterByValues';
import { checkIsReportScriptedCompatible, checkIsReportUniversalCompatible } from 'utils/checkIsUniversalCompatible';
import { selectUserId } from 'store/legacy/user/selectors';
import { formatValueByFieldType } from 'store/legacy/reports/formatValueByFieldType';
import { extendForUniversalReport, getColumnNameForComparison } from 'store/legacy/reports/extendForUniversalReport';
import { RootState } from 'store/index';

import { getReportUserRole } from 'utils/getReportUserRole';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { UserPermissions } from 'types/report';
import { ExternalEditorConfig } from 'sharedFrontBack/src/types/srExternalEditor';

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  checkIsEqual,
);

export const softwareCustomFieldsNames = ['Epic Link', 'Epic Name', 'Story Points', 'Sprint', 'Epic Status'];

export const selectReportCurrentTab = ({ reports }: RootState) => reports.currentTab;
export const selectCurrentReport = ({ reports }: RootState) => reports.currentReport;
export const selectCurrentScriptedReport = (state: RootState) => {
  const report = selectCurrentReport(state);
  if (!checkIsReportScriptedCompatible(report)) {
    throw new Error('Current report is not SR compatible');
  }
  return report;
};
export const selectCurrentUniversalReport = (state: RootState) => {
  const report = selectCurrentReport(state);
  if (!checkIsReportUniversalCompatible(report)) {
    throw new Error('Current report is not universal compatible');
  }
  return report;
};

export const selectReportTimeTrackingUnit = (state: RootState) => selectCurrentUniversalReport(state).timeTrackingUnit;
export const selectExternalEditor = createSelector(
  selectCurrentReport,
  (report) => {
    if (checkIsReportScriptedCompatible(report)) {
      return report.externalEditor;
    }
  },
);
export const selectReportExternalEditorUrl = createSelector(
  selectExternalEditor,
  (externalEditor) => {
    if (!externalEditor) {
      return '';
    }
    return externalEditor.url;
  },
);

export const selectIsExternalEditorEnabled = createSelector(
  selectExternalEditor,
  (externalEditor: ExternalEditorConfig) => Boolean(externalEditor),
);
// @ts-expect-error fixme
export const selectReportDefinedPeriod = (state: RootState) => selectCurrentUniversalReport(state).relativeDate;
export const selectDataType = (state: RootState) => selectCurrentUniversalReport(state).dataType;
export const selectReportViewType = (state: RootState) => selectCurrentUniversalReport(state).reportViewType;
export const selectAggregationType = (state: RootState) => selectCurrentUniversalReport(state).aggregationType;
export const selectAggregationTarget = (state: RootState) => selectCurrentUniversalReport(state).aggregationTarget;

export const selectIsExporting = ({ reports }: RootState) => reports.isExporting;
export const selectIsAttaching = ({ reports }: RootState) => reports.isAttaching;
export const selectAttachmentStatus = ({ reports }: RootState) => reports.attachmentStatus;

export const selectIsReportsFetching = ({ reports }: RootState) => reports.isReportsFetching;
export const selectReportCategory = ({ reports }: RootState) => reports.currentReport.category;
export const selectEntityType = ({ reports }: RootState) => reports.currentReport.type;

export const selectReportsList = ({ reports }: RootState) => reports.reportsList;
export const selectReportInputFields = (state: RootState) => selectCurrentScriptedReport(state).inputFields;
export const selectReportKey = ({ reports }: RootState) => reports.currentReport.key;
export const selectReportDescription = ({ reports }: RootState) => reports.currentReport.description;
export const selectReportTags = ({ reports }: RootState) => reports.currentReport.tags;
export const selectDocsLink = ({ reports }: RootState) => reports.currentReport.docsLink;
export const selectReportIcon = ({ reports }: RootState) => reports.currentReport.icon;
export const selectScriptCallback = (state: RootState) => selectCurrentScriptedReport(state).scriptCallback;
export const selectScript = (state: RootState) => selectCurrentScriptedReport(state).script;
export const selectTemplateText = (state: RootState) => selectCurrentScriptedReport(state).template;

export const selectReportsTotalPages = ({ reports }: RootState) => reports.totalPages;
export const selectReportsSearchString = ({ reports }: RootState) => reports.searchString;
export const selectIsOnlyFavorites = ({ reports }: RootState) => reports.isOnlyFavorites;

export const selectLastPreviewFetchId = ({ reports }: RootState) => reports.lastPreviewFetchId;
// @ts-expect-error fixme
export const selectReportAdditionalColumnsOptions = ({ reports }: RootState) => reports.additionalColumnsOptions;
export const selectReportDatesRange = (state: RootState) => selectCurrentUniversalReport(state).datesRange;
export const selectIsReportFetchedWithErrors = ({ reports }: RootState) => reports.currentReport.isFetchedWithErrors;
export const selectReportRawData = (state: RootState) => selectCurrentUniversalReport(state).worklogs;
export const selectReportGroupBy = (state: RootState) => selectCurrentUniversalReport(state).groupBy;
export const selectInputFieldsList = (state: RootState) => selectCurrentScriptedReport(state).inputFields.fieldsList;

export const selectReportCreatedBy = ({ reports }: RootState) => reports.currentReport.createdBy;
export const selectReportUpdatedOwner = ({ reports }: RootState) => reports.currentReport.updatedOwner;

export const selectReportName = ({ reports }: RootState) => reports.currentReport.name;
export const selectReportId = ({ reports }: RootState) => reports.currentReport.id ?? undefined;

export const selectLastViewedReport = ({ reports }: RootState) => reports.lastViewedReport;

export const selectReportPermissions = ({ reports }: RootState) => reports.currentReport.permissions;

export const selectIsReportDataFetching = ({ reports }: RootState) => reports.currentReport.isDataFetching;
export const selectIsReportDataFetched = ({ reports }: RootState) => reports.currentReport.isDataFetched;
export const selectIsScriptedReportFetched = ({ reports }: RootState) => reports.currentReport.isScriptedReportFetched;
export const selectReportSortBy = (state: RootState) => selectCurrentUniversalReport(state).sortBy;

export const selectIsParentsShouldBeFetched = ({ reports }: RootState) => reports.currentReport.isParentsShouldBeFetched;
export const selectIsChildrenShouldBeFetched = ({ reports }: RootState) => reports.currentReport.isChildrenShouldBeFetched;

export const selectIsDescriptionVisible = ({ reports }: RootState) => reports.currentReport.isDescriptionVisible;

export const selectIsPredefinedReport = createSelector(
  selectCurrentReport,
  ({ category, isPredefined }) => {
    return (isPredefined || (category === ReportCategory.scriptedFromGallery));
  },
);
export const selectIsReportWithChanges = ({ reports }: RootState) => reports.currentReport.isWithChanges;
export const selectColumns = (state: RootState) => selectCurrentUniversalReport(state).columns;

export const selectIsIncludesSubtask = ({ reports }: RootState) => reports.currentReport.isIncludesSubtask;
export const selectIsNewOwnerSaved = ({ reports }: RootState) => reports.currentReport.isNewOwnerSaved;

export const selectCustomFieldsOptions = ({ reports }: RootState) => reports.customFieldsOptions;
export const selectNumberCustomFieldsOptions = createSelector(
  ({ reports }: RootState) => reports.numberCustomFieldsOptions,
  selectAreSoftwareFieldsInitialized,
  (numberCustomFieldsOptions, areSoftwareFieldsInitialized) => {
    const filteredNumberCustomFieldsOptions = numberCustomFieldsOptions.filter(({ name }) => {
      const isSoftwareField = softwareCustomFieldsNames.includes(name);

      if (!areSoftwareFieldsInitialized) {
        return !isSoftwareField;
      }

      return true;
    });

    return filteredNumberCustomFieldsOptions;
  },
);

export const selectIsGalleryReportsFetched = ({ reports }: RootState) => reports.isGalleryReportsFetched;

// @ts-expect-error fixme
export const selectDashboardReports = ({ reports: { dashboardReports } }) => dashboardReports;
export const selectReportLoggedTimeInSeconds = (state: RootState) => selectCurrentUniversalReport(state).loggedTimeInSeconds;
export const selectQuickReportFilterBy = (state: RootState) => selectCurrentUniversalReport(state).quickReportFilterBy;

export const selectCustomJql = createSelector(
  selectFilterBy,
  ({ jqlField }) => ((jqlField?.[0]?.label) || ''),
);
// @ts-expect-error fixme
export const selectReportDataFilters = createSelector(
  selectFilterBy,
  selectCustomJql,
  ({ projectValues, epicValues, issueValues, filterValues }, customJQL) => ({
    filters: [
      ...projectValues,
      ...epicValues,
      ...issueValues,
      ...filterValues,
    ],
    customJQL,
  }),
);
// @ts-expect-error fixme
export const selectIsEnoughUniversalReportFilters = createSelector(
  selectReportDataFilters,
  ({ filters, customJQL }) => Boolean(filters.length || customJQL.length),
);

export const selectFiltersAsString = createSelector(
  selectReportDataFilters,
  // @ts-expect-error fixme
  (reportDataFilters) => createJqlFromFilterByValues(reportDataFilters),
);

export const selectLastFiltersString = createSelector(
  selectFiltersAsString,
  selectIsParentsShouldBeFetched,
  selectIsChildrenShouldBeFetched,
  (filterAsString, isParentShouldBeFetched, isChildrenShouldBeFetched) => `${filterAsString}-isParentShouldBeFetched=${isParentShouldBeFetched}-isChildrenShouldBeFetched=${isChildrenShouldBeFetched}`,
);

export const appSettingsSelectorForReport = createSelector(
  [selectTimeTrackingUnit, selectIsConfigurationFetched],
  (timeTrackingUnit, isConfigFetched) => ({
    timeTrackingUnit, isConfigFetched,
  }),
);

export const reportTableDataSelector = createDeepEqualSelector(
  [
    selectReportRawData, selectReportGroupBy, selectReportSortBy,
    selectIsIncludesSubtask, selectDateFormat,
  ],
  (data, groupBy, sortBy, isIncludesSubtask, dateFormat) => createGroupedData(
    {
      rawData: data,
      groupBy,
      sortBy,
    },
    {
      isIncludesSubtask,
      dateFormat,
    },
    extendWithWorklogs(),
  ),
);
// @ts-expect-error fixme
export const universalReportSelector = createDeepEqualSelector(
  [
    selectReportRawData, selectReportGroupBy, selectReportSortBy,
    selectIsIncludesSubtask, selectDateFormat,
    selectColumns, selectAggregationType, selectAggregationTarget, selectCustomFieldsOptions,
    selectNumberCustomFieldsOptions, selectFieldsList, selectDataType,
  ],
  (
    data, groupBy, sortBy, isIncludesSubtask, dateFormat, columns,
    aggregationType, aggregationTarget, customFields, numberCustomFieldsOptions,
    fieldsList, dataType,
  ) => {
    // @ts-expect-error fixme
    const fieldTypesByNames = customFields.reduce(
      // @ts-expect-error fixme
      (acc, { id, key, schema }) => {
        acc[id || key] = schema.type;

        return acc;
      },
      {},
    );
    // @ts-expect-error fixme
    const customFieldNamesByKeys = customFields.reduce(
      // @ts-expect-error fixme
      (acc, { key, name }) => {
        acc[key] = name;

        return acc;
      },
      {},
    );

    // @ts-expect-error fixme
    const columnKey = columns[0];

    const allAggregations = {
      COUNT,
      SUM,
      AVERAGE,
      MAX,
      MIN,
      MEDIAN,
    };
    // @ts-expect-error fixme
    const extendUniversalReportGroup = (elementForUpdate) => {
      // @ts-expect-error fixme
      const currentAggregationFunction = allAggregations[aggregationType];

      const { info: { raw: { columnsNames } } } = elementForUpdate;
      // @ts-expect-error fixme
      let dataForAggregationTotal = [];

      const uniqueColumnNames = Array.from(new Set(
        Array.from(columnsNames)
          .map(
            (columnName) => getColumnNameForComparison((columnName || 'empty'), (columnKey || 'empty')),
          ),
      ));

      uniqueColumnNames && uniqueColumnNames.forEach((columnNameForComparison) => {
        const columnRawData = elementForUpdate.info.raw[columnNameForComparison];

        if (columnRawData) {
          elementForUpdate.info[columnNameForComparison] = currentAggregationFunction(
            // @ts-expect-error fixme
            columnRawData.filter((item) => item !== 0),
          );

          // @ts-expect-error fixme
          dataForAggregationTotal = [...dataForAggregationTotal, ...columnRawData];
        }
      });

      delete elementForUpdate.info.raw;
      // @ts-expect-error fixme
      elementForUpdate.info.Total = currentAggregationFunction(dataForAggregationTotal.filter((item) => item !== 0));
    };

    const groupedData = createGroupedDataV2(
      {
        rawData: data,
        groupBy,
        sortBy,
        // @ts-expect-error fixme
        typeByFieldId: fieldsList.reduce((acc, current) => {
          acc[current.id] = current.type;

          return acc;
        }, {}),
      },
      {
        isIncludesSubtask,
        dateFormat,
        fieldTypesByNames,
        customFieldNamesByKeys,
      },
      extendForUniversalReport(
        { rawData: data, isIncludesSubtask, columns, numberCustomFieldsOptions },
        { aggregationType, aggregationTarget, dataType },
      ),
      extendUniversalReportGroup,
    );

    return groupedData;
  },
);
export const selectCurrentReportLastDataFetchDate = (state: RootState) => selectCurrentUniversalReport(state).lastDataFetchDate;
export const selectCurrentReportLastDataFetchItemsCount = ({ reports }: RootState) => (
  // @ts-expect-error fixme
  reports.currentReport.lastDataFetchItemsCount
);
// @ts-expect-error fixme
export const selectData = ({ reports }) => reports.currentReport.data;
export const selectReportCalendarView = (state: RootState) => selectCurrentUniversalReport(state).calendarView;
export const selectFieldsByType = (state: RootState) => selectCurrentUniversalReport(state).fieldsByType;
export const selectReportSources = (state: RootState) => selectCurrentUniversalReport(state).sources;
export const selectReportIsWithWeekends = (state: RootState) => selectCurrentUniversalReport(state).isWithWeekends;

export const reportCalendarSelector = createDeepEqualSelector(
  [selectData, selectReportCalendarView, selectFieldsByType, selectDateFormat],

  (data = [], calendarView, fieldsByType, dateFormat) => data
    .reduce(
      // @ts-expect-error fixme
      (acc, current, index) => {
        const startTimeField = current.sourceData.eventStartTime.value;
        const endTimeField = current.sourceData?.eventEndTime?.value;

        if (current?.issues) {
          // @ts-expect-error fixme
          current.issues.forEach((issue) => {
            if (current.sourceData) {
              let viewEventFieldsForIssue = {};

              if (current.sourceData.viewEventFields) {
                viewEventFieldsForIssue = current.sourceData.viewEventFields.reduce(
                  // @ts-expect-error fixme
                  (viewEventsAcc, currentViewEventField) => {
                    if (issue.fields[currentViewEventField.value]) {
                      viewEventsAcc[currentViewEventField.value] = formatValueByFieldType(
                        currentViewEventField.value, issue.fields[currentViewEventField.value], dateFormat,
                      );
                    } else if (currentViewEventField.value === 'assignee') {
                      viewEventsAcc[currentViewEventField.value] = 'Unassigned';
                    }

                    return viewEventsAcc;
                  },
                  {},
                );
              }

              let infoEventFields = [];

              if (current.sourceData.infoEventFields) {
                infoEventFields = current.sourceData.infoEventFields.reduce(
                  // @ts-expect-error fixme
                  (infoEventsAcc, currentInfoEventField) => {
                    if (issue.fields[currentInfoEventField.value]) {
                      const value = issue.fields[currentInfoEventField.value];
                      const foundFieldType = fieldsByType.find(({ key }) => key === currentInfoEventField.value);

                      infoEventsAcc.push({
                        key: currentInfoEventField.value,
                        label: currentInfoEventField.label,
                        // @ts-expect-error fixme
                        value: formatValueByFieldType(foundFieldType?.type, value, dateFormat),
                      });
                    } else if (currentInfoEventField.value === 'assignee') {
                      infoEventsAcc.push({
                        key: 'unassigned',
                        label: currentInfoEventField.label,
                        value: 'Unassigned',
                      });
                    }

                    return infoEventsAcc;
                  },
                  [],
                );
              }

              acc.push({
                key: issue.key,

                sourceIndex: index,
                issueTypeIcon: issue.fields.issuetype?.iconUrl,
                color: current.sourceData.eventsColor,
                start: new Date(issue.fields[startTimeField]),
                end: endTimeField && issue.fields[endTimeField] ? new Date(issue.fields[endTimeField]) : moment(new Date(issue.fields[startTimeField])).endOf('day').toDate(),

                allDay: calendarView !== 'week' && calendarView !== 'work_week',

                startNotFormatted: issue.fields[startTimeField],
                endNotFormatted: endTimeField && issue.fields[endTimeField],

                ...viewEventFieldsForIssue,
                infoEventFields,
              });
            }
          });
        }

        return acc;
      },
      [],
    ),
);

export const selectFilters = (state: RootState) => selectCurrentUniversalReport(state).filters || [];

export const selectIsSharedReport = createSelector(
  selectCurrentReport,
  selectUserId,
  ({ id, createdBy }, userId) => Boolean(id && createdBy && (createdBy !== userId)),
);

export const selectIsEditMode = createSelector(
  ({ reports }: RootState) => reports.currentReport.isEditMode,
  selectReportCategory,
  (isEditMode, category) => isEditMode || category === 'calendar',
);
// @ts-expect-error fixme
export const selectIsNameEditMode = createSelector(
  selectIsEditMode,
  selectIsSharedReport,
  (isEditMode: boolean, isShared: boolean) => isEditMode || isShared,
);
export const selectIsDemoMode = ({ reports }: RootState) => reports.currentReport.isDemoMode;
// @ts-expect-error fixme
export const selectReadModeData = createSelector(
  selectDataType, selectFilterBy, selectReportViewType, selectAggregationTarget,
  selectAggregationType, selectNumberCustomFieldsOptions,
  (dataType, filterBy, reportViewType, aggregationTarget, aggregationType, numberFieldsOptions) => {
    const t = i18next.t.bind(i18next);

    const dataTypeOptions = createDataTypeOptions(t);
    const reportViewTypeOptions = createReportViewTypeOptions(t);
    const aggregationsTypesOptions = createAggregationsTypesOptions(t);

    const formattedDataType = dataTypeOptions.find(({ value }) => value === dataType)?.label;

    // @ts-expect-error fixme
    const { epicValues, filterValues, issueValues, projectValues, jqlField } = filterBy;

    const formattedFilterBy = (() => {
      if (jqlField?.[0]) {
        return `JQL ${jqlField[0]?.label}`;
      }
      // @ts-expect-error fixme
      const getValueWithLabel = (label, values) => values.map(({ value }) => `${label} ${value}`).join(', ');

      const projectValuesString = getValueWithLabel('Project', projectValues);
      const epicValuesString = getValueWithLabel('Epic', epicValues);
      // @ts-expect-error fixme
      const filterValuesString = filterValues.reduce((acc, current) => {
        acc += `Filter ${current.label}`;

        return acc;
      }, '');

      const issueValuesString = getValueWithLabel('Issue', issueValues);

      if (
        projectValuesString.length > 0 || epicValuesString.length > 0
        || filterValuesString.length > 0 || issueValuesString.length > 0
      ) {
        return projectValuesString.concat(' ', epicValuesString, ' ', filterValuesString, ' ', issueValuesString);
      }

      return 'Empty JQL';
    })();

    const formattedReportViewType = reportViewTypeOptions.find(({ value }) => value === reportViewType)?.label;
    const formattedAggregationType = aggregationsTypesOptions.find(({ value }) => value === aggregationType)?.label;

    const aggregationTargetsOptions = getAggregationTargetsOptions(() => { }, dataType, aggregationType, numberFieldsOptions);
    const formattedAggregationTarget = aggregationTargetsOptions.find(({ value }) => value === aggregationTarget)?.label;

    return {
      dataType: formattedDataType,
      filterBy: formattedFilterBy,
      reportViewType: formattedReportViewType,
      aggregationTarget: formattedAggregationTarget,
      aggregationType: formattedAggregationType,
    };
  },
);

const selectIsIncludesSubtaskInReport = ({ reports }: RootState) => reports.currentReport.isIncludesSubtask;
// @ts-expect-error fixme
const selectIsIncludesSubtaskInTimesheets = ({ timesheets }) => timesheets.isIncludesSubtask;

export const isIncludesSubtaskSelector = createSelector(
  (state, { isReport }) => isReport ? selectIsIncludesSubtaskInReport(state) : selectIsIncludesSubtaskInTimesheets(state),
  (isIncludesSubtask) => isIncludesSubtask,
);
export const selectShareableLink = ({ reports }: RootState) => reports.currentReport.shareableLink;
export const selectSharedLinkId = ({ reports }: RootState) => reports.currentReport.reportSharedId; // todo investigate prop meaning and may be swap "shared <-> shareable" (need to rename it to clarify meaning & remove conflict with shared via permissions reports)
export const selectShareableLinkId = ({ reports }: RootState) => reports.currentReport.sharedId; // todo investigate prop meaning and may be swap "shared <-> shareable" (need to rename it to clarify meaning & remove conflict with shared via permissions reports)

export const selectIsNameCorrect = createSelector(
  selectReportName,
  (name) => (name && name.length > 0 && name.length <= 128),
);
export const selectIsEditGloballyPermitted = () => checkIsLicensed();

export const selectUserRoleForReport = (userPermissions: UserPermissions) => createSelector(
  selectReportId,
  selectIsPredefinedReport,
  selectReportCreatedBy,
  selectUserId,
  selectReportPermissions,
  selectShareableLinkId,
  selectSharedLinkId,
  (reportId, isPredefined, createdBy, userId, reportPermissions, sharedIdHash, sharedId) => {
    return getReportUserRole({
      reportPermissions,
      isPredefined,
      reportId,
      userId,
      createdBy,
      sharedIdHash,
      userPermissions,
      sharedId,
    });
  },
);

// @ts-expect-error fixme
export const selectIsViewableByUser = (userPermissions: UserPermissions) => createSelector(
  selectUserRoleForReport(userPermissions),
  (userRole: string) => userRole && userRole !== 'no-view-edit-permissions',
);

export const selectIsAddable = (userPermissions: UserPermissions) => createSelector(
  // @ts-expect-error fixme
  selectIsEditGloballyPermitted,
  selectIsPredefinedReport,
  selectIsSharedReport,
  selectUserRoleForReport(userPermissions),
  selectReportId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (isEditGloballyPermitted: any, isPredefined: any, isShared: any, role: string, id: null | string) => (
    isEditGloballyPermitted && (
      isPredefined || (isShared && role === 'viewer') || !id
    )
  ),
);
export const selectIsEditable = (userPermissions: UserPermissions) => createSelector(
  // @ts-expect-error
  selectUserRoleForReport(userPermissions),
  selectReportCreatedBy,
  selectIsPredefinedReport,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (role: string, ownerId: any, isPredefined: boolean) => (
    (ownerId && role === 'admin') || (!isPredefined && role === 'configurator')
  ),
);

export const selectIsSaveable = (userPermissions: UserPermissions) => createSelector(
  selectIsNameCorrect,
  selectIsEditMode,
  selectIsAddable(userPermissions),
  selectIsEditable(userPermissions),
  selectIsReportWithChanges,
  (isNameCorrect, isEditMode, isAddable, isEditable, isWithChanges) => (
    checkIsLicensed() && isNameCorrect && (isAddable || (isEditable && isWithChanges))
  ),
);

export const selectIsSavingReport = ({ reports }: RootState) => reports.currentReport.isSavingReport;

export const selectReportMeta = createSelector(
  selectReportKey,
  selectReportDescription,
  selectReportTags,
  selectReportIcon,
  selectDocsLink,
  (key, description, tags, icon, docsLink) => ({
    isAnyFieldFilled: Boolean(key || description || (tags && tags.length) || icon || docsLink),
    key,
    description,
    tags,
    icon,
    docsLink,
  }),
);

export const selectColorTheme = ({ reports }: RootState) => reports.currentReport.colorTheme;

export const selectSrRenderError = ({ reports }: RootState) => reports.currentReport.srRenderError;
export const selectIsSrLoading = ({ reports }: RootState) => reports.currentReport.isSrLoading;
