import produce from 'immer';
import types from 'store/legacy/favoriteReports/types';

const initialState = [];

export const favoriteReports = (_prevState = initialState, action) => produce(_prevState, (state) => {
  switch (action.type) {
    case types.GET_FAVORITE_REPORTS:
      const { favoriteReportsList } = action.payload;

      return favoriteReportsList ? [...favoriteReportsList] : [];

    case types.ADD_FAVORITE_REPORT: {
      const { reportId } = action.payload;

      return [...state, { reportId }];
    }

    case types.REMOVE_FAVORITE_REPORT: {
      const { favoriteReportId } = action.payload;

      return state.filter(({ reportId }) => reportId !== favoriteReportId);
    }

    default:
      return state;
  }
});
