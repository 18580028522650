import {
  fetchUserData, checkUserPermissions, getIssueTypes,
} from 'controllers/transport/requestConfigurations';
import { TransportController as Transport } from 'controllers';
import { getConfig } from 'store/legacy/thunks';
import { selectUserPropertyOwnerId } from 'store/legacy/user/selectors';
import { getUserProperty, saveUserProperty } from 'transports/userProperty';

import { getUserId, getUserDataQuery, getUserTeamPermissions } from 'store/legacy/getUserId';
import { getConfiguration } from 'store/legacy/configuration/thunks';
import { checkUserTeamPermission } from 'store/legacy/user/checkUserPermission';
import {
  getUserDataStarted, getUserDataSuccess,
  getUserIssuesTypesFailure, getUserIssuesTypesSuccess,
} from 'store/legacy/user/actions';

export const getUserData = () => async (dispatch, getState) => {
  dispatch(getUserDataStarted());

  const { amplitude } = window;

  const userDataQuery = await getUserDataQuery();
  const {
    displayName,
    name: username,
    avatarUrls,
    timeZone,
    accountId,
    key,
    groups: { items: userGroups },
    // todo sometime in the future use useCurrentUser instead
  } = await Transport.request({
    config: fetchUserData,
    query: userDataQuery,
  });

  if (amplitude) {
    amplitude.getInstance().setUserId(accountId || username);
  }

  const { userId, userNameValue } = await (async () => {
    if (process.env.JIRA_TYPE === 'cloud') {
      const id = await getUserId();

      return { userId: id, userNameValue: id };
    }

    return { userId: key, userNameValue: username };
  })();

  let admin;

  if (process.env.JIRA_TYPE === 'cloud') {
    const requestData = {
      globalPermissions: ['ADMINISTER', 'SYSTEM_ADMIN'],
    };

    const { globalPermissions } = await Transport.request({
      config: checkUserPermissions,
      data: new Blob([JSON.stringify(requestData)], { type: 'application/json' }),
    });

    admin = globalPermissions.some((item) => item === 'ADMINISTER' || item === 'SYSTEM_ADMIN');
  } else {
    // eslint-disable-next-line no-undef
    admin = AJS.Meta.get('is-admin') || AJS.Meta.get('is-sysadmin');
  }

  await dispatch(getConfiguration({ includePanelsConfig: false }));

  const { permissions, actonicPermissions } = getState().configuration;
  const { teamManager: teamManagerList, teamGlobalAdmin } = permissions;

  const userGroupsNames = userGroups.map(({ name }) => name);
  const teamManager = await checkUserTeamPermission(teamManagerList, userId, userGroupsNames);
  const globalTeamAdmin = await checkUserTeamPermission(teamGlobalAdmin, userId, userGroupsNames);

  const { teamGlobalAdmin: actonicTeamGlobalAdmin } = actonicPermissions;

  const actonicTMGlobalTeamAdmin = await checkUserTeamPermission(actonicTeamGlobalAdmin, userId, userGroupsNames);

  const userTeamPermissions = await getUserTeamPermissions();

  dispatch(
    getUserDataSuccess({
      payload: {
        displayName,
        userId,
        name: userNameValue,
        username: userNameValue,
        userPic: avatarUrls['48x48'],
        accountId,
        admin,
        globalTeamAdmin,
        teamManager,
        groups: userGroupsNames,
        teamPermissions: userTeamPermissions || [],
        actonicGlobalTeamAdmin: actonicTMGlobalTeamAdmin,
        timeZone,
      },
    }),
  );

  await dispatch(getConfig());
};

export const getUserIssueTypesNextGen = () => async (dispatch) => {
  try {
    const result = (await Transport.request({
      config: getIssueTypes,
    }));

    const subTasks = [];
    const tasks = [];

    result.forEach((item) => {
      const { subtask } = item;
      subtask ? subTasks.push(item) : tasks.push(item);
    });

    if (process.env.JIRA_TYPE !== 'cloud') {
      return dispatch(
        getUserIssuesTypesSuccess(
          {
            issueTypes: [...tasks, ...subTasks],
          },
        ),
      );
    }

    const cloudTasks = tasks.reduce((acc, cur) => {
      if (!acc.find((el) => el.untranslatedName === cur.untranslatedName)) {
        acc.push({
          ...cur,
          id: cur.untranslatedName || cur.name,
        });
      }
      return acc;
    }, []);

    const cloudSubTasks = subTasks.reduce((acc, cur) => {
      if (!acc.find((el) => el.untranslatedName === cur.untranslatedName)) {
        acc.push({
          ...cur,
          id: cur.untranslatedName || cur.name,
        });
      }
      return acc;
    }, []);

    dispatch(
      getUserIssuesTypesSuccess(
        {
          issueTypes: [
            ...cloudTasks,
            ...cloudSubTasks,
          ],
        },
      ),
    );
  } catch (err) {
    console.error(`[store.user.actions][getUserIssueTypes] Exception: ${err}`);
    dispatch(getUserIssuesTypesFailure(err));
  }
};

export const getUserIssueTypes = () => async (dispatch) => {
  try {
    const result = (await Transport.request({
      config: getIssueTypes,
    })).filter((item) => !item.scope);

    const subTasks = [];
    const defaultTask = [];

    result.forEach((item) => {
      const { subtask } = item;
      subtask ? subTasks.push(item) : defaultTask.push(item);
    });

    dispatch(getUserIssuesTypesSuccess(
      { issueTypes: [...defaultTask, ...subTasks] },
    ));
  } catch (err) {
    console.error(`[store.user.actions][getUserIssueTypes] Exception: ${err}`);

    dispatch(getUserIssuesTypesFailure(err));
  }
};

export const getUserConfig = () => async (dispath, getState) => {
  const state = getState();
  const propertyOwnerId = selectUserPropertyOwnerId(state);

  try {
    return getUserProperty('fast-track-config', { propertyOwnerId });
  } catch (e) {
    console.log('[Exception][getUserProperty]', e);
  }
};

export const saveUserConfig = (value) => async (dispath, getState) => {
  const state = getState();
  const propertyOwnerId = selectUserPropertyOwnerId(state);
  try {
    return saveUserProperty('fast-track-config', value, { propertyOwnerId });
  } catch (e) {
    console.log('[Exception][saveUserProperty]', e);
  }
};
