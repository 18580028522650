export const updateExistingGroup = (
  currentLog, elementForUpdate,
) => {
  const currentIssueKey = currentLog.issueKey;

  elementForUpdate._issues[currentIssueKey] = true;
  elementForUpdate._worklogs[currentLog.worklogId] = true;
};

export const findGroupInArray = (array, key, cellType) => {
  const searchKey = key || null;

  return array.find((item) => item._cellType === cellType && item.key === searchKey);
};

export const searchInItemByMultipleKeys = (itemForSearch, searchArray, level, prevArray) => {
  let data = null;

  if (prevArray) {
    data = prevArray;
  } else {
    data = [];
  }

  if (itemForSearch && searchArray.some((search) => search === itemForSearch.key && level === itemForSearch._level)) {
    if (prevArray) {
      data.push(itemForSearch);
    } else {
      return [itemForSearch];
    }
  }

  if (itemForSearch && itemForSearch._children != null) {
    for (let i = 0; i < itemForSearch._children.length; i++) {
      searchInItemByMultipleKeys(itemForSearch._children[i], searchArray, level, data);
    }
  }

  return data;
};

export const searchInArrayByMultipleKeys = (array, search, level, currentGroup) => {
  const data = [];

  const isForSearchInArray = array && array.some((item) => search.some((searchItem) => {
    if (!searchItem) {
      return searchItem === item.key && currentGroup === item._cellType;
    }

    return (searchItem === item.key && level === item._level);
  }));

  if (isForSearchInArray) {
    return array.filter((item) => search.some((searchItem) => {
      if (!searchItem) {
        return searchItem === item.key && currentGroup === item._cellType;
      }

      return (searchItem === item.key && level === item._level);
    }));
  }

  if (array && array[0] && array[0]._children != null) {
    for (let j = 0; j < array.length; j++) {
      const item = array[j];

      for (let i = 0; i < item._children.length; i++) {
        searchInItemByMultipleKeys(item._children[i], search, level, data);
      }
    }
  }

  return data;
};

export const findPrevGroup = (currentGroupLevel, groups, accumulator, links) => {
  const previousGroups = groups.slice(0, currentGroupLevel);

  return previousGroups.reduce((prevFounded, group, index) => {
    // if we have grouping components > fixVersion > issue or similar
    const linkInArray = [links[group] ? links[group] : null];

    const isLinkArray = Array.isArray(links[group]);

    prevFounded = searchInArrayByMultipleKeys(
      index === 0 ? accumulator : prevFounded,
      isLinkArray ? links[group] : linkInArray,
      index,
      group,
    );

    return prevFounded;
  }, null);
};

export const runGroupExtenderRecursively = (extender, groups) => {
  groups.forEach((group) => {
    extender(group);

    if (group._children && group._children.length > 0) {
      runGroupExtenderRecursively(extender, group._children);
    }
  });
};

export const flatTreeInArray = (sortedArray) => {
  const deleteServiceFields = (object) => {
    delete object._children;
    delete object._issues;
    delete object._worklogs;
  };

  let data = sortedArray.reduce((acc, current) => {
    if (current._children && current._children.length === 0) {
      deleteServiceFields(current);

      acc.push(current);

      return acc;
    }

    if (current._children) {
      acc = [...acc, current, ...current._children];
    } else {
      acc = [...acc, current];
    }

    deleteServiceFields(current);

    return acc;
  }, []);

  if (data.some((item) => item._children)) data = flatTreeInArray(data);

  return data;
};

export const getKeysForCurrentLog = () => ({
  issue: 'issueKey',

  epic: 'epicKey',
  worklog: 'worklogId',
  worklogAuthor: 'worklogAuthorId',
  worklogDate: 'startDate',

  worklogWeek: 'worklogWeekWithYear',
  worklogMonth: 'worklogMonthWithYear',
  worklogQuarter: 'worklogQuarterWithYear',
  worklogYear: 'worklogYear',
});
