/* API Endpoint is used for attachements */

const globalConfig = {
  method: 'POST',
  headers: {
    'X-Atlassian-Token': 'no-check',
  },
};

const config = {
  server: {
    ...globalConfig,
    contentType: false,
    url: '/rest/api/2/issue',
  },
  cloud: {
    ...globalConfig,
    contentType: 'multipart/form-data',
    url: '/rest/api/3/issue',
  },
};

export { config as postIssueMultipart };
