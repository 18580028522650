import { API } from 'controllers/API';

export const checkIsReportAvailable = async (id) => {
  let isAvailable = true;

  try {
    await API.head(`/reports/${id}`);
  } catch (error) {
    console.error(`[checkIsReportAvailable] Exception: ${error}`);
    isAvailable = false;
  }

  return isAvailable;
};
