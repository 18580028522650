import types from 'store/legacy/reports/types';

import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { ExternalEditorConfig } from 'sharedFrontBack/src/types/srExternalEditor';

import { SrInputField } from 'sharedFrontBack/src/types/sr';
import { SrRenderError } from 'features/sr/utils/srInstance/types';

// @ts-expect-error fixme
export const changeIssueName = (issueKey, newName) => ({
  type: types.CHANGE_ISSUE_IN_TABLE_NAME,
  payload: {
    issueKey,
    newName,
  },
});

export const setIsWithChanges = (isWithChanges = true) => ({
  type: types.SET_IS_CURRENT_REPORT_WITH_CHANGES,
  payload: { isWithChanges },
});

export const updateVulnerableFieldsUpdatedFields = (userId: string) => ({
  type: types.SET_UPDATED_VULNERABLE_FIELDS,
  payload: { vulnerableFieldsUpdatedBy: userId },
});

export const setIsVulnerableFieldsUpdated = (isVulnerableFieldsUpdated: boolean | null) => ({
  type: types.SET_IS_VULNERABLE_FIELDS_UPDATED,
  payload: { isVulnerableFieldsUpdated },
});

// @ts-expect-error fixme
export const setReportName = (name) => ({
  type: types.SET_NAME_CURRENT_REPORT,
  payload: {
    name,
  },
});
// @ts-expect-error fixme
export const setSortBy = (sortBy) => ({
  type: types.SET_SORT_BY_CURRENT_REPORT,
  payload: {
    sortBy,
  },
});
// @ts-expect-error fixme
export const resetCurrentReport = (currentReportPart) => ({
  type: types.RESET_CURRENT_REPORT,
  payload: currentReportPart,
});

export const resetReportsToInitialData = () => ({
  type: types.RESET_REPORTS_TO_INITIAL_DATA,
});
// @ts-expect-error fixme
export const setReportId = (id) => ({
  type: types.SET_ID_CURRENT_REPORT,
  payload: {
    id,
  },
});
// @ts-expect-error fixme
export const setCurrentTab = (currentTab) => ({
  type: types.SET_CURRENT_TAB,
  payload: {
    currentTab,
  },
});

export const setReportCategory = (category: ReportCategory) => ({
  type: types.SET_REPORT_CATEGORY,
  payload: {
    category,
  },
});
// @ts-expect-error fixme
export const setReportType = (type) => ({
  type: types.SET_REPORT_TYPE,
  payload: {
    type,
  },
});
// @ts-expect-error fixme
export const setDefinedPeriod = (relativeDate) => ({
  type: types.SET_RELATIVE_DATE,
  payload: {
    relativeDate,
  },
});
// @ts-expect-error fixme
export const setTempPermissions = (permissions) => ({
  type: types.SET_TEMP_PERMISSIONS,
  payload: {
    permissions,
  },
});
// @ts-expect-error fixme
export const setInputFieldsFormScriptedReports = ({ name, title, type, option, optionsList, isMulti }) => ({
  type: types.SET_INPUT_FIELDS_FORM_SCRIPTED_REPORTS,
  payload: {
    ...(typeof name === 'string' && { name }),
    ...(typeof title === 'string' && { title }),
    ...(type && { type }),
    ...(typeof option === 'string' && { option }),
    ...(optionsList && { optionsList }),
    ...(typeof isMulti === 'boolean' && { isMulti }),
  },
});

export const setInputFieldsListScriptedReports = (fieldsList: SrInputField[]) => {
  return {
    type: types.SET_INPUT_FIELDS_LIST_SCRIPTED_REPORTS,
    payload: {
      fieldsList: fieldsList ?? [],
    },
  };
};
// @ts-expect-error fixme
export const setReportKey = (key) => ({
  type: types.SET_KEY_CURRENT_REPORT,
  payload: {
    key,
  },
});
// @ts-expect-error fixme
export const setReportDescription = (description) => ({
  type: types.SET_DESCRIPTION_CURRENT_REPORT,
  payload: {
    description,
  },
});
// @ts-expect-error fixme
export const setReportTags = (tags) => ({
  type: types.SET_TAGS_CURRENT_REPORT,
  payload: {
    tags,
  },
});
// @ts-expect-error fixme
export const setReportIcon = (icon) => ({
  type: types.SET_ICON_CURRENT_REPORT,
  payload: {
    icon,
  },
});
// @ts-expect-error fixme
export const setCategory = (category) => ({
  type: types.SET_CATEGORY_CURRENT_REPORT,
  payload: {
    category,
  },
});

// @ts-expect-error fixme
export const setTemplate = (template) => ({
  type: types.SET_TEMPLATE_CURRENT_REPORT,
  payload: { template },
});

export const setExternalEditor = (externalEditor?: ExternalEditorConfig) => ({
  type: types.SET_EXTERNAL_EDITOR,
  payload: { externalEditor },
});

export const setExternalEditorSyncing = (isExternalEditorSyncing: boolean) => ({
  type: types.SET_EXTERNAL_EDITOR_SYNCING,
  payload: { isExternalEditorSyncing },
});

export const setScript = (script: string) => ({
  type: types.SET_SCRIPT_CURRENT_REPORT,
  payload: {
    script,
  },
});

// @ts-expect-error fixme
export const setGalleryReportsFetched = (value) => ({
  type: types.SET_GALLERY_REPORTS_FETCHED,
  payload: { value },
});

export const setSearchString = (searchString = '') => ({
  type: types.SET_SEARCH_STRING,
  payload: { searchString },
});

export const setIsOnlyFavorites = (isOnlyFavorites = false) => ({
  type: types.SET_IS_ONLY_FAVORITES,
  payload: { isOnlyFavorites },
});

export const cleanReportsTableData = () => ({
  type: types.GET_CURRENT_REPORT_TABLE_DATA_SUCCESS,
  payload: {
    worklogs: [],
    epicsByKeys: [],
  },
});

export const setIsExporting = (isExporting: boolean) => ({
  type: types.SET_IS_EXPORTING,
  payload: { isExporting },
});

export const setIsAttaching = (isAttaching: boolean) => ({
  type: types.SET_IS_ATTACHING,
  payload: { isAttaching },
});
// @ts-expect-error fixme
export const setAttachmentStatus = (attachmentStatus) => ({
  type: types.SET_ATTACHMENT_STATUS,
  payload: { attachmentStatus },
});

export const setIsIncludesSubtaskInReport = (isIncludesSubtask: boolean) => ({
  type: types.SET_IS_INCLUDES_SUBTASK_IN_REPORT,
  payload: {
    isIncludesSubtask,
  },
});
// @ts-expect-error fixme
export const setReportExportPanelVisibility = (reportExportVisibility) => ({
  type: types.SET_REPORT_EXPORT_PANEL_VISIBILITY,
  payload: {
    reportExportVisibility: reportExportVisibility ? reportExportVisibility : [],
  },
});
// @ts-expect-error fixme
export const setReportViewType = (reportViewType) => ({
  type: types.SET_REPORT_VIEW_TYPE,
  payload: {
    reportViewType,
  },
});
// @ts-expect-error fixme
export const setAggregationTarget = (aggregationTarget) => ({
  type: types.SET_AGGREGATION_TARGET,
  payload: {
    aggregationTarget,
  },
});

export const setIsEditMode = (isEditMode: boolean) => ({
  type: types.SET_IS_EDIT_MODE,
  payload: {
    isEditMode,
  },
});

export const setIsDemoMode = ({ isDemoMode = false, demoData = {} }) => ({
  type: types.SET_IS_DEMO_MODE,
  payload: {
    isDemoMode,
    demoData,
  },
});

export const setDocsLink = (docsLink: string) => ({
  type: types.SET_DOCS_LINK,
  payload: {
    docsLink,
  },
});

export const setIsDescriptionVisible = (isDescriptionVisible: string) => ({
  type: types.SET_IS_DESCRIPTION_VISIBLE,
  payload: {
    isDescriptionVisible,
  },
});

export const setIsNewOwnerSaved = (isNewOwnerSaved: string) => ({
  type: types.SET_IS_NEW_OWNER_SAVED,
  payload: {
    isNewOwnerSaved,
  },
});
// @ts-expect-error fixme
export const setShareableLink = (shareableLink) => ({
  type: types.SET_SHAREABLE_LINK,
  payload: {
    shareableLink,
  },
});
// @ts-expect-error fixme
export const setSharedId = (sharedId) => ({
  type: types.SET_SHARED_ID,
  payload: {
    sharedId,
  },
});
// @ts-expect-error fixme
export const setReportCreatedBy = (createdBy) => ({
  type: types.SET_REPORT_CREATED_BY,
  payload: {
    createdBy,
  },
});
// @ts-expect-error fixme
export const setLastFilterAsString = ({ filterAsString, isParentsShouldBeFetched }) => ({
  type: types.SET_LAST_FILTER_AS_STRING,
  payload: { lastFilterAsString: `${filterAsString} isParentsShouldBeFetched=${isParentsShouldBeFetched}` },
});

export const getReportsStarted = () => ({ type: types.GET_REPORTS_STARTED });
// @ts-expect-error fixme
export const getReportsFailure = (error) => ({ type: types.GET_REPORTS_FAILURE, error });
// @ts-expect-error fixme
export const getDashboardReportsSuccess = ({ payload }) => ({
  type: types.GET_DASHBOARD_REPORTS_SUCCESS,
  payload,
});

export const getReportTableDataStarted = () => ({ type: types.GET_CURRENT_REPORT_TABLE_DATA_STARTED });
export const getReportTableDataFinished = () => ({ type: types.GET_CURRENT_REPORT_TABLE_DATA_FINISHED });
// @ts-expect-error fixme
export const getReportTableDataSuccess = ({ worklogs, loggedTimeInSeconds, lastDataFetchItemsCount }) => ({
  type: types.GET_CURRENT_REPORT_TABLE_DATA_SUCCESS,
  payload: {
    worklogs,
    loggedTimeInSeconds,
    lastDataFetchDate: (new Date()).toISOString(),
    lastDataFetchItemsCount,
  },
});
// @ts-expect-error fixme
export const getReportTableDataFailure = (error) => ({ type: types.GET_CURRENT_REPORT_TABLE_DATA_FAILURE, error });
// @ts-expect-error fixme
export const getIssuesByJQL = ({ issues }) => ({
  type: types.GET_ISSUES_BY_JQL,
  payload: {
    issues,
  },
});

export const getCalendarDataStarted = () => ({ type: types.GET_CALENDAR_DATA_STARTED });
// @ts-expect-error fixme
export const getCalendarDataSuccess = ({ data, fieldsByType }) => ({
  type: types.GET_CALENDAR_DATA_SUCCESS,
  payload: {
    data,
    fieldsByType,
  },
});
// @ts-expect-error fixme
export const getCalendarDataFailure = (error) => ({ type: types.GET_CALENDAR_DATA_FAILURE, error });
// @ts-expect-error fixme
export const checkIsAvailableLastReportAction = ({ isAvailable }) => ({
  type: types.CHECK_IS_AVAILABLE_LAST_REPORT,
  payload: {
    isAvailable,
  },
});

export const deletingReportsStarted = () => ({ type: types.DELETING_REPORTS_STARTED });

export const deletingReportsSuccess = () => ({ type: types.DELETING_REPORTS_SUCCESS });
// @ts-expect-error fixme
export const deletingReportFailure = (error) => ({ type: types.DELETING_REPORTS_FAILURE, error });
// @ts-expect-error fixme
export const setReportsList = ({ reportsList }) => ({
  type: types.SET_REPORTS_LIST,
  payload: {
    reportsList,
  },
});
// @ts-expect-error fixme
export const setGroupByAction = ({ groupBy, isWithChanges }) => ({
  type: types.SET_GROUP_BY_CURRENT_REPORT,
  payload: {
    groupBy,
    isWithChanges,
  },
});
// @ts-expect-error fixme
export const setColumnsAction = ({ columns }) => ({
  type: types.SET_COLUMNS,
  payload: {
    columns,
  },
});
// @ts-expect-error fixme
export const setDefaultCurrentReportAction = ({ name }) => ({
  type: types.SET_DEFAULT_CURRENT_REPORT,
  payload: {
    name,
  },
});

export const getScriptedReportStarted = () => ({ type: types.GET_SCRIPTED_REPORT_STARTED });
// @ts-expect-error fixme
export const getScriptedReportSuccess = ({ currentReport, ...rest }) => ({
  type: types.GET_SCRIPTED_REPORT_SUCCESS,
  payload: {
    currentReport,
    ...rest,
  },
});
// @ts-expect-error fixme
export const getScriptedReportFailure = (error) => ({ type: types.GET_SCRIPTED_REPORT_FAILURE, error });

export const getCurrentReportStarted = () => ({ type: types.GET_CURRENT_REPORT_STARTED });
// @ts-expect-error fixme
export const getCurrentReportSuccess = ({ currentReport, ...rest }) => ({
  type: types.GET_CURRENT_REPORT_SUCCESS,
  payload: { currentReport, ...rest },
});
// @ts-expect-error fixme
export const getCurrentReportFailure = (error) => ({ type: types.GET_CURRENT_REPORT_FAILURE, error });
// @ts-expect-error fixme
export const setReportTimeTrackingUnitAction = ({ timeTrackingUnit }) => ({
  type: types.SET_REPORT_TIME_TRACKING_UNIT,
  payload: {
    timeTrackingUnit,
  },
});
// @ts-expect-error fixme
export const setDatesRangeCurrentReport = ({ datesRange }) => ({
  type: types.SET_DATES_RANGE_CURRENT_REPORT,
  payload: {
    datesRange,
  },
});

export const createReportStarted = () => ({ type: types.CREATE_REPORT_STARTED });
// @ts-expect-error fixme
export const createReportSuccess = ({ userId }) => ({
  type: types.CREATE_REPORT_SUCCESS,
  payload: { userId },
});
// @ts-expect-error fixme
export const createReportFailure = (error) => ({ type: types.CREATE_REPORT_FAILURE, error });

export const saveReportStarted = () => ({ type: types.SAVE_REPORT_STARTED });
// @ts-expect-error fixme
export const saveReportSuccess = ({ payload }) => ({
  type: types.SAVE_REPORT_SUCCESS,
  payload,
});
// @ts-expect-error fixme
export const saveReportFailure = (error) => ({ type: types.SAVE_REPORT_FAILURE, error });

export const saveReportComplete = () => ({ type: types.SAVE_REPORT_COMPLETE });

export const editReportStarted = () => ({ type: types.EDIT_REPORT_STARTED });

export const editReportSuccess = () => ({ type: types.EDIT_REPORT_SUCCESS });
// @ts-expect-error fixme
export const editReportFailure = (error) => ({ type: types.EDIT_REPORT_FAILURE, error });
// @ts-expect-error fixme
export const setLastViewedReport = ({ lastViewedReport }) => ({
  type: types.SET_LAST_REPORT,
  payload: { lastViewedReport },
});
// @ts-expect-error fixme
export const setPermissions = (payload) => ({
  type: types.SET_PERMISSIONS,
  payload,
});
// @ts-expect-error fixme
export const setReportNewOwner = ({ updatedOwner }) => ({
  type: types.SET_REPORT_NEW_OWNER,
  payload: { updatedOwner },
});

export const savePermissionsStarted = () => ({ type: types.SAVE_PERMISSIONS_STARTED });

export const savePermissionsSuccess = () => ({ type: types.SAVE_PERMISSIONS_SUCCESS });
// @ts-expect-error fixme
export const savePermissionsFailure = (error) => ({ type: types.SAVE_PERMISSIONS_FAILURE, error });
// @ts-expect-error fixme
export const setInputFieldsListValues = ({ fieldsList }) => ({
  type: types.SET_INPUT_FIELDS_LIST_VALUES_SCRIPTED_REPORTS,
  payload: {
    fieldsList,
  },
});
// @ts-expect-error fixme
export const addFavoriteReportFrontAction = ({ favoriteReportId }) => ({
  type: types.ADD_FAVORITE_REPORT_FRONT,
  payload: { favoriteReportId },
});
// @ts-expect-error fixme
export const removeFavoriteReportFrontAction = ({ favoriteReportId, isOnlyFavorites }) => ({
  type: types.REMOVE_FAVORITE_REPORT_FRONT,
  payload: { favoriteReportId, isOnlyFavorites },
});
// @ts-expect-error fixme
export const setSourcesAction = ({ sources }) => ({
  type: types.SET_SOURCES,
  payload: {
    sources,
  },
});
// @ts-expect-error fixme
export const saveSource = ({ source }) => ({
  type: types.SAVE_SOURCE,
  payload: {
    source,
  },
});
// @ts-expect-error fixme
export const setReportCalendarViewAction = ({ calendarView }) => ({
  type: types.SET_REPORT_CALENDAR_VIEW,
  payload: {
    calendarView,
  },
});
// @ts-expect-error fixme
export const setIsReportWithWeekendsAction = ({ isWithWeekends }) => ({
  type: types.SET_IS_REPORT_WITH_WEEKENDS,
  payload: {
    isWithWeekends,
  },
});
// @ts-expect-error fixme
export const setIsParentsShouldBeFetchedAction = ({ isParentsShouldBeFetched }) => ({
  type: types.SET_IS_PARENTS_SHOULD_BE_FETCHED,
  payload: {
    isParentsShouldBeFetched,
  },
});
// @ts-expect-error fixme
export const setIsChildrenShouldBeFetchedAction = ({ isChildrenShouldBeFetched }) => ({
  type: types.SET_IS_CHILDREN_SHOULD_BE_FETCHED,
  payload: {
    isChildrenShouldBeFetched,
  },
});
// @ts-expect-error fixme
export const fetchCustomFieldsOptionsSuccess = ({ customFieldsOptions, numberCustomFieldsOptions }) => ({
  type: types.FETCH_CUSTOM_FIELDS_OPTIONS_SUCCESS,
  payload: {
    customFieldsOptions,
    numberCustomFieldsOptions,
  },
});
// @ts-expect-error fixme
export const fetchCustomFieldsOptionsFailure = (error) => ({
  type: types.FETCH_CUSTOM_FIELDS_OPTIONS_FAILURE,
  error,
});
// @ts-expect-error fixme
export const setReportDataTypeAction = ({ dataType, areSoftwareFieldsInitialized }) => ({
  type: types.SET_REPORT_DATA_TYPE,
  payload: {
    dataType,
    areSoftwareFieldsInitialized,
  },
});
// @ts-expect-error fixme
export const setAggregationTypeAction = ({ aggregationType, areSoftwareFieldsInitialized }) => ({
  type: types.SET_AGGREGATION_TYPE,
  payload: {
    aggregationType,
    areSoftwareFieldsInitialized,
  },
});
// @ts-expect-error fixme
export const setReportFilters = ({ filters }) => ({
  type: types.SET_REPORT_FILTERS,
  payload: {
    filters,
  },
});
// @ts-expect-error fixme
export const getLastViewedReportSuccess = ({ lastViewedReportValues }) => ({
  type: types.GET_LAST_VIEWED_REPORT_SUCCESS,
  payload: { lastViewedReportValues },
});
// @ts-expect-error fixme
export const getLastViewedReportFailure = (error) => ({ type: types.GET_LAST_VIEWED_REPORT_FAILURE, error });
// @ts-expect-error fixme
export const setTempIssuesData = ({ tempIssues }) => ({ type: types.SET_TEMP_ISSUES_DATA, payload: { tempIssues } });
// @ts-expect-error fixme
export const setColorTheme = (colorTheme) => ({
  type: types.SET_COLOR_THEME,
  payload: {
    colorTheme,
  },
});

export const setIsSecureEnvironment = (isSecureEnvironment: boolean) => ({
  type: types.SET_IS_SECURE_ENVIRONMENT,
  payload: {
    isSecureEnvironment,
  },
});

export const setSrRenderError = (error?: SrRenderError) => ({
  type: types.SET_SR_RENDER_ERROR,
  payload: error,
});

export const setSrLoading = (isLoading: boolean) => ({
  type: types.SET_SR_LOADING,
  payload: isLoading,
});

export const setVersion = (version: string) => ({
  type: types.SET_VERSION_CURRENT_REPORT,
  payload: {
    version,
  },
});