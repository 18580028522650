import { useEffect, useState } from 'react';

export const useRouterLocation = () => {
  const [location, setLocation] = useState('/');
  const [urlBasename, setUrlBasename] = useState<URL>();
  const [isLoading, setIsLoading] = useState(IS_CLOUD ? true : false);

  useEffect(() => {
    if (window.AP && IS_CLOUD) {
      window.AP.getLocation((hostLocation) => {
        const currentURL = new URL(hostLocation);
        const [, newLocation] = (currentURL.hash || '#!/').split('#!');
        currentURL.hash = '';

        setLocation(newLocation ?? '');
        setUrlBasename(currentURL);

        setIsLoading(false);
      });

      // @ts-expect-error FIXME
      window.AP.history.popState((e: HashChangeEvent) => {
        setLocation(e.newURL);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.AP]);

  return { urlBasename, location, isLoading };
};