import routeNames from 'router/routeNames';

export default {
  [routeNames.root]: '/',
  [routeNames.notFoundPage]: '/not-found',
  [routeNames.timesheets]: '/worklogs-page',
  [routeNames.reports]: '/reports',
  [routeNames.reportPage]: '/report',
  [routeNames.test]: '/test',
  [routeNames.teams]: '/teams',
  [routeNames.teamPage]: '/team',
  [routeNames.configuration]: '/configuration',
  [routeNames.scriptedReportsDashboard]: '/scripted-reports-dashboard',
  [routeNames.scriptedReportsDetailPage]: '/scripted-reports-detail-page',
  [routeNames.scriptedReportsGadget]: '/scripted-reports-gadget',
  [routeNames.scriptedReportsIssuePicker]: '/scripted-reports-issue-picker',
  [routeNames.universalReportGadget]: '/universal-report-gadget',
  [routeNames.projectReport]: '/project-page',
  [routeNames.projectUniversalReport]: '/project-report-universal',
  [routeNames.startPage]: '/start-page',
  [routeNames.gettingStartedPage]: '/getting-started-page',
} as const;
