/* eslint-disable max-len */
import styled from 'styled-components5';

export const AppStyledRoot = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  & * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  overflow-y: auto; ${() => ''/* WARNING app should be scrollable even in gadgets because we define fixed height for it */}
`;