import { createQueryString } from 'utils/createQueryString';
import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { JiraUser, ActonicJiraUser } from 'types/jiraUser';

const pathPrefix = IS_CLOUD ? 'api/3/user' : 'api/2/user';

export const userApi = jiraBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<ActonicJiraUser, { accountId?: string; username?: string; key?: string }>({
      query: ({ accountId, username, key }) => {
        return `${pathPrefix}/${createQueryString({
          ...(accountId ? { accountId } : {}),
          ...(username ? { username } : {}),
          ...(key ? { key } : {}),
          expand: 'groups',
        })}`;
      },
      transformResponse: (rawResponse: JiraUser) => ({
        ...rawResponse,
        userKey: 'accountId' in rawResponse ? rawResponse.accountId : rawResponse.key,
        userId: 'accountId' in rawResponse ? rawResponse.accountId : rawResponse.key,
      }),
    }),

    getUserProjectsCloud: builder.query<string[], void>({
      query: () => {
        return {
          url: 'api/3/permissions/project',
          method: 'POST',
          body: { permissions: [] },
        };
      },
      transformResponse: ({ projects }: { projects: { key: string }[] }) => projects.map(({ key }) => key),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserProjectsCloudQuery,
} = userApi;
