import { createQueryString } from 'utils/createQueryString';
import { jiraBaseApi } from 'store/api/jira/baseApi/jiraBaseApi';
import { JiraGetBoardResponse } from 'actonic-types-atlassian/src/jira/jiraBoard';
import { JiraGetBoardSprintResponse } from 'actonic-types-atlassian/src/jira/jiraSprint';

export interface GetBoardsQs {
  name?: string;
  projectKeyOrId?: string;
  startAt?: number;
  maxResults?: number;
}

export const boardApi = jiraBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoards: builder.query<JiraGetBoardResponse, GetBoardsQs>({
      query: (queryParams) => `agile/1.0/board/${createQueryString(queryParams)}`,
    }),
    getBoardSprints: builder.query<JiraGetBoardSprintResponse, string>({
      query: (boardId) => `agile/1.0/board/${boardId}/sprint`,
    }),
  }),
});

export const {
  useGetBoardsQuery,
} = boardApi;
