import { getIssuesCount } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getIssuesCount,
  fieldName: 'total',
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/search',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/search',
  },
};

export { config as getIssuesCount };
