import { getTimeTrackingConfiguration } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getTimeTrackingConfiguration,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/configuration',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/configuration',
  },
};

export { config as getTimeTrackingConfiguration };
