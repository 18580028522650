import i18next from 'i18next';

import { traverse } from '@atlaskit/adf-utils/traverse';
import copy from 'fast-copy';

const t = i18next.t.bind(i18next);

const getNodeText = (node) => {
  if (node && node.content && node.content[0] && node.content[0].text) {
    return node.content[0].text;
  }

  return null;
};

const getFieldMultiLineText = (paragraph) => {
  if (paragraph && paragraph.content) {
    return paragraph.content
      .map((string) => string.text)
      .join(' ');
  }
};

const getTextFromDocType = (doc, getTextFunction) => {
  let docText = doc;

  if (process.env.JIRA_TYPE === 'cloud') {
    if (doc) {
      let data = '';

      const traverseTextNodes = (node) => {
        const nodeText = getTextFunction(node);

        if (nodeText) {
          data += data.length > 0
            ? ' ' + nodeText
            : nodeText;
        }
      };

      // Should be copied because sometimes we using issues from store (FEWR-2419), that was produced from immer
      const copiedField = copy(doc);

      traverse(copiedField, {
        paragraph: traverseTextNodes,
        heading: traverseTextNodes,
      });

      docText = data.length > 0 ? data : null;
    } else {
      docText = null;
    }
  }

  return docText;
};

export const getWorklogComment = (comment, worklogId) => {
  const commentText = getTextFromDocType(comment, getNodeText);

  if (!commentText) {
    return t('timesheets.table.emptyWorklog', { worklogId });
  }

  return commentText;
};

export const getTextFromField = (field) => getTextFromDocType(field, getFieldMultiLineText);
