import { getPrettyPrintedTime } from 'utils/formatTime';
import { createIssueUrl, createProjectUrl, createUserUrl } from 'utils/jiraUrl';
import { calculateProgress, calculateRemainingProgress } from 'utils/countWorklogs';

const getFieldFromArray = (array, field) => {
  if (array && array.length > 0) {
    return array.map((item) => item[field]);
  }
  return [];
};

const getEpicKey = (formatOptions, issue) => {
  if (issue.fields[formatOptions.epicKeyField]) {
    return issue.fields[formatOptions.epicKeyField];
  }

  if (issue.fields[formatOptions.epicNameField]) {
    return issue.key;
  }

  return null;
};

export const formatHierarchyData = (issue, formatOptions) => ({
  issuesId: issue.id,
  issueKey: issue.key,
  issueIcon: issue.fields.issuetype.iconUrl,
  issueUrl: createIssueUrl(issue.key),
  priority: issue.fields.priority ? issue.fields.priority.name : null,
  issueStatus: issue.fields.status ? issue.fields.status.name : null,
  issueType: issue.fields.issuetype.name,
  issueTypeId: issue.fields.issuetype.id,
  issueLinks: issue.fields.issuelinks,
  issueLabels: issue.fields.labels.length > 0 ? issue.fields.labels : null,
  issueName: issue.fields.summary,
  issueAssignee: issue.fields.assignee ? {
    value: issue.fields.assignee.displayName,
    icons: issue.fields.assignee.avatarUrls,
    url: createUserUrl(issue.fields.assignee?.accountId ? issue.fields.assignee?.accountId : issue.fields.assignee?.name),
  } : {},
  issueCreator: issue.fields.creator.diplayName,
  issueReporter: issue.fields.reporter.displayName,
  issueCreationDate: issue.fields.created,
  projectKey: issue.fields.project.key,
  projectName: issue.fields.project.name,
  projectIcon: issue.fields.project.avatarUrls['16x16'],
  projectUrl: createProjectUrl(issue.fields.project.key),
  epicKey: getEpicKey(formatOptions, issue),
  epicName: null,
  epicStatus: null,
  epicLink: null,
  epicIcon: null,
  originalEstimate: issue.fields.aggregatetimeoriginalestimate,
  timeEstimate: issue.fields.timeestimate,
  remainingEstimate: issue.fields.timeestimate
    ? getPrettyPrintedTime(issue.fields.timeestimate).trim()
    : null,
  dueDate: issue.fields.duedate,
  resolution: issue.fields.resolution,
  resolved: !!issue.fields.resolution,
  updated: issue.fields.updated,
  storyPoints: issue.fields[formatOptions.storyPointsKeyField]
    ? issue.fields[formatOptions.storyPointsKeyField]
    : null,
  sprint: issue.fields[formatOptions.sprintKeyField],
  issueStartDate: issue.fields[formatOptions.startDateKeyField]
    ? issue.fields[formatOptions.startDateKeyField]
    : null,
  components: getFieldFromArray(issue.fields.components, 'name').length > 0
    ? getFieldFromArray(issue.fields.components, 'name')
    : null,
  fixVersions: getFieldFromArray(issue.fields.fixVersions, 'name').length > 0
    ? getFieldFromArray(issue.fields.fixVersions, 'name')
    : null,
  issueId: issue.id,
  projectId: issue.fields.project.id,
  fields: issue.fields,
  progress: calculateProgress(issue.fields.timespent, issue.fields.timeoriginalestimate),
  timeOriginalEstimate: issue.fields.timeoriginalestimate,
  timeSpent: issue.fields.timespent,
  children: issue.fields.children ? { ...issue.fields.children } : null,
  childrenKey: issue.fields.children ? issue.fields.children.issueKey : null,
  childrenName: issue.fields.children ? issue.fields.children.issueName : null,
  childrenIcon: issue.fields.children ? issue.fields.children.issueIcon : null,
  childrenUrl: issue.fields.children ? createIssueUrl(issue.fields.children.issueKey) : null,
  progressRemaining: calculateRemainingProgress(issue.fields.timespent, issue.fields.timeestimate),
});
