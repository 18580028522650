export const getProjectStyle = (data) => {
  if (process.env.JIRA_TYPE === 'cloud') {
    return ({
      style: data.style,
      avatarUrls: data.avatarUrls,
      name: data.name,
      key: data.key,
    });
  }

  return data;
};
