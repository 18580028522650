import { jiraDatesDelimiter, tmSequreDatesDelimiter } from 'constants/dateConstants';
import { formatDateForSearch } from 'utils/dates/formatDates';

export const createWorklogDateJql = (start, end, shouldUseTmSecureWorklogs) => {
  const delimiter = shouldUseTmSecureWorklogs ? tmSequreDatesDelimiter : jiraDatesDelimiter;
  const formattedStartDate = formatDateForSearch(start, delimiter);
  const formattedEndDate = formatDateForSearch(end, delimiter);

  if (shouldUseTmSecureWorklogs) {
    return `issue in sWorklogDate("", "${formattedStartDate}", "${formattedEndDate}")`;
  }

  return `worklogDate >= "${formattedStartDate}" AND worklogDate <= "${formattedEndDate}"`;
};
