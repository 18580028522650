import { getPrettyPrintedTime } from 'utils/formatTime';
import { createIssueUrl } from 'utils/jiraUrl';
import { getWorklogComment } from 'controllers/WorklogController/getWorklogComment';

export const createEndTime = (started, timeSpentSeconds) => {
  const ended = Number(new Date(started)) + (timeSpentSeconds * 1000);
  const endDay = Number(new Date(started).setHours(23, 59, 59, 999));

  if (ended > endDay) {
    return new Date(new Date(started).setHours(23, 59, 59, 999));
  }

  return new Date(ended);
};

export const formatCalendarData = (issue, worklog, formatOptions) => {
  const { comment } = worklog;

  const isCloud = process.env.JIRA_TYPE === 'cloud';
  const { isWorklogWithProperties } = formatOptions;

  const worklogId = (() => {
    if (isCloud) return worklog.id;

    if (isWorklogWithProperties) return worklog.worklogId;

    return worklog.id;
  })();

  return {
    // Fields for filtration
    started: worklog.started,
    author: worklog.author,

    id: `${issue.id} ${worklog.id}`,
    title: issue.fields.summary,
    start: new Date(worklog.started),
    end: formatOptions.allDay ? worklog.started : createEndTime(worklog.started, worklog.timeSpentSeconds),
    issueKey: issue.key,
    remainingEstimate: issue.fields.timeestimate
      ? getPrettyPrintedTime(issue.fields.timeestimate).trim()
      : null,
    issueType: issue.fields.issuetype.name,
    issueTypeIcon: issue.fields.issuetype.iconUrl,
    icon: issue.fields.issuetype.iconUrl, // for drag'n'drop functionality
    loggedTime: worklog.timeSpent,
    loggedTimeInSeconds: worklog.timeSpentSeconds,
    projectKey: issue.fields.project.key,
    allDay: formatOptions.allDay,
    worklogId,
    additionalStartTime: formatOptions.isWithTime ? null : new Date(worklog.started),
    isLog: true,
    worklogComment: getWorklogComment(comment),
    issueId: worklog.issueId,
    issueUrl: createIssueUrl(issue.key),
    projectId: issue.fields.project.id,
    additionalEndTime: new Date(Number(new Date(worklog.started)) + (worklog.timeSpentSeconds * 1000)),
    statusName: issue.fields.status.statusCategory.colorName,
    timeEstimate: issue.fields.timeestimate,
    timeOriginalEstimate: issue.fields.timeoriginalestimate,
    timeSpent: issue.fields.timespent,
    teamProperty: Array.isArray(worklog.properties) && worklog.properties.find(({ key }) => key === 'teamValue'),
    properties: worklog.properties,
  };
};
