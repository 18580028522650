import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OnboardingNames,
  SpotlightTargets,
} from 'features/spotlight/constants';
import { userPropertyApi } from 'store/api/jira/userPropertyApi';
import { UserOnboardingConfig } from 'features/spotlight/types';

import { getActiveOnboardingTargetsArray } from 'features/spotlight/utils/getActiveOnboardingTargetsArray';
import { UserPropertyKeys } from 'constants/userPropertyKeys';

export interface OnboardingState {
  shouldShowViewed: boolean;
  activeTarget?: SpotlightTargets;
  availableOnboardings: OnboardingNames[];
  availableTargets: SpotlightTargets[];
  userOnboardings: {
    [key in OnboardingNames]: boolean;
  };
}

const initialState: OnboardingState = {
  shouldShowViewed: false,
  availableOnboardings: [],
  availableTargets: [],
  userOnboardings: {
    [OnboardingNames.startPage]: true,
    [OnboardingNames.general]: true,
    [OnboardingNames.universalReport]: true,
    [OnboardingNames.srGeneral]: true,
    [OnboardingNames.srPermission]: true,
    [OnboardingNames.srTemplate]: true,
    [OnboardingNames.srCode]: true,
    [OnboardingNames.srDevServer]: true,
  },
};

const onboardingNamesArray = Object.keys(initialState.userOnboardings) as Array<keyof typeof initialState.userOnboardings>;

export const onboardingSlice = createSlice({
  name: 'onboarding',

  initialState,

  reducers: {
    setNextSpotlightTarget: (state, { payload: skipCount = 1 }: PayloadAction<number>) => {
      const targetsArray = getActiveOnboardingTargetsArray(state);
      if (!targetsArray) {
        return;
      }
      const currentTargetFoundIndex = targetsArray.findIndex((target) => target === state.activeTarget);
      const currentTargetIndex = currentTargetFoundIndex === -1 ? 0 : currentTargetFoundIndex;
      const nextTarget = targetsArray[currentTargetIndex + skipCount];

      if (!nextTarget) {
        return;
      }
      state.activeTarget = nextTarget;
    },
    resetActiveTarget: (state) => {
      state.activeTarget = undefined;
    },
    setShouldShowViewed: (state, { payload: shouldShowViewed }: PayloadAction<boolean>) => {
      state.shouldShowViewed = shouldShowViewed;
    },
    addAvailableOnboarding: (state, { payload: newOnboarding }: PayloadAction<OnboardingNames>) => {
      state.availableOnboardings = (
        Object.values(OnboardingNames) as Array<OnboardingNames>
      ).reduce((acc, onboarding) => {
        if (onboarding === newOnboarding) {
          return [...acc, onboarding];
        }
        return acc;
      }, state.availableOnboardings);
    },
    removeAvailableOnboarding: (state, { payload: onboarding }: PayloadAction<OnboardingNames>) => {
      state.availableOnboardings = state.availableOnboardings.filter((s) => s !== onboarding);
    },
  },

  extraReducers(builder) {
    builder.addMatcher(userPropertyApi.endpoints.getAllUserProperties.matchFulfilled, (state, action) => {
      const userPropertyKeys = action.payload;

      if (!userPropertyKeys.includes(UserPropertyKeys.onboarding)) {
        state.userOnboardings = onboardingNamesArray.reduce((acc, onboardingName) => ({
          ...acc,
          [onboardingName]: false,
        }), {} as OnboardingState['userOnboardings']);
      }
    });

    builder.addMatcher(userPropertyApi.endpoints.getUserProperty.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.propertyKey !== UserPropertyKeys.onboarding) {
        return;
      }
      const userOnboardings = action.payload as UserOnboardingConfig;
      state.userOnboardings = onboardingNamesArray.reduce((acc, onboardingName) => ({
        ...acc,
        [onboardingName]: Boolean(userOnboardings && userOnboardings[onboardingName]),
      }), {} as OnboardingState['userOnboardings']);
    });
  },
});

export const {
  setShouldShowViewed,
  resetActiveTarget,
  addAvailableOnboarding,
  removeAvailableOnboarding,
  setNextSpotlightTarget,
} = onboardingSlice.actions;