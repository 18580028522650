import { pluginBaseApi } from 'store/api/plugin/baseApi/pluginBaseApi';
import { useCallback } from 'react';
import { getQueryParam } from 'utils/getQueryParam';

import { ShareableLink } from 'sharedFrontBack/src/types/report';

export const shareableLinkApi = pluginBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    createShareableLink: builder.mutation<ShareableLink, string | number>({
      query: (reportId) => ({
        url: `reports/${reportId}/create-shared-link`,
        method: 'PUT',
      }),
      transformResponse: (rawResult: { shareableLink: string }) => {
        const sharedId = getQueryParam(rawResult.shareableLink, 'sharedId');
        return {
          permissionType: 'view' as const,
          shareableLink: rawResult.shareableLink,
          sharedIdHash: sharedId,
        };
      },
    }),
    removeShareableLink: builder.mutation<void, string | number>({
      query: (reportId) => ({
        url: `reports/${reportId}/shared-link`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateShareableLinkMutation,
  useRemoveShareableLinkMutation,
} = shareableLinkApi;

export const useShareableLinkApi = () => {
  const [createShareableLink] = useCreateShareableLinkMutation();
  const [removeShareableLink] = useRemoveShareableLinkMutation();

  const handleCreateShareableLink = useCallback((reportId: string | number) => {
    createShareableLink(reportId);
  }, [createShareableLink]);

  const handleRemoveShareableLink = useCallback((reportId: string | number) => {
    removeShareableLink(reportId);
  }, [removeShareableLink]);

  return {
    createShareableLink: handleCreateShareableLink,
    removeShareableLink: handleRemoveShareableLink,
  };
};