import React, { useMemo } from 'react';
import {
  Pivotopia,
  getCalculationOptions,
  CalculationCapabilities,
  MeasureDefinition,
  DimensionDefinition,
  JiraIssueFieldMeta,
} from 'sharedFrontBack/src/singletonModules/pivotopia';
import { useGetJiraFieldsQuery } from 'store/api/jira/jiraFieldsApi';
import { ReactFC } from 'types/Global';

export interface PivotopiaContextValue {
  pivotopia: undefined | Pivotopia;
  allJiraFields?: JiraIssueFieldMeta[] | undefined;
  isFetching: boolean;
  getCapabilities: undefined | (() => CalculationCapabilities);
  getMeasureDefinitionsMap: undefined | (() => { [p: string]: MeasureDefinition });
  getDimensionDefinitionsMap: undefined | (() => { [p: string]: DimensionDefinition });
}

const initialContextValue = {
  pivotopia: undefined,
  isFetching: true,
  getCapabilities: undefined,
  getMeasureDefinitionsMap: undefined,
  getDimensionDefinitionsMap: undefined,
};

export const PivotopiaContext = React.createContext<PivotopiaContextValue>(initialContextValue);

export const PivotopiaContextProvider: ReactFC = ({ children }) => {
  const { data: allJiraFields, isFetching } = useGetJiraFieldsQuery();

  const value = useMemo(() => {
    if (allJiraFields) {
      const pivotopia = new Pivotopia(getCalculationOptions(allJiraFields));

      let cachedCapabilities: CalculationCapabilities;
      const getCapabilities = () => {
        if (!cachedCapabilities) {
          cachedCapabilities = pivotopia.getCalculationCapabilities();
        }

        return cachedCapabilities;
      };

      let cachedMeasureDefinitionsMap: { [p: string]: MeasureDefinition };
      const getMeasureDefinitionsMap = () => {
        if (!cachedMeasureDefinitionsMap) {
          cachedMeasureDefinitionsMap = getCapabilities().measures.reduce((acc, definition) => {
            return {
              ...acc,
              [definition.name]: definition,
            };
          }, {});
        }

        return cachedMeasureDefinitionsMap;
      };

      let cachedDimensionDefinitionsMap: { [p: string]: DimensionDefinition };
      const getDimensionDefinitionsMap = () => {
        if (!cachedDimensionDefinitionsMap) {
          cachedDimensionDefinitionsMap = getCapabilities().dimensions.reduce((acc, definition) => {
            return {
              ...acc,
              [definition.name]: definition,
            };
          }, {});
        }

        return cachedDimensionDefinitionsMap;
      };

      return {
        pivotopia,
        allJiraFields,
        isFetching,
        getCapabilities,
        getMeasureDefinitionsMap,
        getDimensionDefinitionsMap,
      };
    }
    return initialContextValue;
  }, [allJiraFields, isFetching]);

  if (RUNTIME_ENV === 'development') {
    window.pivotopiaContext = value;
  }

  return (
    <PivotopiaContext.Provider value={value}>
      {children}
    </PivotopiaContext.Provider>
  );
};