import { css } from 'styled-components5';

export const globalPrintStyles = css`
  @media print {

    @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings and hides page meta (page number, url) */
    }

    body {
      width: calc(100% - 64px);
      margin: 32px auto;
    }
  }
`;
