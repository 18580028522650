import types from 'store/legacy/configuration/types';

export const getConfigurationStarted = () => ({ type: types.GET_CONFIGURATION_STARTED });

export const getConfigurationSuccess = ({ general, permissions, actonicPermissions, actonicTeamsListNames }) => ({
  type: types.GET_CONFIGURATION_SUCCESS,
  payload: { general, permissions, actonicPermissions, actonicTeamsListNames },
});

export const getConfigurationFailure = (error) => ({
  type: types.GET_CONFIGURATION_FAILURE,
  error,
});

export const setPermissionsAction = ({ id, permissions }) => ({
  type: types.SET_PERMISSIONS,
  payload: {
    id,
    permissions,
  },
});

export const setCurrentTab = (currentTab) => ({
  type: types.SET_CONFIGURATION_CURRENT_TAB,
  payload: {
    currentTab,
  },
});
