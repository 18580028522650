import { getBulkUsers } from 'controllers/transport/requestConfigurations/getBulkUsers';
import {
  searchUsers,
} from 'controllers/transport/requestConfigurations';
import { API } from 'controllers/API';
import { createUserUrl } from 'utils/jiraUrl';
import { promiseAllWithConcurrency } from 'utils/promiseAllWithConcurrency';
import { TransportController as Transport } from 'controllers/transport';

class UsersController {
  _usersCache = [];

  _queryCache = {};

  _fetchUsersByQuery = async (query, limit = 25) => {
    const configWithLimit = {
      ...searchUsers,
      cloud: {
        ...searchUsers.cloud,
        maxResults: limit,
      },
      server: {
        ...searchUsers.server,
        maxResults: limit,
      },
    };

    const data = await Transport.request({ config: configWithLimit, query }).catch((error) => {
      console.error('[store.filterBySelector.actions][getDataForFilters] Error while getting users', error);
    }) || [];

    this._queryCache[`${query}-${limit}`] = data;

    return data;
  };

  _fetchUsersByIds = async (usersIds) => {
    if (process.env.JIRA_TYPE === 'cloud') {
      const usersChunks = [];

      if (usersIds.length > 0) {
        for (let i = 0; i < usersIds.length; i += 5) {
          const currentChunkUsers = usersIds.slice(i, i + 5);

          const query = `?${currentChunkUsers.reduce(
            (acc, currentId) => {
              acc += `accountId=${currentId}&`;

              return acc;
            },
            '',
          )}`;

          usersChunks.push(query);
        }
      }

      const getUsersFunc = (usersQuery) => async () => {
        const data = await Transport.request({
          config: getBulkUsers,
          query: usersQuery,
        });

        return data;
      };

      const tasks = usersChunks.map((usersQuery) => getUsersFunc(usersQuery));

      const fetchedUsers = (await promiseAllWithConcurrency(tasks, 6))
        .reduce(
          (acc, currentChunk) => {
            acc.push(...(currentChunk.map(({ accountId, key, displayName, avatarUrls, name }) => {
              const url = createUserUrl(accountId ? accountId : name);

              return {
                accountId: accountId ? accountId : key,
                displayName,
                imageUrl: avatarUrls['32x32'],
                url,
                name,
              };
            })));

            return acc;
          },
          [],
        );

      this._usersCache.push(...fetchedUsers);

      return fetchedUsers;
    }

    const { data: fetchedUsers } = await API.post('/custom/users', { keys: usersIds });

    this._usersCache.push(...fetchedUsers);

    return fetchedUsers;
  };

  getUsersByIds = async (usersIds) => {
    const cachedUsersIdsMap = this._usersCache.reduce(
      (acc, currentUser) => {
        acc[currentUser.accountId] = true;

        return acc;
      },
      {},
    );

    const notCachedUsersIds = usersIds.filter((userId) => !cachedUsersIdsMap[userId]);

    if (notCachedUsersIds.length > 0) {
      await this._fetchUsersByIds(notCachedUsersIds);
    }

    const usersIdsMap = usersIds.reduce(
      (acc, current) => {
        acc[current] = true;

        return acc;
      },
      {},
    );

    return this._usersCache.filter(({ accountId, key }) => usersIdsMap[accountId] || usersIdsMap[key]);
  };

  getUsersByQuery = async (query, limit = 25) => {
    if (this._queryCache[`${query}-${limit}`]) {
      return this._queryCache[`${query}-${limit}`];
    }

    return await this._fetchUsersByQuery(query, limit);
  };
}

const UsersCotrollerSignletone = new UsersController();

export default UsersCotrollerSignletone;
