import { REPORTS_QUERY_KEY } from 'constants/constants';
import useGetQueryParam from 'hooks/useGetQueryParam';

const usePageFromQuery = (): number => {
  const getQueryParam = useGetQueryParam();
  const pageQuery = Number(getQueryParam(REPORTS_QUERY_KEY.page));

  return pageQuery > 0 ? pageQuery - 1 : 0;
};

export default usePageFromQuery;
