import { useAccountIdOrUsername } from 'store/hooks/useAccountIdOrUsername';
import { useGetUser } from 'store/hooks/useGetUser';

export const useCurrentUser = () => {
  const accountIdOrUsername = useAccountIdOrUsername();

  const userData = {
    ...(IS_CLOUD ? { accountId: accountIdOrUsername } : { username: accountIdOrUsername }),
  };

  return useGetUser(userData);
};