import axios from 'axios';
import { TransportController as Transport } from 'controllers/index';
import { getLogs } from 'controllers/transport/requestConfigurations';
import store from 'store';
import { getContextPath } from 'utils/getContextPath';

class FieldController {
  // eslint-disable-next-line no-shadow
  constructor({ transport, store }) {
    this.transport = transport;
    this.store = store;
  }

  _getWorklogs = async (issue, currentReportId) => {
    const { loadingStartedProcessId } = this.store.getState().progressBar;

    if (loadingStartedProcessId !== currentReportId) {
      return;
    }

    return await this.transport.request({
      config: getLogs,
      query: `${issue.id}/worklog?expand=properties`,
    });
  };

  _getWorklogsWithTBProperty = async (issue, currentReportId) => {
    const isCloud = process.env.JIRA_TYPE === 'cloud';
    const { loadingStartedProcessId } = this.store.getState().progressBar;

    if (loadingStartedProcessId !== currentReportId) {
      return;
    }

    return isCloud
      ? (
        await this.transport.request({
          config: getLogs,
          query: `${issue.id}/worklog?expand=properties`,
        }))
      : (
        await axios.create({
          baseURL: `${getContextPath()}/rest/actonic-tb/1.0/api`,
          timeout: 900000,
        }).get(`/worklogs/issue/${issue.key}`)).data.worklogs;
  };
}

const FieldControllerClass = FieldController;

let fieldControllerInstance;
const fieldControllerService = () => {
  if (!fieldControllerInstance) {
    if (!store) {
      throw new Error('Store service is not ready yet');
    }
    if (!Transport) {
      throw new Error('Transport service is not ready yet');
    }
    fieldControllerInstance = new FieldController({ transport: Transport, store });
  }

  return fieldControllerInstance;
};

export { FieldControllerClass, fieldControllerService };
