import { requestFunction } from 'utils/requestFunction';
import { ResponseBody } from 'store/api/jira/baseApi/types';

interface Params {
  type: Parameters<typeof window.AP.request>[0]['type'];
  url: string;
  data?: Record<string, unknown>;
  headers?: Record<string, unknown>;
}

export const request = async <
  ResponseJson extends ResponseBody | void,
  AsJson extends boolean = boolean,
  ResponseJsonNotVoid = ResponseJson extends void ? never : ResponseJson,
  Data = AsJson extends true ? ResponseJsonNotVoid : string,
>({ data, ...params }: Params, asJson: AsJson): Promise<Data> => {
  const response = await requestFunction({
    ...params,
    ...(data && {
      data: (
        IS_CLOUD ? new Blob([JSON.stringify(data)], { type: 'application/json' }) : JSON.stringify(data)
      ),
    }),
  }) as (undefined | { body?: string });

  if (asJson) {
    if (IS_CLOUD) {
      return (response?.body ? JSON.parse(response.body) : null) as Data;
    }
    return response as Data;
  }

  return (IS_CLOUD ? (response?.body ?? '') : JSON.stringify(response)) as Data;
};