import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeRouteWithParams } from 'utils/changeRoute';
import { getQueryParams } from 'utils/getQueryParams';
import { QueryParams } from 'types/queryParams';
import RoutePaths from 'router/routePaths';
import { ValueOf } from 'types/Global';

const useChangePage = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const qs = getQueryParams(search);

  const changePage = useCallback(
    (path: ValueOf<typeof RoutePaths>, params?: QueryParams) => changeRouteWithParams(path, navigate, params),
    [navigate],
  );

  const changePageQuery = useCallback(
    (params: QueryParams) => changeRouteWithParams(pathname, navigate, { ...qs, ...params }),
    [navigate, pathname, qs],
  );

  return { changePage, changePageQuery };
};

export default useChangePage;
