import { formatCalendarData } from 'controllers/WorklogController/formatCalendarData';
import { formatWorklogData } from 'controllers/WorklogController/formatWorklogData';

export class WorklogController {
  worklogs;

  constructor(worklogs = []) {
    this.worklogs = worklogs;
  }

  static formatWorklogsForTable = (issues, formatOptions, numberCustomFieldsOptions, fieldDataByFieldId) => issues.reduce(
    (acc, issue) => {
      const worklogs = issue.fields?.worklog?.worklogs?.worklogs || issue.fields?.worklog?.worklogs;
      worklogs?.forEach((worklog) => {
        acc.push(formatWorklogData(issue, worklog, formatOptions, numberCustomFieldsOptions, fieldDataByFieldId));
      });

      return acc;
    },
    [],
  );

  static formatWorklogsForCalendar = (issues, formatOptions) => issues.reduce(
    (acc, issue) => {
      const worklogs = issue.fields?.worklog?.worklogs?.worklogs || issue.fields?.worklog?.worklogs;
      worklogs?.forEach((worklog) => {
        acc.push(formatCalendarData(issue, worklog, formatOptions));
      });

      return acc;
    },
    [],
  );

  getFormattedWorklogsFromIssues = (
    issues,
    formatter,
    formatOptions = {},
    numberCustomFieldsOptions = [],
    fieldDataByFieldId = {},
  ) => {
    this.worklogs = formatter(issues, formatOptions, numberCustomFieldsOptions, fieldDataByFieldId);
  };

  filter = (filterParams) => {
    const { start, end } = filterParams.period || {};

    const startDateInMiliseconds = start ? new Date(start).getTime() : null;
    const endDateInMiliseconds = end ? new Date(end).setDate(new Date(end).getDate()) : null;

    const { users = [] } = filterParams;

    const resultWorklogs = [];

    for (const worklog of this.worklogs) {
      const { author, started } = worklog;

      let shouldBeIncludedInResult = true;

      if (startDateInMiliseconds && endDateInMiliseconds) {
        shouldBeIncludedInResult = (new Date(started).getTime() - startDateInMiliseconds >= 0)
          && (new Date(started).getTime() - endDateInMiliseconds <= 0);
      }

      if (shouldBeIncludedInResult && users.length > 0 && author) {
        const worklogAuthorId = process.env.JIRA_TYPE === 'cloud'
          ? author.accountId
          : author.key;

        shouldBeIncludedInResult = users.some((userId) => worklogAuthorId === userId);
      }

      if (shouldBeIncludedInResult) {
        resultWorklogs.push(worklog);
      }
    }

    return resultWorklogs;
  };

  sort = (field = 'started', order = 'asc') => {
    if (!this.worklogs || !Array.isArray(this.worklogs) || this.worklogs.length === 0) {
      console.error('[WorklogController][sort] worklogs list is empty');
      return [];
    }

    if (!field || typeof field !== 'string' || !this.worklogs[0][field]) {
      console.error('[WorklogController][sort] field is undefined');
      return this.worklogs;
    }

    if (typeof this.worklogs[0][field] === 'string') {
      if (Date.parse(this.worklogs[0][field])) {
        return this.worklogs.sort((a, b) => (
          order.toLowerCase() === 'desc'
            ? new Date(b[field]) - new Date(a[field])
            : new Date(a[field]) - new Date(b[field])
        ));
      }

      return this.worklogs.sort((a, b) => (
        order.toLowerCase() === 'desc'
          ? b.localeCompare(a)
          : a.localeCompare(b)
      ));
    }

    return this.worklogs.sort((a, b) => (
      order.toLowerCase() === 'desc'
        ? b[field] - a[field]
        : a[field] - b[field]
    ));
  };
}
