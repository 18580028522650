import { createIssueUrl } from 'utils/jiraUrl';
import { formatDateForDateGroup } from 'utils/dates/formatDates';

export const getGroupTypeByGroupName = (groupName) => {
  const mapGroupNameToGroupType = {
    project: 'singleGroup',
    user: 'singleGroup',
    epic: 'singleGroup',
    issue: 'singleGroup',
    worklog: 'singleGroup',
    priority: 'singleGroup',
    status: 'singleGroup',
    issueType: 'singleGroup',
    assignee: 'singleGroup',
    reporter: 'singleGroup',
    dueDate: 'singleGroup',
    created: 'singleGroup',
    summary: 'singleGroup',
    resolved: 'singleGroup',
    worklogAuthor: 'singleGroup',
    resolution: 'singleGroup',
    sprint: 'singleGroup',
    dateUpdated: 'singleGroup',
    updated: 'singleGroup',

    worklogWeek: 'singleGroup',
    worklogMonth: 'singleGroup',
    worklogQuarter: 'singleGroup',
    worklogYear: 'singleGroup',

    component: 'multipleGroup',
    fixVersion: 'multipleGroup',
    label: 'multipleGroup',
    versions: 'multipleGroup',

    dateGroup: 'dateGroup',
    worklogDate: 'dateGroup',

    urgency: 'singleGroup',
    pendingReason: 'singleGroup',
    productCategorization: 'singleGroup',
    operationalCategorization: 'singleGroup',
    impact: 'singleGroup',
    approvers: 'multipleGroup',
    requestParticipants: 'multipleGroup',
    organizations: 'multipleGroup',
  };

  if (mapGroupNameToGroupType[groupName]) {
    return mapGroupNameToGroupType[groupName];
  }
};

export const getDataForGroup = (serviceData, optionalData) => {
  const { currentLog, groupName, serviceVariables, keyForCustomGroup } = serviceData;
  const { isIncludesSubtask, dateFormat, fieldTypesByNames } = optionalData;

  if (fieldTypesByNames[groupName]) {
    const fieldValue = currentLog[groupName];

    return {
      [groupName]: {
        key: keyForCustomGroup ? keyForCustomGroup : fieldValue,
        name: keyForCustomGroup ? keyForCustomGroup : fieldValue,
        description: keyForCustomGroup ? keyForCustomGroup : fieldValue,
        icon: null,
        url: null,
        ...serviceVariables,
      },
    };
  }

  return {
    project: {
      key: currentLog.projectKey,
      name: currentLog.projectName,
      description: currentLog.projectName,
      icon: currentLog.projectIcon,
      url: currentLog.projectUrl,
      ...serviceVariables,
    },
    sprint: {
      key: currentLog.sprintName,
      name: currentLog.sprintName,
      description: currentLog.sprintName,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogWeek: {
      key: currentLog.worklogWeekWithYear,
      name: `${currentLog.worklogWeek}`,
      description: `${currentLog.worklogWeek}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogMonth: {
      key: currentLog.worklogMonthWithYear,
      name: `${currentLog.worklogMonth}`,
      description: `${currentLog.worklogMonth}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogQuarter: {
      key: currentLog.worklogQuarterWithYear,
      name: `${currentLog.worklogQuarter}`,
      description: `${currentLog.worklogQuarter}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogYear: {
      key: currentLog.worklogYear,
      name: `${currentLog.worklogYear}`,
      description: `${currentLog.worklogYear}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    user: {
      key: currentLog.worklogAuthorId,
      name: currentLog.worklogAuthor,
      icon: currentLog.worklogAuthorIcons ? currentLog.worklogAuthorIcons['16x16'] : null,
      url: currentLog.worklogAuthorUrl,
      ...serviceVariables,
    },
    // TODO: remove after deletion general pivot report, key should be formatted in formatTableData or generalPivotMigration
    // because of links creation mechanism
    dateUpdated: {
      key: formatDateForDateGroup(currentLog.updated, dateFormat),
      name: formatDateForDateGroup(currentLog.updated, dateFormat),
      icon: null,
      url: null,
      ...serviceVariables,
    },
    updated: {
      key: currentLog.formattedUpdated,
      name: currentLog.formattedUpdated,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    epic: {
      key: currentLog.epicKey,
      name: currentLog.epicName,
      description: currentLog.epicName,
      icon: currentLog.epicIcon,
      url: currentLog.epicLink,
      ...serviceVariables,
    },
    component: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      description: keyForCustomGroup,
      icon: currentLog.issueIcon,
      url: currentLog.projectUrl,
      ...serviceVariables,
    },
    fixVersion: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      description: keyForCustomGroup,
      icon: currentLog.issueIcon,
      url: currentLog.projectUrl,
      ...serviceVariables,
    },
    issue: {
      key: (isIncludesSubtask && currentLog.parent)
        ? currentLog.parentKey
        : currentLog.issueKey,
      name: (isIncludesSubtask && currentLog.parent)
        ? currentLog.parentName
        : currentLog.issueName,
      description: (isIncludesSubtask && currentLog.parent)
        ? currentLog.parentName
        : currentLog.issueName,
      icon: currentLog.issueIcon,
      url: (isIncludesSubtask && currentLog.parent)
        ? currentLog.parentUrl
        : currentLog.issueUrl,

      parentKey: currentLog.parentData && currentLog.parentData.key,
      parentUrl: currentLog.parentData && createIssueUrl(currentLog.parentData.key),
      parentIcon: currentLog.parentIcon && currentLog.parentIcon,
      ...serviceVariables,
    },
    dateGroup: {
      key: formatDateForDateGroup(currentLog.startDate, dateFormat),
      name: formatDateForDateGroup(currentLog.startDate, dateFormat),
      description: formatDateForDateGroup(currentLog.startDate, dateFormat),
      icon: '',
      url: currentLog.issueUrl,
      ...serviceVariables,
    },
    worklog: {
      key: currentLog.worklogId,
      name: currentLog.worklogDescription,
      description: currentLog.worklogDescription,
      icon: currentLog.issueIcon,
      url: currentLog.issueUrl,
      ...serviceVariables,
    },
    label: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      description: keyForCustomGroup,
      icon: currentLog.issueIcon,
      url: currentLog.projectUrl,
      ...serviceVariables,
    },
    status: {
      key: currentLog.issueStatus,
      name: currentLog.issueStatus,
      description: currentLog.issueStatus,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    issueType: {
      key: currentLog.issueType,
      name: currentLog.issueType,
      description: currentLog.issueType,
      icon: currentLog.issueTypeUrl,
      url: null,
      ...serviceVariables,
    },
    priority: {
      key: currentLog.priority,
      name: currentLog.priority,
      description: currentLog.priority,
      icon: currentLog.priorityUrl,
      url: null,
      ...serviceVariables,
    },
    assignee: {
      key: currentLog.issueAssigneeId,
      name: `Assignee: ${currentLog.issueAssignee}`,
      icon: currentLog.issueAssigneeIcons ? currentLog.issueAssigneeIcons['16x16'] : null,
      url: currentLog.issueAssigneeUrl,
      ...serviceVariables,
    },
    reporter: {
      key: currentLog.issueReporterId,
      name: `Reporter: ${currentLog.issueReporter}`,
      icon: currentLog.issueReporterIcons ? currentLog.issueReporterIcons['16x16'] : null,
      url: currentLog.issueReporterUrl,
      ...serviceVariables,
    },
    dueDate: {
      key: currentLog.dueDate,
      name: `Due Date: ${currentLog.dueDate}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    created: {
      key: currentLog.created,
      name: `Created: ${currentLog.created}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    resolution: {
      key: currentLog.resolution,
      name: `Resolution: ${currentLog.resolution}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    versions: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      description: keyForCustomGroup,
      icon: null,
      url: null,
      ...serviceVariables,
    },

    summary: {
      key: currentLog.issueName,
      name: currentLog.issueName,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    resolved: {
      key: currentLog.resolvedDate,
      name: currentLog.resolvedDate,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogAuthor: {
      key: currentLog.worklogAuthorId,
      name: currentLog.worklogAuthor,
      icon: currentLog.worklogAuthorIcons ? currentLog.worklogAuthorIcons['16x16'] : null,
      url: currentLog.worklogAuthorUrl,
      ...serviceVariables,
    },
    worklogDate: {
      key: formatDateForDateGroup(currentLog.startDate, dateFormat),
      name: formatDateForDateGroup(currentLog.startDate, dateFormat),
      icon: null,
      url: null,
      ...serviceVariables,
    },

    urgency: {
      key: currentLog.urgency,
      name: currentLog.urgency,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    pendingReason: {
      key: currentLog.pendingReason,
      name: currentLog.pendingReason,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    productCategorization: {
      key: currentLog.productCategorization,
      name: currentLog.productCategorization,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    operationalCategorization: {
      key: currentLog.operationalCategorization,
      name: currentLog.operationalCategorization,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    impact: {
      key: currentLog.impact,
      name: currentLog.impact,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    approvers: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    requestParticipants: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    organizations: {
      key: keyForCustomGroup,
      name: keyForCustomGroup,
      icon: null,
      url: null,
      ...serviceVariables,
    },
  };
};
