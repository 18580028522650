import types from 'store/legacy/user/actionTypes';

export const getUserDataStarted = () => ({ type: types.GET_USER_DATA_STARTED });
export const getUserDataSuccess = ({ payload }) => ({
  type: types.GET_USER_DATA_SUCCESS,
  payload,
});

export const getUserIssuesTypesSuccess = ({ issueTypes }) => ({
  type: types.GET_USER_ISSUE_TYPES_SUCCESS,
  payload: { issueTypes },
});
export const getUserIssuesTypesFailure = (error) => ({
  type: types.GET_USER_ISSUE_TYPES_FAILURE,
  error,
});
