import styled from 'styled-components5';
import { token } from '@atlaskit/tokens';

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  position: absolute;
  top: ${(props) => props.top || '7%'};
  left: ${(props) => props.left || '30%'};
  z-index: 10;
  background: ${token('utility.elevation.surface.current')};
`;

export const ProgressBarLoaderContainer = styled.div`
  width: 500px;
`;

export const ProgressBarInfo = styled.div`
  position: relative;
  width: 300px;
`;

export const ProgressBarInfoTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

export const ProgressBarInfoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -25px;
`;

export const ProgressBarContentSizeWarning = styled.div`
  display: flex;
  margin: 15px 0;
`;

export const ProgressBarIconBlock = styled.div`
  width: 30px;
`;

export const ProgressBarInfoBody = styled.div`
  margin: 15px 0;
`;

export const ProgressBarActionButtonContainer = styled.span`
  width: 300px;
`;

export const ProgressBarActionButton = styled.span`
  font-weight: bold;
  color: #147cd8;
  cursor: pointer;
`;
