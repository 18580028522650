import { createQueryString } from 'utils/createQueryString';

const withDebug = process.env.NODE_ENV === 'development';

export const changeRoute = (path, navigate) => {
  navigate(path);
};

export const changeRouteWithParams = (path, navigate, params) => {
  const paramsAsString = createQueryString(params);
  // eslint-disable-next-line no-console
  withDebug && console.log('changing route via navigate', path, paramsAsString);

  navigate(`${path}${paramsAsString}`);
};
