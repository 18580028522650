import { setGlobalTheme, useThemeObserver } from '@atlaskit/tokens';
import { PaginatedRequestContextProvider } from 'contexts/PaginatedRequestContext';
import { PivotopiaContextProvider } from 'contexts/PivotopiaContext';
import { usePrevious } from 'hooks';
import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { createHashHistory } from 'history';
import {
  SpotlightManager,
} from '@atlaskit/onboarding';
import { GlobalStyle } from 'globalStyle';
import { PrintContextProvider } from 'features/print/components/PrintContextProvider';

import { ErrorBoundaryWrapper } from 'components/ErrorBoundary';
import { useAdminConfig } from 'store/slices/adminConfig/useAdminConfig';

import store from 'store';
import AppRoutes from 'router/AppRoutes';
import { Main } from 'components/App/Main/Main';
import { initHotjar } from 'utils/hotjar';
import { initSentry } from 'utils/sentry';
import { FlagsProvider } from '@atlaskit/flag';
import { initAmplitude } from 'features/amplitude/utils/amplitude';
import FlagManager from '../FlagManager';
import { AppStyledRoot } from './styled';

const history = createHashHistory({});

const useInitialThemeFixer = () => {
  const { colorMode } = useThemeObserver();

  const prevColorMode = usePrevious(colorMode);
  useEffect(() => {
    if (colorMode && colorMode === prevColorMode) {
      return;
    }
    // on the DC there is "original" theme, that does not provide atlaskit/token values
    // but useThemeObserver provides "light" colorMode when user actually uses "original" theme
    // so, call of setGlobalTheme makes things work as expected
    setGlobalTheme({
      colorMode: colorMode ?? 'light', // DC Jira 9 does not have colorMode, but we need to set something to bring token values
      spacing: 'spacing',
    });
  }, [colorMode, prevColorMode]);
};

const AppInner = () => {
  const { data: { telemetry: { isSentryEnabled, isAmplitudeEnabled, isHotjarEnabled } } } = useAdminConfig();

  useInitialThemeFixer();

  useEffect(() => {
    if (!IS_CLOUD && isSentryEnabled) {
      initSentry();
    }
  }, [isSentryEnabled]);

  useEffect(() => {
    if (isAmplitudeEnabled) {
      initAmplitude();
    }
  }, [isAmplitudeEnabled]);

  useEffect(() => {
    if (isHotjarEnabled && !window.ActonicRB?.isIssuePanel) {
      initHotjar();
    }
  }, [isHotjarEnabled]);

  return (
    <Main>
      <SpotlightManager>
        <FlagManager />
        <AppRoutes history={history} />
      </SpotlightManager>
    </Main>
  );
};

export const App = () => {
  return (
    <AppStyledRoot>
      <GlobalStyle />
      <ErrorBoundaryWrapper>
        <StoreProvider store={store}>
          <PrintContextProvider>
            <PivotopiaContextProvider>
              <PaginatedRequestContextProvider>
                <FlagsProvider>
                  <AppInner />
                </FlagsProvider>
              </PaginatedRequestContextProvider>
            </PivotopiaContextProvider>
          </PrintContextProvider>
        </StoreProvider>
      </ErrorBoundaryWrapper>
    </AppStyledRoot>
  );
};