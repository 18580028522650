export enum OnboardingNames {
  startPage = 'start-page', // value for backward-compatibility
  general = 'reports', // value for backward-compatibility
  universalReport = 'universalReport',
  srGeneral = 'srGeneral',
  srPermission = 'srPermission',
  srTemplate = 'srTemplate',
  srCode = 'srCode',
  srDevServer = 'srDevServer',
}

export enum SharedSpotlightTargets {
  introduction = 'introduction',
  reportName = 'reportName',
  srTabs = 'srTabs',
  srOtherTabs = 'srOtherTabs',
  srEditor = 'srEditor',
  srEditorBeautifyButton = 'srEditorBeautifyButton',
}

export enum GeneralSpotlightTargets {
  introduction = SharedSpotlightTargets.introduction,
  homeButton = 'homeButton',
  createReportButton = 'createReportButton',
  reportsButton = 'reportsButton',
  helpButton = 'helpButton',
  navigationTabs = 'navigationTabs',
  templatesFiltering = 'templatesFiltering',
  templatesFavorites = 'templatesFavorites',
  templatesGrouping = 'templatesGrouping',
  templatesOptions = 'templatesOptions',
  universalReport = 'universalReport',
  scriptedReports = 'scriptedReports',
}

export enum UniversalReportSpotlightTargets {
  introduction = SharedSpotlightTargets.introduction,
  reportName = SharedSpotlightTargets.reportName,
  saveButton = 'saveButton',
  demoData = 'demoData',
  whatToSearch = 'whatToSearch',
  searchFilters = 'searchFilters',
  visualizationType = 'visualizationType',
  rowsAndColumns = 'rowsAndColumns',
  measurementRules = 'measurementRules',
  optionalConfiguration = 'optionalConfiguration',
  reportReady = 'reportReady',
}

export enum SrGeneralSpotlightTargets {
  introduction = SharedSpotlightTargets.introduction,
  reportName = SharedSpotlightTargets.reportName,
  srTabs = SharedSpotlightTargets.srTabs,
  inputFieldType = 'inputFieldType',
  inputFieldButton = 'inputFieldButton',
  metaFields = 'metaFields',
  srOtherTabs = SharedSpotlightTargets.srOtherTabs,
}

export enum SrPermissionSpotlightTargets {
  introduction = SharedSpotlightTargets.introduction,
  owner = 'owner',
  issueExport = 'issueExport',
  permissionsBlock = 'permissionsBlock',
  shareableLink = 'shareableLink',
  srOtherTabs = SharedSpotlightTargets.srOtherTabs,
}

export enum SrTemplateSpotlightTargets {
  srEditor = SharedSpotlightTargets.srEditor,
  srEditorBeautifyButton = SharedSpotlightTargets.srEditorBeautifyButton,
}

export enum SrCodeSpotlightTargets {
  srEditor = SharedSpotlightTargets.srEditor,
  srEditorBeautifyButton = SharedSpotlightTargets.srEditorBeautifyButton,
}

export enum SrDevServerSpotlightTargets {
  options = 'options',
  repositotyLink = 'repositotyLink',
  reportUrl = 'reportUrl',
  hotRerender = 'hotRerender',
}

export const onboardingTargets = {
  [OnboardingNames.startPage]: undefined,
  [OnboardingNames.general]: GeneralSpotlightTargets,
  [OnboardingNames.universalReport]: UniversalReportSpotlightTargets,
  [OnboardingNames.srGeneral]: SrGeneralSpotlightTargets,
  [OnboardingNames.srPermission]: SrPermissionSpotlightTargets,
  [OnboardingNames.srTemplate]: SrTemplateSpotlightTargets,
  [OnboardingNames.srCode]: SrCodeSpotlightTargets,
  [OnboardingNames.srDevServer]: SrDevServerSpotlightTargets,
};

export type SpotlightTargets = (
  SharedSpotlightTargets
  | GeneralSpotlightTargets
  | UniversalReportSpotlightTargets
  | SrGeneralSpotlightTargets
  | SrPermissionSpotlightTargets
  | SrTemplateSpotlightTargets
  | SrCodeSpotlightTargets
  | SrDevServerSpotlightTargets
);