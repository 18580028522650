import { createIssueUrl } from 'utils/jiraUrl';
import { formatDateForDateGroup } from 'utils/dates/formatDates';

export const getDataForGroup = (serviceData, optionalData) => {
  const {
    currentLog, groupName, serviceVariables,
    keyForCustomGroup, nameForCustomGroup, urlForCustomGroup, iconForCustomGroup,
    typeByFieldId,
  } = serviceData;
  const { dateFormat } = optionalData;

  if (typeByFieldId && typeByFieldId[groupName] && groupName !== 'worklog') {
    const fieldName = `${groupName}-name`;
    const fieldUrl = `${groupName}-url`;
    const fieldIcon = `${groupName}-icon`;

    // This data related to getting info with dynamic fields
    const fieldValue = currentLog[groupName];
    const nameForField = currentLog[fieldName];
    const urlForField = currentLog[fieldUrl];
    const iconForField = currentLog[fieldIcon];

    const isMultipleGroup = !!keyForCustomGroup;

    const key = isMultipleGroup ? keyForCustomGroup : fieldValue;

    const name = (() => {
      if (nameForCustomGroup) return nameForCustomGroup;

      if (isMultipleGroup) return keyForCustomGroup;

      return nameForField ? nameForField : fieldValue;
    })();

    const icon = (() => {
      if (iconForCustomGroup) return iconForCustomGroup;

      return iconForField ? iconForField : null;
    })();

    const url = (() => {
      if (groupName === 'summary') return currentLog.issueUrl;

      if (urlForCustomGroup) return urlForCustomGroup;

      return urlForField ? urlForField : null;
    })();

    return {
      [groupName]: {
        key,
        name,
        description: name,
        icon,
        url,
        ...serviceVariables,
      },
    };
  }

  return {
    worklogAuthor: {
      key: currentLog.worklogAuthorId,
      name: currentLog.worklogAuthor,
      icon: currentLog.worklogAuthorIcons ? currentLog.worklogAuthorIcons['16x16'] : null,
      url: currentLog.worklogAuthorUrl,
      ...serviceVariables,
    },
    worklogDate: {
      key: formatDateForDateGroup(currentLog.startDate, dateFormat),
      name: formatDateForDateGroup(currentLog.startDate, dateFormat),
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogWeek: {
      key: currentLog.worklogWeekWithYear,
      name: `${currentLog.worklogWeek}`,
      description: `${currentLog.worklogWeek}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogMonth: {
      key: currentLog.worklogMonthWithYear,
      name: `${currentLog.worklogMonth}`,
      description: `${currentLog.worklogMonth}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogQuarter: {
      key: currentLog.worklogQuarterWithYear,
      name: `${currentLog.worklogQuarter}`,
      description: `${currentLog.worklogQuarter}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    worklogYear: {
      key: currentLog.worklogYear,
      name: `${currentLog.worklogYear}`,
      description: `${currentLog.worklogYear}`,
      icon: null,
      url: null,
      ...serviceVariables,
    },
    issue: {
      key: currentLog.issueKey,
      name: currentLog.issueName,
      description: currentLog.issueName,
      icon: currentLog.issueIcon,
      url: currentLog.issueUrl,

      parentKey: currentLog.parentData && currentLog.parentData.key,
      parentUrl: currentLog.parentData && createIssueUrl(currentLog.parentData.key),
      parentIcon: currentLog.parentIcon && currentLog.parentIcon,

      ...serviceVariables,
    },
    epic: {
      key: currentLog.epicKey,
      name: currentLog.epicName,
      description: currentLog.epicName,
      icon: currentLog.epicIcon,
      url: currentLog.epicLink,
      ...serviceVariables,
    },
    worklog: {
      key: currentLog.worklogId,
      name: currentLog.worklogDescription,
      description: currentLog.worklogDescription,
      icon: currentLog.issueIcon,
      url: currentLog.issueUrl,
      ...serviceVariables,
    },
  };
};
