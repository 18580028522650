import { moment } from 'controllers/moment';
import { formatDataByFieldType } from 'controllers/WorklogController/formatDataByFieldType';
import { getSprintName } from 'controllers/WorklogController/formatWorklogData';
import { createIssueUrl, createUserUrl } from 'utils/jiraUrl';
import store from 'store';
import { getCustomFieldValue } from 'controllers/reportMigrations/getCustomFieldValue';

export const getUserId = (data, fieldName) => (data[fieldName]?.accountId || data[fieldName]?.key || '');

const createFormattedElement = (issue, worklog, formatOptions, customFieldsOptions, fieldDataByFieldId) => {
  const customFieldsKeysMapToType = customFieldsOptions.reduce((acc, { id, schema }) => {
    acc[id] = schema.type;

    return acc;
  }, {});

  const formattedCustomFields = Object.entries(issue.fields).reduce((acc, [key, value]) => {
    if (customFieldsKeysMapToType[key]) {
      const fieldType = customFieldsKeysMapToType[key];

      acc[key] = getCustomFieldValue(value, fieldType);
    }

    return acc;
  }, {});

  const { jiraSoftwareFields } = store.getState().app;

  const epicKeyField = jiraSoftwareFields.epic?.id;
  const epicNameField = jiraSoftwareFields.epicName?.id;
  const epicStatusKeyField = jiraSoftwareFields.epicStatus?.id;
  const storyPointsKeyField = jiraSoftwareFields.storyPoints?.id;

  const worklogObject = worklog || {};
  const loggedTime = worklogObject.timeSpentSeconds || issue.fields.timespent;
  const worklogId = process.env.JIRA_TYPE === 'cloud' ? worklogObject.id : worklogObject.worklogId;
  const worklogAuthor = worklogObject?.author?.displayName || null;
  const worklogAuthorId = worklogObject.author ? getUserId(worklogObject, 'author') : null;
  const worklogAuthorIcons = worklogObject?.author?.avatarUrls || [];
  const worklogAuthorUrl = worklogObject.author
    ? createUserUrl(worklogObject.author.accountId || worklogObject.author.name)
    : null;
  const startDate = worklogObject.started ? moment(worklog.started).formatWithJDF('d/MMM/yy') : null;

  return {
    ...formattedCustomFields,

    includedSubtasks: issue.includedSubtasks,
    subtasksCount: issue.fields.subtasks ? issue.fields.subtasks.length : null,

    sprintName: getSprintName(issue.fields, formatOptions.sprintKeyField),

    // TODO: check if we need aggregation by Story Points, maybe we just need to make it work with only dynamic fields?
    storyPoints: issue.fields[storyPointsKeyField]
      ? issue.fields[storyPointsKeyField]
      : null,

    issueKey: issue.key,
    parentKey: issue.fields.parent ? issue.fields.parent.key : null,

    issueName: issue.fields.summary,
    issueIcon: issue.fields.issuetype.iconUrl,
    issueUrl: createIssueUrl(issue.key),
    issueStatus: issue.fields.status ? issue.fields.status.name : null,
    issueType: issue.fields.issuetype.name,

    parentData: issue.fields.parent ? { ...issue.fields.parent } : null,
    parentName: issue.fields.parent ? issue.fields.parent.fields.summary : null,
    parentUrl: issue.fields.parent ? createIssueUrl(issue.fields.parent.key) : null,
    parentIcon: issue.fields.parent ? issue.fields.parent.fields.issuetype.iconUrl : null,

    epicKey: issue.fields[epicKeyField],
    epicName: issue.fields[epicNameField] ? issue.fields[epicNameField] : issue.fields?.parent?.fields?.summary,
    epicStatus: issue.fields[epicStatusKeyField],

    epicIcon: issue.fields.issuetype.iconUrl,

    loggedTime: loggedTime || null,
    worklogId: worklogId || null,
    worklogAuthor,
    worklogAuthorId,
    worklogAuthorIcons,

    worklogAuthorUrl,
    startDate,

    // TODO: fix problem with getting data from columns - assignee and reporter
    issueAssigneeId: issue.fields.assignee ? getUserId(issue.fields, 'assignee') : null,
    issueReporterId: issue.fields.reporter ? getUserId(issue.fields, 'reporter') : null,

    // TODO: delete and show user name by logic
    issueAssignee: issue.fields.assignee ? issue.fields.assignee.displayName : null,
    issueReporter: issue.fields.reporter ? issue.fields.reporter.displayName : null,

    // NEW OPTIONS THAT WILL BE MAIN AFTER REMOVING REPORTS
    ...(fieldDataByFieldId ? formatDataByFieldType(issue.fields, fieldDataByFieldId, issue.key) : {}),
  };
};

export const formatIssueTableData = (issues, formatOptions, customFieldsOptions, fieldDataByFieldId) => {
  const result = [];

  const worklogsWithIssueKey = [];
  const issueDataByIssueKey = issues.reduce((acc, current) => {
    acc[current.key] = current;

    return acc;
  }, {});

  issues.forEach((issue) => {
    const { fields: { worklog } } = issue;
    let formattedElement;

    if (worklog && worklog.worklogs && worklog.worklogs.length > 0) {
      const worklogs = worklog.worklogs.map((item) => ({ ...item, issueKey: issue.key }));

      worklogsWithIssueKey.push(...worklogs);
    } else {
      formattedElement = createFormattedElement(issue, null, formatOptions, customFieldsOptions, fieldDataByFieldId);

      result.push(formattedElement);
    }
  });

  worklogsWithIssueKey.forEach((worklogItem) => {
    const issue = issueDataByIssueKey[worklogItem.issueKey];
    const formattedElement = createFormattedElement(issue, worklogItem, formatOptions, customFieldsOptions, fieldDataByFieldId);

    result.push(formattedElement);
  });

  return result;
};
