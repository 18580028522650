import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminConfigApi } from 'store/api/plugin/adminConfigApi';
import { RootState } from 'store/index';

import {
  TelemetryConfig,
  PermissionsConfig,
  Permission,
  PermissionTypes,
  AdminConfigState,
  AddonVisibilityConfig,
  GeneralConfig,
} from 'types/adminConfig';

const loadingTelemetryConfig = {
  isHotjarEnabled: false,
  isAmplitudeEnabled: false,
  isSentryEnabled: false,
};

const defaultGeneralConfig = {
  startWeekly: 'sunday',
  dateFormat: 'd/MMM/yy',
  periodsEnabled: false,
  periodsConfiguration: 'monthly',
  periodsStartDate: Date.now(),
  appMenuName: 'Report Builder',
};

const defaultAdminConfig = {
  isLoaded: false,
  telemetry: {
    isHotjarEnabled: true,
    isAmplitudeEnabled: true,
    isSentryEnabled: true,
  },
  permissions: {
    teamManager: [{ type: PermissionTypes.public, value: PermissionTypes.public }],
    teamGlobalAdmin: [{ type: PermissionTypes.private, value: PermissionTypes.private }],
    scriptedReportsManager: [{ type: PermissionTypes.public, value: PermissionTypes.public }],
    issuePanelExport: [] as Permission[],
  },
  general: {
    ...defaultGeneralConfig,
  },
  addonVisibility: [],
};

export const adminConfigSlice = createSlice({
  name: 'adminConfig',
  initialState: {
    isLoaded: false,
    telemetry: loadingTelemetryConfig,
    permissions: defaultAdminConfig.permissions,
    general: defaultAdminConfig.general,
    addonVisibility: [],
  } as AdminConfigState,

  reducers: {
    updateTelemetryConfig: (state, { payload }: PayloadAction<Partial<TelemetryConfig>>) => {
      state.telemetry = { ...state.telemetry, ...payload };
    },
    updatePermissionsConfig: (state, { payload }: PayloadAction<Partial<PermissionsConfig>>) => {
      state.permissions = { ...state.permissions, ...payload };
    },
    updateGeneralConfig: (state, { payload }: PayloadAction<Partial<GeneralConfig>>) => {
      state.general = { ...state.general, ...payload };
    },
    updateAddonVisibility: (state, { payload }: PayloadAction<AddonVisibilityConfig>) => {
      state.addonVisibility = [...payload];
    },
  },

  extraReducers(builder) {
    builder.addMatcher(adminConfigApi.endpoints.getAdminConfig.matchFulfilled, (state, action) => {
      if (state.isLoaded) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
        telemetry: {
          ...(action.payload.telemetry ?? defaultAdminConfig.telemetry),
        },
        permissions: {
          ...(action.payload.permissions ?? defaultAdminConfig.permissions),
        },
        general: {
          ...(action.payload.general ?? defaultAdminConfig.general),
        },
        addonVisibility: action.payload.addonVisibility ?? defaultAdminConfig.addonVisibility,
        isLoaded: true,
      };
    });
  },
});

export const {
  updateTelemetryConfig,
  updatePermissionsConfig,
  updateGeneralConfig,
  updateAddonVisibility,
} = adminConfigSlice.actions;

export const selectAdminConfig = (state: RootState) => state.adminConfig;