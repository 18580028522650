import { getIssueFields } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: getIssueFields,
  maxResults: 50,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/field',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/field',
  },
};

export { config as getIssueFields };
