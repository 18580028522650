import { API } from 'controllers/API';

export const createJQL = (optionsArray, startString, type) => {
  const filteredArray = optionsArray.filter((item) => item.type === type);

  if (filteredArray.length === 0) return '';

  return filteredArray.reduce((acc, item, index, array) => {
    if (index > 0) {
      acc += ', ';
    }

    acc += `"${item.value}"`;

    if (index === array.length - 1) {
      acc += ')';
    }

    return acc;
  }, startString);
};

export const createJQLForUser = async (optionsArray, startString) => {
  const filteredArray = optionsArray.filter((item) => item.type === 'user');

  if (filteredArray.length === 0) return '';

  const namesList = [];

  if (process.env.JIRA_TYPE !== 'cloud') {
    const { users } = (await API.get(
      `/users?keys=${filteredArray.reduce((acc, { value: memberId }, index) => {
        if (index === 0) {
          acc = memberId;
        }

        acc += `,${memberId}`;

        return acc;
      }, '')}`,
    )).data;

    namesList.push(...users);
  }

  if (process.env.JIRA_TYPE === 'cloud') {
    return filteredArray.reduce((acc, item, index, array) => {
      if (index > 0) {
        acc += ', ';
      }

      acc += `"${item.value}"`;

      if (index === array.length - 1) {
        acc += ')';
      }

      return acc;
    }, startString);
  }

  return namesList.reduce((acc, item, index, array) => {
    if (index > 0) {
      acc += ', ';
    }

    acc += `"${item.name}"`;

    if (index === array.length - 1) {
      acc += ')';
    }

    return acc;
  }, startString);
};
