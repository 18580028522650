import { pluginBaseApi } from 'store/api/plugin/baseApi/pluginBaseApi';
import { ServerPluginConfig } from 'types/serverPluginConfig';

export const configApi = pluginBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query<ServerPluginConfig, undefined>({
      query: () => 'configs',
      transformResponse: (rawResult: ServerPluginConfig) => rawResult,
    }),
  }),
});

export const {
  useGetConfigQuery,
} = configApi;