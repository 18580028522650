import configuredLoadable, { BaseComponent, ConfiguredLoadableProps } from 'actonic-ui-components/dist/configuredLoadable';
import { useResizeIframe } from 'hooks/useResizeIframe';
import { useEffect } from 'react';

export default function ConfiguredLoadable<TLazyComponent extends BaseComponent = BaseComponent>(params: ConfiguredLoadableProps<TLazyComponent>) {
  return configuredLoadable<TLazyComponent>({ ...params, useInnerEffect: () => {
    const resizeIframe = useResizeIframe();
    useEffect(() => {
      setTimeout(resizeIframe, 100); // we need to resize iframe after actual component loaded
    });
  }});
};