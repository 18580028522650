/* eslint-disable @typescript-eslint/no-explicit-any */
export const mergeResponseBody = <
  Key extends string = string,
  Body extends Partial<Record<Key, any | any[]>> = Record<Key, any | any[]>,
>(bodies: Array<Body>) => {
  return bodies.reduce((acc, body) => {
    const merged = { ...acc };
    (Object.keys(body) as Array<Key>).forEach((key) => {
      const existValue = merged[key];
      const newValue = body[key];
      if (Array.isArray(existValue) && Array.isArray(newValue)) {
        const mergedItem = [...existValue, ...newValue];
        // @ts-ignore
        merged[key] = mergedItem;
      } else if (!Array.isArray(existValue)) {
        merged[key] = body[key];
      }
    });
    return merged;
  }, {} as Body);
};