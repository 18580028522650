export const getFetchingFieldsList = (
  isCalendar,
  {
    epicKeyField,
    epicNameField,
    storyPointsKeyField,
    sprintKeyField,
    startDateKeyField,
    epicStatusKeyField,
    isWithTime,

    dateFormat,

    ...serviceDeskFields
  },
) => (isCalendar
  ? [
    'summary',
    'project',
    'status',
    'issuetype',
    'timeestimate',
    'timespent',
    'timeoriginalestimate',
    'children',
    'worklog',
    'parent',
    isWithTime,
  ].join(',')
  : [
    'summary',
    'project',
    'status',
    'issuetype',
    'timeestimate',
    'timeoriginalestimate',
    'timespent',
    'aggregatetimeoriginalestimate',
    'priority',
    'labels',
    'assignee',
    'creator',
    'reporter',
    'duedate',
    'resolution',
    'resolutiondate',
    'updated',
    'components',
    'fixVersions',
    'children',
    'worklog',
    'parent',
    'subtasks',
    'created',
    epicKeyField,
    epicNameField,
    storyPointsKeyField,
    sprintKeyField,
    startDateKeyField,
    epicStatusKeyField,

    ...Object.values(serviceDeskFields),
  ].filter((item) => !!item).join(',')
);
