import { TFunction } from 'hooks/useTranslation';

export const reportLabelsByReportType = (t: TFunction) => [
  {
    reportType: 'universalReport',
    label: t('reports.createReport.reportType.universalReport.label'),
  },
  {
    reportType: 'calendar',
    label: t('reports.createReport.reportType.calendar.label'),
  },
  {
    reportType: 'scriptedFromGallery',
    label: t('reports.table.types.scriptedReport'),
  },
  {
    reportType: 'scriptedReport',
    label: t('reports.table.types.scriptedReport'),
  },
  {
    reportType: 'urtReport',
    label: t('reports.createReport.reportType.urtReport.label'),
  },
] as const;
