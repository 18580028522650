export const COUNT = (array) => array.length;

export const SUM = (array) => array.reduce(
  (acc, value) => {
    acc += value;

    return acc;
  },
  0,
);

export const AVERAGE = (array) => {
  let accumulatedValue = 0;
  let valuesCount = 0;

  for (const value of array) {
    accumulatedValue += value;

    valuesCount += 1;
  }

  return Math.round(((accumulatedValue / valuesCount) + Number.EPSILON) * 100) / 100;
};

export const MAX = (array) => array.reduce(
  (acc, value) => {
    if (value > acc) {
      acc = value;
    }

    return acc;
  },
  0,
);

export const MIN = (array) => array.length > 0
  ? array
    .reduce(
      (acc, value, index) => {
        if (index === 0) {
          acc = value;
        }

        if (value < acc) {
          acc = value;
        }

        return acc;
      },
    )
  : 0;

export const MEDIAN = (array) => {
  const sortedValues = array.sort();

  if (sortedValues.length % 2 !== 0) {
    const median = Math.floor(sortedValues.length / 2);

    return sortedValues[median];
  }

  const rightIndex = sortedValues.length / 2;
  const leftIndex = (sortedValues.length / 2) - 1;

  const right = sortedValues[rightIndex];
  const left = sortedValues[leftIndex];

  return Math.floor((right + left) / 2);
};
