import sleep from 'utils/sleep';

export interface PromiseAllLimitedOptions {
  interval: number;
  concurrency: number;
  afterEachResolveCallback?: (resolvedPromisesCount: number) => void;
}

export const promiseAllLimited = async <Result>(
  promiseRunners: Array<() => Promise<Result>>,
  { interval = 100, concurrency = 6, afterEachResolveCallback }: PromiseAllLimitedOptions,
) => {
  const initialPromises = promiseRunners.length;
  let activePromises = 0;
  let finalizedPromises = 0;
  let error: undefined | Error;

  const decreaseActivePromises = () => activePromises--;
  const increaseFinalizedPromises = () => finalizedPromises++;
  const setError = (e: unknown) => {
    error = e as Error;
  };

  let i = 0;
  const results: Result[] = Array(promiseRunners.length);

  while (!error && promiseRunners.length) {
    if (activePromises >= concurrency) {
      await sleep(15); // to give time to other event-loop tasks (prevents interface freezes)
      continue; // eslint-disable-line no-continue
    }

    activePromises++;

    const currentPromiseRunner = promiseRunners.shift();
    if (!currentPromiseRunner) {
      break;
    }

    const currentIndex = i;
    currentPromiseRunner().then(async (res) => {
      results[currentIndex] = res;
    }).catch((e) => {
      setError(e);
    }).finally(async () => {
      decreaseActivePromises();
      const finalizedPromisesLocal = increaseFinalizedPromises();

      if (afterEachResolveCallback) {
        afterEachResolveCallback(finalizedPromisesLocal);
        await sleep(15); // to give time to other event-loop tasks (prevents interface freezes)
      }
    });

    i++;

    await sleep(interval);
  }

  while (!error && finalizedPromises < initialPromises) {
    await sleep(interval);
  }

  if (error) {
    throw error;
  }

  return results;
};