import axios from 'axios';
import { API } from 'controllers';
import { requestApi } from 'controllers/transport/requestApi';
import { getContextPath } from 'utils/getContextPath';

export const getUserId = () => {
  const { AP } = window;

  if (IS_CLOUD && AP) {
    return new Promise((resolve) => {
      AP.user.getCurrentUser((user) => {
        user && user.atlassianAccountId ? resolve(user.atlassianAccountId) : resolve(process.env.REACT_APP_USER_ID);
      });
    });
  }

  return process.env.REACT_APP_USER_ID;
};

export const getUserDataQuery = async () => {
  if (process.env.JIRA_TYPE === 'cloud') {
    const accountId = await getUserId();
    return `?accountId=${accountId}&expand=groups`;
  }

  if (window.AJS) {
    const res = await requestApi({
      path: '/rest/gadget/1.0/currentUser',
      method: 'GET',
    });

    const { username } = res;

    return `?username=${username}&expand=groups`;
  }
};

export const getUserTeamPermissions = async () => {
  try {
    const teamPermissions = process.env.JIRA_TYPE === 'cloud'
      ? (await API.get('/teampermissions'))
      : (await axios.create({
        baseURL: `${getContextPath()}/rest/actonic-tb/1.0/api`,
        timeout: 900000,
      }).get('/teampermissions'));

    const { data: { userTeamPermissions } } = teamPermissions;

    return userTeamPermissions;
  } catch (error) {
    return null;
  }
};
