import { NotificationController as Notification } from 'controllers';
import { requestApi } from 'controllers/transport/requestApi';
import { LicenseStatus } from 'constants/constants';

export const checkIsServerJiraLicensed = async () => {
  try {
    const { isLicensed } = await requestApi({ method: 'GET', path: '/configs' });

    return isLicensed ? 'active' : 'serverExpired';
  } catch (e) {
    console.error(`[checkLicense][checkIsServerJiraLicensed] Error: ${e}`);
    return 'serverExpired';
  }
};

export const getLicenseStatus = () => {
  if (RUNTIME_ENV === 'development') return LicenseStatus.active;

  return window.licenseStatus;
};

export const checkIsLicensed = () => getLicenseStatus() === 'active';

export const validateLicenseStatus = (t, callback) => {
  const licenseStatus = getLicenseStatus();

  if (licenseStatus !== LicenseStatus.active) {
    return Notification.create({
      body: t('warnings.licenseExpired.notification'),
      type: 'error',
      close: 'auto',
    });
  }

  return callback();
};
