export const getCustomFieldValue = (field, fieldType) => {
  switch (fieldType) {
    case 'option': {
      const formattedValue = field && field.value;

      return formattedValue
        ? formattedValue
        : null;
    }

    case 'array': {
      const formattedValue = field && field.map(({ value }) => value);

      return formattedValue
        ? formattedValue
        : null;
    }

    case 'option-with-child': {
      const formattedValue = field && field.value && field.child && field.child.value && `${field.value} - ${field.child.value}`;

      return formattedValue
        ? formattedValue
        : null;
    }

    case 'number':
      return field ? field : null;

    default:
      return null;
  }
};
