import { getIssuesWithWorklogs } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: true,
  migration: getIssuesWithWorklogs,
  fieldName: 'issues',
  url: '/rest/api/2/search?jql=worklogAuthor is not EMPTY ',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  server: {
    ...globalConfig,
  },
  cloud: {
    ...globalConfig,
  },
};

export { config as getIssuesWithWorklogs };
