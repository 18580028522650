import types from 'store/legacy/progressBar/types';

export const setLoadingState = (loadingState) => ({
  type: types.SET_LOADING_STATE,
  payload: { loadingState },
});

export const setIsLoadingCanceled = (isLoadingCanceled) => ({
  type: types.SET_IS_LOADING_CANCEL,
  payload: { isLoadingCanceled },
});

export const setLoadingStartedProcess = (processId = '') => ({
  type: types.SET_LOADING_STARTED_PROCESS,
  payload: { loadingStartedProcessId: processId, loadingFinishedProcessId: '' },
});

export const setLoadingFinishedProcess = (processId = '') => ({
  type: types.SET_LOADING_FINISHED_PROCESS,
  payload: { loadingFinishedProcessId: processId },
});
