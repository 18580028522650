import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { SAVE_REPORT_EVENT } from '../constants/amplitudeEvents';
import { sendStatsToAmplitude } from './amplitude';
import { getDefaultReportKey } from "./getDefaultReportKey";

interface Arguments {
  reportCategory: ReportCategory,
  actionType: 'added' | 'saved' | 'savedAs',
  reportKey?: string,
  tagList?: string[],
}

type SendSaveReportEvent = ({ reportCategory, actionType, reportKey, tagList }: Arguments) => void;

export const sendSaveReportEvent: SendSaveReportEvent = ({ reportCategory, actionType, reportKey, tagList = [] }) => {
  sendStatsToAmplitude(SAVE_REPORT_EVENT, {
    reportKey: reportKey ?? getDefaultReportKey(reportCategory),
    reportCategory,
    actionType,
    tagList,
  });
};