export const getIssueFields = (data) => {
  const systemFieldsWithoutType = {
    statusCategory: true,
    parent: true,
    issuekey: true,
  };

  return data
    .map(({ id, name, schema, untranslatedName }) => {
      const isSystemFieldWithoutType = systemFieldsWithoutType[id];

      const type = (() => {
        if (isSystemFieldWithoutType) {
          return 'systemFieldValue';
        }

        return schema && schema.type;
      })();

      return {
        id,
        name,
        type,

        arrayItemType: schema && schema.items,

        untranslatedName,
      };
    })
    .filter(({ type }) => !!type);
};
