import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { reportApi } from 'store/api/plugin/reportApi';
import { shareableLinkApi } from 'store/api/plugin/shareableLinkApi';
import { getInitialUrtState } from 'features/urt/utils/getInitialUrtState';
import {
  DrillDownReport,
  InitialState,
  UpdateConfigPropTitlePayload,
  UpdateSortingPayload,
} from 'store/slices/urtReport/types';

import { UrtSideBarPanels } from 'features/urt/components/UrtSideBar/constants';
import { createUrtReport } from 'utils/createUrtReport';
import { SelectOption } from 'sharedFrontBack/src/types/select';
import { ReportPermissionListItem } from 'sharedFrontBack/src/types/reportPermissions';
import { SortingDirections } from 'sharedFrontBack/src/singletonModules/pivotopia';
import { UrtReport, UrtConfig, UrtOptions } from 'sharedFrontBack/src/types/urt';
import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { optionsInitialValue } from 'features/urt/constants/constants';

const initialState = getInitialUrtState();

export const urtReportSlice = createSlice({
  name: 'urtReport',

  initialState,

  reducers: {
    resetUrtReport: () => {
      return initialState;
    },
    resetReport: (state, { payload }: PayloadAction<UrtReport>) => {
      state.report = payload;
      state.expandedSidebarPanel = UrtSideBarPanels.getData;
      state.expandedFieldParams = {};
      state.expandedCondition = undefined;
      state.isDirty = false;
    },
    setDrillDownReport: (state, { payload }: PayloadAction<DrillDownReport>) => {
      state.drillDownReport = payload;
    },
    updateName: (state, { payload: name }: PayloadAction<string>) => {
      state.report.name = name;
      state.isDirty = true;
    },
    updateScopePicker: (state, { payload }: PayloadAction<Partial<UrtConfig['scopePicker']>>) => {
      state.report.urtConfig.scopePicker = {
        ...state.report.urtConfig.scopePicker,
        ...payload,
      };
      state.isDirty = true;
    },
    updateUrtData: (state: InitialState, { payload }: PayloadAction<Partial<UrtConfig>>) => {
      state.report.urtConfig = { ...state.report.urtConfig, ...payload };
      state.isDirty = true;
    },

    updateSorting: (state: InitialState, { payload }: PayloadAction<UpdateSortingPayload>) => {
      const { sortingProp, data, reportView = 'urt' } = payload;
      const reportState = reportView === 'drillDownReport' ? state.drillDownReport : state.report;

      if (reportState.urtConfig) {
        const { [sortingProp]: currentSorting } = reportState.urtConfig;

        reportState.urtConfig[sortingProp] = data ? [{
          ...data,
          direction: (
            !currentSorting
            || currentSorting[0]?.target !== data.target
            || ('colIndex' in currentSorting && 'colIndex' in data && currentSorting.colIndex !== data.colIndex)
            || currentSorting[0]?.direction === SortingDirections.desc
          ) ? SortingDirections.asc : SortingDirections.desc,
        }] : data;

        state.isDirty = true;
      }
    },
    updateSidebarExpandedPanel: (state, { payload }: PayloadAction<UrtSideBarPanels | null>) => {
      state.expandedSidebarPanel = payload;
    },
    updateStyles: (state, { payload }: PayloadAction<Readonly<UrtConfig['styles']>>) => {
      state.report.urtConfig.styles = payload;
      state.isDirty = true;
    },
    updateReportOwner: (state, { payload }: PayloadAction<string>) => {
      state.report.updatedOwner = payload;
      state.isDirty = true;
    },
    updateConfigPropCustomName: (state, { payload }: PayloadAction<UpdateConfigPropTitlePayload>) => {
      const { configPropName, index, customName } = payload;
      const prop = state.report.urtConfig[configPropName][index];

      if (!prop) throw new Error('Dimension or measure with the specified index was not found');

      prop.customName = customName;
      state.isDirty = true;
    },
    updateExportVisibility: (state, { payload }: PayloadAction<SelectOption[]>) => {
      state.report.reportExportVisibility = payload;
      state.isDirty = true;
    },
    updatePermissions: (state, { payload }: PayloadAction<{ id: string; permissions: ReportPermissionListItem[] }>) => {
      const { permissions } = payload;
      state.report.permissions = permissions;
      state.isDirty = true;
    },
    updateExpandedFieldParams: (state, { payload }: PayloadAction<Readonly<InitialState['expandedFieldParams']>>) => {
      state.expandedFieldParams = payload;
    },
    resetRowSorting: (state) => {
      state.report.urtConfig.rowsSorting = undefined;
    },
    updateHideSubtotalColumns: (state, { payload }: PayloadAction<UrtOptions>) => {
      state.report.urtConfig.options = payload;
      state.isDirty = true;
    },
    updateIsReportDetailsShown: (state, { payload }: PayloadAction<boolean>) => {
      state.report.urtConfig.options = state.report.urtConfig.options ?? optionsInitialValue;
      state.report.urtConfig.options.isReportDetailsShown = payload;
      state.isDirty = true;
    },
    updateDataTablePosition: (state, { payload }: PayloadAction<UrtOptions>) => {
      state.report.urtConfig.options = payload;
      state.isDirty = true;
    },
    updateConditionalFormatStyles: (state, { payload }: PayloadAction<Readonly<UrtConfig['conditionalFormatStyles']>>) => {
      state.report.urtConfig.conditionalFormatStyles = payload;
      state.isDirty = true;
    },
    updateExpandedCondition: (state: InitialState, { payload }: PayloadAction<Readonly<InitialState['expandedCondition']>>) => {
      state.expandedCondition = payload;
    },
  },

  extraReducers(builder) {
    // Reducer for route change events (Example)
    // builder.addCase(routeChanged, (state, action) => {});
    builder.addMatcher(reportApi.endpoints.updateReport.matchFulfilled, (state, action) => {
      state.isDirty = false;
      if (action.payload.category !== ReportCategory.urtReport) {
        return;
      }

      state.report.updatedBy = action.payload.updatedBy;
      if (state.report._id && state.report._id === action.payload._id) {
        return; // to prevent clearing of memo cache when we work with report
      }

      state.report = {
        ...state.report,
        ...createUrtReport(action.payload),
      };
    });
    builder.addMatcher(shareableLinkApi.endpoints.createShareableLink.matchFulfilled, (state, action) => {
      state.report = {
        ...state.report,
        shareableLink: action.payload,
      };
    });
    builder.addMatcher(shareableLinkApi.endpoints.removeShareableLink.matchFulfilled, (state) => {
      state.report = {
        ...state.report,
        shareableLink: undefined,
      };
    });
    builder.addMatcher(reportApi.endpoints.createReport.matchFulfilled, (state) => {
      state.isDirty = false;
    });
  },
});

export const {
  resetUrtReport,
  resetReport,
  setDrillDownReport,
  updateName,
  updateScopePicker,
  updateUrtData,
  updateSorting,
  updateStyles,
  updateSidebarExpandedPanel,
  updateReportOwner,
  updateExportVisibility,
  updatePermissions,
  updateExpandedFieldParams,
  resetRowSorting,
  updateConfigPropCustomName,
  updateHideSubtotalColumns,
  updateIsReportDetailsShown,
  updateDataTablePosition,
  updateConditionalFormatStyles,
  updateExpandedCondition,
} = urtReportSlice.actions;
