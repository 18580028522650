import { galleryViewTypes } from 'constants/constants';
import { getDefinedPeriodDates } from 'utils/dates/getStartAndEndDates';
import { selectStartWeekly } from 'store/legacy/configuration/selectors';
import { getUserConfig, saveUserConfig } from 'store/legacy/user/thunks';
import {
  setIsWithWeekends, setCalendarView,
  setCurrentPeriod, setCalendarViewConfig, setIsColorIssueStatus,
  setGroupBy, setSortBy, setDatesRange, setDefinedPeriod,
} from 'store/legacy/timesheets/actions';

import {
  setCurrentModule, setGalleryViewType, setIsCompletionPanelVisible,
  setIsTotalPanelVisible, setIsUnapprovedReportsEnabled, setReportsListSortBy, setTimeTrackingUnit,
} from 'store/legacy/app/actions';
import { getFieldsList } from 'store/legacy/app/thunks';

import { defineDatesRange } from 'store/legacy/defineDatesRange';
import { validateGroupBy, validateSortBy } from 'store/legacy/dataValidation';
import types from 'store/legacy/app/types';
import { setLastReport } from 'store/legacy/reports/thunks';
import { defaultReportsListSortBy } from 'constants/report';

const validateCalendarView = (view) => {
  const views = ['month', 'week', 'work_week', 'week_without_time', 'work_week_without_time'];
  const result = views.find((item) => (item === view));
  if (result) {
    return result;
  }
  return views[0];
};

export const setConfig = () => async (dispatch, getState) => {
  const { app, timesheets, reports } = getState();

  try {
    dispatch({ type: types.SET_CONFIG_STARTED });

    if (getState().app.isConfigFetched) {
      const userConfig = {
        currentModule: app.currentModule,
        timeTrackingUnit: app.timeTrackingUnit,
        isCompletionPanelVisible: app.isCompletionPanelVisible,
        isTotalPanelVisible: app.isTotalPanelVisible,
        isUnapprovedReportsEnabled: app.isUnapprovedReportsEnabled,
        galleryViewType: app.galleryViewType,

        calendarView: timesheets.calendarView,
        currentPeriod: timesheets.currentPeriod,
        isCalendarWithWeekends: timesheets.isWithWeekends,
        isColorIssueStatus: timesheets.isColorIssueStatus,

        isIncludesSubtask: timesheets.isIncludesSubtask,

        tableDatesRange: timesheets.datesRange,
        groupBy: timesheets.groupBy,
        sortBy: validateSortBy(timesheets.sortBy),
        definedPeriod: timesheets.definedPeriod,

        lastReportId: reports.lastViewedReport.id,
        lastReportCategory: reports.lastViewedReport.category,

        reportsListSortBy: app.reportsListSortBy,
      };

      await dispatch(saveUserConfig(userConfig));
      dispatch({ type: types.SET_CONFIG_SUCCESS });
      return userConfig;
    }

    console.warn('Skipping setConfig because app.isConfigFetched == false');

    dispatch({ type: types.SET_CONFIG_SUCCESS });
  } catch (error) {
    console.error('[store.actions][setConfig] Error while saving config', error);

    dispatch({ type: types.SET_CONFIG_FAILURE, error });
  }
};

export const getConfig = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_CONFIG_STARTED });

  const state = getState();
  const startWeekly = selectStartWeekly(state);
  let userConfig = await dispatch(getUserConfig());

  if (!userConfig) {
    await dispatch({ type: types.GET_CONFIG_FAILURE });
    userConfig = await dispatch(setConfig());

    if (!userConfig) {
      return;
    }
  }

  const {
    isCalendarWithWeekends, calendarView, currentPeriod,
    currentModule, groupBy, sortBy, tableDatesRange, timeTrackingUnit = 'hours', definedPeriod, isColorIssueStatus,
    lastReportId, lastReportCategory, isCompletionPanelVisible = true, isTotalPanelVisible = true,
    isUnapprovedReportsEnabled: isUnapprovedReportsEnabledRaw, reportsListSortBy: reportsListSortByRaw,
    galleryViewType: galleryViewTypeRaw,
  } = userConfig;

  const definedPeriodDates = getDefinedPeriodDates(definedPeriod, startWeekly);

  const { startDate, endDate } = defineDatesRange(
    definedPeriodDates,
    { start: tableDatesRange.startDate, end: tableDatesRange.endDate },
  );

  const datesRange = {
    startDate,
    endDate,
  };
  const reportsListSortBy = reportsListSortByRaw ?? defaultReportsListSortBy;
  const isUnapprovedReportsEnabled = isUnapprovedReportsEnabledRaw ?? false;
  const galleryViewType = galleryViewTypeRaw ?? galleryViewTypes.groups;

  dispatch(setIsWithWeekends(isCalendarWithWeekends));
  dispatch(setCalendarView(validateCalendarView(calendarView)));
  dispatch(setCalendarViewConfig(true));
  dispatch(setTimeTrackingUnit(timeTrackingUnit));
  dispatch(setIsCompletionPanelVisible(isCompletionPanelVisible));
  dispatch(setIsTotalPanelVisible(isTotalPanelVisible));
  dispatch(setIsColorIssueStatus(isColorIssueStatus));
  dispatch(setIsUnapprovedReportsEnabled(isUnapprovedReportsEnabled));
  dispatch(setGalleryViewType(galleryViewType));
  dispatch(setReportsListSortBy(reportsListSortBy));

  if (currentPeriod.start && currentPeriod.end) {
    dispatch(setCurrentPeriod({
      start: new Date(currentPeriod.start),
      end: new Date(currentPeriod.end),
      config: true,
    }));
  }

  const { fieldsList } = getState().app;

  if (!fieldsList || fieldsList.length === 0) {
    await dispatch(getFieldsList());
  }

  await dispatch(setCurrentModule(currentModule));

  const fieldsIds = getState().app.fieldsOptions.map(({ value }) => value);

  await dispatch(setGroupBy(validateGroupBy(fieldsIds, groupBy)));
  await dispatch(setSortBy(validateSortBy(sortBy)));
  await dispatch(setDatesRange(datesRange));
  await dispatch(setDefinedPeriod(definedPeriod));

  if (lastReportId) {
    await dispatch(setLastReport({
      id: lastReportId,
      category: lastReportCategory,
    }, true));
  }

  dispatch({ type: types.GET_CONFIG_SUCCESS });
};
