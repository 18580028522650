import { TransportController as Transport } from 'controllers/transport';
import { getProjectStyle } from 'controllers/transport/requestConfigurations';

const projectsCache = [];

export const checkUserTeamPermission = async (data, userId, userGroupsNames) => {
  const projectStyles = await Promise.all(
    data
      .filter(({ type, value }) => type === 'project' && !projectsCache.find(({ value: projectValue }) => projectValue === value))
      .map(({ value }) => Transport.request({ config: getProjectStyle, query: value })),
  );

  const userTeamPermission = data.reduce(
    (acc, { type, value }) => {
      if (type === 'public') {
        acc = true;
      }

      if (type === 'group' && userGroupsNames.some((groupName) => groupName === value)) {
        acc = true;
      }

      if (type === 'project') {
        const checkProjectsCache = projectsCache.find(({ value: projectValue }) => (projectValue === value));
        if (checkProjectsCache) {
          acc = checkProjectsCache.permission;
        } else {
          try {
            const foundProject = projectStyles.find(({ key }) => key === value);

            let permission = false;

            if (foundProject) {
              permission = true;
            }
            acc = permission;
            projectsCache.push({ value, permission });
          } catch (e) {
            console.error('[store.user.actions][checkUserTeamPermission] Get project info error');
          }
        }
      }

      if (type === 'user' && value === userId) {
        acc = true;
      }
      return acc;
    },
    false,
  );

  return userTeamPermission;
};
