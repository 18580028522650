const globalConfig = {
  method: 'GET',
  allPages: true,
  fieldName: 'worklogs',
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/issue/',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/issue/',
  },
};

export { config as getLogs };
