import { useEffect, useRef, useCallback } from 'react';

export const useDidMount = (callback) => {
  useEffect(callback, []);
};

export const useWillUnmount = (callback) => {
  // eslint-disable-next-line
  useEffect(() => {
    // returned function will be called on component unmount
    return callback;
  }, [callback]);
};

export const useCheckIsMounted = () => {
  // copied from https://usehooks-ts.com/react-hook/use-is-mounted
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};
