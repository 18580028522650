import { ReportCategory } from 'sharedFrontBack/src/constants/report';
import { checkIsEnumValue } from 'utils/checkIsEnumValue';

export const getDefaultReportKey = (reportCategory: string) => {
  if (checkIsEnumValue(ReportCategory, reportCategory)) {
    return reportCategory === ReportCategory.scriptedReport || reportCategory === ReportCategory.scriptedFromGallery
      ? 'scripted-report'
      : 'universal-report';
  }

  return 'unknown-report'
}