import { formatDateForDateGroup } from 'utils/dates/formatDates';
import {
  updateExistingGroup, findPrevGroup,
  findGroupInArray, flatTreeInArray,
  getEmptyName, getKeysForCurrentLog,
} from 'features/legacy/groupsLegacy/groupHelpers';
import { getDataForGroup, getGroupTypeByGroupName } from 'features/legacy/groupsLegacy/data/groupsData';
import { sortByParameters } from 'features/legacy/groupsLegacy/sortGroups';

const createGroupFromLog = (
  coreData,
  optionalData,
  groupLinks,
) => {
  const {
    groupName,
    groupLevel,

    keyForCustomGroup,
    currentLog,
  } = coreData;

  const {
    dateFormat,
    isIncludesSubtask,
    fieldTypesByNames = {},
    customFieldNamesByKeys,
  } = optionalData;

  const serviceVariables = {
    _level: groupLevel,
    _cellType: groupName,
    _children: [],

    _issues: {
      [currentLog[(optionalData.isIncludesSubtask && currentLog.parentKey) ? 'parentKey' : 'issueKey']]: true,
    },
    _worklogs: {
      [currentLog.worklogId]: true,
    },
  };

  // TODO: make getDataForGroup more dynamic, create data for it by group if possible
  // will be fixed in FEWR-2125
  const dataForGroup = getDataForGroup(
    {
      currentLog,
      serviceVariables,
      keyForCustomGroup,
      groupName,
    },
    {
      isIncludesSubtask, dateFormat, fieldTypesByNames,
    },
    groupLinks,
  );

  const group = {
    ...dataForGroup[groupName],
  };

  if (!group.key) {
    group.key = null;
    group.name = getEmptyName(groupName, customFieldNamesByKeys);
  }

  return group;
};

const createOrUpdateGroup = (
  coreData,
  optionalData,
) => {
  const {
    group,
    accumulator,
    currentLog,

    groupLinks,
    groupLevel,
    groupsArray,

    extender,
  } = coreData;

  const { groupName, currentGroupKey, type } = group;

  const foundPrevGroups = findPrevGroup(groupLevel, groupsArray, accumulator, groupLinks);

  let createdOrUpdatedGroup;

  switch (type) {
    case 'singleGroup': {
      if (groupLevel === 0) {
        const existGroup = findGroupInArray(accumulator, currentLog[currentGroupKey], groupName);

        if (existGroup) {
          updateExistingGroup(currentLog, existGroup, optionalData);

          createdOrUpdatedGroup = existGroup;
        } else {
          const createdGroup = createGroupFromLog(
            {
              groupName,
              groupLevel,
              currentLog,
            },
            optionalData,
            groupLinks,
          );

          const newGroup = { ...createdGroup, _parent: null };
          accumulator.push(newGroup);

          createdOrUpdatedGroup = newGroup;
        }

        extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
      } else {
        foundPrevGroups.forEach((foundPrevGroupItem) => {
          const existGroupInPrev = findGroupInArray(
            foundPrevGroupItem._children,
            currentLog[currentGroupKey],
            groupName,
          );

          const parentKey = foundPrevGroupItem.key;

          if (existGroupInPrev) {
            if (parentKey === existGroupInPrev._parent) {
              updateExistingGroup(currentLog, existGroupInPrev, optionalData);

              createdOrUpdatedGroup = existGroupInPrev;
            }
          } else {
            const createdGroup = createGroupFromLog(
              {
                groupName,
                groupLevel,
                currentLog,
              },
              optionalData,
              groupLinks,
            );

            const groupWithParentLink = {
              ...createdGroup,
              _parent: parentKey,
            };

            foundPrevGroupItem._children.push(groupWithParentLink);

            createdOrUpdatedGroup = groupWithParentLink;
          }

          extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
        });
      }

      break;
    }

    case 'multipleGroup': {
      if (!currentLog[currentGroupKey]) {
        if (groupLevel === 0) {
          const existMultipleGroup = findGroupInArray(accumulator, null, groupName);

          if (existMultipleGroup) {
            updateExistingGroup(currentLog, existMultipleGroup, optionalData);

            createdOrUpdatedGroup = existMultipleGroup;
          } else {
            const emptyMultipleGroup = createGroupFromLog(
              {
                groupName,
                groupLevel,
                currentLog,
              },
              optionalData,
              groupLinks,
            );

            const newEmptyGroup = { ...emptyMultipleGroup, _parent: null };

            accumulator.push(newEmptyGroup);

            createdOrUpdatedGroup = newEmptyGroup;
          }

          extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
        } else {
          foundPrevGroups.forEach((prevGroup) => {
            const foundGroup = prevGroup._children.find((item) => item.key === null);
            const parentKey = prevGroup.key;

            if (foundGroup) {
              if (foundGroup._parent === parentKey) {
                updateExistingGroup(currentLog, foundGroup, optionalData);

                createdOrUpdatedGroup = foundGroup;
              }
            } else {
              const emptyMultipleGroup = createGroupFromLog(
                {
                  groupName,
                  groupLevel,
                  currentLog,
                },
                optionalData,
                groupLinks,
              );

              const newMultipleGroup = { ...emptyMultipleGroup, _parent: parentKey };

              prevGroup._children.push(newMultipleGroup);

              createdOrUpdatedGroup = newMultipleGroup;
            }

            extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
          });
        }

        break;
      }

      currentLog[currentGroupKey].forEach((currentGroup) => {
        if (groupLevel === 0) {
          const existMultipleGroup = findGroupInArray(accumulator, currentGroup, groupName);

          if (existMultipleGroup) {
            updateExistingGroup(currentLog, existMultipleGroup, optionalData);

            createdOrUpdatedGroup = existMultipleGroup;
          } else {
            const multipleGroup = createGroupFromLog(
              {
                groupName,
                groupLevel,
                currentLog,
                keyForCustomGroup: currentGroup,
              },
              optionalData,
              groupLinks,
            );

            const newMultipleGroup = { ...multipleGroup, _parent: null };

            accumulator.push(newMultipleGroup);

            createdOrUpdatedGroup = newMultipleGroup;
          }

          extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
        } else {
          foundPrevGroups.forEach((prevGroup) => {
            const foundMultipleGroup = prevGroup._children.find((item) => item.key === currentGroup);
            const parentKey = prevGroup.key;

            if (foundMultipleGroup) {
              if (foundMultipleGroup._parent === parentKey) {
                updateExistingGroup(currentLog, foundMultipleGroup, optionalData);

                createdOrUpdatedGroup = foundMultipleGroup;
              }
            } else {
              const multipleGroup = createGroupFromLog(
                {
                  groupName,
                  groupLevel,
                  currentLog,
                  keyForCustomGroup: currentGroup,
                },
                optionalData,
                groupLinks,
              );

              const newMultipleGroup = { ...multipleGroup, _parent: parentKey };

              prevGroup._children.push(newMultipleGroup);

              createdOrUpdatedGroup = newMultipleGroup;
            }

            extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
          });
        }
      });

      break;
    }

    case 'dateGroup': {
      const existDateGroup = findGroupInArray(
        accumulator,
        currentLog.startDate
          ? formatDateForDateGroup(currentLog.startDate, optionalData.dateFormat)
          : null,
        groupName,
      );

      if (groupLevel === 0) {
        if (existDateGroup) {
          updateExistingGroup(currentLog, existDateGroup, optionalData);

          createdOrUpdatedGroup = existDateGroup;
        } else {
          const dateGroup = createGroupFromLog(
            {
              groupName,
              groupLevel,
              currentLog,
            },
            optionalData,
            groupLinks,
          );

          const newDateGroup = { ...dateGroup, _parent: null };

          accumulator.push(newDateGroup);

          createdOrUpdatedGroup = newDateGroup;
        }

        extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
      } else {
        foundPrevGroups.forEach((prevGroup) => {
          const foundDateGroup = prevGroup._children.find(
            (item) => item.key === formatDateForDateGroup(currentLog.startDate, optionalData.dateFormat),
          );

          const parentKey = prevGroup.key;

          if (foundDateGroup) {
            if (parentKey === foundDateGroup._parent) {
              updateExistingGroup(currentLog, foundDateGroup, optionalData);

              createdOrUpdatedGroup = foundDateGroup;
            }
          } else {
            const dateGroup = createGroupFromLog(
              {
                groupName,
                groupLevel,
                currentLog,
              },
              optionalData,
              groupLinks,
            );

            const newDateGroup = { ...dateGroup, _parent: parentKey };

            prevGroup._children.push(newDateGroup);

            createdOrUpdatedGroup = newDateGroup;
          }

          extender && typeof extender === 'function' && extender(currentLog, createdOrUpdatedGroup);
        });
      }

      break;
    }

    default:
      console.error('[group][createGroupObjects] Wrong group');
      break;
  }
};

export const createGroupedData = (
  groupingData,
  optionalData,
  extender,
) => {
  const { rawData, groupBy, sortBy } = groupingData;

  const tableData = rawData
    .reduce((accumulator, currentLog) => {
      const keysByGroupName = getKeysForCurrentLog(currentLog, optionalData);

      const groupLinksForCurrentLog = {};

      groupBy
        .forEach((groupName, groupLevel, groupsArray) => {
          const currentGroupKey = keysByGroupName[groupName]
            ? keysByGroupName[groupName]
            : groupName;

          let currentGroupType = getGroupTypeByGroupName(groupName);

          if (!currentGroupType) {
            const mapFieldTypeToGroupType = {
              option: 'singleGroup',
              array: 'multipleGroup',
              'option-with-child': 'singleGroup',
            };

            if (optionalData.fieldTypesByNames) {
              const fieldType = optionalData.fieldTypesByNames[groupName];

              currentGroupType = mapFieldTypeToGroupType[fieldType];
            }
          }

          groupLinksForCurrentLog[groupName] = currentGroupType === 'dateGroup'
            ? formatDateForDateGroup(currentLog[currentGroupKey], optionalData.dateFormat)
            : currentLog[currentGroupKey];

          const coreData = {
            group: {
              groupName,
              type: currentGroupType,
              currentGroupKey,
            },

            extender,

            accumulator,
            currentLog,

            groupLevel,
            groupsArray,
            groupLinks: groupLinksForCurrentLog,
          };

          createOrUpdateGroup(
            coreData,
            optionalData,
          );
        });

      return accumulator;
    }, []);

  sortByParameters(tableData, sortBy);

  const flattenedArray = flatTreeInArray(tableData);

  return flattenedArray;
};
