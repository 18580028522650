import { ReportPermissionsList } from 'sharedFrontBack/src/types/reportPermissions';
import { ShareableLink } from 'sharedFrontBack/src/types/report';
import { UserPermissions } from 'types/report';

interface Params {
  reportId?: string | number;
  userId?: string;
  createdBy?: string;
  reportPermissions: undefined | ReportPermissionsList;
  userPermissions: UserPermissions;
  sharedIdHash?: ShareableLink['sharedIdHash'];
  sharedId?: string;
  isPredefined?: boolean;
}

export const getReportUserRole = ({
  reportId,
  userId,
  createdBy,
  reportPermissions,
  userPermissions,
  sharedIdHash,
  sharedId,
  isPredefined,
}: Params) => {
  if (!reportId || (createdBy && userId && userId === createdBy)) {
    return 'admin';
  }
  if (isPredefined) {
    return 'configurator';
  }
  if (!reportPermissions || !userPermissions) {
    return 'no-view-edit-permissions';
  }

  const { concatenated } = userPermissions;

  const { hasEditPermissions, hasViewPermissions } = reportPermissions?.reduce((acc, p) => {
    const isPermissionMatchUser = concatenated.includes(p.value);
    if (isPermissionMatchUser && p.category === 'edit') {
      return { ...acc, hasEditPermissions: true };
    }
    if (isPermissionMatchUser) {
      return { ...acc, hasViewPermissions: true };
    }
    return acc;
  }, { hasEditPermissions: false, hasViewPermissions: false }) ?? {};

  if (hasEditPermissions) {
    return 'configurator';
  }

  if (hasViewPermissions) {
    return 'viewer';
  }

  if (sharedIdHash && sharedIdHash === sharedId) {
    return 'viewer';
  }

  return 'no-view-edit-permissions';
};