const globalConfig = {
  method: 'POST',
  allPagesConcurrently: true,
  fieldName: 'issues',
  maxResults: 50,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/search',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/search',
  },
};

export { config as searchJqlPost };
