import { API } from 'controllers/API';
import { TransportController as Transport } from 'controllers/transport/TransportController';
import { RequestApiParams, RequestApiQs } from 'controllers/transport/types';

const createQs = (obj: RequestApiQs) => {
  const keys = Object.keys(obj);
  return keys.reduce((acc, key) => {
    return [...acc, `${key}=${obj[key]}`];
  }, [] as string[]).join('&');
};

const configProp = process.env.JIRA_TYPE === 'cloud' ? 'cloud' : 'server';

export const requestApi = async <TResponse>(params: RequestApiParams) => {
  const {
    path,
    qs,
    method,
    headers,
    data,
    allPages,
  } = params;

  const queryString = qs ? createQs(qs) : '';
  const url = `${path}${queryString ? `?${queryString}` : ''}`;

  if (!path.startsWith('/rest/')) {
    // @ts-expect-error
    const response = await API.request({
      url,
      method,
      headers,
      data,
    });
    return response.data as TResponse;
  }

  const transportConfig = {
    [configProp]: {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(headers ? headers : {}),
      },
      allPages,
      url,
    },
  };
  // @ts-expect-error
  return Transport.request({ config: transportConfig, data }) as Promise<TResponse>;
};
