import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getPluginApiBaseUrl } from 'store/api/plugin/baseApi/getPluginApiBaseUrl';
import { getJwtToken } from 'utils/countWorklogs';

export const pluginBaseApi = createApi({
  reducerPath: 'pluginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getPluginApiBaseUrl(),
    ...(IS_CLOUD ? {
      prepareHeaders: async (headers) => {
        const token = await getJwtToken();
        headers.set('Authorization', `JWT ${token}`);
        return headers;
      },
    } : {}),
  }),
  endpoints: () => ({}),
});