export const searchIssues = (data) => {
  const result = [...data[0].issues];

  if (data[1] && data[1].issues) {
    for (const issue of data[1].issues) {
      if (result.findIndex((r) => r.key === issue.key) === -1) {
        result.push(issue);
      }
    }
  }

  return result;
};
