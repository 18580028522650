import { searchProject } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: searchProject,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/project',
  },
  cloud: {
    allPages: true,
    fieldName: 'values',
    ...globalConfig,
    url: '/rest/api/3/project/search',
  },
};

export { config as searchProject };
