export const filterOptionsBasedOnApplications = (options, areSoftwareFieldsInitialized, isServiceDeskInstalled) => {
  if (!options) return [];

  return options.filter(
    ({ isSoftwareOption, isServiceDeskOption }) => {
      if (areSoftwareFieldsInitialized && isServiceDeskInstalled) return true;

      if (!isSoftwareOption && !isServiceDeskOption) return true;

      if (isSoftwareOption && areSoftwareFieldsInitialized) return true;
      if (isServiceDeskOption && isServiceDeskInstalled) return true;

      return false;
    },
  );
};
