import { requestApi } from 'controllers/transport/requestApi';

let existPropertiesCache = [];

export const getAllUserProperties = async ({ propertyOwnerId, withCache = false }) => {
  if (!propertyOwnerId) {
    throw new Error('Cannot without propertyOwnerId');
  }
  if (withCache && existPropertiesCache.length) {
    return existPropertiesCache;
  }

  try {
    const response = await requestApi({
      method: 'GET',
      ...(IS_CLOUD ? {
        qs: { accountId: propertyOwnerId },
        path: '/rest/api/3/user/properties',
      } : {
        qs: { userKey: propertyOwnerId },
        path: '/rest/api/2/user/properties',
      }),
    });

    if (response?.keys?.length) {
      existPropertiesCache = response.keys.map((meta) => meta.key);
      return existPropertiesCache;
    }
  } catch (e) {
    console.error('[Exception][getAllUserProperties]', e);
  }

  return [];
};

export const getUserProperty = async (propertyKey, { propertyOwnerId }) => {
  const allProperties = await getAllUserProperties({ propertyOwnerId, withCache: true });

  if (!propertyKey) {
    throw new Error('Cannot getUserProperty without propertyKey');
  }
  if (!allProperties?.includes(propertyKey)) {
    return;
  }

  const params = {
    method: 'GET',
    ...(IS_CLOUD ? {
      qs: { accountId: propertyOwnerId },
      path: `/rest/api/3/user/properties/${propertyKey}`,
    } : {
      qs: { userKey: propertyOwnerId },
      path: `/rest/api/2/user/properties/${propertyKey}`,
    }),
  };

  let response;
  try {
    response = await requestApi(params);
  } catch (e) {
    if (e?.xhr?.status === 404) {
      return;
    }
    console.error('[Exception][getAllUserProperties]', e);
  }
  return response?.value;
};

export const saveUserProperty = async (propertyKey, propertyValue, { propertyOwnerId }) => {
  if (!propertyKey) {
    throw new Error('Cannot saveUserProperty without propertyKey');
  }
  const stringifiedData = JSON.stringify(propertyValue);
  const data = IS_CLOUD ? new Blob([stringifiedData], { type: 'application/json' }) : stringifiedData;

  await requestApi({
    data,
    method: 'PUT',
    allPages: false,
    ...(IS_CLOUD ? {
      qs: { accountId: propertyOwnerId },
      path: `/rest/api/3/user/properties/${propertyKey}`,
    } : {
      qs: { userKey: propertyOwnerId },
      path: `/rest/api/2/user/properties/${propertyKey}`,
    }),
  });

  if (!existPropertiesCache.includes(propertyKey)) {
    existPropertiesCache.push(propertyKey);
  }
};
