import { userData } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  migration: userData,
  allPages: false,
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/user',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/user',
  },
};

export { config as fetchUserData };
