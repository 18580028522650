import { checkIsServiceDeskInstalled as checkIsServiceDeskInstalledMigration } from 'controllers/transport/migrations/checkIsServiceDeskInstalled';

const globalConfig = {
  method: 'GET',
  migration: checkIsServiceDeskInstalledMigration,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const config = {
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/instance/license',
  },
  server: {
    ...globalConfig,
    url: '/rest/api/2/applicationrole',
  },
};

export { config as checkIsServiceDeskInstalled };
