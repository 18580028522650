import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import { PostHogProvider as BasePostHogProvider } from 'posthog-js/react';
import { useDebouncedCallback } from 'use-debounce';
import { posthogProjectKey, posthogApiHost } from 'constants/constants';
import { useGetReports } from 'store/hooks/reports/useGetReports';
import RoutePaths from 'router/routePaths';

const USER_INACTIVE_TIMEOUT = 15000;

const REPORTS_PAGES: string[] = [RoutePaths.reports, RoutePaths.scriptedReportsDetailPage, RoutePaths.reportPage];

export const PostHogProvider = () => {
  const [isUserIncative, setIsUserIncative] = useState(false);
  const isGadget = window.ActonicRB?.isGadget;
  const isDevelopment = RUNTIME_ENV === 'development';

  const { pathname } = useLocation();
  const { data: reportsList } = useGetReports();

  const isSurveyMatchShowLogic = isUserIncative
    && !isGadget
    && reportsList.length !== 0
    && REPORTS_PAGES.includes(pathname)
    && !isDevelopment;

  const changePosthogInitState = () => {
    setIsUserIncative(true);
  };

  const handleChangePosthogInitState = useDebouncedCallback(changePosthogInitState, USER_INACTIVE_TIMEOUT);

  useLayoutEffect(() => {
    if (!isSurveyMatchShowLogic) {
      document.addEventListener('keydown', handleChangePosthogInitState);
      document.addEventListener('pointerdown', handleChangePosthogInitState);
      handleChangePosthogInitState();
    }
    return () => {
      document.removeEventListener('keydown', handleChangePosthogInitState);
      document.removeEventListener('pointerdown', handleChangePosthogInitState);
    };
  }, [handleChangePosthogInitState, isSurveyMatchShowLogic]);

  if (!isSurveyMatchShowLogic) {
    return null;
  }

  return (
    <PostHogProviderInner />
  );
};

const PostHogProviderInner = () => {
  posthog.init(posthogProjectKey, {
    api_host: posthogApiHost,
  });

  return (
    <BasePostHogProvider
      client={posthog}
    >
    </BasePostHogProvider>
  );
};
