export const permissionTypes = {
  WORK_ON_ISSUES: 'WORK_ON_ISSUES',
  VIEW_WORK_LOGS: 'VIEW_WORK_LOGS',
  MANAGE_WORK_LOGS: 'MANAGE_WORK_LOGS',
};

export const getTeamValue = (properties) => {
  if (Array.isArray(properties)) {
    const foundProperty = properties.find(({ key }) => key === 'teamValue');

    if (foundProperty) {
      return foundProperty.value?.value;
    }
  }

  return null;
};

export const addWorklogTeamPermissions = (worklogs, { teamPermissions, currentUserAccountId }) => {
  if (!currentUserAccountId) return [];

  return worklogs.reduce((acc, cur) => {
    const { properties } = cur;
    const worklogAuthorAccountId = cur?.author?.accountId;
    const worklogAuthorKey = cur?.author?.key;

    if (currentUserAccountId === worklogAuthorAccountId || currentUserAccountId === worklogAuthorKey) {
      return [
        ...acc,
        { ...cur, isOwner: true },
      ];
    }

    const teamValue = getTeamValue(properties);
    if (!teamValue) return [...acc, cur];

    const foundTeamPermission = teamPermissions.find(({ teamId }) => teamId.toString() === teamValue.toString());
    if (!foundTeamPermission) return acc;

    const { teamLead, permissions } = foundTeamPermission;

    if (teamLead && teamLead === currentUserAccountId) return [...acc, { ...cur, isController: true, isManager: true }];

    if (!Array.isArray(permissions) || permissions.length === 0) return acc;

    const isController = permissions.includes(permissionTypes.VIEW_WORK_LOGS);
    const isManager = permissions.includes(permissionTypes.MANAGE_WORK_LOGS);

    if (!isController && !isManager) return acc;

    return [...acc, { ...cur, isController, isManager }];
  }, []);
};

export const checkWorklogsPermissions = (worklogs, { teamPermissions, currentUserAccountId }, teamsList = []) => {
  if (!currentUserAccountId) return [];
  return worklogs
    .reduce(
      (acc, current) => {
        const { properties } = current;

        const worklogAuthorAccountId = current?.author?.accountId;
        const worklogAuthorKey = current?.author?.key;

        if (currentUserAccountId === worklogAuthorAccountId || currentUserAccountId === worklogAuthorKey) {
          return [
            ...acc,
            { ...current, isOwner: true },
          ];
        }

        const teamValue = getTeamValue(properties);
        const foundTeamName = teamsList.find(({ _id }) => _id === teamValue)?.name;
        const foundTeamValue = (IS_CLOUD && foundTeamName) || !IS_CLOUD ? teamValue : null;

        if (!foundTeamValue) return [...acc, { ...current, isEmptyTeam: true }];

        const foundTeamPermission = teamPermissions.find(({ teamId }) => teamId.toString() === foundTeamValue.toString());
        if (!foundTeamPermission) return [...acc, { ...current, isHiddenWorklog: true }];

        const { teamLead, permissions } = foundTeamPermission;

        if (teamLead && teamLead === currentUserAccountId) return [...acc, { ...current, isController: true, isManager: true }];

        if (!Array.isArray(permissions) || permissions.length === 0) return [...acc, { ...current, isHiddenWorklog: true }];

        const isController = permissions.includes(permissionTypes.VIEW_WORK_LOGS);
        const isManager = permissions.includes(permissionTypes.MANAGE_WORK_LOGS);

        if (!isController && !isManager) return [...acc, { ...current, isHiddenWorklog: true }];

        return [...acc, { ...current, isController, isManager }];
      },
      [],
    );
};
