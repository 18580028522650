import {
  IssueController as ActonicIssueController,
  TransportControllerForPrivateAPI,
} from 'actonic-controllers';
import {
  TransportController as Transport,
} from 'controllers';

import {
  setLoadingState,
} from 'store/legacy/progressBar/actions';

const APIforTM = new TransportControllerForPrivateAPI('/rest/actonic-tb/1.0/api');

export const getSecuredWorklogs = async (
  { issues, processId, fieldsList, expand },
  { getState, dispatch },
) => {
  if (!issues.length) {
    return [];
  }

  const issueController = new ActonicIssueController({
    transport: Transport,
    store: { getState, dispatch },
    setLoadingState,
    API: APIforTM,
  });

  const searchParams = {
    issues: issues.map(({ key }) => key),
  };

  const newIssues = await issueController.searchIssuesByParams(searchParams, fieldsList, expand, true, processId);

  const rawIssuesWithSecuredWorklogs = await issueController.getFieldsForIssues(['worklog'], newIssues, processId);

  const issuesWithSecuredWorklogs = rawIssuesWithSecuredWorklogs.map((item) => {
    const worklogs = item?.fields?.worklog?.worklogs;
    const newWorklogs = worklogs?.map((worklogItem) => {
      return {
        ...worklogItem,
        started: new Date(worklogItem?.started).toISOString(),
        created: new Date(worklogItem?.created).toISOString(),
        updated: new Date(worklogItem?.updated).toISOString(),
      };
    });
    return {
      ...item,
      fields: {
        ...item?.fields,
        worklog: {
          ...item?.fields.worklog,
          worklogs: newWorklogs,
        },
      },
    };
  });

  issues.forEach((issue) => {
    const shouldBeAdded = !issuesWithSecuredWorklogs.find(({ key }) => key === issue.key);
    if (shouldBeAdded) {
      issuesWithSecuredWorklogs.push(issue);
    }
  });

  return issuesWithSecuredWorklogs;
};
