import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintContext } from 'features/print/components/contexts';
import { PrintContextValue, PrintContainerRefValue, PrintContextProviderProps } from 'features/print/types';

const print = async (target: HTMLIFrameElement) => {
  if (target.contentWindow) {
    const appHtmlEl = document.querySelector('html');
    const targetHtmlEl = target.contentDocument?.querySelector('html');
    if (appHtmlEl && targetHtmlEl) {
      Object.values(appHtmlEl.attributes).forEach((attr) => {
        targetHtmlEl.setAttribute(attr.name, attr.value);
      });
    }
    target.contentWindow.print();
  } else {
    console.error('print iframe is not ready');
  }
};

export const PrintContextProvider = ({ children, extraStyles, removeAfterPrint = false }: PrintContextProviderProps) => {
  const containerInnerRef = useRef<PrintContainerRefValue>(null);

  const getPrintElement = useCallback(() => {
    if (!containerInnerRef?.current?.current) {
      throw new Error('PrintContextProvider.containerInnerRef is not set');
    }
    return containerInnerRef.current.current;
  }, []);

  const onBeforeGetContent = () => {
    const printElement = getPrintElement();
    if (!printElement) return;

    const printDocument = printElement.ownerDocument;
    if (!printDocument) return;

    const styleEl = printDocument.createElement('style');
    styleEl.id = 'print-styles';
    styleEl.innerHTML = extraStyles ?? '';

    printDocument.head.appendChild(styleEl);
  };

  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    print,
    content: getPrintElement,
    removeAfterPrint,
    onAfterPrint: async () => {
      setIsPrinting(false);
    },
    onBeforeGetContent,
  });

  const handlePrintClick = useCallback(() => {
    setIsPrinting(true);
    setTimeout(handlePrint, 1);
  }, [handlePrint]);

  const printContextValueFinal = useMemo<PrintContextValue>(() => ({
    setContainerRef: (ref) => {
      containerInnerRef.current = ref;
    },
    getPrintElement,
    isPrinting,
    handlePrintClick,
  }), [getPrintElement, handlePrintClick, isPrinting]);

  return (
    <PrintContext.Provider value={printContextValueFinal}>
      {children}
    </PrintContext.Provider>
  );
};