import amplitude from 'amplitude-js';
import { sha256 } from 'utils/sha256';
import { AmplitudeEvents } from 'features/amplitude/constants/amplitudeEvents';

const eventInstancePrefix = IS_CLOUD ? 'JIRA_CLOUD' : 'JIRA_SERVER';
const eventInstancePostfix = RUNTIME_ENV === 'production' ? '' : `-${RUNTIME_ENV}`;
const eventInstanceName = eventInstancePrefix + eventInstancePostfix;
const amplitudeInstanceName = 'actonic-' + eventInstanceName;
const amplitudeApiKey = '5366cac4cbb5d10250b8815faeca2aa2';

const blacklistRoutePaths = [
  'scripted-reports-issue-picker',
];

const withDebug = false;
let isAplitudeInited = false;

export const initAmplitude = async () => {
  if (isAplitudeInited) {
    console.log('amplitude already inited');
    return;
  }

  isAplitudeInited = true;

  if (!withDebug && RUNTIME_ENV === 'development') {
    console.log('initAmplitude stub in dev mode');
    return;
  }

  const routePathSource = IS_CLOUD ? window.location.pathname : window.location.hash.replace(/[#\/]+/, '');

  const isBlacklistedPath = Boolean(!routePathSource || (
    blacklistRoutePaths.find((routePath) => routePathSource.match(routePath))
  ));

  withDebug && console.log('initAmplitude', { routePathSource, isBlacklistedPath }, window.location.pathname);

  if (isBlacklistedPath) {
    withDebug && console.log(`initAmplitude: ${window.location.pathname} path is blacklisted, skipping init`);
    return;
  }

  // todo make unified getAmplitudeUserHash (add implementation for cloud, for srIframe app) https://actonic.atlassian.net/browse/RB-1964
  if (IS_CLOUD) {
    amplitude.getInstance(amplitudeInstanceName).init(amplitudeApiKey);
  } else if (window.AJS) {
    const user = window.AJS.Meta.get('remote-user');
    const url = window.AJS.Meta.get('base-url');
    const hash = await sha256(url + user);

    amplitude.getInstance(amplitudeInstanceName).init(amplitudeApiKey, hash.toString());
  }
};

export const sendStatsToAmplitude = <
  Additional extends Record<string, unknown> = Record<string, unknown>,
>(eventName: AmplitudeEvents, additionalProperties?: Additional) => {
  const { userAccountId, licenseStatus, clientKey, reportBuilderVersion } = window;

  if (!withDebug && RUNTIME_ENV === 'development') {
    console.log('sendToAmplitude stub in dev mode', eventName, additionalProperties);
    return;
  }

  if (amplitude) {
    amplitude.getInstance(amplitudeInstanceName).logEvent(eventName, {
      instanceType: eventInstanceName,
      url: '',
      instanceId: clientKey,
      license: licenseStatus,
      userAccountId,
      version: reportBuilderVersion,
      ...(typeof additionalProperties === 'object' && { ...additionalProperties }),
    });
  }
};
