import { getUserByGroup } from 'controllers/transport/migrations';

const globalConfig = {
  method: 'GET',
  allPages: true,
  migration: getUserByGroup,
  fieldName: 'values',
};

const config = {
  server: {
    ...globalConfig,
    url: '/rest/api/2/group/member?groupname=',
  },
  cloud: {
    ...globalConfig,
    url: '/rest/api/3/group/member?groupname=',
  },
};

export { config as getUserByGroup };
