import { moment } from 'controllers/moment';

export const formatValueByFieldType = (fieldType, value, dateFormat = 'd/MMM/yy') => {
  if (!value) return '';

  switch (fieldType) {
    case 'date':
    case 'datetime':
    case 'duedate':
      return moment(value).formatWithJDF(dateFormat);

    case 'number':
      return value;

    case 'project':
      return `(${value.key}) ${value.name}`;

    case 'watches':
      return value.watchCount;

    case 'comments-page':
      return value.comments && `${value.comments.length} comments`;

    case 'priority':
      return value.iconUrl;

    case 'status':
      return {
        color: value.statusCategory.colorName,
        name: value.name,
        isObjectValue: true,
      };

    case 'issuetype':
    case 'resolution':
    case 'security':
      return value.name;

    case 'timetracking':
      return value.timeSpent;

    case 'assignee':
      return value.displayName || 'Unassigned';

    case 'user':
    case 'reporter':
      return value.displayName;

    case 'progress':
      return `${value.percent}%`;

    case 'votes':
      return value.votes;

    case 'parent':
      return value.key;

    case 'array':
      if (Array.isArray(value)) {
        return value.reduce(
          (acc, current, index) => {
            if (typeof current === 'string') {
              acc += index === 0
                ? `${current}`
                : `, ${current}`;
            } else if (current.name) {
              acc += index === 0
                ? `${current.name}`
                : `, ${current.name}`;
            } else if (current.type && current.inwardIssue) {
              acc += index === 0
                ? `${current.type.inward} ${current.inwardIssue.key}`
                : `, ${current.type.inward} ${current.inwardIssue.key}`;
            }

            return acc;
          },
          '',
        );
      }

      if (value && value.comments) {
        return value.reduce(
          (acc, current, index) => {
            if (typeof current === 'string') {
              acc += index === 0
                ? `${current}`
                : `, ${current}`;
            }

            return acc;
          },
          '',
        );
      }

      break;

    case 'issuelinks':
      return Array.isArray(value) ? value.map((link) => {
        const { inwardIssue = null, outwardIssue = null } = link;
        if (inwardIssue) {
          const { key, fields: { summary } } = inwardIssue;
          return `(${key}) ${summary}`;
        }
        if (outwardIssue) {
          const { key, fields: { summary } } = inwardIssue;
          return `(${key}) ${summary}`;
        }
        return '';
      }).join(', ') : '';

    case 'issuerestriction':
      return value.shouldDisplay ? 'Should display' : 'Shouldn\'nt display';

    case 'components':
    case 'versions':
    case 'fixVersions':
      return Array.isArray(value) ? value.map(({ name }) => name).join(', ') : '';

    case 'labels':
      return Array.isArray(value) ? value.map((item) => item).join(', ') : '';

    case 'description':
    case 'environment':
    default:
      return value;
  }
};
