export const selectIsAppConfigFetched = ({ app }) => app.isConfigFetched;
export const selectIsAppConfigFetching = ({ app }) => app.isConfigFetching;

export const selectAreSoftwareFieldsInitialized = ({ app }) => app.areSoftwareFieldsInitialized;

export const selectFieldsOptions = ({ app }) => app.fieldsOptions;
export const selectFieldsList = ({ app }) => app.fieldsList;
export const selectIsUnapprovedReportsEnabled = ({ app }) => Boolean(app.isUnapprovedReportsEnabled);
export const selectGalleryViewType = ({ app }) => app.galleryViewType;
export const selectLocale = ({ app }) => app.locale;
export const selectTimeTrackingUnit = ({ app }) => app.timeTrackingUnit;
export const selectReportsListSortBy = ({ app }) => app.reportsListSortBy;