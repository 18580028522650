import {
  MINUTE_IN_SECONDS, HOURS_IN_MINUTES, HOURS_IN_SECONDS,
} from 'constants/constants';

const hourInSeconds = MINUTE_IN_SECONDS * HOURS_IN_MINUTES;

export const formatToMinutes = (seconds) => {
  const roundedValue = Math.round((seconds / MINUTE_IN_SECONDS) * 100) / 100;

  if (roundedValue < 0.02) return 0.02;

  return roundedValue;
};

export const formatToHours = (seconds) => {
  const roundedValue = Math.round((seconds / HOURS_IN_SECONDS) * 100) / 100;

  if (roundedValue < 0.02) return 0.02;

  return roundedValue;
};

const formatHoursToSeconds = (hours) => hours * hourInSeconds;

export const formatToDays = (seconds) => {
  const { workingHoursPerDay } = window.ActonicRB.timeTrackingConfiguration;
  const dayInSeconds = HOURS_IN_SECONDS * workingHoursPerDay;
  const roundedValue = seconds / dayInSeconds > 1
    ? Math.round(((seconds / dayInSeconds) + Number.EPSILON) * 100) / 100
    : Math.round(((seconds / dayInSeconds) + Number.EPSILON) * 1000) / 1000;

  if (roundedValue < 0.002) return 0.002;

  return roundedValue;
};

const getTimeCounter = (value, duration, name) => {
  const result = Math.floor((value / duration));

  return { [name]: result };
};

export const getTotalInSecondsForWorklogs = (worklogs) => worklogs.reduce(
  (acc, worklog) => {
    if (worklog.loggedTime) {
      acc += worklog.loggedTime;
    }

    return acc;
  },
  0,
);

export const getPrettyPrintedTime = (time) => {
  let result = '';
  let value = time;

  const { workingHoursPerDay, workingDaysPerWeek } = window.ActonicRB.timeTrackingConfiguration;

  const dayInSeconds = hourInSeconds * workingHoursPerDay;
  const weekInSeconds = dayInSeconds * workingDaysPerWeek;

  const { week } = getTimeCounter(value, weekInSeconds, 'week');
  if (week && week > 0) {
    value -= (week * weekInSeconds);
    result += `${week}w `;
  }

  const { day } = getTimeCounter(value, dayInSeconds, 'day');
  if (day && day > 0) {
    value -= (day * dayInSeconds);
    result += `${day}d `;
  }

  const { hour } = getTimeCounter(value, hourInSeconds, 'hour');
  if (hour && hour > 0) {
    value -= (hour * hourInSeconds);
    result += `${hour}h `;
  }

  const { minute } = getTimeCounter(value, MINUTE_IN_SECONDS, 'minute');
  if (minute && minute > 0) {
    result += `${minute}m `;
  }

  if (result && result.length > 0) {
    return result.trim();
  }

  return '0h';
};

export const getTimeInDays = (seconds, isWithDimension = true) => {
  const result = formatToDays(seconds);

  return isWithDimension ? `${result}d` : result;
};

export const getTimeInHours = (time, isWithDimension = true) => {
  const result = formatToHours(time);

  return isWithDimension ? `${result}h` : result;
};

export const getTimeInMinutes = (seconds, isWithDimension = true) => {
  const result = formatToMinutes(seconds);

  return isWithDimension ? `${result}m` : result;
};

export const getTimeByTimeTrackingUnit = (timeTrackingUnit, seconds, isWithDimension = true) => {
  if (seconds === 0) return 0;

  switch (timeTrackingUnit) {
    case 'prettyPrint':
      return getPrettyPrintedTime(seconds);

    case 'days':
      return getTimeInDays(seconds, isWithDimension);

    case 'hours':
      return getTimeInHours(seconds, isWithDimension);

    case 'minutes':
    default:
      return getTimeInMinutes(seconds, isWithDimension);
  }
};

export const addDimensionUnit = (timeTrackingUnit, timeValue) => {
  if (timeValue === 0) return 0;

  switch (timeTrackingUnit) {
    case 'prettyPrint':
      const seconds = formatHoursToSeconds(timeValue);

      return getPrettyPrintedTime(seconds);

    case 'days':
      return `${timeValue}d`;

    case 'hours':
      return `${timeValue}h`;

    case 'minutes':
    default:
      return `${timeValue}m`;
  }
};
