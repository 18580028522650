enum ReportActionTypes {
  GET_REPORTS_STARTED = 'GET_REPORTS_STARTED',
  GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE',

  GET_DASHBOARD_REPORTS_SUCCESS = 'GET_DASHBOARD_REPORTS_SUCCESS',

  SET_IS_CURRENT_REPORT_WITH_CHANGES = 'SET_IS_CURRENT_REPORT_WITH_CHANGES',
  SET_LAST_FILTER_AS_STRING = 'SET_LAST_FILTER_AS_STRING',

  GET_ADDITIONAL_COLUMNS_START = 'GET_ADDITIONAL_COLUMNS_START',
  GET_ADDITIONAL_COLUMNS_SUCCESS = 'GET_ADDITIONAL_COLUMNS_SUCCESS',
  GET_ADDITIONAL_COLUMNS_FAILURE = 'GET_ADDITIONAL_COLUMNS_FAILURE',

  GET_SCRIPTED_REPORT_STARTED = 'GET_SCRIPTED_REPORT_STARTED',
  GET_SCRIPTED_REPORT_SUCCESS = 'GET_SCRIPTED_REPORT_SUCCESS',
  GET_SCRIPTED_REPORT_FAILURE = 'GET_SCRIPTED_REPORT_FAILURE',

  GET_CURRENT_REPORT_STARTED = 'GET_CURRENT_REPORT_STARTED',
  GET_CURRENT_REPORT_SUCCESS = 'GET_CURRENT_REPORT_SUCCESS',
  GET_CURRENT_REPORT_FAILURE = 'GET_CURRENT_REPORT_FAILURE',

  SET_IS_DESCRIPTION_VISIBLE = 'SET_IS_DESCRIPTION_VISIBLE',

  DELETING_REPORTS_STARTED = 'DELETING_REPORTS_STARTED',
  DELETING_REPORTS_SUCCESS = 'DELETING_REPORTS_SUCCESS',
  DELETING_REPORTS_FAILURE = 'DELETING_REPORTS_FAILURE',

  SET_NAME_CURRENT_REPORT = 'SET_NAME_CURRENT_REPORT',
  SET_GROUP_BY_CURRENT_REPORT = 'SET_GROUP_BY_CURRENT_REPORT',

  SET_SORT_BY_CURRENT_REPORT = 'SET_SORT_BY_CURRENT_REPORT',
  SET_DEFAULT_CURRENT_REPORT = 'SET_DEFAULT_CURRENT_REPORT',
  RESET_CURRENT_REPORT = 'RESET_CURRENT_REPORT',
  SET_ID_CURRENT_REPORT = 'SET_ID_CURRENT_REPORT',
  SET_REPORT_CATEGORY = 'SET_REPORT_CATEGORY',
  SET_REPORT_NEW_OWNER = 'SET_REPORT_NEW_OWNER',
  SET_REPORT_TYPE = 'SET_REPORT_TYPE',
  SET_CURRENT_TAB = 'SET_CURRENT_TAB',

  SET_REPORT_TIME_TRACKING_UNIT = 'SET_REPORT_TIME_TRACKING_UNIT',

  SET_COLUMNS = 'SET_COLUMNS',

  GET_CURRENT_REPORT_TABLE_DATA_STARTED = 'GET_CURRENT_REPORT_TABLE_DATA_STARTED',
  GET_CURRENT_REPORT_TABLE_DATA_SUCCESS = 'GET_CURRENT_REPORT_TABLE_DATA_SUCCESS',
  GET_CURRENT_REPORT_TABLE_DATA_FAILURE = 'GET_CURRENT_REPORT_TABLE_DATA_FAILURE',
  GET_CURRENT_REPORT_TABLE_DATA_FINISHED = 'GET_CURRENT_REPORT_TABLE_DATA_FINISHED',

  SET_DATES_RANGE_CURRENT_REPORT = 'SET_DATES_RANGE_CURRENT_REPORT',

  CREATE_REPORT_STARTED = 'CREATE_REPORT_STARTED',
  CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS',
  CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE',

  SAVE_REPORT_STARTED = 'SAVE_REPORT_STARTED',
  SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS',
  SAVE_REPORT_COMPLETE = 'SAVE_REPORT_COMPLETE',
  SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE',

  EDIT_REPORT_STARTED = 'EDIT_REPORT_STARTED',
  EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS',
  EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE',

  SET_LAST_REPORT = 'SET_LAST_REPORT',
  SET_RELATIVE_DATE = 'SET_RELATIVE_DATE',
  SET_PERMISSIONS = 'SET_PERMISSIONS',

  SAVE_PERMISSIONS_STARTED = 'SAVE_PERMISSIONS_STARTED',
  SAVE_PERMISSIONS_SUCCESS = 'SAVE_PERMISSIONS_SUCCESS',
  SAVE_PERMISSIONS_FAILURE = 'SAVE_PERMISSIONS_FAILURE',

  SET_IS_NEW_OWNER_SAVED = 'SET_IS_NEW_OWNER_SAVED',

  RESET_REPORTS_TO_INITIAL_DATA = 'RESET_REPORTS_TO_INITIAL_DATA',

  SET_REPORTS_LIST = 'SET_REPORTS_LIST',

  SET_AGGREGATIONS = 'SET_AGGREGATIONS',
  CHECK_IS_AVAILABLE_LAST_REPORT = 'CHECK_IS_AVAILABLE_LAST_REPORT',

  SET_TEMP_PERMISSIONS = 'SET_TEMP_PERMISSIONS',
  SET_REPORT_CREATED_BY = 'SET_REPORT_CREATED_BY',

  // Scripted Reports
  SET_KEY_CURRENT_REPORT = 'SET_KEY_CURRENT_REPORT',
  SET_DESCRIPTION_CURRENT_REPORT = 'SET_DESCRIPTION_CURRENT_REPORT',
  SET_TAGS_CURRENT_REPORT = 'SET_TAGS_CURRENT_REPORT',
  SET_ICON_CURRENT_REPORT = 'SET_ICON_CURRENT_REPORT',
  SET_CATEGORY_CURRENT_REPORT = 'SET_CATEGORY_CURRENT_REPORT',
  SET_TEMPLATE_CURRENT_REPORT = 'SET_TEMPLATE_CURRENT_REPORT',
  SET_EXTERNAL_EDITOR = 'SET_EXTERNAL_EDITOR',
  RESET_EXTERNAL_EDITOR = 'RESET_EXTERNAL_EDITOR',
  SET_EXTERNAL_EDITOR_SYNCING = 'SET_EXTERNAL_EDITOR_SYNCING',
  SET_SCRIPT_CURRENT_REPORT = 'SET_SCRIPT_CURRENT_REPORT',
  SET_SCRIPT_CALLBACK_CURRENT_REPORT = 'SET_SCRIPT_CALLBACK_CURRENT_REPORT',

  SET_INPUT_FIELDS_FORM_SCRIPTED_REPORTS = 'SET_INPUT_FIELDS_FORM_SCRIPTED_REPORTS',
  SET_INPUT_FIELDS_LIST_SCRIPTED_REPORTS = 'SET_INPUT_FIELDS_LIST_SCRIPTED_REPORTS',
  SET_INPUT_FIELDS_LIST_VALUES_SCRIPTED_REPORTS = 'SET_INPUT_FIELDS_LIST_VALUES_SCRIPTED_REPORTS',

  SET_REPORT_EXPORT_PANEL_VISIBILITY = 'SET_REPORT_EXPORT_PANEL_VISIBILITY',

  SET_GALLERY_REPORTS_FETCHED = 'SET_GALLERY_REPORTS_FETCHED',

  SET_CURRENT_LEVEL_INDEX = 'SET_CURRENT_LEVEL_INDEX',

  ADD_FAVORITE_REPORT_FRONT = 'ADD_FAVORITE_REPORT_FRONT',
  REMOVE_FAVORITE_REPORT_FRONT = 'REMOVE_FAVORITE_REPORT_FRONT',

  SET_SEARCH_STRING = 'SET_SEARCH_STRING',

  SET_IS_ONLY_FAVORITES = 'SET_IS_ONLY_FAVORITES',

  GET_ISSUES_BY_JQL = 'GET_ISSUES_BY_JQL',

  CHANGE_ISSUE_IN_TABLE_NAME = 'CHANGE_ISSUE_IN_TABLE_NAME',

  SET_IS_EXPORTING = 'SET_IS_EXPORTING',
  SET_IS_ATTACHING = 'SET_IS_ATTACHING',

  SET_ATTACHMENT_STATUS = 'SET_ATTACHMENT_STATUS',

  SET_REPORT_CURRENT_PERIOD = 'SET_REPORT_CURRENT_PERIOD',

  SET_SOURCES = 'SET_SOURCES',
  SAVE_SOURCE = 'SAVE_SOURCE',

  GET_CALENDAR_DATA_STARTED = 'GET_CALENDAR_DATA_STARTED',
  GET_CALENDAR_DATA_SUCCESS = 'GET_CALENDAR_DATA_SUCCESS',
  GET_CALENDAR_DATA_FAILURE = 'GET_CALENDAR_DATA_FAILURE',

  SET_HIERARCHY_TREE = 'SET_HIERARCHY_TREE',
  SET_HIERARCHY_LVLS_CURRENT_REPORT = 'SET_HIERARCHY_LVLS_CURRENT_REPORT',
  SET_HIERARCHY_GROUP_BY = 'SET_HIERARCHY_GROUP_BY',

  SET_REPORT_CALENDAR_VIEW = 'SET_REPORT_CALENDAR_VIEW',
  SET_IS_REPORT_WITH_WEEKENDS = 'SET_IS_REPORT_WITH_WEEKENDS',
  GET_LAST_VIEWED_REPORT_SUCCESS = 'GET_LAST_VIEWED_REPORT_SUCCESS',
  GET_LAST_VIEWED_REPORT_FAILURE = 'GET_LAST_VIEWED_REPORT_FAILURE',

  SET_IS_INCLUDES_SUBTASK_IN_REPORT = 'SET_IS_INCLUDES_SUBTASK_IN_REPORT',
  SET_IS_PARENTS_SHOULD_BE_FETCHED = 'SET_IS_PARENTS_SHOULD_BE_FETCHED',

  FETCH_CUSTOM_FIELDS_OPTIONS_SUCCESS = 'FETCH_CUSTOM_FIELDS_OPTIONS_SUCCESS',
  FETCH_CUSTOM_FIELDS_OPTIONS_FAILURE = 'FETCH_CUSTOM_FIELDS_OPTIONS_FAILURE',

  SET_IS_CHILDREN_SHOULD_BE_FETCHED = 'SET_IS_CHILDREN_SHOULD_BE_FETCHED',

  SET_REPORT_DATA_TYPE = 'SET_REPORT_DATA_TYPE',
  SET_REPORT_VIEW_TYPE = 'SET_REPORT_VIEW_TYPE',
  SET_AGGREGATION_TYPE = 'SET_AGGREGATION_TYPE',
  SET_AGGREGATION_TARGET = 'SET_AGGREGATION_TARGET',
  SET_REPORT_FILTERS = 'SET_REPORT_FILTERS',

  SET_IS_EDIT_MODE = 'SET_IS_EDIT_MODE',
  SET_IS_DEMO_MODE = 'SET_IS_DEMO_MODE',
  SET_DOCS_LINK = 'SET_DOCS_LINK',

  SET_SHAREABLE_LINK = 'SET_SHAREABLE_LINK',
  SET_SHARED_ID = 'SET_SHARED_ID',

  SET_TEMP_ISSUES_DATA = 'SET_TEMP_ISSUES_DATA',

  SET_COLOR_THEME = 'SET_COLOR_THEME',
  SET_UPDATED_VULNERABLE_FIELDS = 'SET_UPDATED_VULNERABLE_FIELDS',
  SET_IS_VULNERABLE_FIELDS_UPDATED = 'SET_IS_VULNERABLE_FIELDS_UPDATED',

  SET_IS_SECURE_ENVIRONMENT = 'SET_IS_SECURE_ENVIRONMENT',
  SET_SR_RENDER_ERROR = 'SET_SR_RENDER_ERROR',
  SET_SR_LOADING = 'SET_SR_LOADING',

  SET_VERSION_CURRENT_REPORT = 'SET_VERSION_CURRENT_REPORT',
}

export default ReportActionTypes;
