import { HashRouter, MemoryRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { ReportProgressBar } from 'components/ReportProgressBar/ReportProgressBar';
import useChangePage from 'hooks/useChangePage';
import { AppSpinner } from 'globalStyle';
import paths from 'router/routePaths';
import { ConnectRouter } from 'router/ConnectRouter';
import { sendPageOpenEvent } from 'features/amplitude/utils/sendPageOpenEvent';
import { useRouterLocation } from './useRouterLocation';
import { RouteWatcher } from 'router/RouteWatcher';
import { PostHogProvider } from 'components/PostHogProvider';

const ReportsDashboard = React.lazy(() => import('pages/ReportsDashboard'));
const Report = React.lazy(() => import('pages/ReportPage/ReportPage'));
const Configuration = React.lazy(() => import('pages/Configuration'));
const ScriptedReportsPage = React.lazy(() => import('pages/ScriptedReportPage'));
const ScriptedReportsGadget = React.lazy(() => import('pages/ScriptedReportGadget'));

const UniversalReportGadget = React.lazy(() => import('pages/UniversalReportGadget'));
const IssueExportPanel = React.lazy(() => import('pages/ScriptedReportsPanels/IssuePanel'));
const ProjectReport = React.lazy(() => import('pages/ProjectReport'));
const ProjectUrtReport = React.lazy(() => import('pages/ProjectUrtReport'));
const StartPage = React.lazy(() => import('pages/StartPage'));

// Check Known limitations from this article - https://elements-apps.com/blog/url-routing-connect-apps/
const checkIsMicrosoftDefenderActive = () => typeof __JSWRAPPER !== 'undefined';

const getCurrentRouter = (isExternalModule) => {
  const isMicrosoftDefenderActive = checkIsMicrosoftDefenderActive();

  if (window.AP && IS_CLOUD && !isMicrosoftDefenderActive && !isExternalModule) {
    return ConnectRouter;
  }

  if (window.ActonicRB?.isIssuePanel) {
    return MemoryRouter;
  }

  return HashRouter;
};

const InsideRouter = () => {
  const location = useLocation();

  useEffect(() => {
    sendPageOpenEvent(location.pathname);
  }, [location.pathname]);

  const { changePage } = useChangePage();

  useLayoutEffect(() => {
    if (window.ActonicRB?.isIssuePanel
      && !location.pathname.includes(paths.scriptedReportsIssuePicker)) {
      changePage(paths.scriptedReportsIssuePicker);
    }
  }, [changePage, location.pathname]);

  return (
    <ReportProgressBar top='15%' left='40%' />
  );
};

const AppRoutes = ({ history }) => {
  const { externalModule } = window;
  const isExternalModule = externalModule && externalModule.length > 0;

  const CurrentRouter = getCurrentRouter(isExternalModule);
  const { location, urlBasename, isLoading } = useRouterLocation();

  if (window.ActonicRB?.isIssuePanel) {
    return (
      <Suspense fallback={<AppSpinner />}>
        <CurrentRouter history={history}>
          <InsideRouter />
          <Routes>
            <Route
              path='*'
              element={<IssueExportPanel />}
            />
          </Routes>
        </CurrentRouter>
      </Suspense>
    );
  }

  if (isLoading) return (<AppSpinner />);

  return (
    <Suspense fallback={<AppSpinner />}>
      <CurrentRouter history={history} location={location} urlBasename={urlBasename}>
        <RouteWatcher />
        <InsideRouter />
        <PostHogProvider />
        <Routes>
          <Route
            exact
            path={paths.root}
            element={
              isExternalModule ? <Navigate to={decodeURIComponent(externalModule)} /> : <ReportsDashboard />
            }
          />

          <Route
            exact
            path={paths.reports}
            element={<ReportsDashboard />}
          />

          <Route
            exact
            path={paths.configuration}
            element={<Configuration />}
          />

          <Route
            exact
            path={paths.reportPage}
            element={<Report />}
          />

          <Route
            exact
            path={paths.universalReportGadget}
            element={<UniversalReportGadget />}
          />

          <Route
            exact
            path={paths.scriptedReportsDetailPage}
            element={<ScriptedReportsPage />}
          />

          <Route
            exact
            path={paths.scriptedReportsGadget}
            element={<ScriptedReportsGadget />}
          />

          <Route
            exact
            path={paths.scriptedReportsIssuePicker}
            element={<IssueExportPanel />}
          />

          <Route
            exact
            path={paths.projectReport}
            element={<ProjectReport />}
          />

          <Route
            exact
            path={paths.projectUniversalReport}
            element={<ProjectUrtReport />}
          />

          <Route
            exact
            path={paths.startPage}
            element={<StartPage />}
          />

          <Route
            exact
            path={paths.gettingStartedPage}
            element={<StartPage />}
          />

          <Route
            exact
            path={paths.timesheets}
            element={<StartPage />}
          />

          <Route
            exact
            path={paths.teams}
            element={<StartPage />}
          />

          <Route
            exact
            path={paths.teamPage}
            element={<StartPage />}
          />

          <Route
            exact
            path={paths.notFoundPage}
            element={<StartPage />}
          />

          <Route
            path='*'
            element={
              <Navigate to={paths.notFoundPage} />
            }
          />
        </Routes>
      </CurrentRouter>
    </Suspense>
  );
};

export default AppRoutes;
