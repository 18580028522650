const GET_GALLERY_REPORTS = 'GET_GALLERY_REPORTS';
const ADD_GALLERY_REPORT = 'ADD_GALLERY_REPORT';
const UPDATE_GALLERY_REPORT = 'UPDATE_GALLERY_REPORT';
const REMOVE_GALLERY_REPORT = 'REMOVE_GALLERY_REPORT';

export default {
  GET_GALLERY_REPORTS,
  ADD_GALLERY_REPORT,
  UPDATE_GALLERY_REPORT,
  REMOVE_GALLERY_REPORT,
};
